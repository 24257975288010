import { Vue } from 'vue-property-decorator'

export const INTERVAL_AUTO = -2
export const INTERVAL_MAX = -1
export const INTERVAL_AUTO_DATAPOINTS = 250

/**
 * Keeps the zoom state for a ChartTab and relays events between children
 */
export default class ChartState extends Vue {}
