import ThingReference from '@/components/shared/ThingReference'
import type { HysteresisRuleFunction } from '@/components/site/alerts/wizard/models/AlertRule'
import { AlertRule, Comparator } from '@/components/site/alerts/wizard/models/AlertRule'
import type { HysteresisAlertParamDTO } from '@/api/alarm/models/dto/AlertParamDTOs'
import type { Duration } from 'moment'
import moment from 'moment'
import type { SeverityLevel } from '@/api/alarm/models/dto/SeverityLevel'

export class HysteresisDownAlertRule {
  constructor(
    public statusPath: ThingReference | null,
    public hysteresis: number | null,
    public comparator: Comparator,
    public ruleFunction: HysteresisRuleFunction,
    public timespan: Duration | null,

    // severityThresholds
    public warning: number | null,
    public error: number | null,
    public critical: number | null,

    public activeSeverityThresholds: boolean[],

    public severity?: SeverityLevel,
  ) {}

  get isValid() {
    const isValidStatusPath =
      this.statusPath != null && this.statusPath.thingId != null && this.checkType()
    if (!isValidStatusPath) {
      return false
    }

    const isHysteresisDefined = this.hysteresis !== null

    return (
      isHysteresisDefined &&
      this.isAtLeastOneThresholdDefined &&
      this.noThresholdConflicts &&
      this.activatedThresholdsDefined
    )
  }

  get isAtLeastOneThresholdDefined() {
    return this.warning != null || this.error != null || this.critical != null
  }

  get activatedThresholdsDefined(): boolean {
    const thresholds = [this.warning, this.error, this.critical]

    if (!this.activeSeverityThresholds) {
      return false
    }

    for (const [index, threshold] of thresholds.entries()) {
      const thresholdActiveButNotDefined = this.activeSeverityThresholds[index] && threshold == null
      if (thresholdActiveButNotDefined) {
        return false
      }
    }
    return true
  }

  get noThresholdConflicts(): boolean {
    const thresholds: number[] = []

    if (this.warning != null) {
      thresholds.push(this.warning)
    }
    if (this.error != null) {
      thresholds.push(this.error)
    }
    if (this.critical != null) {
      thresholds.push(this.critical)
    }

    // noinspection UnnecessaryLocalVariable as it improves code readability
    const thresholdsDescending = thresholds.every(function (threshold, index) {
      return index === 0 || threshold < thresholds[index - 1]
    })

    return thresholdsDescending
  }

  static default() {
    const activeSeverityThresholds = [true, true, true]

    return new HysteresisDownAlertRule(
      null,
      null,
      Comparator.LESS,
      'mean',
      moment.duration(15, 'minutes'),
      null,
      null,
      null,
      activeSeverityThresholds,
    )
  }

  static fromObject(
    object: Omit<
      HysteresisDownAlertRule,
      | 'isValid'
      | 'checkBooleanConstraints'
      | 'checkType'
      | 'checkComparatorConstraints'
      | 'toDto'
      | 'checkSupportedType'
      | 'convertPercentageFormat'
      | 'convertAlertThreshold'
      | 'activatedThresholdsDefined'
      | 'noThresholdConflicts'
      | 'isAtLeastOneThresholdDefined'
    >,
  ): HysteresisDownAlertRule {
    return new HysteresisDownAlertRule(
      object.statusPath,
      object.hysteresis,
      object.comparator,
      object.ruleFunction,
      object.timespan,
      object.warning,
      object.error,
      object.critical,
      object.activeSeverityThresholds,
      object.severity,
    )
  }

  static fromDTO(object: HysteresisAlertParamDTO): HysteresisDownAlertRule {
    return new HysteresisDownAlertRule(
      ThingReference.fromString(object.property_path),
      object.hysteresis,
      object.comparator,
      object.function,
      moment.duration(object.timespan, 's'),
      object.warning ?? null,
      object.error ?? null,
      object.critical ?? null,
      [object.warning != null, object.error != null, object.critical != null],
      object.severity,
    )
  }

  /**
   * Checks type in status path
   */
  checkType() {
    return (
      this.statusPath != null &&
      this.statusPath.thingId != null &&
      ['number', 'int', 'float', 'double'].includes(this.statusPath.type ?? '')
    )
  }

  toDto(): HysteresisAlertParamDTO {
    // copied so the value in the modal is not updated
    const ruleCopy: HysteresisDownAlertRule = HysteresisDownAlertRule.fromObject({
      ...JSON.parse(JSON.stringify(this)),
      statusPath: this.statusPath,
      hysteresis: this.hysteresis,
      comparator: this.comparator,
      function: this.ruleFunction,
      timespan: this.timespan,
      warning: this.warning,
      error: this.error,
      critical: this.critical,
      severity: this.severity,
    })
    ruleCopy.convertPercentageFormat(false)

    return {
      property_path: ruleCopy.statusPath?.toRefString() ?? '',
      hysteresis: ruleCopy.hysteresis ?? 0,
      comparator: ruleCopy.comparator,
      function: ruleCopy.ruleFunction,
      timespan: ruleCopy.timespan?.as('seconds'),
      warning: ruleCopy.warning ?? undefined,
      error: ruleCopy.error ?? undefined,
      critical: ruleCopy.critical ?? undefined,
      severity: ruleCopy.severity,
    }
  }

  /**
   * See {@link #AlertRule.convertPercentageFormat}
   */
  public convertPercentageFormat(scaleUp: boolean) {
    if (this.statusPath?.unit !== 'percent') {
      return
    }

    // @ts-ignore
    this.hysteresis = AlertRule.convertPercentageThreshold(this.hysteresis, scaleUp)

    if (this.warning != null) {
      // @ts-ignore
      this.warning = AlertRule.convertPercentageThreshold(this.warning, scaleUp)
    }

    if (this.error != null) {
      // @ts-ignore
      this.error = AlertRule.convertPercentageThreshold(this.error, scaleUp)
    }

    if (this.critical != null) {
      // @ts-ignore
      this.critical = AlertRule.convertPercentageThreshold(this.critical, scaleUp)
    }
  }
}
