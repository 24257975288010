import { createModule, extractVuexModule } from 'vuex-class-component'
import { Timespan } from '@/@types/Timespan'
import { config } from '@/config'
import { mutation } from 'vuex-class-component/dist'
import { INTERVAL_AUTO } from '@/components/site/charts/chartviewtab/ChartState'
import moment from 'moment/moment'

const ChartModule = createModule({
  namespaced: 'charts',
})

/** The interval in which the data is automatically updated [ms] */
export const AUTO_UPDATE_INTERVAL = 15_000

/** The default time span for new charts */
export const DEFAULT_CHART_TIMESPAN: moment.Duration = moment.duration(2, 'days')

/** The default aggregation interval for new charts */
export const DEFAULT_CHART_INTERVAL = INTERVAL_AUTO

export enum ChartSettings {
  Charts = 'charts',
  ChartOrder = 'order',
  Interval = 'interval',
  Timespan = 'timespan',
}

/**
 * Stores user specific settings in the local storage
 */
export class ChartStateStore extends ChartModule {
  private _changedSettings: string[] = []

  get settingsChanged() {
    return this._changedSettings.length > 0
  }

  get onlyTimeChanged() {
    return !this._changedSettings.includes(ChartSettings.Charts)
  }

  /**
   * Whether Zoom is applied to the charts
   */
  _zoomedOrShifted = false

  get zoomedOrShifted() {
    return this._zoomedOrShifted
  }

  /** Resembles the moment at which the interval was updated the last time
   *
   * If the timespan is relative _(e.g. last 3 hours)_ the endDate is null and not defined,
   * the getter {@link endDate} will just take the current timestamp.
   * This would lead to issues when shifting _(e.g. when the page is not
   * reloaded for a longer time)_, so we take the value of the last loaded end date.
   */
  private _currentlyDisplayedEndDate = moment()

  get currentlyDisplayedEndDate() {
    return this._currentlyDisplayedEndDate
  }

  set currentlyDisplayedEndDate(displayedEndDate: moment.Moment) {
    this._currentlyDisplayedEndDate = displayedEndDate
  }

  /**
   *  Timespan of the displayed data
   */
  private _timeSpan: Timespan | null = new Timespan(null, DEFAULT_CHART_TIMESPAN)

  get timeSpan(): Timespan | null {
    return this._timeSpan
  }

  set timeSpan(timespan: Timespan | null) {
    this._timeSpan = timespan
    this._zoomedOrShifted = false // Reset Zoom or Shift on new Timespan
  }

  /**
   * Sampling interval for displayed data
   */
  private _interval: number = DEFAULT_CHART_INTERVAL

  get interval() {
    return this._interval
  }

  /**
   * Timespan to display when Zoom is applied
   */
  private _zoomedOrShiftedTimeSpan: Timespan | null = null

  get zoomedOrShiftedTimeSpan() {
    return this._zoomedOrShiftedTimeSpan
  }

  /** Sampling rate for data when Zoom is applied */
  private _zoomedInterval = DEFAULT_CHART_INTERVAL

  get zoomedInterval() {
    return this._zoomedInterval
  }

  get displayedChartRange() {
    return {
      interval: this.zoomedOrShifted ? this.zoomedInterval : this.interval,
      timespan: this.zoomedOrShifted ? this.zoomedOrShiftedTimeSpan : this.timeSpan,
    }
  }

  private _autoUpdate = false

  get autoUpdate() {
    return this._autoUpdate
  }

  @mutation
  setInterval(interval: number) {
    this._interval = interval
  }

  @mutation
  setZoomedOrShiftedTimespan(zoomedOrShiftedTimeSpan: Timespan) {
    this._zoomedOrShiftedTimeSpan = zoomedOrShiftedTimeSpan
    this._zoomedOrShifted = true
    this._autoUpdate = false
  }

  @mutation
  setZoomedOrShiftedInterval(zoomedInterval: number) {
    this._zoomedInterval = zoomedInterval
    this._zoomedOrShifted = true
    this._autoUpdate = false
  }

  /**
   * Called when the Chart is zoomed with the given parameters
   * @param params
   */
  @mutation
  zoom(params: { zoomedInterval: number; zoomedTimespan: Timespan }) {
    this._zoomedInterval = params.zoomedInterval
    this._zoomedOrShiftedTimeSpan = params.zoomedTimespan
    this._zoomedOrShifted = true
    this._autoUpdate = false
  }

  @mutation
  init() {
    this._zoomedOrShiftedTimeSpan = new Timespan(null, moment.duration(0, 'second'))
    this._zoomedInterval = INTERVAL_AUTO
    this.timeSpan = new Timespan(null, DEFAULT_CHART_TIMESPAN)
    this._interval = DEFAULT_CHART_INTERVAL
    this._changedSettings = []
    this._autoUpdate = false
  }

  /** Reset zoom does not just reset the zoom but also fetch the latest data
   * due to the getter implementation of {@link Timespan#endDate} - this is intended as e.g. for
   * {@link LineChart} the interval in which the data is displayed and loaded may change*/
  @mutation
  public resetZoom() {
    this._zoomedOrShifted = false
  }

  @mutation
  public setSettingsChanged(changed: string) {
    if (!this._changedSettings.includes(changed)) {
      this._changedSettings.push(changed)
    }
  }

  @mutation
  public resetChangedSettings() {
    this._changedSettings = []
  }

  @mutation
  setAutoUpdate(autoUpdate: boolean) {
    this._autoUpdate = autoUpdate
  }
}

export const charts = extractVuexModule(ChartStateStore)
