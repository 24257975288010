<template>
  <span class="icon">
    <span
      v-if="hasIcon"
      :class="{
        'text-danger': unit === 'error',
        'text-warning': unit === 'warning',
        'text-info': unit === 'info',
      }"
      class="material-icons"
    >
      {{ icon }}
    </span>
    <span v-if="hasUnit && !hasIcon" class="mr-2 unit">
      {{ unitSymbol }}
    </span>
    <span v-if="propertyType === 'configuration'" class="material-icons setting-modifier">
      settings
    </span>
  </span>
</template>

<script lang="ts">
  const convert = require('@othermo/convert-units')

  const UNIT_ICONS: Record<string, string> = {
    message: 'format_list_bulleted',
    error: 'report',
    warning: 'warning_amber',
    info: 'info_outline',
    number: 'pin',
    seconds: 'schedule',
    UnixTime: 'schedule',
    ISO8601time: 'schedule',
    ISO8601: 'schedule',
  }

  const UNIT_TEXTS: Record<string, string> = {
    percent: '%',
    text: 'txt',
  }

  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class UnitIcon extends Vue {
    @Prop()
    readonly unit!: string

    @Prop()
    readonly propertyType!: 'configuration' | 'status'

    @Prop()
    readonly type!: string

    get icon() {
      if (this.type == 'boolean' && this.unit == 'state') {
        return 'toggle_off'
      }
      return UNIT_ICONS[this.unit]
    }

    get hasIcon() {
      return this.icon != undefined
    }

    get unitSymbol() {
      try {
        return convert().describe(this.unit)?.symbol ?? this.unit
      } catch (e) {
        return UNIT_TEXTS[this.unit] ?? ''
      }
    }

    get hasUnit() {
      return this.unitSymbol != undefined
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  .icon {
    display: inline-block;
    position: relative;
    text-align: center;
    width: 2em;
    color: $gray-600;
  }

  .setting-modifier {
    position: absolute;
    font-size: 0.8em;
    bottom: -0.4em;
    right: 0;
    text-shadow: 0 0 3px #ffffff;
  }

  .unit {
    display: inline-block;
    width: 2em;
    margin: 0;
    color: $gray-600;
  }
</style>
