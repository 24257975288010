<template>
  <svg
    viewBox="0 0 15.875 15.875"
    xmlns="http://www.w3.org/2000/svg"
    width="70px"
    :class="rotation"
  >
    <circle
      cx="8.045"
      cy="7.828"
      transform="rotate(.783) skewX(.003)"
      r="5.16"
      class="circle"
      :class="circleClasses"
    />
    <path
      transform="matrix(1.0507 .00136 -.00018 1.03254 -47.25 -50.464)"
      d="m57.247 56.476-7.155 4.13v-8.26z"
      class="pump-triangle"
      :class="triangleClass"
    />
    <path d="M2.646 7.937H0M13.23 7.937h2.645" class="connector" />
  </svg>
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class Pump extends Vue {
    @Prop({ required: false, default: false })
    readonly running!: boolean | null

    @Prop({ required: false, default: () => false })
    readonly hasWarning!: boolean

    @Prop({ required: false, default: () => false })
    readonly hasError!: boolean

    @Prop()
    readonly rotation!: string

    get circleClasses() {
      if (this.hasError) {
        return ['fill-error']
      }
      if (this.hasWarning) {
        return ['fill-warning']
      }
      return []
    }

    get triangleClass() {
      if (this.running === null) {
        return ''
      }
      if (this.running) {
        return 'running'
      }
      return 'stopped'
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  .connector {
    stroke: #000;
    stroke-width: 1px;
  }

  .circle {
    fill: #fff;
    stroke: #000;
    stroke-width: 0.264583px;
    stroke-miterlimit: 4;
  }

  .pump-triangle {
    fill: #fff;
    stroke: #000;
    stroke-width: 0.25402;
    stroke-miterlimit: 4;
  }

  .running {
    fill: green;
  }

  .stopped {
    fill: grey;
  }

  .fill-error {
    fill: red;
  }

  .fill-warning {
    fill: $warning;
  }

  .south {
    transform: rotate(90deg);
  }

  .west {
    transform: rotate(180deg);
  }

  .north {
    transform: rotate(270deg);
  }
</style>
