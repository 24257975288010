import ThingReference from '@/components/shared/ThingReference'
import type { SeverityLevel } from '@/api/alarm/models/dto/SeverityLevel'
import type { Duration } from 'moment'
import moment from 'moment'
import type { AlertRuleFunction } from '@/components/site/alerts/wizard/models/AlertRule'
import {
  AlertRule,
  ALLOWED_TYPES,
  Comparator,
} from '@/components/site/alerts/wizard/models/AlertRule'
import type { CascadeAlertParamDTO } from '@/api/alarm/models/dto/AlertParamDTOs'

export class CascadeAlertRule extends AlertRule {
  constructor(
    public propertyPath: ThingReference | null,
    public threshold: number | [number, number] | boolean | null,
    public comparator: Comparator,
    public func: AlertRuleFunction,
    public timespan: Duration,
    public severity: SeverityLevel,
  ) {
    super(timespan, severity)
  }

  get isValid() {
    return (
      this.threshold != null &&
      this.checkType() &&
      this.checkComparatorConstraints() &&
      this.checkBooleanConstraints()
    )
  }

  static default() {
    return new CascadeAlertRule(
      null,
      0,
      Comparator.EQUAL,
      'mean',
      moment.duration(15, 'minutes'),
      'error',
    )
  }

  static fromDTO(object: CascadeAlertParamDTO): CascadeAlertRule {
    return new CascadeAlertRule(
      ThingReference.fromString(object.propertyPath),
      object.threshold,
      object.comparator,
      object.function as AlertRuleFunction,
      moment.duration(object.timespan, 's'),
      object.severity,
    )
  }

  static fromObject(
    object: Omit<
      CascadeAlertRule,
      | 'isValid'
      | 'checkBooleanConstraints'
      | 'checkType'
      | 'checkComparatorConstraints'
      | 'toDto'
      | 'convertPercentageFormat'
      | 'convertAlertThreshold'
    >,
  ): CascadeAlertRule {
    return new CascadeAlertRule(
      object.propertyPath,
      object.threshold,
      object.comparator,
      object.func,
      object.timespan,
      object.severity,
    )
  }

  /**
   * Checks if this alert fulfills constraints for boolean if it is of boolean type
   */
  private checkBooleanConstraints(): boolean {
    return !(
      this.propertyPath?.type == 'boolean' &&
      (this.comparator != Comparator.EQUAL || typeof this.threshold != 'boolean')
    )
  }

  /**
   * Checks constraints on comparator if applicable
   */
  private checkComparatorConstraints(): boolean {
    return !(
      (this.comparator == Comparator.IN_RANGE || this.comparator == Comparator.OUT_OF_RANGE) &&
      typeof this.threshold != 'object'
    )
  }

  /**
   * Checks type in property path
   */
  private checkType(): boolean {
    return this.propertyPath != null && ALLOWED_TYPES.includes(this.propertyPath.type ?? '')
  }

  toDto() {
    // copied so the value in the modal is not updated
    const ruleCopy: CascadeAlertRule = CascadeAlertRule.fromObject({
      ...JSON.parse(JSON.stringify(this)),
      propertyPath: this.propertyPath,
      timespan: this.timespan,
    })
    ruleCopy.convertPercentageFormat(false)

    return {
      propertyPath: ruleCopy.propertyPath?.toRefString() ?? '',
      threshold: ruleCopy.threshold,
      comparator: ruleCopy.comparator.toString(),
      function: ruleCopy.func,
      timespan: ruleCopy.timespan.as('seconds'),
      severity: ruleCopy.severity,
    }
  }

  /**
   * See {@link #AlertRule.convertPercentageFormat}
   */
  convertPercentageFormat(scaleUp: boolean) {
    if (this.propertyPath?.unit === 'percent' && this.threshold) {
      // @ts-ignore
      this.threshold = AlertRule.convertPercentageThreshold(this.threshold, scaleUp)
    }
  }
}
