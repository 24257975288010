import { Attribute, safeGet } from '@/modules/svgbindings/BindingParser'
import { BoolVisibilityBinding } from '@/modules/svgbindings/impl/BoolVisibilityBinding'
import type { SVGBinding } from '@/modules/svgbindings/SVGBinding'
import type { IBindingVisitor } from '@/modules/svgbindings/visitors/binding/IBindingVisitor'

/**
 * Visits an element and checks if it has a NumberTextBinding
 */
export default class BoolVisibilityVisitor implements IBindingVisitor {
  visit(element: Element, path: string): SVGBinding | undefined {
    if (element.hasAttribute(Attribute.VISIBILITY)) {
      const visibleIf = safeGet(element, Attribute.VISIBILITY)!.toUpperCase() === 'TRUE'
      return new BoolVisibilityBinding(element, path, visibleIf)
    }
  }
}
