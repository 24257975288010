import { config } from '@/config'
import { getSeverityColorMapping } from '@/components/site/charts/chartviewtab/ColorConstants'
import type { Language } from '@/modules/translations/PropertyTranslationService'
import Logger from '@/logger'

/** Translations for a single enum value */
export interface EnumEntry {
  severity: string

  de?: string

  en?: string

  color?: string
}

export type EnumDescription = Record<string, EnumEntry>

export default class EnumValueTranslationService {
  private static instance: EnumValueTranslationService | null = null

  private enumTranslations: Record<string, Record<string, EnumEntry>> = {}

  private constructor() {}

  static getInstance(): EnumValueTranslationService {
    if (this.instance === null) {
      this.instance = new EnumValueTranslationService()
    }
    return this.instance
  }

  /**
   * Fetches enum translations from the backend server.
   *
   * @param {string} authToken - The authentication token (JWT) to authorize the fetch request
   * @param {string} realm - Not used by the backend, only appended to the request URL to avoid caching responses from different realms.
   * @param {boolean} forceReload - If true, forces the browser to bypass cache and fetch from server.
   *
   * @returns {Promise<void>}
   */
  public async fetchTranslations(
    authToken: string,
    realm: string = '',
    forceReload: boolean = false,
  ): Promise<void> {
    try {
      const result = await fetch(
        `${config.api.klempner.url}translations/value-names?realm=${realm}`,
        {
          headers: { authorization: 'Bearer ' + authToken },
          cache: forceReload ? 'reload' : 'default',
        },
      )
      this.enumTranslations = await result.json()
    } catch (error) {
      Logger.error(error)
    }
  }

  /**
   * Returns all possible enum values with their full description for a given model.
   *
   * @param {string} type - The type of the enum.
   *
   * @returns {EnumDescription | null} An object containing the descriptions of the enum values if found, else null.
   */
  public getPossibleValuesForEnum(type: string): EnumDescription | null {
    const resolvedEnum = this.enumTranslations[type.toLowerCase()]
    return resolvedEnum ?? null
  }

  public getNumEnums(): number {
    return Object.keys(this.enumTranslations).length
  }

  public get enums(): Record<string, Record<number, EnumEntry>> {
    return this.enumTranslations
  }

  /**
   * Retrieves information about a single enum value.
   *
   * @param {string} typeName - The name of the model.
   * @param {string} value - The enum index to look up.
   *
   * @returns {EnumEntry | null} The enum entry for the provided value if found, else null.
   */
  public getEnum(typeName: string, value: string): EnumEntry | null {
    const enumDescriptor = this.getPossibleValuesForEnum(typeName)
    if (enumDescriptor != null) {
      const enumEntry = enumDescriptor[value]
      if (enumEntry == undefined) {
        return null
      }
      return { color: getSeverityColorMapping(enumEntry.severity), ...enumDescriptor[value] }
    }
    return null
  }

  /**
   * Checks if an enum with the given name is known.
   *
   * @param {string} typeName - The name of the model.
   *
   * @returns {boolean} true if the enum with the provided name is known, else false.
   */
  public isKnownEnum(typeName: string): boolean {
    return this.enumTranslations[typeName.toLowerCase()] !== undefined
  }

  /**
   * This method translates a value associated with an enum from a specified model using the specified language.
   * If the translation is not available in the specified language, it falls back to a default value.
   *
   * @public
   * @param {string | null | undefined} typeName - The name of the model where the enum is defined.
   * @param {string} value - The value to be translated.
   * @param {Language} language - The language to use for the translation.
   * @param {string} [fallback=''] - A fallback value that is returned in case the enum has no translation.
   *
   * @returns {string} Translated ENUM value in the specified language, or the fallback.
   *
   * @example
   * translateEnum('UserModel', 'userStatus', 'active', 'en', 'Unknown Status');
   *
   * // This will try to find the 'ACTIVE' value in the 'userStatus' ENUM of 'UserModel' using English.
   * // If the translation is not available in English, it will return 'Unknown Status'.
   */
  public translateEnum(
    typeName: string | null | undefined,
    value: string,
    language: Language,
    fallback: string = '',
  ): string {
    if (typeName == null) {
      return fallback
    }
    const enumEntry = this.getEnum(typeName, value)
    if (enumEntry != null) {
      return enumEntry[language] ?? fallback
    }
    return fallback
  }
}
