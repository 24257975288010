import type IUnitHandler from '@/modules/units/handlers/IUnitHandler'
import EnumValueTranslationService from '@/modules/translations/EnumValueTranslationService'
import type { Language } from '@/modules/translations/PropertyTranslationService'

export default class EnumHandler implements IUnitHandler {
  private enumTranslatorInstance = EnumValueTranslationService.getInstance()

  format(type: string, unit: string, value: any, locale: string): string {
    const shortLocale = locale.substring(0, 2) as Language
    return this.enumTranslatorInstance.translateEnum(
      type,
      String(value),
      shortLocale,
      String(value),
    )
  }

  formatUnit(type: string, unit: string, locale: string): string {
    return unit
  }

  isSupported(type: string, unit: string): boolean {
    return (unit == 'message' || unit == 'string') && this.enumTranslatorInstance.isKnownEnum(type)
  }

  scaleValue(value: number, scalingFactor: number): number {
    return value
  }

  getScaling(
    xValues: [string, number | null][][],
    unit: string,
  ): { best: any; scalingFactor: number } {
    return { best: { unit: unit, symbol: '' }, scalingFactor: 1 }
  }

  isDifferentiable(): boolean {
    return false
  }
}
