export default class PercentageUtil {
  static readonly PERCENTAGE_SCALING_FACTOR: number = 100
  static readonly PERCENTAGE_DECIMAL_PLACES: number = 4

  /**
   * Rounds a number to the amount of decimal places and throws an error for invalid specified decimalPlaces.
   *
   * @param numberToRound
   * @param decimalPlaces that shall be considered after the . (no negative values allowed)
   * @return roundedNumber to the specified amount of decimalPlaces
   */
  public static roundDecimals(numberToRound: number, decimalPlaces: number): number {
    if (decimalPlaces < 0) {
      throw new Error('Invalid number of decimal places!')
    }
    const factor = Math.pow(10, decimalPlaces)
    return Math.round(numberToRound * factor) / factor
  }

  /**
   * Combines the scaling and rounding in one method for better extensibility
   */
  public static roundPercentage(percentageToRound: number, scaleUp: boolean): number {
    return this.roundDecimals(
      this.scaleUpOrDown(percentageToRound, scaleUp),
      this.PERCENTAGE_DECIMAL_PLACES,
    )
  }

  private static scaleUpOrDown(numberToScale: number, scaleUp: boolean): number {
    if (scaleUp) {
      return numberToScale * this.PERCENTAGE_SCALING_FACTOR
    } else {
      return numberToScale / this.PERCENTAGE_SCALING_FACTOR
    }
  }
}
