import { Component, Vue } from 'vue-property-decorator'
import { SiteApiClient } from '@/api/klempner/apiClients/SiteApiClient'
import { ThingApiClient } from '@/api/klempner/apiClients/ThingApiClient'
import { mixins } from 'vue-class-component'
import AuthMixin from '@/modules/tools/AuthMixin'
import { RecommendationApiClient } from "@/api/klempner/apiClients/RecommendationApiClient";

@Component
export default class ApiMixin extends mixins(AuthMixin) {
  private siteApiInstance: SiteApiClient | undefined
  private thingApiClientInstance: ThingApiClient | undefined
  private recommendationApiClientInstance: RecommendationApiClient | undefined

  public getThingApi() {
    if (this.thingApiClientInstance === undefined) {
      this.thingApiClientInstance = new ThingApiClient(() => this.token)
    }
    return this.thingApiClientInstance
  }

  public getSiteApiClient() {
    if (this.siteApiInstance === undefined) {
      this.siteApiInstance = new SiteApiClient(() => this.token)
    }
    return this.siteApiInstance
  }

  public getRecommendationApiClient() {
    if (this.recommendationApiClientInstance === undefined) {
      this.recommendationApiClientInstance = new RecommendationApiClient(() => this.token)
    }
    return this.recommendationApiClientInstance
  }
}
