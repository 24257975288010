import type { PluginObject } from 'vue'
import PropertyTranslationService from '@/modules/translations/PropertyTranslationService'
import EnumValueTranslationService from '@/modules/translations/EnumValueTranslationService'

export const ThingTranslationPlugin: PluginObject<any> = {
  install(Vue) {
    Vue.prototype.$propertyTranslation = PropertyTranslationService.getInstance()
    Vue.prototype.$valueTranslation = EnumValueTranslationService.getInstance()
  },
}
