<template>
  <b-button :to="to" class="mx-1 custom-button" @click="$emit('click')">
    <slot v-if="$slots.icon" name="icon"></slot>
    <span v-else class="material-icons align-text-top">{{ icon }}</span>
    <span v-if="(active && $slots.default) || showText" class="ml-lg-2">
      <slot></slot>
    </span>
    <b-badge v-if="count != null && count > 0" class="counter" size="sm" variant="secondary">
      {{ countText }}
    </b-badge>
  </b-button>
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class NavIconButton extends Vue {
    @Prop()
    readonly icon!: string

    @Prop()
    readonly to!: string | object

    @Prop({ required: false, default: null })
    readonly count!: number | null

    @Prop({ required: false, type: Boolean })
    readonly showText!: boolean

    get active() {
      return this.$route.fullPath === this.to
    }

    get countText() {
      if ((this.count ?? 0) > 99) {
        return '99+'
      }
      return this.count
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  .custom-button {
    border-radius: $border-radius * 3.5;
    background: none !important;
    border: none !important;
    color: $gray-800;
    position: relative;
    white-space: nowrap;

    &:hover,
    &.router-link-exact-active {
      color: $primary !important;
      background: desaturate(lighten($primary, 58%), 30%) !important;
    }

    &:focus:not(.router-link-exact-active) {
      color: $gray-800;
    }
  }

  .counter {
    background: $gray-600;
  }
</style>
