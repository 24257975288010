<template>
  <div :class="rotationClassList" class="sensor-reading-container">
    <div :class="lineOrientationClasses" class="mr-1 name">{{ description }}</div>
    <div class="current-value">{{ displayValue }}</div>
    <div :class="lineOrientationClasses" class="sensor-reading-connection"></div>
    <div :class="lineOrientationClasses" class="connections"></div>
    <Socket
      v-socket:input="input"
      :left="socketPositions.input.left"
      :name="input.name"
      :top="socketPositions.input.top"
      type="input"
    />
    <Socket
      v-socket:output="output"
      :left="socketPositions.output.left"
      :name="output.name"
      :top="socketPositions.output.top"
      type="output"
    />
  </div>
</template>
<script lang="ts">
  //@ts-ignore
  import VueRender from 'rete-vue-render-plugin'
  //@ts-ignore
  import AreaPlugin from 'rete-area-plugin'
  //@ts-ignore
  import ConnectionReroutePlugin from 'rete-connection-reroute-plugin'
  //@ts-ignore
  import ConnectionPathPlugin from 'rete-connection-path-plugin'
  import { Component, Watch } from 'vue-property-decorator'
  import type { Input, Node, NodeEditor, Output } from 'rete'
  import { mixins } from 'vue-class-component'
  import SiteTreeDropdown from '@/components/app/SiteThingsTree/SiteTreeDropdown.vue'
  import { vxm } from '@/store/store'
  import type { Thing } from '@/api/klempner/models/SiteListResponse'
  import type ThingReference from '@/components/shared/ThingReference'
  import Logger from '@/logger'
  import UnitHandler from '@/modules/units/UnitHandler'
  import Socket from '@/components/site/scada/editor-components/Socket.vue'

  @Component({
    components: { SiteTreeDropdown, Socket },
    filters: {
      round: Math.round,
    },
  })
  export default class SensorReadingComponent extends mixins(VueRender.mixin) {
    declare node: Node

    declare editor: NodeEditor

    declare inputs: () => Input[]

    declare outputs: () => Output[]

    protected value: unknown | null = null

    displayValue = ''

    protected unitHandler = UnitHandler.getInstance()

    get things(): Thing[] {
      return vxm.currentSite.things
    }

    get input() {
      return this.inputs()[0]
    }

    get output() {
      return this.outputs()[0]
    }

    get description(): string {
      return (this.node?.data?.measurementPointDescription as string) ?? ''
    }

    get selectedThing(): ThingReference | null {
      return (this.node?.data?.selectedThing as ThingReference) ?? null
    }

    get rotationClassList(): string {
      return this.node.data.rotation as string
    }

    get isVertical() {
      return ['north', 'south'].includes((this.node.data.rotation as string) ?? '')
    }

    get socketPositions() {
      const socketOffset = '2px'
      switch (this.node.data.rotation ?? 'west') {
        case 'north': // 270
          return {
            input: { left: '0%', top: socketOffset },
            output: {
              left: `100%`,
              top: socketOffset,
            },
          }
        default:
        case 'west': // 180
          return {
            input: { left: socketOffset, top: '0%' },
            output: {
              left: socketOffset,
              top: '100%',
            },
          }
        case 'south': // 90
          return {
            input: {
              left: '0',
              top: `calc(100%  - ${socketOffset})`,
            },
            output: {
              left: '100%',
              top: `calc(100% - ${socketOffset})`,
            },
          }
        case 'east': // 0
          return {
            input: {
              top: '0',
              left: `calc(100%  - ${socketOffset})`,
            },
            output: {
              left: `calc(100%  - ${socketOffset})`,
              top: '100%',
            },
          }
      }
    }

    get lineOrientationClasses() {
      return { vertical: this.isVertical, horizontal: !this.isVertical }
    }

    async mounted() {
      await this.node.update()
      this.editor.view.updateConnections({ node: this.node })
    }

    updated() {
      this.editor.view.updateConnections({ node: this.node })
    }

    @Watch('things', { deep: true })
    currentValueFormatted() {
      this.setUnformattedValue()

      if (this.selectedThing?.unit === undefined || this.selectedThing.type === undefined) {
        Logger.warn(`The thing ${this.selectedThing?.thingId} does not have the unit and type set.`)
        return ''
      }

      this.displayValue = this.unitHandler.format(
        this.selectedThing.unit,
        this.selectedThing.type,
        this.value,
      )
    }

    @Watch('node.data.rotation')
    @Watch('value')
    async rotationChanged() {
      await this.node.update()
      this.editor.view.updateConnections({ node: this.node })
    }

    setUnformattedValue() {
      if (!this.selectedThing) {
        return
      }

      if (
        this.selectedThing.feature === undefined ||
        this.selectedThing.featureProperty === undefined
      ) {
        Logger.warn(
          `The thing ${this.selectedThing.thingId} does not have the feature and featureProperty set.`,
        )
        return
      }

      const thing: Thing | undefined = this.things.find(
        (thing: Thing) => this.selectedThing?.thingId === thing.id,
      )

      this.value =
        thing?.features?.[this.selectedThing.feature]?.properties.status[
          this.selectedThing.featureProperty
        ]?.value
    }
  }
</script>

<style lang="scss" scoped>
  @import 'SensorReading';

  .sensor-reading-container.north {
    .name {
      transform: translate(-50%, 0);
      top: 100%;
      left: 50%;
    }
  }

  .sensor-reading-container.south {
    .name {
      transform: translate(-50%, -100%);
      top: 0;
      left: 50%;
    }
  }

  .connections {
    border: 2px solid gray;
    position: relative;

    &.horizontal {
      width: 0;
      height: 50px;
    }

    &.vertical {
      height: 0;
    }

    &.horizontal::after {
      content: '';
      position: relative;

      border-width: 16px 12px;
      border-style: solid;

      top: 65%;
      right: 12px;

      border-color: grey transparent transparent transparent;
    }

    &.vertical::after {
      content: '';
      position: relative;
      display: block;

      border-width: 12px 16px;
      border-style: solid;

      bottom: 12px;
      left: 40%;

      border-color: transparent transparent transparent grey;
    }
  }

  .socket {
    position: absolute;
  }
</style>
