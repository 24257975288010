<template>
  <!--This Template shows a dialog with minimal configuration-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting" />

      <Title>Lamp</Title>
      <Info>This is the lamp in the othermo office</Info>

      <toggle-item
        :value="thing.features.Lamp.properties.status.active"
        displayName="On"
        featureName="Lamp"
        propertyType="operation"
        propertyName="setState"
        msgParamName="active"
        toolTip="Is the lamp currently burning?"
      />
      <text-item
        :value="thing.features.Lamp.properties.configuration.description"
        displayName="Device Description"
        featureName="Lamp"
        toolTip="Set the description"
      />
      <Group title="Actions">
        <button-item
          featureName="Lamp"
          displayName="Toggle"
          propertyName="toggle"
          propertyType="operation"
        />
        <button-item
          featureName="Lamp"
          displayName="On"
          propertyName="on"
          propertyType="operation"
        />
        <button-item
          featureName="Lamp"
          displayName="Off"
          propertyName="off"
          propertyType="operation"
        />
      </Group>
    </template>
  </div>
</template>

<script lang="ts">
  // This code must not be touched when developing a new template except for the ClassName
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'

  /*REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      TextItem,
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  /*REPLACE 'TestId' WITH A FITTING COMPONENT NAME*/
  export default class ShowcaseLamp extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style lang="scss" scoped>
  /*The edit component can be styled here*/
</style>
