import moment from 'moment'
import { mixins } from 'vue-class-component'
import { Component, Vue } from 'vue-property-decorator'
import type IUnitHandler from '@/modules/units/handlers/IUnitHandler'
import TimeMixin from '@/modules/tools/TimeMixin'
import { AutoScale } from '@/modules/charts/AutoScale'

export const NUMBER_TYPES = ['float', 'double', 'int', 'long', 'integer', 'number']
export const SUPPORTED_UNITS = ['seconds', 'weekday', 'month']

@Component
export default class TimeHandler extends mixins(Vue, TimeMixin) implements IUnitHandler {
  readonly outputFormat = 'L LTS'

  format(
    type: string,
    unit: string,
    value: any,
    locale = 'de-DE',
    t?: (name: string) => string | undefined,
  ): string {
    switch (unit) {
      case 'UnixTime':
        return moment.unix(value).locale(locale).format(this.outputFormat)
      case 'ISO8601':
        return moment(value).locale(locale).format(this.outputFormat)
      case 'ISO8601time':
        return value
      case 'seconds':
        return this.formatSeconds(value)
      case 'weekday':
        return this.handleWeekday(value, t)
      case 'month':
        return this.handleMonth(value, t)
      case 'time':
        return this.formatTime(value)
      default:
        throw new Error(`Unsupported unit: ${unit}`)
    }
  }

  isSupported(type: string, unit: string): boolean {
    return (
      unit === 'UnixTime' ||
      unit === 'ISO8601' ||
      unit === 'ISO8601time' ||
      (SUPPORTED_UNITS.includes(unit) && NUMBER_TYPES.includes(type)) ||
      unit === 'time'
    )
  }

  formatUnit(type: string, unit: string, locale: string): string {
    return ''
  }

  scaleValue(value: number, scalingFactor: number): number {
    return value
  }

  getScaling(
    xValues: [string, number | null][][],
    unit: string,
  ): { best: any; scalingFactor: number } {
    const preciseBounds = AutoScale.getAxisBounds(xValues)

    return {
      best: { unit: unit, min: preciseBounds.min, max: preciseBounds.max },
      scalingFactor: 1,
    }
  }

  isDifferentiable(): boolean {
    return false
  }

  /**
   * Formats a number of seconds since midnight as hh:mm:ss
   */
  private formatTime(value: number) {
    const time = moment.duration(value, 'second')
    const hours = String(time.hours()).padStart(2, '0')
    const minutes = String(time.minutes()).padStart(2, '0')
    const seconds = String(time.seconds()).padStart(2, '0')
    return `${hours}:${minutes}:${seconds}`
  }

  private formatSeconds(value: number) {
    const units = ['h ', 'm ', 's']
    const components = [
      Math.floor(value / 3600),
      Math.floor((value % 3600) / 60),
      Math.floor(value % 60),
    ]

    let result = ''
    for (let i = 0; i < units.length; i++) {
      if (components[i] > 0) {
        result += components[i] + units[i]
      }
    }
    return result !== '' ? result.trim() : '0s'
  }

  private handleWeekday(value: number, t?: (name: string) => string | undefined): string {
    if (!t) {
      return value.toString()
    }
    const monthName = t(`weekday_names.${value}`)
    return monthName ?? ''
  }

  private handleMonth(value: number, t?: (name: string) => string | undefined): string {
    if (!t) {
      return value.toString()
    }
    const monthName = t(`month_names.${value}`)
    return monthName ?? ''
  }
}
