<template>
  <div class="mb-3">
    <h5 v-if="title != null">{{ title }}</h5>
    <div class="grouped p-2">
      <slot v-for="feature of matchingFeatures" :feature="feature"></slot>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
  import events from '@/events'
  import type { Feature } from '@/api/klempner/models/SiteListResponse'
  import { Thing } from '@/api/klempner/models/SiteListResponse'

  @Component({ name: 'MultiGroup' })
  export default class MultiGroup extends Vue {
    @Inject()
    submitEventBus!: Vue

    @Prop({ required: false, default: () => null })
    readonly title!: string | null

    @Prop({ required: true })
    readonly featureNamePrefix!: string

    @Prop({ required: true })
    readonly thing!: Thing

    get matchingFeatures(): Feature[] {
      if (this.thing?.features === undefined) {
        return []
      }
      return Object.values(this.thing.features)
        .filter((feature) => feature.name.startsWith(this.featureNamePrefix))
        .sort((feature1, feature2) => feature1.name.localeCompare(feature2.name))
    }

    mounted() {
      this.submitEventBus.$emit(events.scada.templates.mounted)
    }
  }
</script>

<style lang="scss" scoped></style>
