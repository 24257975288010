import type IUnitHandler from '@/modules/units/handlers/IUnitHandler'

export default class StateHandler implements IUnitHandler {
  format(type: string, unit: string, value: any, locale: string): string {
    return value ?? ''
  }

  isSupported(type: string, unit: string): boolean {
    return ['state', 'info', 'warning', 'error'].includes(unit)
  }

  formatUnit(type: string, unit: string, locale: string): string {
    return ''
  }

  scaleValue(value: number, scalingFactor: number): number {
    return value
  }

  getScaling(
    xValues: [string, number | null][][],
    unit: string,
  ): { best: any; scalingFactor: number } {
    return { best: { symbol: '', unit: unit }, scalingFactor: 1 }
  }

  isDifferentiable(): boolean {
    return false
  }
}
