import type { ThingProperty } from '@/api/klempner/models/SiteListResponse'

const errorUnits = ['info', 'warning', 'error']

export type PropertyInfo = { name: string; translatedName: string; property: ThingProperty }

export type PropertyCompareFunction = (propA: PropertyInfo, propB: PropertyInfo) => number

export function compareFeaturePropertyUnits(
  nameA?: string,
  unitA?: string,
  nameB?: string,
  unitB?: string,
): number {
  const aIndex = errorUnits.findIndex((s) => s == unitA ?? ''),
    bIndex = errorUnits.findIndex((s) => s == unitB ?? '')
  if (aIndex > -1 && bIndex > -1) {
    if (aIndex == bIndex) {
      return nameA?.localeCompare(nameB ?? '') ?? 0
    }
    return aIndex - bIndex
  } else if (aIndex > -1) {
    // a is a error/warning/info and b is not
    return 1
  } else if (bIndex > -1) {
    // b is a error/warning/info and a is not
    return -1
  }
  if (unitA == unitB) {
    return nameA?.localeCompare(nameB ?? '') ?? 0
  }
  return unitA?.localeCompare(unitB ?? '') ?? 1
}

export const propertyUnitCompareFunction: PropertyCompareFunction = (
  { translatedName: nameA, property: propA },
  { translatedName: nameB, property: propB },
) => compareFeaturePropertyUnits(nameA, propA.value_unit, nameB, propB.value_unit)

export const propertyNameCompareFunction: PropertyCompareFunction = (
  { translatedName: nameA },
  { translatedName: nameB },
) => nameA.localeCompare(nameB)
