import type { Node } from 'rete'
import { Component, Input, Output } from 'rete'
import type { NodeData, WorkerInputs, WorkerOutputs } from 'rete/types/core/data'
import Logger from '@/logger'
import SensorReadingComponent from '@/components/site/scada/editor-components/rete_components/SensorReadingComponent.vue'
import {
  getSocketByType,
  SocketType,
} from '@/components/site/scada/editor-components/KnownDevicesAndSockets'
import { ScadaReteComponents } from '@/components/site/scada/editor-components/rete_components/rete_nodes/ReteNode'

export default class ScadaReteSensorReadingComponent extends Component {
  constructor() {
    super(ScadaReteComponents.Sensor)
    // @ts-ignore
    this.data.component = SensorReadingComponent
  }

  async builder(node: Node) {
    const descriptor = node.data.deviceDefinition
    node.data.rotation = node.data.rotation ?? 'east'

    if (descriptor == undefined) {
      Logger.error(`unknown descriptor ${node.data.deviceDefinition}`)
      return
    }

    // @ts-ignore
    node.addInput(
      new Input(SocketType.universal, SocketType.universal, getSocketByType(SocketType.universal)),
    )

    // @ts-ignore
    node.addOutput(
      new Output(SocketType.universal, SocketType.universal, getSocketByType(SocketType.universal)),
    )
  }

  worker(node: NodeData, inputs: WorkerInputs, outputs: WorkerOutputs, ...args: unknown[]) {}
}
