import moment from 'moment'

export class Timespan {
  _endDate: moment.Moment | null

  duration: moment.Duration

  constructor(endDate: moment.Moment | null, duration: moment.Duration) {
    this._endDate = endDate
    this.duration = duration
  }

  public get isRelative(): boolean {
    return this._endDate == null
  }

  public get startDate(): moment.Moment {
    return this.endDate.clone().subtract(this.duration)
  }

  /**
   * returns endDate if it is set or current time for relative time spans
   */
  // TODO: I do not think that creating a new timestamp in the getter is a good approach
  public get endDate(): moment.Moment {
    if (this._endDate == null) {
      return moment()
    } else {
      return this._endDate.clone()
    }
  }

  /**
   * The "ActualEndDate" refers to handling null as such and not creating a new moment.
   * @return unix timestamp in milliseconds or null if {@link _endDate} is not defined
   */
  public getActualEndDateAsUnixTimestamp(): number | null {
    return this._endDate ? this._endDate.valueOf() : null
  }

  public static fromMsTimestamps(start: number, end: number) {
    const endDate = moment(end)
    const startDate = moment(start)
    const duration = moment.duration(endDate.diff(startDate))
    return new Timespan(endDate, duration)
  }

  public static fromMoments(start: moment.Moment, end: moment.Moment) {
    const duration = moment.duration(end.diff(start))
    return new Timespan(end.clone(), duration)
  }

  public toString() {
    return JSON.stringify(this)
  }

  public equals(other: Timespan) {
    if (
      (this._endDate == null && other._endDate != null) ||
      (this._endDate != null && other._endDate == null)
    ) {
      return false
    }
    return (
      ((this._endDate == null && other._endDate == null) ||
        this._endDate!.isSame(other._endDate)) &&
      this.duration.asSeconds() === other.duration.asSeconds()
    )
  }
}
