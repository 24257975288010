import type { SeriesDto } from '@/components/site/charts/ChartTypes'
import Logger from '@/logger'
import DerivativeUtil from '@/components/site/charts/chartviewtab/DerivativeUtil'
import type { SeriesRequestOptions } from '@/api/analytics/models/DataSeries'

const OLD_SERIES_DEFAULT_TYPE = 'status'

export default class ThingReference {
  constructor(
    public readonly thingId?: string,
    public readonly feature?: string,
    public readonly propertyType?: string,
    public readonly featureProperty?: string,
    public unit?: string,
    public type?: string,
  ) {}

  /**
   * The DittoReference is expected to have the format <thingId>/features/<featureName>/properties/<propertyType>/<propertyName>,
   * but the features part is always the same and simply required for the path in the Ditto REST API, so it's not stored in the DittoReference (if present)
   */
  static fromString(str: string): ThingReference {
    const parts = str.split('/')
    if (parts.length == 0) {
      return new ThingReference()
    }

    if (parts[1] == 'features') {
      return new ThingReference(parts[0], parts[2], parts[4], parts[5])
    }

    return new ThingReference(parts[0], parts[1], parts[3], parts[4])
  }

  /**
   * Constructs a new DittoReference from a string in the format thingid.feature.[status|configuration].property
   * @param str
   */
  static fromDotSeperatedString(str: string): ThingReference {
    const parts = str.split('.')
    if (parts == undefined) {
      return new ThingReference()
    }

    if (parts[1] == 'features') {
      return new ThingReference(parts[0], parts[2], parts[4], parts[5])
    }
    return new ThingReference(parts[0], parts[1], parts[2], parts[3])
  }

  toRefString(): string {
    let result = ''

    if (this.thingId != undefined) {
      result += this.thingId

      if (this.feature != undefined) {
        result += '/features/' + this.feature
      }

      if (this.featureProperty != undefined) {
        result += '/properties'
        if (this.propertyType != undefined) {
          result += '/' + this.propertyType
        }
        result += '/' + this.featureProperty
      }
    }

    return result
  }

  /**
   * DeltaResponseId has the format: thingId.feature.propertyType.propertyName <br>
   * _e.g.: c0011:24950GG83AF3.WiloPump.configuration.running_
   * @param seriesDto
   */
  public static buildDeltaResponseId(seriesDto: SeriesDto): string | null {
    const featureTypeAndName = ThingReference.parseFeatureProperty(seriesDto)

    if (!featureTypeAndName) {
      Logger.warn(`The response id of '${seriesDto.name}' could not be build.`)
      return null
    }

    return [
      seriesDto.query?.thingId,
      seriesDto.query?.feature,
      featureTypeAndName[0],
      featureTypeAndName[1],
    ].join('.')
  }

  public static buildHistoryResponseId(seriesDto: SeriesDto, aggregation?: string) {
    const featureTypeAndName = ThingReference.parseFeatureProperty(seriesDto)

    if (!featureTypeAndName) {
      Logger.warn(`The response id of '${seriesDto.name}' could not be build.`)
      return null
    }

    /** @ts-ignore the deriveUnit should be set */
    if (aggregation === 'derivative' && seriesDto.options?.deriveUnit !== undefined) {
      /** @ts-ignore the deriveUnit should be set */
      aggregation += DerivativeUtil.timeUnitAsSeconds(seriesDto.options.deriveUnit)
    }

    const responseParts = [
      seriesDto.query?.thingId,
      seriesDto.query?.feature,
      featureTypeAndName[0],
      featureTypeAndName[1],
    ]
    if (aggregation !== undefined) {
      responseParts.push(aggregation)
    }
    return responseParts.join('.')
  }

  public static buildHistoryResponseSeriesId(seriesInfo: SeriesRequestOptions) {
    let functionName = seriesInfo.function.functionName
    if (seriesInfo.function.functionName === 'derivative') {
      functionName += (seriesInfo.function.parameter as { literal: string }).literal
    }
    return [
      seriesInfo.series.thingId,
      seriesInfo.series.featureName,
      seriesInfo.series.propertyType,
      seriesInfo.series.propertyName,
      functionName,
    ].join('.')
  }

  /**
   * Old series might not have a property type as the property type has once always been 'status'.
   * <br>
   * _So the assumed format 'type/name' for featureProperty is not fulfilled_
   *
   * @param seriesDto of the series for which the feature property shall be retrieved
   * @return propertyType at [0] and propertyName at [1], otherwise null
   */
  public static parseFeatureProperty(seriesDto: SeriesDto): string[] | null {
    const featureTypeAndName: string[] = seriesDto.query?.featureProperty?.split('/') ?? []

    const expectedFormatNotMatched = featureTypeAndName?.length < 2
    if (expectedFormatNotMatched) {
      const onlyNameDefined = featureTypeAndName.length === 1
      if (onlyNameDefined) {
        Logger.warn(
          `The series '${seriesDto.name}' is assumed to have the type '${OLD_SERIES_DEFAULT_TYPE}'.`,
        )
        featureTypeAndName[1] = featureTypeAndName[0]
        featureTypeAndName[0] = OLD_SERIES_DEFAULT_TYPE
        return featureTypeAndName
      } else {
        Logger.error(
          `For series '${seriesDto.name}' property type and name could not be retrieved.`,
        )
        return null
      }
    }

    return featureTypeAndName
  }
}
