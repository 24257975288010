import type DomVisitor from '@/modules/svgbindings/DomVisitor'
import { Attribute, SVG_ERROR_PREFIX } from '@/modules/svgbindings/BindingParser'
import Logger from '@/logger'

/**
 * Visits a dom element and determines if it has a template action attached
 */
export default class ActionVisitor implements DomVisitor {
  private clickableElements: Element[] = []

  collectResults(): Element[] {
    return this.clickableElements
  }

  visit(element: Element): void {
    if (element.hasAttribute(Attribute.TEMPLATE_ID)) {
      if (element.hasAttribute(Attribute.THING_ID)) {
        this.clickableElements.push(element)
      } else {
        Logger.warn(
          SVG_ERROR_PREFIX + 'othermo:templateId found but missing required othermo:thingId',
          element,
        )
      }
    } else if (element.hasAttribute(Attribute.THING_ID)) {
      Logger.warn(
        SVG_ERROR_PREFIX +
          'found othermo:thingid, but to bind an action othermo:templateId is required.',
        element,
      )
    }
  }
}
