import { action, createModule, extractVuexModule } from 'vuex-class-component'
import { SiteConnectionStatus } from '@/api/klempner/models/SiteListResponse'
import type { SiteFilterSeverityLevel } from '@/api/klempner/apiClients/SiteFilterOptionsBuilder'

// Store for relevant/ shared variables among the components of the dashboard alarm view with alarm options
const DashboardFilterStoreModule = createModule({
  namespaced: 'dashboardFilterStore',
})

export enum SiteMaintenanceStatus {
  NONE = 'none',
  MAINTENANCE = 'maintenance',
}

/** maintenanceStatus: possible maintenance modes
 * For better maintainability this variable is an array and not boolean.
 * If another status is added to this category it is easier to include it, if this variable is already an array and not boolean.*/
export const MAINTENANCE_STATES: SiteMaintenanceStatus[] = [
  SiteMaintenanceStatus.NONE,
  SiteMaintenanceStatus.MAINTENANCE,
]

export const CONNECTION_STATES: SiteConnectionStatus[] = [
  SiteConnectionStatus.DISCONNECTED,
  SiteConnectionStatus.CONNECTED,
]

export type SortField = 'severity' | 'created_at' | 'name'

export type SortDirection = 'asc' | 'desc'

export interface SortOption {
  field: SortField

  direction: SortDirection
}

export class DashboardFilterStore extends DashboardFilterStoreModule {
  /** includeSeverities: severities selected by user in Dropdown */
  includeSeverities: SiteFilterSeverityLevel[] = []

  /** includeConnectionStatus: connection status modes selected by user in Dropdown*/
  includeConnectionStates: SiteConnectionStatus[] = [...CONNECTION_STATES]

  /** maintenance modes selected by user*/
  includeMaintenanceStates: SiteMaintenanceStatus[] = [...MAINTENANCE_STATES]

  selectedSortOption: SortOption = { field: 'name', direction: 'asc' }

  searchString: string = ''

  @action
  async resetFilter() {
    this.selectedSortOption = { field: 'name', direction: 'asc' }
    this.includeSeverities = []
    this.includeConnectionStates = [...CONNECTION_STATES]
    this.includeMaintenanceStates = [...MAINTENANCE_STATES]
    this.searchString = ''
  }
}

export const alarmDashboardStore = extractVuexModule(DashboardFilterStore)
