<template>
  <!--  This is a test template for development-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting"></SubmitOverlay>

      <Title>Heizkreis</Title>
      <Info>Stellen Sie hier die Parameter des Weishaupt Heizkreises ein.</Info>
      <Group title="Einstellungen">
        <enum-item
          :featureName="featureId"
          :map="[
            ['0', 'Konstanttemperatur'],
            ['1', 'Witterungsführung'],
          ]"
          :value="thing.features[featureId].properties.configuration.controlMode"
          displayName="Regelvariante"
          propertyType="configuration"
          propertyName="controlMode"
          toolTip="Regelvariante des Heizkreises"
        />
        <enum-item
          :featureName="featureId"
          :map="[
            ['1', 'Standby'],
            ['3', 'Normal'],
            ['4', 'Absenk'],
            ['5', 'Sommer'],
            ['11', 'Programm 1'],
            ['12', 'Programm 2'],
            ['13', 'Programm 3'],
            ['255', 'wie Leitstelle'],
          ]"
          :value="thing.features[featureId].properties.configuration.operatingMode"
          displayName="Betriebsart"
          propertyType="configuration"
          propertyName="operatingMode"
          toolTip="Betriebsart des Heizkreises"
        />
      </Group>
      <Group title="Regelvariante: Konstanttemperatur">
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.flowTemperatureNormal`"
          displayName="Normal VL Soll"
          propertyType="configuration"
          propertyName="flowTemperatureNormal"
          toolTip="Vorlauftemperatur Normal Soll"
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.flowTemperatureSetback`"
          displayName="Absenk VL Soll"
          propertyType="configuration"
          propertyName="flowTemperatureSetback"
          toolTip="Vorlauftemperatur Absenk Soll"
        />
      </Group>
      <Group title="Regelvariante: Witterungsführung">
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.roomTemperatureNormal`"
          displayName="Normal Raumtemperatur Soll"
          propertyType="configuration"
          propertyName="roomTemperatureNormal"
          toolTip="Raumtemperatur Normal Soll"
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.roomTemperatureSetback`"
          displayName="Absenk Raumtemperatur Soll"
          propertyType="configuration"
          propertyName="roomTemperatureSetback"
          toolTip="Raumtemperatur Absenk Soll"
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.gradient`"
          displayName="Steilheit"
          propertyType="configuration"
          propertyName="gradient"
          toolTip="Steilheit der Heizkurve"
        />
      </Group>
    </template>
  </div>
</template>

<script lang="ts">
  /*This code does not has to be touched when developing a new template except the ClassName*/
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'

  /*REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      TextItem,
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  /*REPLACE 'TestId' WITH A FITTING COMPONENT NAME*/
  export default class HeatingCircuitWeishaupt extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style scoped lang="scss">
  /*The edit component can be styled here*/
</style>
