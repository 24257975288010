import type KeycloakUser from '@/api/keycloak/models/KeycloakUser'
import type userRepresentation from 'keycloak-admin/lib/defs/userRepresentation'
import moment from 'moment'

export class UserMapper {
  static toDto(user: KeycloakUser): userRepresentation {
    return {
      id: user.id,
      enabled: true,
      createdTimestamp: user.createdAt?.valueOf() ?? undefined,
      username: user.username ?? undefined,
      attributes: user.attributes ?? undefined,
      email: user.email,
      firstName: user.firstName ?? undefined,
      lastName: user.lastName ?? undefined,
    }
  }

  static toDomain(user: userRepresentation) {
    return {
      id: user.id ?? '',
      createdAt: user.createdTimestamp ? moment(user.createdTimestamp) : null,
      username: user.username ?? null,
      attributes: user.attributes ?? {},
      email: user.email ?? '',
      firstName: user.firstName ?? null,
      lastName: user.lastName ?? null,
    }
  }
}
