import type SiteTreeNode from '@/components/app/SiteThingsTree/SiteTreeNode'
import { SITE_TREE_MAX_NAME_LENGTH } from '@/components/app/SiteThingsTree/SiteTreeNode'
import type PropertyNode from '@/components/app/SiteThingsTree/PropertyNode'
import { ellipsis } from '@/modules/tools/Filters'
import ThingReference from '@/components/shared/ThingReference'

export default class FeatureNode implements SiteTreeNode {
  children: SiteTreeNode[]

  data: {
    dropData: ThingReference
    type: string
    keywords: string
  }

  state: unknown

  text: string

  constructor(
    displayName: string,
    thingId: string,
    featureName: string,
    _description: string,
    children: PropertyNode[],
    selectable: boolean,
  ) {
    this.text = ellipsis(displayName, SITE_TREE_MAX_NAME_LENGTH)

    this.children = children

    this.data = {
      type: 'feature',
      keywords: displayName,
      dropData: new ThingReference(thingId, featureName, '', ''),
    }

    this.state = {
      expanded: false,
      selectable: selectable,
    }
  }
}
