/**
 * Ellipsises a string with ... the given length of chars. If the string is shorter, returns the original string
 * @param value
 * @param length
 */
export function ellipsis(value: string, length: number): string {
  if (value.length > length) {
    return value.substr(0, length - 3) + '...'
  }
  return value ?? ''
}
