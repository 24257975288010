var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataLoaded)?[(_vm.submitting)?_c('SubmitOverlay'):_vm._e(),_c('Title',[_vm._v("Heizkreis")]),_c('Info',[_vm._v("Stellen Sie hier die Parameter des Weishaupt Heizkreises ein.")]),_c('Group',{attrs:{"title":"Einstellungen"}},[_c('enum-item',{attrs:{"featureName":_vm.featureId,"map":[
          ['0', 'Konstanttemperatur'],
          ['1', 'Witterungsführung'] ],"value":_vm.thing.features[_vm.featureId].properties.configuration.controlMode,"displayName":"Regelvariante","propertyType":"configuration","propertyName":"controlMode","toolTip":"Regelvariante des Heizkreises"}}),_c('enum-item',{attrs:{"featureName":_vm.featureId,"map":[
          ['1', 'Standby'],
          ['3', 'Normal'],
          ['4', 'Absenk'],
          ['5', 'Sommer'],
          ['11', 'Programm 1'],
          ['12', 'Programm 2'],
          ['13', 'Programm 3'],
          ['255', 'wie Leitstelle'] ],"value":_vm.thing.features[_vm.featureId].properties.configuration.operatingMode,"displayName":"Betriebsart","propertyType":"configuration","propertyName":"operatingMode","toolTip":"Betriebsart des Heizkreises"}})],1),_c('Group',{attrs:{"title":"Regelvariante: Konstanttemperatur"}},[_c('numeric-value-item',{attrs:{"featureName":_vm.featureId,"valuePath":("features." + _vm.featureId + ".properties.configuration.flowTemperatureNormal"),"displayName":"Normal VL Soll","propertyType":"configuration","propertyName":"flowTemperatureNormal","toolTip":"Vorlauftemperatur Normal Soll"}}),_c('numeric-value-item',{attrs:{"featureName":_vm.featureId,"valuePath":("features." + _vm.featureId + ".properties.configuration.flowTemperatureSetback"),"displayName":"Absenk VL Soll","propertyType":"configuration","propertyName":"flowTemperatureSetback","toolTip":"Vorlauftemperatur Absenk Soll"}})],1),_c('Group',{attrs:{"title":"Regelvariante: Witterungsführung"}},[_c('numeric-value-item',{attrs:{"featureName":_vm.featureId,"valuePath":("features." + _vm.featureId + ".properties.configuration.roomTemperatureNormal"),"displayName":"Normal Raumtemperatur Soll","propertyType":"configuration","propertyName":"roomTemperatureNormal","toolTip":"Raumtemperatur Normal Soll"}}),_c('numeric-value-item',{attrs:{"featureName":_vm.featureId,"valuePath":("features." + _vm.featureId + ".properties.configuration.roomTemperatureSetback"),"displayName":"Absenk Raumtemperatur Soll","propertyType":"configuration","propertyName":"roomTemperatureSetback","toolTip":"Raumtemperatur Absenk Soll"}}),_c('numeric-value-item',{attrs:{"featureName":_vm.featureId,"valuePath":("features." + _vm.featureId + ".properties.configuration.gradient"),"displayName":"Steilheit","propertyType":"configuration","propertyName":"gradient","toolTip":"Steilheit der Heizkurve"}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }