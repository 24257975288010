import { Component } from 'vue-property-decorator'
import Sugar from 'sugar'
import Vue from 'vue'
import type { PropertyType } from '@/@types/Things'
import PropertyTranslationService from '@/modules/translations/PropertyTranslationService'
import type { DittoFormatter } from '@/components/app/SiteThingsTree/TreeGenerator'
import type { Feature, Thing } from '@/api/klempner/models/SiteListResponse'
import type ThingReference from '@/components/shared/ThingReference'

@Component
export default class ThingDisplayMixin extends Vue implements DittoFormatter {
  /**
   * Builds the display name for a thing node
   */
  getDisplayNameWithTypeForThing(thing: Thing): string {
    // Only consider definition + major version for translation (https://gitlab.com/othermo/frontend/-/issues/501#note_413763957)
    return this.getDisplayNameWithType(
      thing.name,
      thing.definition,
      thing.id,
      thing.description,
      thing.serial_number,
    )
  }

  getDisplayNameWithType(
    thingName: string,
    thingDefinition: string,
    thingId: string,
    thingDescription: string,
    thingSerialNumber: string,
  ): string {
    const type = this.getDisplayTypeForThing(thingDefinition, thingId)
    const name = this.getDisplayNameForThing(thingName, thingDescription, thingSerialNumber)
    if (name !== '' && type !== '') {
      return type + ': ' + name
    } else if (name !== '') {
      return name
    } else {
      return type
    }
  }

  /**
   * Builds the "translated" display type for a thing
   * @param definition thing definition
   * @param id thing id
   * @return The display type or an empty string if no type could be found
   */
  getDisplayTypeForThing(definition: string, id: string): string {
    // Only consider definition + major version for translation (https://gitlab.com/othermo/frontend/-/issues/501#note_413763957)
    if (definition === '') {
      return ''
    }
    const name = this.getNameFromDefinition(definition, id)
    return name ?? ''
  }

  /**
   * Builds the display name for a thing
   * @param thing Thing to get the display name for
   * @return The display name or an empty string if no name could be found
   */
  getDisplayNameForThing(name: string, description: string, serial_number: string): string {
    return name || description || serial_number || ''
  }

  /**
   * Builds the display name for a ditto reference
   * @param ref {@link DittoReference} object
   * @param things List of things to search for the thingId in
   *
   * @example For a reference with thingId: 'c0011:24950GG83AF3', feature: 'WiloPump', featureProperty: 'configuration.running' and propertyType: 'configuration'
   *  the function would return 'WiloPump/
   */
  getDisplayNameForReference(ref: ThingReference | null, things: Thing[]): string {
    if (ref == null) {
      return ''
    }

    const thing = things.find((thing) => thing.id === ref.thingId)
    if (thing == undefined) {
      return ''
    }

    const displayName: string = this.getDisplayNameWithTypeForThing(thing)

    let text: string
    text = displayName ?? ref.thingId ?? ''

    const model = PropertyTranslationService.getModelFromDittoDefinition(thing.definition ?? '')

    if (ref.feature === undefined || !(ref.feature in thing.features)) {
      return text
    }

    const feature = thing?.features?.[ref.feature]
    if (feature === undefined) {
      return text
    }
    const featureDisplayName = this.getDisplayNameForFeature(model, feature)

    const propertyName =
      ref.featureProperty
        ?.split('.')
        .map((prop, index, parts) => {
          const pathUntilHere = parts.slice(0, index + 1).join('.')
          return this.getDisplayNameForPropertyByPath(
            model,
            `${ref.feature}.${ref.propertyType}.${pathUntilHere}`,
            Sugar.String.titleize(prop),
          )
        })
        .join('/') ?? ''

    text += `/${featureDisplayName}/${propertyName}`
    return text
  }

  getDisplayNameForFeature(model: string | undefined, feature: Feature): string {
    let displayname = this.$propertyTranslation.translate(
      model,
      feature.name,
      Sugar.String.titleize(feature.name),
    )
    const description = feature?.properties?.configuration?.description?.value
    if (description != undefined && description !== '') {
      displayname += ': ' + description
    }
    return displayname
  }

  getDisplayNameForPropertyByPath(
    model: string | undefined,
    propertyPath: string,
    fallback: string = '',
  ): string {
    return this.$propertyTranslation.translate(model, propertyPath, fallback)
  }

  /**
   * Get the translated name for a property
   * @param model The model the property belongs to
   * @param feature Name of the feature the property belongs to
   * @param type Whether the property is a status or configuration property
   * @param propertyName Name of the property
   * @param fallback Fallback string to return when translation is not possible
   *
   * @return The translated name or fallback if translation is not possible
   */
  getDisplayNameForProperty(
    model: string | undefined,
    feature: string,
    type: PropertyType,
    propertyName: string,
    fallback: string = '',
  ): string {
    return this.$propertyTranslation.translate(
      model,
      `${feature}.${type}.${propertyName}`,
      fallback,
    )
  }

  /**
   * Tries to parse & translate the definition of a thing
   * @return The translated name or thingId if translation is not possible
   */
  getNameFromDefinition(def: string, id: string): string {
    let name: string | undefined
    const model = PropertyTranslationService.getModelFromDittoDefinition(def)

    if (model == undefined) {
      name = id
    } else {
      name = this.$propertyTranslation.translate(
        model,
        undefined,
        Sugar.String.titleize(model ?? ''),
      )
    }

    return name
  }
}
