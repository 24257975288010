import { Attribute, safeGet, SVG_ERROR_PREFIX } from '@/modules/svgbindings/BindingParser'
import { BoolColorMap, NumberColorMap } from '@/modules/svgbindings/ColorMap'
import { BoolColorBinding } from '@/modules/svgbindings/impl/BoolColorBinding'
import { NumberColorBinding } from '@/modules/svgbindings/impl/NumberColorBinding'
import type { SVGBinding } from '@/modules/svgbindings/SVGBinding'
import { ColorType } from '@/modules/svgbindings/SVGBinding'
import Logger from '@/logger'
import type { IBindingVisitor } from '@/modules/svgbindings/visitors/binding/IBindingVisitor'

/**
 * Visits an element and checks if it has a NumberTextBinding
 */
export default class ColorBindingVisitor implements IBindingVisitor {
  visit(element: Element, path: string): SVGBinding | undefined {
    if (!element.hasAttribute(Attribute.COLOR)) {
      return
    }

    const mapType = this.parseMapType(element)
    if (mapType === undefined) {
      return
    }

    const colorType = this.getColorAttributeType(element)
    if (colorType === undefined) {
      return
    }

    const colorMapString = safeGet(element, Attribute.COLOR_MAP)!
    return this.createColorBinding(mapType, colorMapString, element, path, colorType)
  }

  private createColorBinding(
    mapType: string,
    colorMapString: string,
    element: Element,
    path: string,
    colorType: ColorType.STROKE | ColorType.FILL | ColorType.STOP,
  ) {
    if (mapType === 'BOOLEAN') {
      try {
        const colorMap = new BoolColorMap(colorMapString)
        return new BoolColorBinding(element, path, colorMap, colorType)
      } catch (e) {
        Logger.error(SVG_ERROR_PREFIX + 'Invalid attribute "colorMap", must be valid JSON', element)
      }
    } else if (mapType === 'NUMBER') {
      try {
        const colorMap = NumberColorMap.fromString(colorMapString)
        return new NumberColorBinding(element, path, colorMap, colorType)
      } catch (e) {
        Logger.error(SVG_ERROR_PREFIX + 'Invalid attribute "colorMap", must be valid JSON', element)
      }
    } else {
      Logger.warn(
        `${SVG_ERROR_PREFIX}unrecognized map type "${mapType}", must be one of: boolean, number`,
        element,
      )
    }
  }

  private parseMapType(element: Element): string | undefined {
    const value = safeGet(element, Attribute.MAP_TYPE)
    if (value === undefined) {
      Logger.error(
        SVG_ERROR_PREFIX + 'Missing attribute othermo:mapType on element with othermo:color',
        element,
      )
    }
    return value?.toUpperCase()
  }

  /**
   * Converts the value of the othermo:color attribute to the corresponding enum
   * @param element
   */
  getColorAttributeType(element: Element): ColorType | undefined {
    const color = safeGet(element, Attribute.COLOR)!.toUpperCase()
    switch (color) {
      case 'STROKE':
        return ColorType.STROKE
      case 'STOP':
        return ColorType.STOP
      case 'FILL':
        return ColorType.FILL
      default:
        Logger.error(
          SVG_ERROR_PREFIX + 'Invalid othermo:color, must be one of: fill, stroke, stop',
          element,
        )
        return undefined
    }
  }
}
