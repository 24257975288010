export default class PathHandler {
  static updatePath(basePath: string | null, attr: string): string {
    const isRelativePath = attr.startsWith('./')

    // attr is an absolute path
    if (!isRelativePath) {
      return attr
    }

    // attr is a relative path and base path is not defined
    if (!basePath) {
      throw 'There cannot be a relative path with an undefined base path!'
    }

    // Construct absolute path
    const relativePath = attr.substring(2)
    const linker = basePath.endsWith('/') ? '' : '/'
    return basePath + linker + relativePath
  }
}
