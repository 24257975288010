import PhysicalQuantityHandler from '@/modules/units/handlers/PhysicalQuantityHandler'
import TimeHandler from '@/modules/units/handlers/TimeHandler'
import DefaultHandler from '@/modules/units/handlers/DefaultHandler'
import PercentHandler from '@/modules/units/handlers/PercentHandler'
import type IUnitHandler from '@/modules/units/handlers/IUnitHandler'
import StateHandler from '@/modules/units/handlers/StateHandler'
import { GeoLocationHandler } from '@/modules/units/handlers/GeoLocationHandler'
import { AddressHandler } from '@/modules/units/handlers/AddressHandler'
import EnumHandler from '@/modules/units/handlers/EnumHandler'
import { vxm } from '@/store/store'
import BooleanHandler from '@/modules/units/handlers/BooleanHandler'

/**
 * Provides formatting support for all units and data types
 */
export default class UnitHandler {
  readonly handlers: IUnitHandler[] = [
    new BooleanHandler(),
    new PhysicalQuantityHandler(),
    new TimeHandler(),
    new PercentHandler(),
    new StateHandler(),
    new AddressHandler(),
    new GeoLocationHandler(),
    new EnumHandler(),
    new DefaultHandler(),
  ]

  get locale() {
    return vxm.settings.lang
  }

  private constructor() {}

  private static instance: UnitHandler

  public static getInstance(): UnitHandler {
    if (UnitHandler.instance == undefined) {
      UnitHandler.instance = new UnitHandler()
    }
    return UnitHandler.instance
  }

  /**
   * Formats the passed value and combines the scaled value with the unit in a result string
   *
   * @param unit of the value that is used for scaling and shall be displayed in the result (e.g. dB, ...)
   * @param type of the value that is used in javascript (e.g. float)
   * @param value that shall be scaled in reference to the unit
   * @param getTranslatedProperty
   * @return the scaled value and the unit in a result string
   */
  public format(
    unit: string | null,
    type: string | null,
    value: any,
    getTranslatedProperty?: (name: string) => string | undefined,
  ): string {
    if (type != null && unit != null) {
      const handler = this.getHandler(unit, type)
      return handler.format(type, unit, value, this.locale, getTranslatedProperty)
    } else {
      return new DefaultHandler().format(null, null, value, this.locale)
    }
  }

  /**
   * This method formats a given unit according to a specified type. If the 'type' and 'unit' are not
   * provided or are null, it returns the default format.
   *
   * @public
   * @param {string | null} unit - The unit to be formatted.
   * @param {string | null} type - The type of the unit.
   *
   * @returns {string} The formatted unit according to the given type or the default format if no 'unit' or 'type' is provided.
   *
   */
  public formatUnit(unit: string | null, type: string | null): string {
    if (type != null && unit != null) {
      const handler = this.getHandler(unit, type)
      return handler.formatUnit(type, unit, this.locale)
    } else {
      return new DefaultHandler().formatUnit('', '', this.locale)
    }
  }

  /**
   * Determines whether there is a handler class for the given unit & type
   * or if the value would be handled by the default handler
   */
  handlerFound(unit: string, type: string) {
    return this.getHandler(unit, type) instanceof DefaultHandler
  }

  /**
   * This method determines if a given unit of a specific type is differentiable.
   *
   * @public
   * @param {string} unit - The unit to be tested.
   * @param {string} type - The type of the unit.
   *
   * @returns {boolean} True if the unit of the specified type is differentiable, false otherwise.
   *
   */
  public isDifferentiable(unit: string, type: string): boolean {
    return this.getHandler(unit, type).isDifferentiable()
  }

  // TODO This method should be private and not accessed directly
  public getHandler(unit: string, type: string): IUnitHandler {
    for (const handler of this.handlers) {
      if (handler.isSupported(type, unit)) {
        return handler
      }
    }
    throw new Error(`No handler found that supports type ${type} with unit ${unit}`)
  }
}
