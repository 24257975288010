import type { BoolColorMap } from '@/modules/svgbindings/ColorMap'
import type { ColorType } from '@/modules/svgbindings/SVGBinding'
import { ColorBinding } from '@/modules/svgbindings/SVGBinding'
import type { Thing } from '@/api/klempner/models/SiteListResponse'

/**
 * Binds a bool value to different colors
 */
export class BoolColorBinding extends ColorBinding {
  private colorMap!: BoolColorMap

  constructor(
    domElement: Element,
    valuePath: string,
    colorMap: BoolColorMap,
    colorType: ColorType,
  ) {
    super(domElement, valuePath, colorType)
    this.colorMap = colorMap
  }

  async updateBinding(things: Thing[]): Promise<void> {
    const value = this.getReferencedValue(things)?.value === true
    const color = this.colorMap.getColorForValue(value)
    this.resetInlineStyle()
    if (color) {
      this.element.setAttribute(this.attributeName, color)
    } else {
      this.element.setAttribute(this.attributeName, <string>this.defaultColor)
    }
  }
}
