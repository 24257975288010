<template>
  <b-button-group>
    <b-dropdown
      ref="dropdown"
      v-b-tooltip.bottom.hover
      :text="thingText"
      size="sm"
      boundary=""
      :popperOpts="{ positionFixed: true }"
    >
      <div class="tree-container">
        <SiteThingsTree
          v-model="selectedThing"
          :depth="depth"
          :dragEnabled="false"
          class="tree"
          :selectionLevels="selectionLevels"
          :filter="filter"
          @selection-changed="updateModel"
        />
      </div>
    </b-dropdown>
    <b-button v-if="selectedThing != null" size="sm" variant="primary" @click="resetThing">
      <span class="material-icons">close</span>
    </b-button>
  </b-button-group>
</template>

<script lang="ts">
  import { Component, Model, Prop, Ref, Watch } from 'vue-property-decorator'
  import SiteThingsTree from '@/components/app/SiteThingsTree.vue'
  import type ThingReference from '@/components/shared/ThingReference'
  import { SelectionLevels } from '@/components/app/SiteThingsTree/TreeGenerator'
  import type { PropertyNodeFilter } from '@/components/app/SiteThingsTree/TreeGenerator'
  import { mixins } from 'vue-class-component'
  import ThingDisplayMixin from '@/modules/ditto/ThingDisplayMixin'
  import SiteMixin from '@/modules/ditto/SiteMixin'
  import { BDropdown } from 'bootstrap-vue'
  import type { Thing } from '@/api/klempner/models/SiteListResponse'

  @Component({ components: { SiteThingsTree } })
  export default class SiteTreeDropdown extends mixins(ThingDisplayMixin, SiteMixin) {
    @Model('thing-changed')
    selectedThing!: ThingReference | null

    @Prop({ required: false, default: 'all' })
    readonly selectionLevels!: SelectionLevels

    @Prop({ required: true })
    readonly placeholder!: string

    @Prop({ required: false, default: 3 })
    readonly depth!: number

    @Ref('dropdown')
    readonly dropdown!: BDropdown

    @Prop({ required: false, default: null })
    readonly filter!: PropertyNodeFilter | null

    private things: Thing[] = []

    @Watch('currentSiteId')
    async fetchThings() {
      this.things = await this.getThingApi().list(this.currentSiteId)
    }

    async mounted() {
      await this.fetchThings()
    }

    resetThing() {
      this.$emit('thing-changed', null)
    }

    updateModel() {
      this.dropdown.hide()
      this.$emit('thing-changed', this.selectedThing)
    }

    get thingText() {
      if (this.selectedThing != null) {
        return this.getDisplayNameForReference(this.selectedThing, this.things)
          .replaceAll('/', '/\u200B')
          .replaceAll(' ', '\xa0')
      } else {
        return this.placeholder
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  .material-icons {
    margin: -2px;
  }

  .tree-container {
    z-index: 1007;
    max-height: 70vh;
    overflow-y: auto;
    display: flex;
    flex-grow: 1;

    .tree {
      padding-right: 15px;
      width: 100%;
    }
  }
</style>
