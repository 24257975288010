<template>
  <!--  This is a test template for development-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting"></SubmitOverlay>

      <Title>Temperatur-Sensor</Title>
      <Info>Stellen Sie hier die Paramter des Sensors ein.</Info>
      <Group title="Sensor">
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.rangeMin`"
          displayName="Untergrenze"
          maxValue="100"
          minValue="0"
          propertyType="configuration"
          propertyName="rangeMin"
          toolTip="Untergrenze des Messbereichs."
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.rangeMax`"
          displayName="Obergrenze"
          maxValue="100"
          minValue="0"
          propertyType="configuration"
          propertyName="rangeMax"
          toolTip="Obergrenze des Messbereichs."
        />
      </Group>
      <Group title="Analoger Eingang">
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.offset`"
          displayName="Offset"
          propertyType="configuration"
          propertyName="offset"
          toolTip="Offset"
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.slope`"
          displayName="Slope"
          propertyType="configuration"
          propertyName="slope"
          toolTip="Slope"
        />
      </Group>
    </template>
  </div>
</template>

<script lang="ts">
  /*This code does not has to be touched when developing a new template except the ClassName*/
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'

  /*REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      TextItem,
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  /*REPLACE 'TestId' WITH A FITTING COMPONENT NAME*/
  export default class TemperatureSensor extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style scoped lang="scss">
  /*The edit component can be styled here*/
</style>
