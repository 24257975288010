import type DomVisitor from '@/modules/svgbindings/DomVisitor'
import type { SVGBinding } from '@/modules/svgbindings/SVGBinding'
import BoolTextVisitor from '@/modules/svgbindings/visitors/binding/BoolTextVisitor'
import BoolVisibilityVisitor from '@/modules/svgbindings/visitors/binding/BoolVisibilityVisitor'
import ColorBindingVisitor from '@/modules/svgbindings/visitors/binding/ColorBindingVisitor'
import NumberTextVisitor from '@/modules/svgbindings/visitors/binding/NumberTextVisitor'
import type { IBindingVisitor } from '@/modules/svgbindings/visitors/binding/IBindingVisitor'
import { Attribute } from '@/modules/svgbindings/BindingParser'

/**
 * Checks the given dom element for a binding
 */
export default class BindingDomVisitor implements DomVisitor {
  private readonly bindingVisitors: IBindingVisitor[] = [
    new NumberTextVisitor(),
    new BoolTextVisitor(),
    new BoolVisibilityVisitor(),
    new ColorBindingVisitor(),
  ]

  private foundBindings: SVGBinding[] = []

  collectResults(): SVGBinding[] {
    return this.foundBindings
  }

  visit(element: Element, path: string): void {
    if (!element.hasAttribute(Attribute.REF)) {
      return
    }

    for (const bindingVisitor of this.bindingVisitors) {
      const result = bindingVisitor.visit(element, path)
      if (result) {
        this.foundBindings.push(result)
        break
      }
    }
  }
}
