export const RemoteAccessTranslationsDe = {
  page_title: 'Fernzugriff',
  disconnect: 'Verbindung Trennen',
  connect: 'Verbinden',
  password: 'Passwort',
  username: 'Nutzername',
  enter_credentials: 'Geben Sie Ihre Anmeldeinformationen ein',
  state: {
    idle: 'Inaktiv',
    connecting: 'Verbindung wird hergestellt',
    waiting: 'Warten auf Antwort',
    connected: 'Verbunden',
    disconnecting: 'Verbindung wird getrennt',
    disconnected: 'Verbindung getrennt',
  },
  error: {
    connecting: 'Fehler beim Verbinden: {error}',
  },
}
