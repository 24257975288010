import { prodConfig } from '@/config/prod.config'
import { stagingConfig } from '@/config/staging.config'
import { localConfig } from '@/config/local.config'

export function isProd(): boolean {
  return process.env.VUE_APP_DEPLOY_ENV === 'production'
}

function getApiConfig() {
  switch (process.env.VUE_APP_DEPLOY_ENV) {
    case 'production':
      return prodConfig.api
    case 'staging':
      return stagingConfig.api
    default:
      return localConfig.api
  }
}

export const config = {
  // Enables recording of performance metrics using performance.mark and vue.config.performance
  performanceMeasureEnabled: false,

  // Set to true to print performance data to console when rendering charts/trends
  debugChartPerformance: false,

  keycloak: {
    realm: '',
    url: 'https://auth.othermo.de/',
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID_OVERRIDE ?? 'othermo-portal',
    roles: {
      trends: {
        read: 'dashboard_read',
        write: 'dashboard_write',
      },
      scada: {
        read: 'scada_read',
        write: 'scada_write',
      },
      ditto: {
        read: 'ditto_viewer',
        write: 'ditto_operator',
      },
      site_access_full: 'site_access_full',
      alarm_manager: 'alarm_manager',
      user_manager: 'user_manager',
      site_manager: 'site_manager',
      analytics: {
        read: 'analysis_read',
        write: 'analysis_write',
      },
    },
  },

  api: getApiConfig(),

  ui: {
    charts: {
      colors: [
        '#c00000',
        '#ff9100',
        '#ffea00',
        '#32cb00',
        '#1de9b6',
        '#00b0ff',
        '#3d5afe',
        '#d500f9',
        '#263238',
      ],
    },
  },

  appVersion: PACKAGE_VERSION ?? '0.0.0',
}
