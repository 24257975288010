<template>
  <h2 class="pb-1 mb-3">
    <slot></slot>
  </h2>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'

  /**
   * Can be used as the main title for a Template
   */
  @Component
  export default class Title extends Vue {}
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  h2 {
    border-bottom: thin solid $othermo-grey;
  }
</style>
