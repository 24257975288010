<template>
  <b-container v-if="siteEmpty" class="bg-secondary" fluid>
    <h2 class="text-white text-center m-5">{{ $t('tree_site_empty') }}</h2>
  </b-container>
  <div v-else>
    <b-form-input
      v-model="searchString"
      :placeholder="$t('search')"
      :state="null"
      class="mx-1"
      debounce="100"
      trim
    />
    <div v-if="loading" id="spinner" class="d-flex w-100 justify-content-center">
      <b-spinner variant="primary" />
    </div>
    <Tree
      v-show="!loading"
      ref="tree"
      v-observe-visibility="visibilityChanged"
      :filter="searchString"
      :options="{
        multiple: false,
        parentSelect: true,
        autoCheckChildren: false,
        filter: filterOptions,
      }"
      class="overflow-x-hidden"
      @node:selected="updateSelection"
    >
      <div :key="nodeKey(node)" slot-scope="{ node }" class="tree-text">
        <template v-if="node.data.type === 'thing'">
          <span :id="node.data.dropData.thingId">
            <b>{{ node.text }}</b>
          </span>
        </template>

        <template v-else-if="node.data.type === 'feature'">
          {{ node.text }}
        </template>

        <template v-else-if="node.data.type === 'property'">
          <drag :transferData="node.data.dropData">
            <span
              v-b-tooltip.hover.ds500.dh50.top="tooltip(node.data.dropData)"
              :class="{ 'draggable-feature': dragEnabled }"
            >
              <UnitIcon
                :propertyType="node.data.dropData.propertyType"
                :unit="node.data.dropData.unit"
                :type="node.data.dataType"
              />
              {{ node.text + ': ' }}
              <i>
                {{ formatValues(node.data.dropData.unit, node.data.dataType, node.data.value) }}
              </i>
            </span>
          </drag>
        </template>

        <template v-else>
          <span>{{ node.text }}</span>
        </template>
      </div>
    </Tree>
  </div>
</template>

<script lang="ts">
  import { Component, Model, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import SiteMixin from '@/modules/ditto/SiteMixin'
  import type SiteTreeNode from '@/components/app/SiteThingsTree/SiteTreeNode'
  import ThingDisplayMixin from '@/modules/ditto/ThingDisplayMixin'
  import Logger from '@/logger'
  import { SelectionLevels } from '@/components/app/SiteThingsTree/TreeGenerator'
  import type { PropertyNodeFilter } from '@/components/app/SiteThingsTree/TreeGenerator'
  import TreeGenerator from '@/components/app/SiteThingsTree/TreeGenerator'
  import UnitIcon from '@/components/app/UnitIcon.vue'
  import UnitHandler from '@/modules/units/UnitHandler'
  import type ThingReference from '@/components/shared/ThingReference'
  import type { Thing } from '@/api/klempner/models/SiteListResponse'
  import ApiMixin from '@/modules/tools/ApiMixin'

  @Component({
    components: { UnitIcon },
  })
  export default class SiteThingsTree extends mixins(SiteMixin, ThingDisplayMixin, ApiMixin) {
    @Ref('tree')
    readonly tree!: Vue

    @Prop({ default: true })
    readonly dragEnabled!: boolean

    @Prop({ default: 'none' })
    readonly selectionLevels!: SelectionLevels

    @Prop({ required: false, default: 3 })
    readonly depth!: number

    @Prop({ required: false, default: null })
    readonly filter!: PropertyNodeFilter | null

    /** No things found for this site */
    siteEmpty = false

    /** Toggles the loading state of the component */
    private loading = false

    searchString = ''

    private generator = new TreeGenerator(this)

    @Model('selection-changed')
    private readonly selection: any

    /**
     * All things found for the current site
     */
    private things: Thing[] = []

    private unitHandler = UnitHandler.getInstance()

    formatValues(unit: string, type: string, value: any) {
      return this.unitHandler.format(unit, type, value, this.$t)
    }

    /**
     * Generates tooltip text for a node
     */
    tooltip(data: ThingReference) {
      if (data) {
        return `${data.thingId}/${data.feature}/${data.propertyType}/${data.featureProperty}`
      }
      return ''
    }

    get filterOptions() {
      return {
        emptyText: this.$t('tree_site_empty'),
        matcher: (query: string, node: SiteTreeNode) => {
          const lowerCaseQuery = query.toLowerCase()
          return (
            node.text?.toLowerCase().includes(lowerCaseQuery) ||
            node.data.keywords?.toLowerCase().includes(lowerCaseQuery)
          )
        },
        plainList: true,
        showChildren: true,
      }
    }

    get language() {
      return this.$i18n.locale()
    }

    nodeKey(node: SiteTreeNode) {
      const d = node.data.dropData
      return d.thingId + (d.feature ?? '') + (d.featureProperty ?? '')
    }

    /**
     * Clean the search string if the menu is reopened
     * */
    visibilityChanged(isVisible: boolean) {
      if (!isVisible) {
        this.searchString = ''
      }
    }

    @Watch('language')
    languageChange() {
      this.generateTree()
    }

    @Watch('selection')
    selectionChanged(selection: any) {
      if (Object.keys(selection).length === 0) {
        //@ts-ignore
        this.tree.selected().unselect()
      }
    }

    async mounted() {
      // Avoid race condition with router by checking siteId first
      const siteId = this.currentSiteId
      if (siteId == undefined) {
        return
      }

      try {
        this.loading = true
        this.things = await this.getThingApi().list(siteId)
        Logger.info('Things loaded', this.things)
      } catch (e) {
        Logger.error('Error getting things in tree', e)
      }

      if (this.things.length === 0) {
        this.siteEmpty = true
      } else {
        this.generateTree()
      }
      this.loading = false
    }

    /**
     * Generates the Tree for all given things
     */
    generateTree() {
      if (this.tree === undefined) {
        return
      }
      //@ts-ignore
      this.tree.remove({}, true)

      const nodes = this.generator.generateSiteTree(
        this.things,
        this.selectionLevels,
        this.depth,
        this.filter,
      )

      for (const node of nodes) {
        //@ts-ignore
        this.tree.append(node)
      }
    }

    private updateSelection(selected: { data: { dropData: any } }) {
      this.$emit('selection-changed', selected?.data?.dropData ?? {})
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  .draggable-feature {
    background: white;
    padding: 5px;
    border-radius: 0.33rem;
    margin: 0;
    border: thin solid $gray-200;

    img {
      height: 1em;
      display: inline;
    }
  }

  ::v-deep .tree-anchor {
    margin: 0;
  }

  ::v-deep li.selected.tree-node > .tree-content {
    background: transparent !important;

    .tree-anchor {
      color: $primary;
      font-weight: bold;
      background: $gray-400;
    }
  }

  .tree-text {
    display: inline-flex;
    vertical-align: middle;
  }

  .overflow-x-hidden {
    overflow-x: hidden;
    padding-right: 15px;
  }

  #spinner {
    padding: 5rem 0;
  }
</style>
