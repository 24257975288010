export const analyticsTranslationsEn = {
  analytics: 'Analytics',
  create_analysis: 'Create new Analysis',
  x_axis: 'X Axis',
  y_axis: 'Y Axis',
  add_series: 'Add new series',
  edit_series: 'Edit "{name}"',
  discard_analytics_changes: 'Discard changes',
  unit: 'Unit',
  series_unit_mismatch:
    'Unit {series_unit} of {axis}-axis is not compatible with {first_unit} of first series. Values might not be displayed correctly.',
  no_datapoint_selected: 'No series selected',
  //Analytics analytics_types
  BAR_CHART: 'Bar Chart',
  PROFILE: 'Load Profile',
  SCATTER_PLOT: 'Scatter Plot',
  DURATION_CURVE: 'Load Duration Curve',
  HISTOGRAM: 'Histogram',
  CARPET_PLOT: 'Heat Map',
  // Form feedback
  type_required: 'A type must be selected.',
  name_required: 'Name for the new Analysis is required.',
  reference_required: 'Series cannot remain empty',
  select_datapoint: 'Select series',
  series: 'Series',
  //Aggregations
  min: 'Minimum',
  max: 'Maximum',
  last: 'Last Value',
  all_values: 'All Values',
  difference: 'Difference',
  '95%': '95th percentile',
  '5%': '5th percentile',
  aggregation: 'Aggregation',
  count: 'Count',
  distinct: 'Distinct',
  integral: 'Integral',
  mean: 'Mean',
  median: 'Median',
  mode: 'Mode',
  spread: 'Spread',
  stddev: 'Standard Deviation',
  sum: 'Sum',
  delete_tab: 'Delete tab',
  //Toasts
  error_loading_analyses: 'Error loading analyses: {error}',
  error_deleting_tab: 'Error deleting tab: {error}',
  error_getting_analysis: 'Error getting analysis: {error}',
  tab_save_success: 'Saved successfully',
  tab_save_error: 'Could not save: {error}',
  new_analysis_created: 'New analysis created',
  error_creating_analysis: 'Analysis could not be created: {error}',
  error_getting_analysis_data: 'Could not load analytics data: {error}',
  //Permissions
  analytics_read_forbidden: "You don't have permission to view the analyses for this site.",
  analytics_write_forbidden: "You don't have permissions to edit analyses for this site.",
  //Heatmap
  calendar_week: 'Week',
  //Bar chart
  stacked: 'Stacked',
  grouped: 'Grouped',
  //Histogram
  bin_mode: 'Bin mode',
  bin_mode_auto: 'Auto',
  bin_mode_manual: 'Manual',
  bin_min: 'Min',
  bin_max: 'Max',
  bin_size: 'Size',
  cumulative_mode: 'Cumulative view',
  binning_function: 'Binning function',
  avg: 'Average',
  // Load profiles
  mode_daily: 'Daily',
  mode_daily_average: 'Daily average',
  mode_weekly: 'Weekly',
}
