export const notificationCenterDe = {
  notification_center: {
    title: 'Benachrichtigungen',
    unread: 'Neu',
    dismissed: 'Gelesen',
    group_by_site: 'Nach Standort gruppieren',
    clear_all: 'Alle als gelesen markieren',
    mark_read: 'Als gelesen markieren',
    mark_group_read: 'Gruppe als gelesen markieren',
    no_notifications: 'Keine neuen Benachrichtigungen vorhanden',
    error: {
      mark_read: 'Benachrichtigung konnte nicht als gelesen markiert werden: {error}',
      fetching_notifications: 'Benachrichtigungen konnten nicht abgerufen werden: {error}',
      clear_all: 'Benachrichtigungen konnten nicht als gelesen markiert werden: {error}',
    },
    show_more: 'Mehr laden',
  },
}
