export const topologyTranslationsEn = {
  topology: 'Site Topology',
  cogeneration: 'Cogeneration Unit',
  electricity_source: 'Electricity',
  electricity_sink: 'Electricity',
  electricity_self_consumption: 'Internal Consumption',
  boiler: 'Boiler',
  heat_sink: 'Heat',
  gas_source: 'Gas',
  tank: 'Reservoir',
  pump: 'Pump',
  heat_meter: 'Heat meter',
  gas_meter: 'Gas meter',
  electricity_production_meter: 'Electricity meter (consumption)',
  electricity_consumption_meter: 'Electricity meter (production)',
  grid_connections: 'Grid connections',
  site_components: 'Site components',
  no_nodes_in_category: 'No components in this category',
  reset_view: 'Reset view',
  show_all: 'Show all',
  //Errors
  error_loading_nodes: 'Could not load available things: {error}',
  error_loading_topology: 'Could not load topology: {error}',
  error_saving_topology: 'Could not save topology: {error}',
  success_saving_topology: 'Topology saved successfully',
  clear_editor: 'Clear all',
  confirm_delete_all: 'This removes all nodes from the editor and cannot be undone.',
  discard_topology_changes: 'This discards all unsaved changes in the site topology.',
  leave_topology_unsaved: 'Leave without saving',
  scada_editor_toolbox: {
    available_devices: 'Available Devices',
    general_symbols: 'General Symbols',
  },
  air_heat_pump: 'Heat pump (air)',
  water_heat_pump: 'Heat pump (water)',
  electric_energy_storage: 'Electricity storage',
  thermal_energy_storage: 'Thermal storage',
  solar_thermal: 'Solar thermal',
  solar_pv: 'Photovoltaic',
  solar_inverter: 'Inverter',
}
