import KcAdminClient from 'keycloak-admin'
import { config } from '@/config'
import type KeycloakUser from '@/api/keycloak/models/KeycloakUser'
import { UserMapper } from '@/api/keycloak/mappers/UserMapper'
import type UserRoleMappings from '@/api/keycloak/models/RoleMapping'
import RoleMappingMapper from '@/api/keycloak/mappers/RoleMappingMapper'
import RoleMapper from '@/api/keycloak/mappers/RoleMapper'
import type { KeycloakRole } from '@/api/keycloak/models/KeycloakRole'
import { RequiredActionAlias } from 'keycloak-admin/lib/defs/requiredActionProviderRepresentation'

/** Maximum positive value of a singed 32 bit integer */
const MAX_INT_32 = 2147483647

export default class KeycloakApiClient {
  private adminClient = new KcAdminClient()

  constructor(realm: string) {
    this.adminClient.setConfig({
      baseUrl: config.keycloak.url,
      realmName: realm,
    })
  }

  /**
   * Finds the UUID for a client with specific name
   * @param name of the client to find
   * @param token auth token
   */
  async getClientIdByName(name: string, token: string): Promise<string> {
    this.adminClient.setAccessToken(token)
    const clients = await this.adminClient.clients.find()
    return clients.find((value) => value.clientId == name)?.id ?? ''
  }

  //<editor-fold desc="Client Roles">
  /**
   * Returns all available roles for a specific client
   * @param clientId
   * @param token
   */
  async getAvailableClientRoles(clientId: string, token: string) {
    this.adminClient.setAccessToken(token)
    const roles = await this.adminClient.clients.listRoles({ id: clientId })
    return RoleMapper.multipleToDomain(roles)
  }

  /**
   * Returns a list of all users that have a specific client role
   * @param clientId id of the client to search in
   * @param roleName Name of the role to search for
   * @param token
   */
  async getUsersWithRole(clientId: string, roleName: string, token: string) {
    this.adminClient.setAccessToken(token)
    const users = await this.adminClient.clients.findUsersWithRole({
      id: clientId,
      roleName: roleName,
    })
    return users.map(UserMapper.toDomain)
  }

  /**
   * Returns effective roles in realm
   * @param clientId
   * @param userId
   * @param token
   */
  async getEffectiveClientRoles(clientId: string, userId: string, token: string) {
    this.adminClient.setAccessToken(token)
    const getCompositeRoles = this.adminClient.users.makeRequest({
      method: 'GET',
      path: '/{id}/role-mappings/clients/{clientUniqueId}/composite',
      urlParamKeys: ['id', 'clientUniqueId'],
    })
    const effectiveRoles = await getCompositeRoles({ id: userId, clientUniqueId: clientId })
    return RoleMapper.multipleToDomain(effectiveRoles)
  }

  /**
   * Adds a new role mapping for a client
   * @param userId of the user to map the role to
   * @param clientId UUID of the client that has this role
   * @param role the new mapping
   * @param token auth token
   */
  async addClientMapping(userId: string, clientId: string, role: KeycloakRole, token: string) {
    this.adminClient.setAccessToken(token)

    const payload = {
      id: userId,
      clientUniqueId: clientId,
      roles: [role],
    }

    return this.adminClient.users.addClientRoleMappings(payload)
  }

  /**
   * Removes a client role for a user
   * @param userId of the user to modify
   * @param clientId UUID of the client application
   * @param role to remove
   * @param token
   */
  async removeClientMapping(userId: string, clientId: string, role: KeycloakRole, token: string) {
    this.adminClient.setAccessToken(token)

    const payload = {
      id: userId,
      clientUniqueId: clientId,
      roles: [role],
    }

    return this.adminClient.users.delClientRoleMappings(payload)
  }

  //</editor-fold>

  //<editor-fold desc="Realm Roles">
  /**
   * Gets all roles in the realm that match the pattern for a ditto thing id
   * @param realm
   * @param token
   */
  async getAvailableRoles(realm: string, token: string): Promise<KeycloakRole[]> {
    this.adminClient.setAccessToken(token)
    //@ts-ignore
    const roleDTOs = await this.adminClient.roles.find({ realm: realm })
    return roleDTOs.map(RoleMapper.toDomain)
  }

  /**
   * Gets effective roles in realm
   * @param userId
   * @param token
   */
  async getEffectiveRealmRoles(userId: string, token: string) {
    this.adminClient.setAccessToken(token)
    const roles = await this.adminClient.users.listCompositeRealmRoleMappings({ id: userId })
    return RoleMapper.multipleToDomain(roles)
  }

  /**
   * Adds a realm role to a user
   * @param userId UUID of the user
   * @param role Role to add
   * @param token auth token
   */
  async addRealmMapping(userId: string, role: KeycloakRole, token: string) {
    this.adminClient.setAccessToken(token)
    const roleDTO = RoleMapper.toDto(role)
    return this.adminClient.users.addRealmRoleMappings({ id: userId, roles: [roleDTO] })
  }

  /**
   * Removes a realm role from a specific user
   * @param userId UUID of the user
   * @param role role to remove (must have id AND name)
   * @param token auth token
   */
  async removeRealmMapping(userId: string, role: KeycloakRole, token: string) {
    this.adminClient.setAccessToken(token)
    const roleDTO = RoleMapper.toDto(role)
    return this.adminClient.users.delRealmRoleMappings({ id: userId, roles: [roleDTO] })
  }

  //</editor-fold>

  //<editor-fold desc="User CRUD">
  /**
   * Retrieves a single user with the given ID
   * @param userId ID (sub) of the user to find
   * @param token auth token
   */
  async getUserById(userId: string, token: string): Promise<KeycloakUser> {
    this.adminClient.setAccessToken(token)
    const user = await this.adminClient.users.findOne({ id: userId })
    return UserMapper.toDomain(user)
  }

  /**
   * Adds new user
   * @param newUser user representation to add
   * @param token
   */
  async createUser(newUser: KeycloakUser, token: string): Promise<{ id: string }> {
    this.adminClient.setAccessToken(token)
    return this.adminClient.users.create(UserMapper.toDto(newUser))
  }

  /**
   * Invalidates user password and sends mail
   * @param userId user to send mail to
   * @param token
   */
  async sendPasswortResetEmail(userId: string, token: string): Promise<void> {
    this.adminClient.setAccessToken(token)
    const payload = {
      id: userId,
      clientId: config.keycloak.clientId,
      actions: [RequiredActionAlias.UPDATE_PASSWORD],
      lifespan: 24 * 60 * 60,
    }
    await this.adminClient.users.executeActionsEmail(payload)
  }

  /**
   * Lists all users in realm that have "visibility=user" set
   * Returned representations lack role mappings.
   * @param token
   */
  async getUsersInRealm(token: string): Promise<KeycloakUser[]> {
    this.adminClient.setAccessToken(token)
    // Keycloak defaults to a maximum of 100 Users per "page", but does not provide any pagination info (i.e. total users etc.)
    // Because we filter client-side, the easiest workaround for now is to just get as many users as possible in one request
    // and filter them in the client.
    // If this leads to performance issues in the future, we need another solution
    const users = await this.adminClient.users.find({ max: MAX_INT_32 })
    return users
      .map(UserMapper.toDomain)
      .filter((user: KeycloakUser) => user.attributes?.['visibility']?.[0] == 'user' ?? false)
  }

  /**
   * Updates a user's info
   * @param user New user info object
   * @param token auth token
   */
  async updateUser(user: KeycloakUser, token: string) {
    this.adminClient.setAccessToken(token)
    const userDTO = UserMapper.toDto(user)
    return this.adminClient.users.update({ id: user.id! }, userDTO)
  }

  /**
   * Gets client and realm roles for a specific user
   * @param userId
   * @param token
   */
  async getUserRoles(userId: string, token: string): Promise<UserRoleMappings> {
    this.adminClient.setAccessToken(token)
    const mapping = await this.adminClient.users.listRoleMappings({ id: userId })
    return RoleMappingMapper.toDomain(mapping)
  }

  /**
   * Gets client and realm roles for a specific user including effective (composite) roles
   */
  async getEffectiveUserRoles(userId: string, token: string): Promise<KeycloakRole[]> {
    this.adminClient.setAccessToken(token)
    const roles = await this.adminClient.users.listCompositeRealmRoleMappings({ id: userId })
    return RoleMapper.multipleToDomain(roles)
  }

  /**
   * Deletes a user by id
   * @param userId of the user to delete
   * @param token auth token
   */
  async deleteUser(userId: string, token: string) {
    this.adminClient.setAccessToken(token)
    return this.adminClient.users.del({ id: userId })
  }

  //</editor-fold>
}
