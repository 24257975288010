import Logger from '@/logger'
import { uTime } from '@othermo/convert-units'
import { Vue } from 'vue-property-decorator'
import type { DataSeries } from '@/api/analytics/models/DataSeries'
import UnitHandler from '@/modules/units/UnitHandler'
import moment from 'moment'

export type SeriesOptions = {
  derive?: boolean
  deriveUnit?: DerivativeTimeUnit
}

export type BootstrapSelectOption = { value: string; text: string | undefined }

/**
 * See {@link uTime} for possible right-hand side values
 */
export enum DerivativeTimeUnit {
  second = 's',
  minute = 'min',
  hour = 'h',
  day = 'd',
  week = 'week',
}

const UNITS_DERIVED_BY_DAY = ['B']

export const DEFAULT_DERIVATIVE_TIME_UNIT = DerivativeTimeUnit.hour
export const seriesOptionsDerivative = {
  derive: true,
  deriveUnit: DEFAULT_DERIVATIVE_TIME_UNIT,
}
export const seriesOptionsNoDerivative = {
  derive: false,
  deriveUnit: DEFAULT_DERIVATIVE_TIME_UNIT,
}

const NOT_DIFFERENTIABLE_UNITS: string[] = ['seconds']

const DIFFERENTIABLE_TYPES = ['float', 'int', 'double', 'long']

const INVALID_KLEMPNER_TIME_LITERAL = '0s'

/**
 * Even though this is an Util class it extends Vue and has a not static method to
 * make use of our translation framework in {@link getAxisUnit}
 */
export default class DerivativeUtil extends Vue {
  private static derivativeUtil: DerivativeUtil | null = null

  private constructor() {
    super()
  }

  public static getInstance(): DerivativeUtil {
    if (!DerivativeUtil.derivativeUtil) {
      DerivativeUtil.derivativeUtil = new DerivativeUtil()
    }
    return DerivativeUtil.derivativeUtil
  }

  /**
   * @param unit that might not be equal to the one saved in dataSeries due to unit scaling
   * @param dataSeries
   */
  public getAxisUnit(unit: string, dataSeries: DataSeries): string {
    let adjustedUnit = unit
    if (dataSeries.aggregationFunction === 'derivative') {
      adjustedUnit += '/' + this.$t('derivativeUnit.' + dataSeries.derivativeTimeUnit)
    }
    return adjustedUnit
  }

  public getSeriesOptionsAxisUnit(unit: string, options: SeriesOptions | undefined): string {
    let adjustedUnit = unit
    if (options?.derive && options.deriveUnit != null) {
      adjustedUnit += '/' + this.$t('derivativeUnit.' + options.deriveUnit)
    }
    return adjustedUnit
  }

  public getTimeUnits(): BootstrapSelectOption[] {
    const timeUnits: BootstrapSelectOption[] = []
    Object.values(DerivativeTimeUnit).forEach((timeUnit) => {
      timeUnits.push({
        value: timeUnit,
        text: this.$t('derivativeUnit.' + timeUnit),
      })
    })
    return timeUnits
  }

  public static timeUnitAsSeconds(timeUnit: string | undefined): string {
    if (timeUnit === 'min') {
      timeUnit = 'm' /** 'min' is not supported by {@link moment.unitOfTime.Base} */
    }

    let unitAsSeconds =
      moment.duration(1, (timeUnit ?? 'h') as moment.unitOfTime.Base).asSeconds() + 's'
    if (unitAsSeconds === INVALID_KLEMPNER_TIME_LITERAL) {
      Logger.error(`The time unit ${timeUnit} was not convertable to seconds.`)
      unitAsSeconds = '3600s' // hour as default value
    }
    return unitAsSeconds
  }

  public static isDifferentiable(unit: string | undefined, type: string | undefined) {
    if (unit && NOT_DIFFERENTIABLE_UNITS.includes(unit)) {
      return false
    }

    // the type is regarded as the most robust way to define whether a unit is differentiable or not
    if (type) {
      return DIFFERENTIABLE_TYPES.includes(type)
    }

    const unitHandler = UnitHandler.getInstance()
    return unit ? unitHandler.isDifferentiable(unit, type ?? '') : false
  }

  public static determineDerivativeTimeUnit(unit: string | undefined) {
    if (unit && UNITS_DERIVED_BY_DAY.includes(unit)) {
      return {
        derive: true,
        deriveUnit: DerivativeTimeUnit.day,
      }
    } else {
      return seriesOptionsDerivative
    }
  }
}
