<template>
  <div class="m-0 p-0 d-flex align-items-center">
    <transition mode="out-in" name="scale-fade">
      <div v-if="state === 'submitting'" key="submitting">
        <span class="text-muted mr-3">{{ $t('submitting') }}</span>
        <b-spinner small type="grow" variant="primary" />
      </div>
      <span v-else-if="state === 'error'" key="error" class="text-primary">{{ errorMessage }}</span>
      <div v-else-if="state === 'success'" key="success">
        <span class="text-success mr-3">{{ $t('submit_success') }}</span>
        <i class="material-icons text-success">done</i>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

  /**
   * Simple component that provides feedback when submitting data in a template
   *
   * How to use:
   * Set state to
   * - submitting to show a spinner
   * - success to show a green checkmark
   * - error to show the error text (requires errorMessage to be set)
   *
   * When the state is set to something other than idle, a timeout is started that emits the timeout-end event after 5s.
   * Use this event in the parent component to reset the state to idle.
   */
  @Component
  export default class SubmitState extends Vue {
    @Prop({ default: 'idle', required: true })
    readonly state!: 'idle' | 'submitting' | 'success' | 'error'

    @Prop({ default: '' })
    readonly errorMessage!: string

    private timeout: number | null = null

    @Watch('state')
    stateChanged() {
      if (this.state === 'idle' && this.timeout != null) {
        window.clearTimeout(this.timeout)
        this.timeout = null
      }
      if (this.state == 'error') {
        this.setTimeout(7.5)
      } else if (this.state == 'success') {
        this.setTimeout(5)
      }
    }

    /**
     * Creates a timeout that emits the timeout-end event after desired number of seconds
     */
    setTimeout(seconds: number) {
      if (this.timeout != null) {
        window.clearTimeout(this.timeout)
        this.timeout = null
      }
      this.timeout = window.setTimeout(() => {
        this.$emit('timeout-end')
        this.timeout = null
      }, 1000 * seconds)
    }

    beforeDestroy() {
      if (this.timeout != null) {
        window.clearTimeout(this.timeout)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .scale-fade-enter-active {
    transition: all 0.25s ease;
  }

  .scale-fade-leave-active {
    transition: all 0.25s ease;
  }

  .scale-fade-enter,
  .scale-fade-leave-to {
    transform: scale(0);
    opacity: 0;
  }
</style>
