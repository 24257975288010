const isProduction = process.env.VUE_APP_DEPLOY_ENV === 'production'

/**
 * Exports a logging interface for the application
 */
export default class Logger {
  static consola = require('consola')

  constructor() {
    Logger.consola.level = 4 // DEBUG

    if (isProduction) {
      Logger.consola.level = 2 // ERROR
    } else {
      Logger.consola.level = 3 // INFO
    }
  }

  static debug(a: any, ...args: any[]) {
    this.consola.debug(a, args)
  }

  static error(a: any, ...args: any[]) {
    this.consola.error(a, args)
  }

  static warn(a: any, ...args: any[]) {
    this.consola.warn(a, args)
  }

  static info(a: any, ...args: any[]) {
    this.consola.info(a, args)
  }

  static trace(a: any, ...args: any[]) {
    this.consola.trace(a, args)
  }
}
