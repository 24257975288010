import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { from } from 'apollo-link'
import VueApollo from 'vue-apollo'
import Vue from 'vue'
import { config } from '@/config'

export function setupApollo() {
  // HTTP connection to the API
  const httpLink = createHttpLink({
    uri: config.api.hasura.url,
  })

  // Authentication provider
  const authLink = setContext(async (request, prevContext: any) => {
    const token = Vue.prototype.$keycloak.token
    if (
      Vue.prototype.$keycloak.isTokenExpired != undefined &&
      Vue.prototype.$keycloak.isTokenExpired(5)
    ) {
      console.warn('Token has less than 5s of validity left!')
    }

    return {
      headers: {
        ...(prevContext.headers ?? {}),
        authorization: 'Bearer ' + (token ?? ''),
      },
    }
  })

  // Cache implementation
  const cache = new InMemoryCache()

  // Create the apollo client
  const apolloClient = new ApolloClient({
    link: from([authLink, httpLink]),
    cache,
    connectToDevTools: false,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  })

  Vue.use(VueApollo)

  return new VueApollo({
    defaultClient: apolloClient,
  })
}

export const apolloProvider = setupApollo()
