import type IUnitHandler from '@/modules/units/handlers/IUnitHandler'

export class GeoLocationHandler implements IUnitHandler {
  format(type: string, unit: string, value: any, locale: string): string {
    if (value == null) {
      return ''
    }
    const latitude = value.latitude ?? 0
    const longitude = value.longitude ?? 0

    const dirLatitude = latitude >= 0 ? 'N' : 'S'
    const dirLongitude = longitude >= 0 ? 'W' : 'E'

    const latitudeStr = Math.abs(latitude).toFixed(6)
    const longitudeStr = Math.abs(longitude).toFixed(6)

    return `${latitudeStr}° ${dirLatitude} ${longitudeStr}° ${dirLongitude}`
  }

  formatUnit(type: string, unit: string, locale: string): string {
    return ''
  }

  isSupported(type: string, unit: string): boolean {
    return type == 'GeoLocation'
  }

  scaleValue(value: number, scalingFactor: number): number {
    return value
  }

  getScaling(
    xValues: [string, number | null][][],
    unit: string,
  ): { best: any; scalingFactor: number } {
    return { best: { unit: unit }, scalingFactor: 1 }
  }

  isDifferentiable(): boolean {
    return false
  }
}
