<template>
  <b-modal
    :id="'sensor-reading-modal-' + currentSiteId"
    :cancelTitle="$t('cancel')"
    :okTitle="$t('scada.rete_tab.measurement_point.create')"
    :title="$t('scada.rete_tab.measurement_point.title')"
    @cancel="resetModal"
    @close="resetModal"
    @ok="createNode"
  >
    <b-form-input
      v-model="measurementPointDescription"
      :placeholder="$t('scada.rete_tab.measurement_point.description')"
      class="mb-2"
    ></b-form-input>
    <SiteTreeDropdown
      v-model="selectedThing"
      :placeholder="$t('select_thing')"
      selectionLevels="properties"
    />
  </b-modal>
</template>

<script lang="ts">
  import { Component, Prop } from 'vue-property-decorator'
  import ToastMixin from '@/modules/tools/ToastMixin'
  import { mixins } from 'vue-class-component'
  import SiteTreeDropdown from '@/components/app/SiteThingsTree/SiteTreeDropdown.vue'
  import type ThingReference from '@/components/shared/ThingReference'
  import { TransferData } from '@/components/site/scada/ScadaEditor.vue'

  @Component({
    components: { SiteTreeDropdown },
  })
  export default class SensorReadingConfigurationModal extends mixins(ToastMixin) {
    @Prop()
    readonly currentSiteId!: string

    @Prop()
    private transferData!: TransferData

    private measurementPointDescription: string = ''

    private selectedThing: ThingReference | null = null

    /**
     * Before the 'create-node' event is emitted the data from the modal needs
     * to be set in the transfer data
     */
    private createNode() {
      this.transferData.data.measurementPointDescription = this.measurementPointDescription
      this.transferData.data.selectedThing = this.selectedThing
      this.$emit('create-node')
      this.resetModal()
    }

    private resetModal() {
      this.measurementPointDescription = ''
      this.selectedThing = null
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .dropdown-toggle {
    white-space: break-spaces !important;

    &::after {
      white-space: normal;
    }
  }
</style>
