<template>
  <b-container class="container">
    <b-form-group label="Template">
      <b-form-select v-model="selectedTemplate" :options="possibleTemplates" />
    </b-form-group>
    <b-form-group label="Thing ID">
      <b-form-input v-model="thingId"></b-form-input>
    </b-form-group>
    <b-form-group label="Feature Name">
      <b-form-input v-model="featureName"></b-form-input>
    </b-form-group>
    <component :is="selectedTemplate" :featureId="featureName" :thingId="thingId"></component>
    <b-button class="mt-3" @click="submit">Submit</b-button>
  </b-container>
</template>

<script lang="ts">
  import { Component, Provide } from 'vue-property-decorator'
  import Vue from 'vue'
  import ShowcaseExplain from '@/components/site/scada/command_response/templates/showcase-explain.vue'
  import SubmitState from '@/components/site/scada/command_response/toolbox/base/SubmitState.vue'
  import * as templates from '../components/site/scada/command_response/templates'

  const templatesModule = require('../components/site/scada/command_response/templates.ts')

  @Component({
    components: { ...templates, SubmitFeedback: SubmitState, ShowcaseExplain },
  })
  export default class TemplateTestPage extends Vue {
    selectedTemplate = 'lamp'

    featureName = ''

    thingId = ''

    @Provide()
    private submitEventBus = new Vue()

    get possibleTemplates() {
      return Object.keys(templatesModule)
    }

    submit() {
      this.submitEventBus.$emit('submit-template')
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    max-height: 100%;
    overflow-y: auto;
  }
</style>
