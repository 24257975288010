import { render, staticRenderFns } from "./CogenStandard.vue?vue&type=template&id=0b031ffe&scoped=true&"
import script from "./CogenStandard.vue?vue&type=script&lang=ts&"
export * from "./CogenStandard.vue?vue&type=script&lang=ts&"
import style0 from "./CogenStandard.vue?vue&type=style&index=0&id=0b031ffe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b031ffe",
  null
  
)

export default component.exports