import type { AlertDTO } from '@/components/site/alerts/wizard/models/Alert'
import Alert from '@/components/site/alerts/wizard/models/Alert'
import { HysteresisDownAlertRule } from '@/components/site/alerts/wizard/models/HysteresisDownAlertRule'
import type { AlertFragment } from '@/generated/graphql'

export default class HysteresisAlert extends Alert {
  static default() {
    return new HysteresisAlert([HysteresisDownAlertRule.default()], '', 0)
  }

  toDto(): AlertDTO {
    const rules = this.rules as HysteresisDownAlertRule[]
    return {
      name: this.name,
      type: this.getType(),
      params: rules.map((rule: HysteresisDownAlertRule) => rule.toDto()),
    }
  }

  getType(): 'hysteresis' {
    return 'hysteresis'
  }

  static fromFragment(customAlert: AlertFragment['custom_alert']): Alert {
    if (customAlert == null) {
      throw new TypeError('Hysteresis-Alert cannot be null/undefined!')
    }

    let rules: HysteresisDownAlertRule[] = []
    if (Array.isArray(customAlert.params)) {
      rules = customAlert.params.map(HysteresisDownAlertRule.fromDTO) ?? []
    }
    return new HysteresisAlert(rules, customAlert.name, customAlert.id)
  }
}
