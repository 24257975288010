import type { AlertRule } from '@/components/site/alerts/wizard/models/AlertRule'
import type { AlertParamDTO, HysteresisAlertParamDTO } from '@/api/alarm/models/dto/AlertParamDTOs'
import type { HysteresisDownAlertRule } from '@/components/site/alerts/wizard/models/HysteresisDownAlertRule'

export default abstract class Alert {
  constructor(
    public rules: (AlertRule | HysteresisDownAlertRule)[],
    public name: string,
    readonly id: number,
  ) {}

  /**
   * Checks if the definition is valid and all rules are valid
   */
  public get isValid() {
    return (
      !(this.name == undefined) &&
      this.rules.length > 0 &&
      this.rules.find((rule) => !rule.isValid) == undefined
    )
  }

  toDto(): AlertDTO {
    return {
      name: this.name,
      type: this.getType(),
      params: this.rules.map((rule) => rule.toDto()),
    }
  }

  abstract getType(): 'cascade' | 'controlDeviation' | 'hysteresis'

  /**
   * Percentages are saved in the format "1% = 0.01", "1.55% = 0.0155", "100% = 1"
   * To represent the percentages properly in the view, we need to scale the threshold of the alertRules.
   * The threshold is set accordingly for the alertRules with this method, rounded to 4 decimal places.
   *
   * @param scaleUp if the value is set to true, otherwise scaling the value down
   */
  public convertPercentageFormat(scaleUp: boolean) {
    this.rules.forEach((rule) => {
      rule.convertPercentageFormat(scaleUp)
    })
  }
}

export interface AlertDTO {
  name: string

  type: string

  params: (AlertParamDTO | HysteresisAlertParamDTO)[]
}
