<template>
  <div class="thermal-storage-container">
    <svg
      viewBox="0 0 24.193293 49.322269"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
    >
      <defs>
        <linearGradient :id="gradientId" x1="0.5" x2="0.5" y1="0" y2="1">
          <stop
            v-for="stop in gradientStops"
            :key="stop.offset + (stop.color || '')"
            :offset="stop.offset"
            :stop-color="stop.color"
          />
        </linearGradient>
      </defs>
      <g transform="matrix(1.24584,0,0,1.2861,-36.543439,-84.466379)">
        <path
          class="storage-outer-rect"
          style="fill: #d2d2d2; fill-rule: evenodd; stroke: #000000; stroke-width: 0.169261"
          d="m 29.417,65.761 h 19.25 v 38.181 h -19.25 z"
        />
        <path
          class="storage-gradient-outline"
          d="m 39.09,102.617 c -9.813,0.125 -8.667,2.431 -8.729,-17.555 -0.062,-19.987 -1.194,-17.652 8.62,-17.779 9.812,-0.125 8.666,-2.43 8.728,17.556 0.061,19.986 1.193,17.652 -8.62,17.778 z"
          style="stroke: #000000; stroke-width: 0.270832"
          :fill="`url(#${gradientId})`"
        />
      </g>
      <line style="stroke: green; stroke-width: 1px" />
    </svg>
    <div class="sensor-container">
      <div
        v-for="(sensor, index) in sensorPositionsAndValues"
        class="sensor"
        :style="{ top: 4 + (100 - sensor.position) * 0.92 + '%' }"
      >
        {{ formatSensorValue(sensorPositionsAndValues[index].value) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import UnitHandler from '@/modules/units/UnitHandler'
  import { NumberColorMap } from '@/modules/svgbindings/ColorMap'
  import ColorMapConfig from './StorageColorMap.json'

  const colorMap = new NumberColorMap(ColorMapConfig)

  @Component
  export default class ThermalStorage extends Vue {
    private unitHandler = UnitHandler.getInstance()

    declare _uid: string

    @Prop({ required: true })
    readonly sensorPositionsAndValues!: { position: number; value: number }[]

    get gradientId() {
      return 'gradient' + this._uid
    }

    get gradientStops() {
      if (this.sensorPositionsAndValues.length == 0) {
        return [
          { offset: 0, color: 'red' },
          { offset: 100, color: 'blue' },
        ]
      } else {
        return this.sensorPositionsAndValues
          .map(({ position, value }) => {
            const color = value == null ? 'white' : colorMap.getColorForValue(value)
            const offset = 1 - position / 100
            return { offset, color }
          })
          .sort((a, b) => a.offset - b.offset)
      }
    }

    formatSensorValue(value: number | null) {
      if (value == null) {
        return '...'
      } else {
        return this.unitHandler.format('C', 'float', value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  .thermal-storage-container {
    height: 100%;
    display: flex;

    svg {
      height: 100%;
    }

    .sensor-container {
      width: 12ch;
      flex: 12ch 0 0;
      position: relative;
    }

    .sensor {
      margin-left: $spacer;
      min-width: 6ch;
      position: absolute;
      outline: thin solid black;
      white-space: nowrap;
      padding: 0.25 * $spacer 0.5 * $spacer;
      transform: translate(0, -50%);
      background: white;
      pointer-events: none;

      &:before {
        content: '';
        min-width: 100px;
        border-top: 2px dashed black;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
</style>
