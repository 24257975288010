// noinspection GraphQLSchemaValidation

import type INotificationClient from '@/api/notifications/INotificationClient'
import { NotificationFilter } from '@/api/notifications/INotificationClient'
import type { NotificationFragment } from '@/generated/graphql'
import gql from 'graphql-tag'
import { apolloProvider } from '@/config/apollo'
import type SiteNotificationGroup from '@/api/notifications/models/SiteNotificationGroup'

export default class HasuraNotificationClient implements INotificationClient {
  constructor(
    readonly userId: string,
    readonly realm: string,
  ) {}

  async getNotificationCount(): Promise<number> {
    const query = gql`
        query GetUnreadNotifications($user_id: uuid!) {
          notifications: ${this.realm}_user_notifications_aggregate(
                where: { _not: { read_by: { user_uuid: { _eq: $user_id } } } }
            ) {
                aggregate {
                    count
                }
            }
        }
    `

    const result = await apolloProvider.defaultClient.query({
      query,
      variables: { user_id: this.userId },
    })

    return result.data.notifications.aggregate.count
  }

  async getReadNotificationCount(): Promise<number> {
    const query = gql`
        query GetUnreadNotifications($user_id: uuid!) {
            notifications: ${this.realm}_user_notifications_aggregate(
            where: {  read_by: { user_uuid: { _eq: $user_id } } }
        ) {
                aggregate {
                    count
                }
            }
        }
    `

    const result = await apolloProvider.defaultClient.query({
      query,
      variables: { user_id: this.userId },
    })

    return result.data.notifications.aggregate.count
  }

  async getNotifications(
    filter: NotificationFilter,
    pageSize: number,
    pageNumber: number,
  ): Promise<NotificationFragment[]> {
    const filterExpression = this.getFilterExpression(filter)

    const query = gql`
        query GetUnreadNotifications($user_id: uuid!, $page_size: Int!, $offset: Int!) {
            notifications: ${this.realm}_user_notifications(where: ${filterExpression}, limit: $page_size, offset: $offset) {
                siteid
                thingid
                property
                unit
                uuid
                time
                event_type
                description
                username
                value_new
                value_old
                customAlert: custom_alert {
                    name
                    params
                    type
                }
                alert {
                    alertgroup
                }
            }
        }
    `

    const result = await apolloProvider.defaultClient.query({
      query,
      variables: {
        user_id: this.userId,
        page_size: pageSize,
        offset: pageSize * pageNumber,
      },
    })
    return result.data.notifications
  }

  /**
   * Gets all notifications for a given user, grouped by site
   * @param filter Which notifications to return (unread, read or all)
   */
  async getNotificationGroups(filter: NotificationFilter): Promise<SiteNotificationGroup[]> {
    const filterExpression = this.getFilterExpression(filter)

    const query = gql`
        query GetUnreadNotifications($user_id: uuid!) {
            notifications: ${this.realm}_user_notifications_grouped(where: ${filterExpression}) {
                siteid
                thingid
                property
                unit
                uuid
                time
                event_type
                description
                username
                value_new
                value_old
                customAlert: custom_alert {
                    name
                    params
                    type
                }
                alert {
                    alertgroup
               }       
            }
        }
    `

    const result = await apolloProvider.defaultClient.query({
      query,
      variables: {
        user_id: this.userId,
      },
    })
    return this.groupNotificationsBySite(result.data.notifications)
  }

  private getFilterExpression(filter: NotificationFilter): string {
    switch (filter) {
      case NotificationFilter.UNREAD:
        return '{_not: {read_by: {user_uuid: {_eq: $user_id}}}}'
      case NotificationFilter.READ:
        return '{read_by: {user_uuid: {_eq: $user_id}}}'
      case NotificationFilter.ALL:
      default:
        return '{}'
    }
  }

  /**
   * Groups individual NotificationFragments by site.
   * This method assumes that notifications are sorted by site.
   * @param notifications
   * @private
   */
  private groupNotificationsBySite(notifications: NotificationFragment[]): SiteNotificationGroup[] {
    if (notifications.length === 0) {
      return []
    }

    const groups: SiteNotificationGroup[] = []

    let currentGroup: SiteNotificationGroup = { siteId: notifications[0].siteid, notifications: [] }

    notifications.forEach((notification) => {
      if (currentGroup.siteId === notification.siteid) {
        currentGroup.notifications.push(notification)
      } else {
        groups.push(currentGroup)
        currentGroup = { siteId: notification.siteid, notifications: [notification] }
      }
    })

    groups.push(currentGroup)

    return groups
  }

  /**
   * Marks all notifications in the given array as read.
   * @param notifications all notifications to mark as read
   */
  async markMultipleAsRead(notifications: NotificationFragment[]): Promise<void> {
    const insertInputs = notifications.map((notification) => ({
      event_uuid: notification.uuid,
    }))

    const query = gql`
        mutation MarkAllForSiteRead($objects: [${this.realm}_notifications_read_by_insert_input!]!) {
            insert_${this.realm}_notifications_read_by(objects: $objects) {
                affected_rows
            }
        }
    `
    await apolloProvider.defaultClient.mutate({
      mutation: query,
      variables: { objects: insertInputs },
    })
  }

  /**
   *  Marks a single notification as read
   */
  async markNotificationRead(notificationId: string): Promise<void> {
    const query = gql`
        mutation MarkNotificationRead($notification_id: uuid!) {
            insert_${this.realm}_notifications_read_by_one(object: { event_uuid: $notification_id }) {
                event_uuid
            }
        }
    `
    await apolloProvider.defaultClient.mutate({
      mutation: query,
      variables: {
        notification_id: notificationId,
      },
    })
  }

  /**
   * Marks all currently unread notifications as read
   */
  async markAllNotificationsRead(): Promise<void> {
    const query = gql`
        mutation MarkAllRead {
            ${this.realm}_mark_all_notifications_read {
                user_uuid
            }
        }
    `
    await apolloProvider.defaultClient.mutate({ mutation: query })
  }
}
