<template>
  <!--This Template shows a dialog with explanations-->
  <div>
    <template v-if="dataLoaded">
      <!-- Overlay which is shown while a message is being submitted -->
      <submit-overlay v-if="submitting"></submit-overlay>

      <!-- Start of the definition of base and configuration items -->
      <title>Explain Showcase</title>

      <info>This configuration shows how an example settings dialog can look like.</info>

      <Group title="Toggle item">
        <info>This basic toggle switch allows to set boolean values in ditto.</info>
        <toggle-item
          :value="true"
          displayName="Name"
          featureName="SomeFeature"
          toolTip="Tooltip text."
        />
      </Group>

      <divider />

      <Group title="Numeric value item">
        <info>This basic number input field allows you to set numeric values in ditto.</info>
        <numeric-value-item
          :toolTip="$t('translation key')"
          displayName="Configure numeric value"
          featureName="TemperatureSensor_28aaf839541401a2"
          maxValue="100"
          minValue="0"
          step="any"
          valuePath="features.MotionSensor.properties.status.batteryLevel"
        />
      </Group>

      <divider />

      <Group title="Text Item">
        <info>Allows user to edit text value</info>
        <text-item
          displayName="Text Item"
          featureName="SomeFeature"
          propertyType="type"
          propertyName="hello"
          toolTip="Text Item"
        />
      </Group>

      <Group title="Enum component">
        <info>This basic enum component lets you choose from one of the predefined values.</info>
        <enum-item
          :map="[
            ['ON', 'on'],
            ['OFF', 'off'],
            ['TEST', 'not translated'],
          ]"
          displayName="Enum Property"
          featureName="SomeFeature"
          toolTip="Sample ENUM Value"
          value="ON"
        />
      </Group>

      <divider />

      <Group title="Button component">
        <info>
          This basic button component lets you trigger an action which does not take any arguments.
        </info>
        <button-item
          displayName="Action without arguments"
          featureName="SomeFeature"
          toolTip="Triggers an action without arguments."
        />
      </Group>
    </template>
  </div>
</template>

<script lang="ts">
  /* This code does not have to be touched when developing a new template except the class name */
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'

  @Component({
    components: {
      TextItem,
      SubmitOverlay,
      ButtonItem,
      Group,
      Divider,
      Info,
      Title,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  export default class ExplainShowCase extends mixins(BaseTemplate) {}
</script>

<style lang="scss" scoped></style>
