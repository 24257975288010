<template>
  <modal
    ref="modal"
    :clickToClose="false"
    :minHeight="minHeight"
    :minWidth="minWidth"
    :name="modalName"
    :reset="true"
    :resizable="true"
    :scrollable="false"
    draggable=".window-header"
  >
    <b-card-header
      ref="header"
      class="window-header d-flex justify-content-between p-0 flex-shrink-0 flex-grow-0 flex-row-reverse"
    >
      <button :disabled="submitting" class="btn-close p-0 float-right" @click="close">
        <span class="material-icons">close</span>
      </button>
    </b-card-header>
    <b-card-body>
      <component
        :is="templateId"
        :key="thingId"
        ref="template"
        :featureId="featureId"
        :thingId="thingId"
        class="p-2"
        @submit-finished="submitFinished"
      />
      <b-alert v-if="!templateFound" ref="noTemplateError" show variant="warning">
        <span>
          {{ $t('template_not_found', { templateName: templateId }) }}
        </span>
      </b-alert>
    </b-card-body>
    <b-card-footer ref="footer">
      <span v-show="userChangedSomething" class="text-secondary m-2">
        <span id="icon-warning" class="material-icons">warning</span>
        {{ $t('unsaved_changes') }}
      </span>
      <b-button :disabled="submitting" class="mr-2" @click="close">
        {{ $t('cancel') }}
      </b-button>
      <b-button
        v-show="userChangedSomething"
        :disabled="submitting"
        variant="primary"
        @click="submit"
      >
        {{ $t('apply') }}
      </b-button>
    </b-card-footer>
  </modal>
</template>

<script lang="ts">
  import { Component, Provide, Ref, Vue } from 'vue-property-decorator'
  import * as templates from './command_response/templates'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import events from '@/events'

  /**
   * Shows a propertiesmodal to edit values
   */
  @Component({
    components: { ...templates },
  })
  export default class ScadaViewEditModal extends Vue {
    @Provide()
    submitEventBus = new Vue()

    public readonly modalName = 'edit'

    @Ref('header')
    readonly header!: HTMLElement

    @Ref('template')
    readonly content!: BaseTemplate

    @Ref('footer')
    readonly footer!: HTMLElement

    private thingId = ''

    private templateId = ''

    private featureId: string = ''

    private ready = false

    private templateFound = false

    private minWidth = 350

    private minHeight = 250

    /**
     * Used to disable buttons when template is currently submitting
     */
    private submitting = false

    private userChangedSomething = false

    mounted() {
      this.submitEventBus.$on(
        events.scada.templates.itemChanged,
        () => (this.userChangedSomething = true),
      )

      this.submitEventBus.$on(events.scada.templates.mounted, () => this.resizeModal())
    }

    beforeDestroy() {
      this.submitEventBus.$off('submit-finished')
      this.submitEventBus.$off(events.scada.templates.itemChanged)
      this.submitEventBus.$off(events.scada.templates.mounted)
    }

    /**
     * Not all components are rendered when the modal is mounted. This requires us to
     * re-measure the height of the modal whenever a child is mounted.
     * If the modal is rendered with a too small width/height the problem is most likely
     * that a child does not emit the <b>template-mounted</b> event.
     * <br>
     * This is <b>error-prone</b> as we rely on newly added components to
     * implement {@link BaseTemplate} or {@link BaseItem}, which do emit the expected event.
     * (NOTE: custom mounted methods override the event emitting base mounted method)
     * <br>
     * Components that are used to group elements need to pass the events of
     * their children <i>(e.g. {@link Group}, {@link MultiGroup})</i>>.
     */
    resizeModal() {
      //@ts-ignore
      this.$refs.modal.$refs.modal.style.width = 'fit-content'

      const height = this.measureHeight()

      //@ts-ignore
      this.$refs.modal.$refs.modal.style.height = height + 'px'

      //@ts-ignore
      this.$refs.modal.$refs.modal.style.top = (window.innerHeight - height) / 2 + 'px'
    }

    private measureHeight() {
      const contentHeight = this.content.$el.scrollHeight ?? 0
      const footerHeight = this.footer?.offsetHeight ?? 0
      const headerHeight = this.header?.offsetHeight ?? 0

      return Math.min(headerHeight + footerHeight + contentHeight, window.innerHeight * 0.75)
    }

    open(thingId: string, templateId: string, featureId: string | null) {
      this.userChangedSomething = false
      this.thingId = thingId
      this.templateId = templateId
      this.featureId = featureId ?? ''
      if (this.$options.components?.[this.templateId] !== undefined) {
        this.templateFound = true
      }
      this.ready = true
      this.$modal.show(this.modalName)
      this.$root.$emit('show-draggable')
    }

    close() {
      this.$modal.hide(this.modalName)
      this.$root.$emit('hide-draggable')
    }

    submit() {
      this.submitEventBus.$emit('submit-template')
      this.submitting = true
    }

    submitFinished(allSuccessful: boolean) {
      this.submitting = false
      this.userChangedSomething = !allSuccessful
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  .v--modal-overlay,
  .v--modal-background-click {
    // remove overlay from propertiesmodal library
    pointer-events: none;
    background: transparent;
    z-index: 1004;
  }

  ::v-deep .v--modal-box {
    display: flex;
    flex-direction: column;

    // Allow click events on dialog box (otherwise inherits from overlay and is not clickable at all)
    pointer-events: auto;

    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.5);
  }

  .card-footer {
    flex: 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .card-body {
    flex-grow: 1;
    padding: 0;
    overflow-y: auto;
  }

  .btn-close {
    background: $othermo-red;
    margin: 0;
    border: none;
    flex-grow: 0;

    &:hover {
      background: lighten($othermo-red, 10);
    }

    &:focus {
      outline: thin solid white;
    }
  }
</style>
