export const RemoteAccessTranslationsEn = {
  page_title: 'Remote Access',
  disconnect: 'Disconnect',
  connect: 'Connect',
  password: 'Password',
  username: 'Username',
  enter_credentials: 'Enter your credentials',
  state: {
    idle: 'Idle',
    connecting: 'Connecting',
    waiting: 'Waiting for response',
    connected: 'Connected',
    disconnecting: 'Disconnecting',
    disconnected: 'Disconnected',
  },
  error: {
    connecting: 'Error connecting: {error}',
  },
}
