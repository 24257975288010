import type SiteNotificationGroup from '@/api/notifications/models/SiteNotificationGroup'
import type { NotificationFragment } from '@/generated/graphql'

export enum NotificationFilter {
  UNREAD = 'unread',
  READ = 'read',
  ALL = 'all',
}

export default interface INotificationClient {
  getNotificationCount(): Promise<number>

  getReadNotificationCount(): Promise<number>

  getNotifications(
    filter: NotificationFilter,
    pageSize: number,
    pageNumber: number,
  ): Promise<NotificationFragment[]>

  getNotificationGroups(filter: NotificationFilter): Promise<SiteNotificationGroup[]>

  markNotificationRead(notificationId: string): Promise<void>

  markMultipleAsRead(notifications: NotificationFragment[]): Promise<void>

  markAllNotificationsRead(): Promise<void>
}
