import { action, createModule, extractVuexModule, mutation } from 'vuex-class-component'
import { config } from '@/config'

const GuacamoleAuthenticationModule = createModule({ namespaced: 'guacamoleAuth' })

async function makeTokenRequest(data: URLSearchParams) {
  const fetchResult = await fetch(
    `https://auth.othermo.de/realms/${config.keycloak.realm}/protocol/openid-connect/token`,
    {
      method: 'POST',
      body: data,
      headers: {
        Accept: 'application/json',
      },
    },
  )

  const tokens = await fetchResult.json()
  return tokens
}

export class GuacamoleAuthStore extends GuacamoleAuthenticationModule {
  private accessToken: string | null = null

  private refreshToken: string | null = null

  private tokenExpiration: number | null = null

  private refreshTokenExpiration: number | null = null

  private _idToken: string | null = null

  private _guacamoleToken: string | null = null

  @action
  async getIdToken() {
    if ((this.tokenExpiration ?? 0) > Date.now()) {
      return this._idToken
    } else if ((this.refreshTokenExpiration ?? 0) > Date.now()) {
      await this.updateToken()
      return this._idToken
    } else {
      return null
    }
  }

  @action
  async fetchToken(code: string) {
    console.log('fetchToken triggered')
    const data = new URLSearchParams()
    data.set('code', code)
    data.set('grant_type', 'authorization_code')
    data.set('client_id', config.keycloak.clientId)
    data.append(
      'redirect_uri',
      window.location.origin + window.location.pathname + window.location.hash,
    )

    const tokenResponse = await makeTokenRequest(data)
    this.setAccessTokens(tokenResponse)
  }

  @action
  async updateToken() {
    if (this.refreshToken === null) {
      throw new Error('No refresh token available')
    }
    if (Date.now() >= (this.refreshTokenExpiration ?? 0)) {
      throw new Error('Refresh token expired')
    }

    const data = new URLSearchParams()
    data.set('refresh_token', this.refreshToken)
    data.set('grant_type', 'refresh_token')
    data.set('client_id', config.keycloak.clientId)

    const tokenResponse = await makeTokenRequest(data)
    this.setAccessTokens(tokenResponse)
  }

  @mutation
  setAccessTokens(tokenResponse: {
    id_token: string
    access_token: string
    refresh_token: string
    refresh_expires_in: number
    expires_in: number
  }) {
    console.log('setting tokens')
    this.refreshTokenExpiration = Date.now() + tokenResponse.refresh_expires_in * 1000
    this.tokenExpiration = Date.now() + tokenResponse.expires_in * 1000

    this.accessToken = tokenResponse.access_token
    this.refreshToken = tokenResponse.refresh_token
    this._idToken = tokenResponse.id_token
  }

  set guacamoleToken(authToken: string | null) {
    this._guacamoleToken = authToken
  }

  get guacamoleToken(): string | null {
    return this._guacamoleToken
  }

  get hasGuacamoleCredentials(): boolean {
    return (
      this._guacamoleToken !== null ||
      (this.tokenExpiration ?? 0) > Date.now() ||
      (this.refreshTokenExpiration ?? 0) > Date.now()
    )
  }
}

export const guacamoleAuth = extractVuexModule(GuacamoleAuthStore)
