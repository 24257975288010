import ThingReference from '@/components/shared/ThingReference'
import type { SeverityLevel } from '@/api/alarm/models/dto/SeverityLevel'
import type { Duration } from 'moment'
import moment from 'moment'
import { AlertRule } from '@/components/site/alerts/wizard/models/AlertRule'
import type { ControlDeviationAlertParamDTO } from '@/api/alarm/models/dto/AlertParamDTOs'

export const SUPPORTED_TYPES = ['']

export class ControlDeviationAlertRule extends AlertRule {
  constructor(
    public statusPath: ThingReference | null,
    public setpointPath: ThingReference | null,
    public setpointValue: number | null,
    public threshold: number,
    public timespan: Duration,
    public severity: SeverityLevel,
  ) {
    super(timespan, severity)
  }

  get isValid() {
    return (
      this.threshold != null &&
      this.statusPath != null &&
      this.statusPath.thingId != null &&
      ((this.setpointPath != null && this.setpointPath.thingId != null) ||
        this.setpointValue != null) &&
      this.checkType()
    )
  }

  static default() {
    return new ControlDeviationAlertRule(
      null,
      null,
      null,
      0,
      moment.duration(15, 'minutes'),
      'error',
    )
  }

  static fromObject(
    object: Omit<
      ControlDeviationAlertRule,
      | 'isValid'
      | 'checkBooleanConstraints'
      | 'checkType'
      | 'checkComparatorConstraints'
      | 'toDto'
      | 'checkSupportedType'
      | 'convertPercentageFormat'
      | 'convertAlertThreshold'
    >,
  ): ControlDeviationAlertRule {
    return new ControlDeviationAlertRule(
      object.statusPath,
      object.setpointPath,
      object.setpointValue,
      object.threshold,
      object.timespan,
      object.severity,
    )
  }

  static fromDTO(object: ControlDeviationAlertParamDTO): ControlDeviationAlertRule {
    let setpointPath = null
    let setpointValue = null
    if (object.control_path != undefined) {
      setpointPath = ThingReference.fromString(object.control_path)
    } else {
      setpointValue = object.control_value ?? 0
    }
    return new ControlDeviationAlertRule(
      ThingReference.fromString(object.actual_path),
      setpointPath,
      setpointValue,
      object.threshold,
      moment.duration(object.timespan, 's'),
      object.severity,
    )
  }

  /**
   * Checks type in property path and control path
   */
  checkType() {
    return (
      (this.setpointPath == null || this.setpointPath.type === this.statusPath?.type) &&
      this.checkSupportedType()
    )
  }

  toDto() {
    // copied so the value in the modal is not updated
    const ruleCopy: ControlDeviationAlertRule = ControlDeviationAlertRule.fromObject({
      ...JSON.parse(JSON.stringify(this)),
      statusPath: this.statusPath,
      setpointPath: this.setpointPath,
      timespan: this.timespan,
    })
    ruleCopy.convertPercentageFormat(false)

    return {
      actual_path: ruleCopy.statusPath?.toRefString() ?? '',
      control_path: ruleCopy.setpointPath?.toRefString() ?? undefined,
      control_value: ruleCopy.setpointValue ?? undefined,
      threshold: ruleCopy.threshold,
      timespan: ruleCopy.timespan.as('seconds'),
      severity: ruleCopy.severity,
    }
  }

  public checkSupportedType() {
    return (
      this.statusPath != null &&
      this.statusPath.thingId != null &&
      ['number', 'int', 'float', 'double'].includes(this.statusPath.type ?? '')
    )
  }

  /**
   * See {@link #AlertRule.convertPercentageFormat}
   */
  public convertPercentageFormat(scaleUp: boolean) {
    if (this.statusPath?.unit === 'percent') {
      // @ts-ignore
      this.threshold = AlertRule.convertPercentageThreshold(this.threshold, scaleUp)

      if (this.setpointValue) {
        // @ts-ignore
        this.setpointValue = AlertRule.convertPercentageThreshold(this.setpointValue, scaleUp)
      }
    }
  }
}
