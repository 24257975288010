export const scadaTranslationsEn = {
  scada: {
    manage: {
      upload: 'Upload new SVG',
      name: {
        label: 'Name',
        placeholder: 'Enter name',
        desc: 'The name is displayed in the title of the tab. (Max. 30 characters)',
      },

      description: {
        label: 'Description',
        placeholder: 'Description for the SVG',
        desc: 'Enter a short description for the SVG. (Max. 80 characters)',
      },
      svgFile: {
        label: 'SVG',
        desc: 'Upload a SVG file for the site',
        add_file: 'Click to choose a SVG or drop it here.',
        required: 'Please upload a file',
      },
      create_from_digital_twin: 'Create from digital twin',
      keep_svg: 'Keep SVG',
      save: 'Save',
    },
    rete_tab: {
      confirm_delete: 'Delete this tab?',
      undo_warning: 'This cannot be undone.',
      measurement_point: {
        measurement_point: 'Sensor reading',
        measurement_point_standalone: 'Sensor reading (without connectors)',
        title: 'Configure measurement point',
        description: 'Measurement point description',
        create: 'Create measurement point',
      },
      textlabel: 'Textlabel',
      label: 'Name',
      node_rotation: 'Rotation',
      label_position: 'Label position',
      consumer_circuit: 'Consumer circuit',
      flow_sink: 'Flow',
      flow_source: 'Flow',
      return_source: 'Return',
      return_sink: 'Return',
      electricity_sink: 'Power grid',
      electricity_source: 'Power grid',
      gas_sink: 'Gas',
      gas_source: 'Gas',
      heat_exchanger: 'Heat Exchanger',
      hydraulic_switch: 'Hydraulic Switch',
      mixer: 'Mixer',
      pump: 'Pump',
      thermal_storage: 'Storage',
      label_pos: {
        left: 'Left',
        right: 'Right',
        top: 'Top',
        bottom: 'Bottom',
      },
      pump_modal: {
        create: 'Add pump',
        title: 'Configure new pump',
        running_datapoint: 'Datapoint for on/off',
        warning_datapoint: 'Datapoint for active warning',
        error_datapoint: 'Datapoint for active error',
      },
      storage: {
        add_sensor: 'Add temperature sensor',
        value: 'Value',
        sensor_position: 'Position',
        create: 'Add storage',
        modal_title: 'Configure temperature sensors',
        modal_invalid_warning:
          'A value must be chosen for every sensor in order to create a storage element.',
        leave_unsaved: 'Discard changes',
        discard_changes: 'This discards all changes to the temperature sensor.',
      },
    },

    confirm_delete_all: 'This removes all nodes from the editor and cannot be undone.',
    success_saving: 'SCADA representation saved successfully',
    error_loading: 'Could not load SCADA representation: {error}',
    error_saving: 'Could not save SCADA representation: {error}',
    leave_editor_unsaved: 'Leave edit mode without saving',
    discard_editor_changes: 'Discard all changes and leave edit mode?',
  },

  svg_upload_warning: 'Please choose exactly one SVG file for the upload!',
  scada_tab_upload_success: 'Successfully saved SCADA tab!',
  scada_tab_save_error: 'Error occurred while saving the SCADA tab!',
  rete_loading_error: 'Could not load plant schematics!',
  edit_svg: 'Edit SVG',
  svg_upload_btn_update: 'Update SVG',
  error_svg_missing:
    'The Diagram for this site could not be loaded. ' +
    'Try refreshing the page and contact the system administrator if the problem persists.',
  svg_not_found: 'SVG with name "{name}" could not be found.',
  svg_has_errors:
    'This SVG contains invalid references. It may not represent the current state. Please contact the support.',
  svg_loading: 'SVG loading...',
  scada_no_read_permission: "You don't have the permission to see the diagrams of this Site.",
  scada_no_write_permission: "You don't have the permission to edit the diagrams of this Site.",
  template_not_found:
    'Edit template with the name "{templateName}" could not be found.' +
    ' Please contact the othermo support if the problem persists.',
  message_send_success: 'Data successfully sent',
  message_send_some_failed: 'Some values could not be sent',
  message_send_all_failed: 'Sending data failed',
  manual_on: 'Manuel ON',
  manual_off: 'Manuel OFF',
  auto: 'AUTO',
  value_changed_to: 'This value has been set to {value} by another user or process',
  ditto_path: 'Ditto path',
  show_errors: 'Show errors',
  svg_errors: 'Errors in SVG',

  //Form feedback for templates
  feedback_above_max: 'Only values up to {max} are allowed',
  feedback_below_min: 'Only values greater than {min} are allowed',
  feedback_not_in_step:
    'Value can only be set to increments of {step}. Closest possible values are {nextSmaller} and {nextBigger}',
  feedback_invalid_number: 'Please enter a valid number',
  submitting: 'Submitting...',
  submit_success: 'Data submitted successfully',

  template: {
    error: {
      loading_thing: 'The digital twin you are trying to edit could not be loaded: {error}',
    },
  },
  open_digital_twin: 'Open in "digital twins"',

  command_and_response: {
    digital_outputs_of: 'Digital Outputs of ',
    digital_outputs: 'Digital Outputs',
    digital_output: 'Digital Output',
    configuration: 'Configuration',
    description: 'Description',
    description_tooltip: 'Set a meaningful description for this DO.',
    on: 'on',
    off: 'off',
    operations: 'Operations',
    active: 'Active',
  },
  confirm_copy_template_tab: 'Please confirm that this tab should be copied.',
  template_tab: 'Template generated Tab',
  template_generated_tab: 'This is an autogenerated tab. You need to copy this tab to edit it.',
  title_for_copy: 'Please enter a Title for the copy:',
}
