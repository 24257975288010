<template>
  <div style="height: 300px">
    <div class="socket-container">
      <Socket
        v-for="(input, index) in inputs()"
        :key="`input_${index}`"
        v-socket:input="input"
        :left="retrievePositionFromDescriptor(true, index, 'left')"
        :name="input.name"
        :top="retrievePositionFromDescriptor(true, index, 'top')"
        type="input"
      />
      <Socket
        v-for="(output, index) in outputs()"
        :key="`output_${index}`"
        v-socket:output="output"
        :left="retrievePositionFromDescriptor(false, index, 'left')"
        :name="output.name"
        :top="retrievePositionFromDescriptor(false, index, 'top')"
        type="output"
      />
    </div>
    <ThermalStorage :sensorPositionsAndValues="positionAndValues" />
  </div>
</template>

<script lang="ts">
  //@ts-ignore
  import VueRender from 'rete-vue-render-plugin'
  import { Component, Watch } from 'vue-property-decorator'
  import ThermalStorage from '@/components/site/scada/editor-components/rete_components/ThermalStorage.vue'
  import type { TemperatureSensorDTO } from '@/components/site/scada/modals/ThermalStorageConfigurationModal.vue'
  import type { Thing } from '@/api/klempner/models/SiteListResponse'
  import { vxm } from '@/store/store'
  import { mixins } from 'vue-class-component'
  import Socket from '@/components/site/scada/editor-components/Socket.vue'
  import type { DeviceDescriptor } from '@/components/site/scada/editor-components/KnownDevicesAndSockets'
  import DeviceDescriptorUtil from '@/components/site/scada/editor-components/DeviceDescriptorUtil'
  import type { Node, NodeEditor } from 'rete'
  import { resolve } from '@/modules/tools/Resolve'
  import ThingReference from '@/components/shared/ThingReference'

  @Component({
    components: { ThermalStorage, Socket },
  })
  export default class ThermalStorageComponent extends mixins(VueRender.mixin) {
    declare node: Node

    declare editor: NodeEditor

    positionAndValues: { position: number; value: number }[] = []

    get sensors(): TemperatureSensorDTO[] {
      return (this.node.data['sensors'] as TemperatureSensorDTO[]) ?? []
    }

    get things(): Thing[] {
      return vxm.currentSite.things
    }

    async mounted() {
      await this.node.update()
      this.editor.view.updateConnections({ node: this.node })
    }

    @Watch('sensors')
    @Watch('things', { deep: true })
    updateSensorPositionAndValues() {
      this.positionAndValues = this.sensors.map((sensor: TemperatureSensorDTO) => {
        const value = this.findValueForSensor(sensor.dittoPath)
        return { position: sensor.position, value }
      })
    }

    private findValueForSensor(path: string) {
      const dittoReference = ThingReference.fromString(path)
      const propertyPathComponents = path.split('/')

      const thing = this.things.find((thing) => thing.id === dittoReference.thingId)
      if (thing === undefined) {
        return null
      }
      // Because we already found the thing with the right ID, we don't need it in the path to find the value anymore
      // (Array.slice(1) returns all elements except the one with index 0)
      return resolve(propertyPathComponents.slice(1), thing)
    }

    get nodeDescriptor(): DeviceDescriptor {
      return (
        DeviceDescriptorUtil.findDeviceDescriptor(this.node.data.deviceDefinition as string) ?? {
          svg: '',
          inputs: [],
          outputs: [],
        }
      )
    }

    retrievePositionFromDescriptor(
      isInput: boolean,
      index: number,
      requiredValue: 'top' | 'left',
    ): string {
      let deviceDescription
      if (isInput) {
        deviceDescription = this.nodeDescriptor.inputs[index]
      } else {
        deviceDescription = this.nodeDescriptor.outputs[index]
      }

      if (requiredValue === 'top') {
        return deviceDescription.top
      } else {
        return deviceDescription.left
      }
    }
  }
</script>

<style lang="scss" scoped>
  .socket-container {
    position: absolute;
    height: 300px;
    width: 147px;
  }
</style>
