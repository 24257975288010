<template>
  <!--  This is a test template for development-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting"></SubmitOverlay>

      <Title>{{ featureId }}</Title>
      <toggle-item displayName="On" propertyName="set" :featureName="featureId"></toggle-item>
      <toggle-item displayName="On" propertyName="set" :featureName="featureId"></toggle-item>
      <toggle-item displayName="On" propertyName="set" :featureName="featureId"></toggle-item>
      <toggle-item displayName="On" propertyName="set" :featureName="featureId"></toggle-item>
      <toggle-item displayName="On" propertyName="set" :featureName="featureId"></toggle-item>
    </template>
  </div>
</template>

<script lang="ts">
  /* This code does not have to be touched when developing a new template except the class name */
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'
  import MultiGroup from '@/components/site/scada/command_response/toolbox/base/MultiGroup.vue'

  /* REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      MultiGroup,
      TextItem,
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  /* REPLACE 'TestId' WITH A FITTING COMPONENT NAME */
  export default class TestId extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style lang="scss" scoped>
  /* The edit component can be styled here */
</style>
