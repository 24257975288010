import type { AlertDTO } from '@/components/site/alerts/wizard/models/Alert'
import Alert from '@/components/site/alerts/wizard/models/Alert'
import { ControlDeviationAlertRule } from '@/components/site/alerts/wizard/models/ControlDeviationAlertRule'
import type { AlertFragment } from '@/generated/graphql'
import type { SeverityLevel } from '@/api/alarm/models/dto/SeverityLevel'

export default class ControlDeviationAlert extends Alert {
  static default() {
    return new ControlDeviationAlert([ControlDeviationAlertRule.default()], '', 0)
  }

  toDto(): AlertDTO {
    const rules = this.rules as ControlDeviationAlertRule[]
    return {
      name: this.name,
      type: this.getType(),
      params: rules.map((rule) => rule.toDto()),
    }
  }

  getType(): 'controlDeviation' {
    return 'controlDeviation'
  }

  static fromFragment(customAlert: AlertFragment['custom_alert']): Alert {
    if (customAlert == null) {
      throw new TypeError('Alert cannot be null/undefined!')
    }

    let rules: ControlDeviationAlertRule[] = []
    if (Array.isArray(customAlert.params)) {
      rules = customAlert.params.map(ControlDeviationAlertRule.fromDTO) ?? []
    }
    return new ControlDeviationAlert(rules, customAlert.name, customAlert.id)
  }
}

export interface DeviationAlertDto extends AlertDTO {
  type: 'controlDeviation'

  params: {
    actual_path: string
    target_path: string
    target_value: number
    threshold: number
    timespan: number
    severity: SeverityLevel
  }[]
}
