<template>
  <div id="node" :style="styleObject">
    <inline-svg
      id="svg-content"
      :src="require(`@/assets/scada/${svgToUse}`)"
      v-bind="svgProps"
      :width="width"
      :height="height"
      @loaded="svgLoaded"
    />
    <Socket
      v-for="(input, index) in inputs()"
      :key="`input_${index}`"
      v-socket:input="input"
      :name="input.name"
      type="input"
      v-bind="transformedConnectorPositions(nodeDescriptor.inputs, index)"
      @mounted="updateConnections"
      @updated="updateConnections"
    />
    <Socket
      v-for="(output, index) in outputs()"
      :key="`output_${index}`"
      v-socket:output="output"
      :name="output.name"
      type="output"
      v-bind="transformedConnectorPositions(nodeDescriptor.outputs, index)"
      @mounted="updateConnections"
      @updated="updateConnections"
    />
    <div class="text-label">
      <b-form-input
        v-if="editMode && node.data.label !== undefined"
        v-model="node.data.label"
        type="text"
        :placeholder="$t('scada.rete_tab.label')"
        size="sm"
        class="text-center scada-editor-font-size"
      ></b-form-input>
      <span v-else-if="node.data.label !== undefined">{{ node.data.label }}</span>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Watch } from 'vue-property-decorator'
  import Socket from '@/components/site/scada/editor-components/Socket.vue'
  import ScadaNode from '@/components/site/scada/editor-components/rete_components/ScadaNode.vue'
  import type { SocketDescriptor } from '@/components/site/scada/editor-components/KnownDevicesAndSockets'

  /**
   * Displays an interactive SVG that can be rotated in the Editor.
   * @see {@link ScadaReteNodeRotatable} for the Rete Component definition
   */
  @Component({
    components: { Socket },
  })
  export default class ScadaNodeRotatable extends ScadaNode {
    get rotation(): string {
      //@ts-ignore
      return this.node.data.rotation ?? 'east'
    }

    get height() {
      if (this.rotation == 'south' || this.rotation == 'north') {
        // Width is returned on purpose here because it's the only size we have for the svg
        // noinspection JSSuspiciousNameCombination
        return this.nodeDescriptor.width
      }
      return '100%'
    }

    get width() {
      if (this.rotation == 'east' || this.rotation == 'west') {
        return this.nodeDescriptor.width
      }
      return '100%'
    }

    get svgToUse(): string {
      if (this.rotation == 'east') {
        return this.nodeDescriptor.svg!
      }
      return this.nodeDescriptor.rotatedSvgs?.[this.rotation] ?? this.nodeDescriptor.svg!
    }

    get svgProps() {
      if (this.rotation === 'east' || this.rotation === 'west') {
        return { width: this.nodeDescriptor.width ?? '300px', height: 'auto' }
      } else {
        return { height: this.nodeDescriptor.width ?? '300px', width: 'auto' }
      }
    }

    @Watch('rotation')
    async updateConnections() {
      await this.node.update()
      this.editor.view.updateConnections({ node: this.node })
    }

    transformedConnectorPositions(config: SocketDescriptor[], index: number) {
      const socketDescriptor = config[index]
      const top = socketDescriptor.top
      const left = socketDescriptor.left
      switch (this.rotation) {
        case 'south':
          // noinspection JSSuspiciousNameCombination Properties are flipped on purpose here
          return { right: top, top: left }
        case 'west':
          return { bottom: top, right: left }
        case 'north':
          // noinspection JSSuspiciousNameCombination Properties are flipped on purpose here
          return { bottom: left, left: top }
        default:
          return { top: top, left: left }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  #svg-content {
    user-select: none;
  }

  .pointer {
    cursor: pointer !important;
  }

  .text-label {
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    min-width: 200px;
    text-align: center;
  }

  .scada-editor-font-size {
    font-size: $font-size-scada-editor;
  }
</style>
