import type { ApiUrlConfig } from '@/config/apiUrlConfig'

/**
 * Provides the config for the production environment: portal.othermo.de
 */
export const prodConfig: { api: ApiUrlConfig } = {
  api: {
    ditto: {
      domain: 'twin.othermo.de',
      path: '/cloakedapi',
    },
    klempner: {
      url: 'https://api.othermo.de/api/v2/',
    },
    hasura: {
      url: 'https://graphql.api.othermo.de/v1/graphql',
    },
    guacamole: {
      baseDomain: 'guacamole.othermo.de',
    },
    changelogs: {
      overviewURL: 'https://docs.othermo.de/portal-user-docs/main/changelog.html',
      postBaseURL: 'https://docs.othermo.de/portal-user-docs/main/changelogs/',
    },
  },
}
