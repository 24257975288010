import 'regenerator-runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store, vxm } from './store/store'
import BootstrapVue from 'bootstrap-vue'
import TableLitePlugin from 'bootstrap-vue'
import vuexI18n from 'vuex-i18n'
import VModal from 'vue-js-modal'
import { translationsDe } from './languages/de'
import { translationsEn } from './languages/en'
import type { KeycloakInstance } from 'keycloak-js'
import { apolloProvider } from '@/config/apollo'

import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
//@ts-ignore
import LiquorTree from 'liquor-tree-withoutdragndrop/src/main'
import 'vue-class-component/hooks' // import hooks type to enable auto-complete
import VueDragDrop from 'vue-drag-drop'

// Import styles & fonts
import './app.scss'

import { config } from '@/config'
import Component from 'vue-class-component'
import VueObserveVisibility from 'vue-observe-visibility'
import vuelidate from 'vuelidate'

import 'leaflet/dist/leaflet.css'
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'
// noinspection ES6UnusedImports
import L, { Icon } from 'leaflet'

import ApexCharts from 'apexcharts'
import VueCookies from 'vue-cookies'
import Logger from '@/logger'
import InlineSvg from 'vue-inline-svg'
import type { VueKeycloakOptions } from '@dsb-norge/vue-keycloak-js/dist/types'

import VueScreen from 'vue-screen'
import { ThingTranslationPlugin } from '@/plugins/ThingTranslationPlugin'

Vue.use(VueScreen, { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1900 })

Vue.component('LMap', LMap)
Vue.component('LTileLayer', LTileLayer)
Vue.component('LMarker', LMarker)

//@ts-ignore
window.ApexCharts = ApexCharts

// Enable Tree View
Vue.use(LiquorTree)

// Enable Drag and Drop
Vue.use(VueDragDrop)

Vue.config.productionTip = false
Vue.config.performance = config.performanceMeasureEnabled

Vue.filter('kebab', (str: string) => {
  const replace = (s: string) => s.toLowerCase().replace(/ /g, '-')

  return Array.isArray(str) ? str.map(replace) : replace(str)
})

//  Enable Bootstrap for the Site
Vue.use(BootstrapVue)
Vue.use(TableLitePlugin)

// Add i18n to the Site
Vue.use(vuexI18n.plugin, store, {
  warnings: false,
})

Vue.use(ThingTranslationPlugin)

Vue.component('InlineSvg', InlineSvg)

Vue.i18n.add('en', translationsEn)
Vue.i18n.add('de', translationsDe)
vxm.settings.init(Vue.i18n)

Vue.use(VueObserveVisibility)

// Form validation
Vue.use(vuelidate)

Vue.use(VModal, { dynamic: true, injectModalsContainer: true })

Vue.use(VueCookies)

//leaflet icon fix: https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
type D = Icon.Default & {
  _getIconUrl?: string
}

delete (Icon.Default.prototype as D)._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

/**
 * Load Keycloak Options and make sure that you can only see the page if you are authenticated
 */
const options: VueKeycloakOptions & { config: { realm: string } } = {
  init: {
    checkLoginIframe: false,
    onLoad: 'login-required',
    redirectUri: window.location.href,
    enableLogging: true,
  },
  config: config.keycloak,

  onReady: async (keycloak: KeycloakInstance) => {
    Logger.info('Keycloak is ready')
    await vxm.user.loadUserProfile(keycloak)
    createVue()
  },
  onAuthRefreshError() {
    Logger.info('could not refresh token')
  },

  logout: {
    redirectUri: 'https://www.othermo.de/',
  },
}

const urlParams = new URLSearchParams(window.location.search)

// Set realm if defined in Cookie
if (Vue.$cookies.isKey('realm')) {
  console.log('cookie set, initializing vue')
  options.config!.realm = Vue.$cookies.get('realm')
  Vue.use(VueKeyCloak, options)
} else if (urlParams.has('realm')) {
  console.log('param set, initializing vue')
  options.config!.realm = urlParams.get('realm') as string
  Vue.use(VueKeyCloak, options)
} else {
  Logger.warn('No cookie found or param found, redirecting to login page')
  const loginPageURL = new URL('/login.html', window.location.origin)
  loginPageURL.searchParams.append('redirectTo', window.location.href)
  window.location.href = loginPageURL.href
}
//Note: Realm wird nur nach einem erfolgreichen Login persistiert

// Register the router hooks with their names
Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate'])

/**
 * Creates the actual Vue instance
 */
function createVue() {
  new Vue({
    router,
    store,
    apolloProvider,

    render: (h) => h(App),
  }).$mount('#app')
}
