<template>
  <b-input-group :append="displayUnit" :size="size">
    <b-input
      v-model="value"
      v-bind="{
        min: toDisplayUnit(min),
        max: toDisplayUnit(max),
        step: adjustedStep,
      }"
      :state="isValid && valueBelowMax && valueAboveMin && valueInStep"
      number
      type="number"
      @input="modelChanged"
    />
  </b-input-group>
</template>

<script lang="ts">
  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
  import UnitHandler from '@/modules/units/UnitHandler'
  import type { unit } from '@othermo/convert-units'
  import Logger from '@/logger'

  const convert = require('@othermo/convert-units')

  @Component
  export default class UnitInput extends Vue {
    @Model('value-changed', { required: true })
    readonly currentValue!: number

    @Prop({ required: true })
    readonly baseUnit!: string

    @Prop({ default: null })
    readonly step!: number | null

    @Prop({ default: null })
    readonly min!: number | null

    @Prop({ default: null })
    readonly max!: number | null

    @Prop({ default: 'sm' })
    readonly size!: 'sm' | 'md'

    /** Gets set to true on first user input to stop the input field form updating the value from ditto */
    private changed = false

    private unitHandler = UnitHandler.getInstance()

    private value: number | string = 0

    get displayUnit() {
      return this.unitHandler.formatUnit(this.unit, 'float')
    }

    get unit() {
      let range: number
      if (this.step != null) {
        range = this.step
      } else {
        range = this.currentValue
      }
      if (this.baseUnit == 'percent') {
        return this.baseUnit
      }
      try {
        const bestUnit = convert(range)
          .from(<unit>this.baseUnit)
          .toBest()
        return bestUnit.unit
      } catch (e) {
        Logger.warn(`Unsupported unit ${this.baseUnit}`)
        return this.baseUnit
      }
    }

    get adjustedStep() {
      if (this.step == null) {
        return 'any'
      }
      return this.toDisplayUnit(this.step)
    }

    get isValid() {
      if (!this.changed) {
        return true
      }
      return this.value !== ''
    }

    get valueBelowMax() {
      if (this.max == null) {
        return true
      }

      return typeof this.value == 'number' && this.toBaseUnit(this.value) <= this.max
    }

    get valueAboveMin() {
      if (this.min != null) {
        return typeof this.value == 'number' && this.toBaseUnit(this.value) >= this.min
      }
      return true
    }

    get valueInStep() {
      if (this.step == null) {
        return true
      }
      if (typeof this.value == 'number') {
        return (
          Math.round(Math.abs(this.toBaseUnit(this.value) - (this.min ?? 0)) * 100) %
            (this.step * 100) ===
          0
        )
      }
    }

    mounted() {
      this.changed = false
      this.valueChanged()
    }

    activated() {
      this.changed = false
      this.valueChanged()
    }

    toDisplayUnit(value: number | null) {
      if (value == null) {
        return null
      }
      if (this.baseUnit === 'percent') {
        return value * 100
      }

      try {
        return convert(value).from(this.baseUnit).to(this.unit)
      } catch (e) {
        Logger.warn(`unsupported unit "${this.baseUnit}"`)
        return value
      }
    }

    toBaseUnit(value: number | null) {
      if (value == null) {
        return null
      }
      if (this.unit === 'percent') {
        return value / 100
      }
      try {
        return convert(value).from(this.unit).to(this.baseUnit)
      } catch (e) {
        Logger.warn(`unsupported unit "${this.unit}"`)
        return value
      }
    }

    @Watch('currentValue')
    valueChanged() {
      if (!this.changed) {
        this.value = this.toDisplayUnit(this.currentValue)
      }
    }

    modelChanged(value: string) {
      this.changed = true
      const parsedValue = Number(value)
      if (!Number.isNaN(parsedValue)) {
        this.$emit('value-changed', this.toBaseUnit(parsedValue))
      }
      this.$emit('valid-changed', {
        valid: this.isValid,
        belowMax: this.valueBelowMax,
        aboveMin: this.valueAboveMin,
        inStep: this.valueInStep,
      })
    }
  }
</script>

<style lang="scss" scoped></style>
