<template>
  <!--This Template shows a dialog with minimal configuration-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting" />

      <Title>Heatmeter</Title>
      <Info>{{ thing.attributes.classDescription }}</Info>

      <numeric-value-item
        :value="thing.features.MBUS.properties.configuration.updateInterval"
        valuePath="features.MBUS.properties.configuration.updateInterval"
        displayName="Update Interval"
        featureName="MBUS"
        maxValue="50"
        propertyType="operation"
        propertyName="setUpdateInterval"
        minValue="0"
        msgParamName="interval"
      />
      <button-item
        propertyType="operation"
        propertyName="update"
        displayName="Update Value"
        featureName="MBUS"
      />
    </template>
  </div>
</template>

<script lang="ts">
  /*This code does not has to be touched when developing a new template except the ClassName*/
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'

  /*REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  /*REPLACE 'TestId' WITH A FITTING COMPONENT NAME*/
  export default class ShowcaseHeatmeter extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style lang="scss" scoped>
  /*The edit component can be styled here*/
</style>
