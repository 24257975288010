import Vue from 'vue'
import Vuex from 'vuex'

import { user, UserStore } from '@/store/modules/user.store'
import createPersistedState from 'vuex-persistedstate'
import { settings, SettingsStore } from '@/store/modules/settings.store'
import { createProxy } from 'vuex-class-component'
import { charts, ChartStateStore } from '@/store/modules/chartState.store'
import { alertWizard, AlertWizardStore } from '@/store/modules/AlertWizard.store'
import { userInfo, UserInfoStore } from '@/store/modules/UserInfo.store'
import { alarmDashboardStore, DashboardFilterStore } from '@/store/modules/dashboardFilter.store'
import { currentSite, CurrentSiteStore } from '@/store/modules/CurrentSite.store'
import { scadaEditor, ScadaEditorStore } from '@/store/modules/scadaEditor.store'
import { notifications, NotificationsStore } from '@/store/modules/Notifications.store'
import { guacamoleAuth, GuacamoleAuthStore } from '@/store/modules/GuacamoleAuth.store'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    ...user,
    ...userInfo,
    ...settings,
    ...charts,
    ...alertWizard,
    ...scadaEditor,
    ...alarmDashboardStore,
    ...currentSite,
    ...notifications,
    ...guacamoleAuth,
  },
  plugins: [createPersistedState({ paths: ['settings'] })],
})

export const vxm = {
  // You have to explicitly cast it to get intellisense working in .vue files
  user: createProxy(store, UserStore) as UserStore,
  userInfo: createProxy(store, UserInfoStore) as UserInfoStore,
  settings: createProxy(store, SettingsStore) as SettingsStore,
  charts: createProxy(store, ChartStateStore) as ChartStateStore,
  alertWizard: createProxy(store, AlertWizardStore) as AlertWizardStore,
  scadaEditor: createProxy(store, ScadaEditorStore) as ScadaEditorStore,
  dashboardFilterStore: createProxy(store, DashboardFilterStore) as DashboardFilterStore,
  currentSite: createProxy(store, CurrentSiteStore) as CurrentSiteStore,
  notifications: createProxy(store, NotificationsStore) as NotificationsStore,
  guacamoleAuth: createProxy(store, GuacamoleAuthStore) as GuacamoleAuthStore,
}
