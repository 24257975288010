export const scadaTranslationsDe = {
  scada: {
    manage: {
      upload: 'Neues SVG hochladen',
      name: {
        label: 'Name',
        placeholder: 'Name des SVGs',
        desc: 'Der Name wird im Titel des Tabs angezeigt. (Max. 30 Zeichen.)',
      },
      description: {
        label: 'Beschreibung',
        placeholder: 'Beschreibung des SVGs',
        desc: 'Geben Sie eine kurze Beschreibung für das SVG ein. (Max. 80 Zeichen.)',
      },
      svgFile: {
        label: 'SVG',
        desc: 'Ein SVG für die Anlage hochladen',
        add_file: 'Klicken Sie, um ein SVG auszuwählen oder ziehen Sie es hier rein.',
        required: 'Bitte laden Sie ein SVG hoch',
      },
      create_from_digital_twin: 'Basierend auf digitalen Zwillingen erstellen',
      keep_svg: 'SVG weiterverwenden',
      save: 'Speichern',
      update: 'SVG aktualisieren',
    },
    rete_tab: {
      confirm_delete: 'Diesen Tab löschen?',
      undo_warning: 'Dies kann nicht rückgängig gemacht werden.',
      measurement_point: {
        measurement_point: 'Messpunkt',
        measurement_point_standalone: 'Messpunkt (ohne Verbinder)',
        title: 'Messpunkt konfigurieren',
        description: 'Messpunkt Beschreibung',
        create: 'Messpunkt erstellen',
      },
      textlabel: 'Textfeld',
      label: 'Name',
      node_rotation: 'Drehung',
      label_position: 'Textposition',
      consumer_circuit: 'Verbraucherkreis',
      flow_sink: 'Netzanschluss Vorlauf',
      flow_source: 'Netzanschluss Vorlauf',
      return_source: 'Netzanschluss Rücklauf',
      return_sink: 'Netzanschluss Rücklauf',
      electricity_sink: 'Netzanschluss Strom',
      electricity_source: 'Netzanschluss Strom',
      gas_sink: 'Netzanschluss Gas',
      gas_source: 'Netzanschluss Gas',
      heat_exchanger: 'Wärmetauscher',
      hydraulic_switch: 'Hydraulische Weiche',
      thermal_storage: 'Speicher',
      mixer: 'Mischer',
      pump: 'Pumpe',
      label_pos: {
        left: 'Links',
        right: 'Rechts',
        top: 'Oben',
        bottom: 'Unten',
      },
      pump_modal: {
        create: 'Pumpe einfügen',
        title: 'Neue Pumpe konfigurieren',
        running_datapoint: 'Datenpunkt für Ein/Aus',
        warning_datapoint: 'Datenpunkt für aktive Warnung',
        error_datapoint: 'Datenpunkt für aktiven Fehler',
      },
      storage: {
        add_sensor: 'Temperaturfühler hinzufügen',
        value: 'Datenpunkt',
        sensor_position: 'Position',
        create: 'Wärmespeicher erstellen',
        modal_title: 'Temperaturfühler zuordnen',
        modal_invalid_warning:
          'Es muss für jeden Temperaturfühler ein Datenpunkt ausgewählt werden, um den Wärmespeicher zu erstellen.',
        leave_unsaved: 'Änderungen verwerfen',
        discard_changes: 'Alle Änderungen am Temperaturfühler werden verworfen.',
      },
    },
    confirm_delete_all:
      'Alle Elemente werden aus der SCADA Repräsentation entfernt. Dies kann nicht rückgängig gemacht werden.',
    success_saving: 'SCADA Repräsentation gespeichert',
    error_loading: 'SCADA Repräsentation konnte nicht geladen werden: {error}',
    error_saving: 'SCADA Repreäsentation konnte nicht gespeichert werden: {error}',
    leave_editor_unsaved: 'Bearbeiten ohne speichern beenden',
    discard_editor_changes: 'Wollen sie alle ungespeicherten Änderungen verwerfen?',
  },

  svg_upload_warning: 'Bitte wählen Sie nur genau ein SVG zum Upload aus!',
  scada_tab_upload_success: 'SCADA Tab wurde erfolgreich gespeichert!',
  scada_tab_save_error: 'Fehler beim Speichern des SCADA Tabs!',
  rete_loading_error: 'Die Anlagen-Schema konnten nicht geladen werden!',
  error_svg_missing:
    'Das Diagramm für diese Anlage konnte nicht geladen werden. Versuchen Sie bitte, die Seite neu ' +
    'zu laden und kontaktieren Sie den Systemadministrator, wenn das Problem weiter besteht.',
  delete_svg: 'SVG löschen?',
  confirm_delete: 'Bitte bestätigen Sie, dass die das SVG {title} löschen wollen.',
  edit_svg: 'SVG bearbeiten',
  svg_has_errors:
    'Diese SVG enthält ungültige Referenzen. Es kann sein, dass es nicht den aktuellen Zustand repräsentiert. Bitte wenden Sie sich an den Support.',
  svg_loading: 'SVG lädt...',
  svg_not_found: 'Das SVG wurde nicht gefunden.',
  scada_no_read_permission: 'Keine Berechtigung zum Lesen von SCADA Diagrammen',
  scada_no_write_permission: 'Keine Berechtigung zum Schreiben von SCADA Diagrammen',
  template_not_found:
    'Die Bearbeitungsmaske mit dem Namen "{templateName}" konnte nicht gefunden werden.' +
    ' Wenden Sie sich bitte an den Support.',
  message_send_success: 'Thing erfolgreich aktualisiert',
  message_send_some_failed: 'Einige Werte konnten nicht an Ditto geschickt werden',
  message_send_all_failed: 'Senden fehlgeschlagen',
  manual_on: 'Manuell EIN',
  manual_off: 'Manuell AUS',
  auto: 'AUTO',
  value_changed_to: 'Dieser Wert wurde zwischenzeitlich zu {value} geändert.',
  ditto_path: 'Ditto-Pfad',
  svg_errors: 'Fehler im SVG',
  show_errors: 'Fehler anzeigen',

  //Form feedback for templates
  feedback_above_max: 'Nur Werte kleiner als {max} erlaubt',
  feedback_below_min: 'Nur Werte größer als {min} erlaubt',
  feedback_not_in_step:
    'Wert kann nur in Schritten von {step} gesetzt werden. Die nächstmöglichen Werte sind {nextSmaller} und {nextBigger}',
  feedback_invalid_number: 'Bitte geben Sie eine gültige Zahl ein',
  submitting: 'Wird gesendet...',
  submit_success: 'Erfolgreich gesendet',

  template: {
    error: {
      loading_thing: 'Das zu bearbeitende Gerät konnte nicht geladen werden: {error}',
    },
  },
  open_digital_twin: 'In "Digitale Zwillinge" öffnen',

  command_and_response: {
    digital_outputs_of: 'Digitale Ausgänge von ',
    digital_outputs: 'Digitale Ausgänge',
    digital_output: 'Digitaler Ausgang',
    configuration: 'Konfiguration',
    description: 'Beschreibung',
    description_tooltip:
      'Definiere eine aussagekräftige Beschreibung für diesen Digitalen Ausgang.',
    on: 'an',
    off: 'aus',
    operations: 'Operationen',
    active: 'Aktiv',
  },
  confirm_copy_template_tab: 'Bitte bestätigen Sie, dass dieser Tab kopiert werden soll.',
  template_tab: 'Tab aus Vorlage generiert',
  template_generated_tab:
    'Dieser Tab wurde automatisch aus einer Vorlage erstellt, zum Bearbeiten müssen sie eine Kopie speichern.',
  title_for_copy: 'Bitte geben Sie einen Titel für die Kopie ein:',
}
