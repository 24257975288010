import type { SVGBinding } from '@/modules/svgbindings/SVGBinding'
import PathHandler from '@/modules/svgbindings/PathHandler'
import type DomVisitor from '@/modules/svgbindings/DomVisitor'
import ActionVisitor from '@/modules/svgbindings/visitors/ActionVisitor'
import BindingBaseVisitor from '@/modules/svgbindings/visitors/BindingDomVisitor'
import SvgLinkVisitor from '@/modules/svgbindings/visitors/SvgLinkVisitor'
import Logger from '@/logger'

// For some stupid reason, hasAttribute is case sensitive and the browser implementation lower cases all attributes.
// Therefore, these Enums must be lower case, even though the spec says otherwise.
export enum Attribute {
  REF = 'othermo:ref',
  TEXT_FORMAT = 'othermo:format',
  TEXT_FALSE = 'othermo:textfalse',
  TEXT_TRUE = 'othermo:texttrue',
  VISIBILITY = 'othermo:visibleif',
  COLOR = 'othermo:color',
  COLOR_MAP = 'othermo:colormap',
  MAP_TYPE = 'othermo:maptype',
  TEMPLATE_ID = 'othermo:templateid',
  THING_ID = 'othermo:thingid',
  FEATURE_ID = 'othermo:featureid',
  SVGLINK = 'othermo:svglink',
}

export const SVG_ERROR_PREFIX = 'SVG ERROR: '

export function safeGet(element: Element, attributeName: string): string | undefined {
  if (element.hasAttribute(attributeName)) {
    return element.getAttribute(decodeURI(attributeName)) ?? undefined //Necessary for escaped strings
  } else {
    Logger.warn(`Missing expected attribute "${attributeName}" on element `, element)
    return undefined
  }
}

/**
 * Recursively iterates through all children of element and applies all visitors
 * @param element
 * @param basePath
 * @param visitor
 */
function visitElementsRecursive(element: Element, basePath: string | null, visitor: DomVisitor) {
  if (element.hasAttribute(Attribute.REF)) {
    basePath = PathHandler.updatePath(basePath, safeGet(element, Attribute.REF)!)
  }

  visitor.visit(element, basePath!)

  for (const child of element.children) {
    visitElementsRecursive(child, basePath, visitor)
  }
}

/**
 * Iterates through element and finds all Bindings
 * @param svg SVG root element
 * @param basePath Optional base path for relative paths in SVG
 */
export function parseBindings(svg: Element, basePath: string | null = null): SVGBinding[] {
  const bindingVisitor = new BindingBaseVisitor()

  visitElementsRecursive(svg, basePath, bindingVisitor)

  return bindingVisitor.collectResults()
}

/**
 * Iterates through element recursively and finds all Elements that have an action associated
 * @param element
 */
export function findActions(element: Element): Element[] {
  const visitor = new ActionVisitor()
  visitElementsRecursive(element, '', visitor)
  return visitor.collectResults()
}

/**
 * Iterates through element and finds all links to other SVGs
 * @param element base element to recursively visit
 */
export function findLinks(element: Element): Element[] {
  const visitor = new SvgLinkVisitor()
  visitElementsRecursive(element, '', visitor)
  return visitor.collectResults()
}
