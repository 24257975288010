import type ThingReference from '@/components/shared/ThingReference'

export const SITE_TREE_MAX_NAME_LENGTH = 50

export default interface SiteTreeNode {
  text: string

  children: SiteTreeNode[]

  state: unknown

  data: {
    dropData: ThingReference
    type: string
    keywords: string
  }
}
