<template>
  <!--  This is a test template for development-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting"></SubmitOverlay>

      <Title>Kesselsteuerung</Title>
      <Info>Stellen Sie hier die Parameter des Wolf BM Moduls ein.</Info>

      <enum-item
        :featureName="featureId"
        :map="[
          ['2', 'Automatikbetrieb'],
          ['5', 'Sommerbetrieb'],
          ['3', 'Heizbetrieb'],
          ['4', 'Absenkbetrieb'],
          ['1', 'Standby-Betrieb'],
          ['0', 'Abgastest'],
        ]"
        :value="thing.features[featureId].properties.configuration.operatingMode"
        displayName="Betriebsart"
        propertyType="configuration"
        propertyName="operatingMode"
        toolTip="Betriebsart des Kessels"
      />
      <numeric-value-item
        :featureName="featureId"
        :valuePath="`features.${featureId}.properties.configuration.setpointCorrection`"
        displayName="Sollwertkorrektur"
        propertyType="configuration"
        propertyName="setpointCorrection"
        toolTip="Sollwertkorrektur"
      />
      <numeric-value-item
        :featureName="featureId"
        :valuePath="`features.${featureId}.properties.configuration.dayTemperature`"
        displayName="Tagtemperatur"
        propertyType="configuration"
        propertyName="dayTemperature"
        toolTip="Tagtemperatur"
      />
      <numeric-value-item
        :featureName="featureId"
        :valuePath="`features.${featureId}.properties.configuration.economyTemperature`"
        displayName="Spartemperatur"
        propertyType="configuration"
        propertyName="economyTemperature"
        toolTip="Spartemperatur"
      />
      <enum-item
        :featureName="featureId"
        :map="[
          ['0', 'Zeitprogramm 1'],
          ['1', 'Zeitprogramm 2'],
          ['2', 'Zeitprogramm 3'],
        ]"
        :value="thing.features[featureId].properties.configuration.timeProgram"
        displayName="Zeitprogramm"
        propertyType="configuration"
        propertyName="timeProgram"
        toolTip="Zeitprogramm"
      />
      <numeric-value-item
        :featureName="featureId"
        :valuePath="`features${featureId}.properties.configuration.hotWaterTemperature`"
        displayName="Warmwassersolltemperatur"
        propertyType="configuration"
        propertyName="hotWaterTemperature"
        toolTip="Eingestellte Warmwassersolltemperatur"
      />
    </template>
  </div>
</template>

<script lang="ts">
  /*This code does not has to be touched when developing a new template except the ClassName*/
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'

  /*REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      TextItem,
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  /*REPLACE 'TestId' WITH A FITTING COMPONENT NAME*/
  export default class WolfBoiler extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style scoped lang="scss">
  /*The edit component can be styled here*/
</style>
