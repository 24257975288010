<template>
  <b-badge v-if="toolTipText" v-b-tooltip.hover :title="toolTipText" class="mx-2">
    <span class="material-icons">info_outline</span>
  </b-badge>
</template>

<script lang="ts">
  import { Component, Prop } from 'vue-property-decorator'
  import Vue from 'vue'

  /**
   * Component that shows a short explanation for a configuration component.
   */
  @Component({
    components: {},
  })
  export default class ToolTip extends Vue {
    @Prop()
    private toolTipText!: string
  }
</script>

<style lang="scss" scoped>
  .badge {
    cursor: default;

    span {
      font-size: 1.5em;
    }
  }
</style>
