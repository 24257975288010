<template>
  <b-container id="content" class="container overflow-auto">
    <pre>{{ JSON.stringify(translations, null, 2) }}</pre>
  </b-container>
</template>

<script lang="ts">
  import { Component } from 'vue-property-decorator'
  import Vue from 'vue'
  import ThermalStorageConfigurationModal from '@/components/site/scada/modals/ThermalStorageConfigurationModal.vue'
  import Pump from '@/components/site/scada/editor-components/rete_components/Pump.vue'
  import { config } from '@/config'
  import KeycloakTools from '@/modules/tools/KeycloakTools'

  @Component({
    components: { Pump, ThermalStorageConfigurationModal },
  })
  export default class TestPage extends Vue {
    translations: Record<string, Record<string, string>> = {}

    async created() {
      const result = await fetch(
        `${config.api.klempner.url}translations/property-names?realm=${KeycloakTools.getRealm(
          this.$keycloak,
        )}`,
        {
          headers: { authorization: 'Bearer ' + this.$keycloak.token },
        },
      )
      this.translations = await result.json()
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    max-height: 90vh;
    overflow: hidden;
  }
</style>
