import { Socket } from 'rete'
import Logger from '@/logger'
import { ScadaReteComponents } from '@/components/site/scada/editor-components/rete_components/rete_nodes/ReteNode'

export enum SocketType {
  gas = 'scada_gas',
  flow = 'flow',
  return = 'return',
  electricity = 'scada_electricity',
  data = 'data',
  universal = 'universal',
}

export enum ToolboxComponent {
  Generic = 'GenericToolboxComponent',
  DummyNode = 'ScadaDummyNode',
}

export const SOCKET_GAS = new Socket(SocketType.gas)
export const SOCKET_FLOW = new Socket(SocketType.flow)
export const SOCKET_RETURN = new Socket(SocketType.return)
export const SOCKET_EL = new Socket(SocketType.electricity)
export const SOCKET_DATA = new Socket(SocketType.data)

export const SOCKET_UNIVERSAL = new Socket(SocketType.universal)

// Allow connection between flow and return
SOCKET_RETURN.combineWith(SOCKET_FLOW)
SOCKET_FLOW.combineWith(SOCKET_RETURN)

// Allow connection from Universal output to another input
SOCKET_UNIVERSAL.combineWith(SOCKET_GAS)
SOCKET_UNIVERSAL.combineWith(SOCKET_FLOW)
SOCKET_UNIVERSAL.combineWith(SOCKET_RETURN)
SOCKET_UNIVERSAL.combineWith(SOCKET_EL)
SOCKET_UNIVERSAL.combineWith(SOCKET_DATA)
SOCKET_UNIVERSAL.combineWith(SOCKET_UNIVERSAL)

// Allow connection from Universal output to another input
SOCKET_GAS.combineWith(SOCKET_UNIVERSAL)
SOCKET_FLOW.combineWith(SOCKET_UNIVERSAL)
SOCKET_RETURN.combineWith(SOCKET_UNIVERSAL)
SOCKET_EL.combineWith(SOCKET_UNIVERSAL)
SOCKET_DATA.combineWith(SOCKET_UNIVERSAL)

export const allSocketTypes = [
  SOCKET_GAS,
  SOCKET_FLOW,
  SOCKET_RETURN,
  SOCKET_EL,
  SOCKET_DATA,
  SOCKET_UNIVERSAL,
]

export function getSocketByType(socketType: SocketType) {
  switch (socketType) {
    case SocketType.flow:
      return SOCKET_FLOW
    case SocketType.return:
      return SOCKET_RETURN
    case SocketType.electricity:
      return SOCKET_EL
    case SocketType.gas:
      return SOCKET_GAS
    case SocketType.data:
      return SOCKET_DATA
    case SocketType.universal:
      return SOCKET_UNIVERSAL
    default:
      Logger.warn(`The socket type ${socketType} was not found!`)
      return SOCKET_UNIVERSAL
  }
}

export interface SocketDescriptor {
  top: string

  left: string

  type: SocketType
}

export interface ThingDescriptor {
  thingId: string

  definition: string

  displayName?: string

  deviceDescriptor: DeviceDescriptor

  /** Name of the component that is shown in the toolbox */
  editorToolboxComponent: string

  /** Name of the Rete component shown in the editor itself */
  editorReteComponent: string
}

export interface DeviceDescriptor {
  svg?: string

  /** SVGs to use for rotation if applicable */
  rotatedSvgs?: Record<string, string>

  /** Width to use in the editor/view */
  width?: string

  /** Width to use in the toolbox */
  previewWidth?: string

  /** Which template (if any) to open in the C&R modal when the node is clicked */
  commandResponseTemplate?: string

  /** Name of the feature (if any) to open in the C&R modal when node is clicked */
  commandResponseFeature?: string

  inputs: SocketDescriptor[]

  outputs: SocketDescriptor[]

  name?: string

  icon?: string

  toolboxComponent?: string

  reteComponent?: string
}

export const GenericScadaComponents: Record<string, DeviceDescriptor> = {
  'flow-sink': {
    svg: 'input.svg',
    width: '80px',
    inputs: [{ top: '50%', left: '0%', type: SocketType.flow }],
    outputs: [],
    name: 'flow_sink',
  },
  'flow-source': {
    svg: 'input.svg',
    width: '80px',
    inputs: [],
    outputs: [{ top: '50%', left: '100%', type: SocketType.flow }],
    name: 'flow_source',
  },
  'return-source': {
    svg: 'input.svg',
    width: '80px',
    inputs: [],
    outputs: [{ top: '50%', left: '100%', type: SocketType.return }],
    name: 'return_source',
  },
  'return-sink': {
    svg: 'input.svg',
    width: '80px',
    inputs: [{ top: '50%', left: '0%', type: SocketType.return }],
    outputs: [],
    name: 'return_sink',
  },
  'electricity-sink': {
    svg: 'input.svg',
    width: '80px',
    inputs: [{ top: '50%', left: '0%', type: SocketType.electricity }],
    outputs: [],
    name: 'electricity_source',
  },
  'electricity-source': {
    svg: 'input.svg',
    width: '80px',
    inputs: [],
    outputs: [{ top: '50%', left: '100%', type: SocketType.electricity }],
    name: 'electricity_sink',
  },
  'gas-sink': {
    svg: 'input.svg',
    width: '80px',
    inputs: [{ top: '50%', left: '0%', type: SocketType.gas }],
    outputs: [],
    name: 'gas_sink',
  },
  'gas-source': {
    svg: 'input.svg',
    width: '80px',
    inputs: [],
    outputs: [{ top: '50%', left: '100%', type: SocketType.gas }],
    name: 'gas_source',
  },
  'consumer-circuit': {
    svg: 'consumer-circuit.svg',
    width: '120px',
    inputs: [{ top: '100%', left: '6%', type: SocketType.flow }],
    outputs: [{ top: '100%', left: '94%', type: SocketType.return }],
    reteComponent: ScadaReteComponents.Labeled,
    name: 'consumer_circuit',
  },
  'othermo:heatExchanger': {
    svg: 'HeatExchanger.svg',
    width: '120px',
    inputs: [
      { top: '12%', left: '-3%', type: SocketType.flow },
      { top: '88%', left: '100%', type: SocketType.return },
    ],
    outputs: [
      { top: '12%', left: '100%', type: SocketType.flow },
      { top: '88%', left: '-3%', type: SocketType.return },
    ],
    name: 'heat_exchanger',
  },
  'othermo:hydraulicSwitch': {
    svg: 'HydraulicSwitch.svg',
    width: '120px',
    inputs: [
      { top: '12%', left: '-3%', type: SocketType.flow },
      { top: '88%', left: '100%', type: SocketType.return },
    ],
    outputs: [
      { top: '12%', left: '100%', type: SocketType.flow },
      { top: '88%', left: '-3%', type: SocketType.return },
    ],
    name: 'hydraulic_switch',
  },
  mixer: {
    svg: 'mixer/mixer.svg',
    rotatedSvgs: {
      south: 'mixer/mixer_90.svg',
      west: 'mixer/mixer_180.svg',
      north: 'mixer/mixer_270.svg',
    },
    previewWidth: '80px',
    width: '80px',
    inputs: [
      { top: '93%', left: '50%', type: SocketType.flow },
      { top: '50%', left: '95%', type: SocketType.return },
      { top: '50%', left: '8%', type: SocketType.data },
    ],
    outputs: [{ top: '14%', left: '50%', type: SocketType.flow }],
    toolboxComponent: ToolboxComponent.DummyNode,
    reteComponent: ScadaReteComponents.Rotatable,
    name: 'mixer',
  },
  pump: {
    inputs: [{ top: '50%', left: '0%', type: SocketType.flow }],
    outputs: [{ top: '50%', left: '100%', type: SocketType.flow }],
    svg: 'generic_pump.svg',
    toolboxComponent: ToolboxComponent.DummyNode,
    reteComponent: ScadaReteComponents.Pump,
    name: 'pump',
  },
  storage: {
    inputs: [
      { top: '16%', left: '0%', type: SocketType.flow },
      { top: '0%', left: '42%', type: SocketType.flow },
      { top: '16%', left: '100%', type: SocketType.flow },
      { top: '76%', left: '0%', type: SocketType.return },
      { top: '100%', left: '42%', type: SocketType.return },
      { top: '76%', left: '100%', type: SocketType.return },
    ],
    outputs: [
      { top: '84%', left: '0%', type: SocketType.return },
      { top: '100%', left: '58%', type: SocketType.return },
      { top: '84%', left: '100%', type: SocketType.return },
      { top: '24%', left: '0%', type: SocketType.flow },
      { top: '0%', left: '58%', type: SocketType.flow },
      { top: '24%', left: '100%', type: SocketType.flow },
    ],
    name: 'scada.rete_tab.thermal_storage',
    toolboxComponent: ToolboxComponent.Generic,
    previewWidth: '50px',
    svg: 'ThermalStorage.svg',
    reteComponent: ScadaReteComponents.ThermalStorage,
  },
  'sensor-reading': {
    inputs: [],
    outputs: [],
    name: 'scada.rete_tab.measurement_point.measurement_point',
    icon: 'sensors',
    toolboxComponent: ToolboxComponent.Generic,
    reteComponent: ScadaReteComponents.Sensor,
  },
  'sensor-reading-standalone': {
    inputs: [],
    outputs: [],
    name: 'scada.rete_tab.measurement_point.measurement_point_standalone',
    icon: 'sensors',
    reteComponent: ScadaReteComponents.StandaloneSensor,
    toolboxComponent: ToolboxComponent.Generic,
  },
  textlabel: {
    inputs: [],
    outputs: [],
    name: 'scada.rete_tab.textlabel',
    icon: 'notes',
    toolboxComponent: ToolboxComponent.Generic,
    reteComponent: ScadaReteComponents.Comment,
  },
}

// Contains definitions for R&I components based on the category of devices
export const DeviceDescriptorByCategory: Record<string, DeviceDescriptor> = {
  'Meter/Electricity': {
    svg: 'ElectricityMeter/Electricity_0.svg',
    rotatedSvgs: {
      south: 'ElectricityMeter/Electricity_90.svg',
      west: 'ElectricityMeter/Electricity_180.svg',
      north: 'ElectricityMeter/Electricity_270.svg',
    },
    previewWidth: '140px',
    width: '160px',
    commandResponseTemplate: 'GenericElectricityMeter',
    commandResponseFeature: 'GenericElectricityMeter',
    inputs: [{ top: '27%', left: '15%', type: SocketType.electricity }],
    outputs: [{ top: '85%', left: '15%', type: SocketType.electricity }],
    toolboxComponent: ToolboxComponent.DummyNode,
    reteComponent: ScadaReteComponents.Rotatable,
  },
  'Meter/Heat': {
    svg: 'HeatMeter/HeatMeter_0.svg',
    rotatedSvgs: {
      south: 'HeatMeter/HeatMeter_90.svg',
      west: 'HeatMeter/HeatMeter_180.svg',
      north: 'HeatMeter/HeatMeter_270.svg',
    },
    previewWidth: '75px',
    width: '75px',
    outputs: [{ top: '80%', left: '5%', type: SocketType.return }],
    inputs: [{ top: '80%', left: '95%', type: SocketType.return }],
    toolboxComponent: ToolboxComponent.DummyNode,
    reteComponent: ScadaReteComponents.Rotatable,
  },
  'Meter/Gas': {
    svg: 'GenericGasMeter/Gas_0.svg',
    rotatedSvgs: {
      south: 'GenericGasMeter/Gas_90.svg',
      west: 'GenericGasMeter/Gas_180.svg',
      north: 'GenericGasMeter/Gas_270.svg',
    },
    previewWidth: '140px',
    width: '160px',
    commandResponseTemplate: 'GenericGasMeter',
    commandResponseFeature: 'GenericGasMeter',
    inputs: [{ top: '31%', left: '14%', type: SocketType.gas }],
    outputs: [{ top: '70%', left: '14%', type: SocketType.gas }],
    toolboxComponent: ToolboxComponent.DummyNode,
    reteComponent: ScadaReteComponents.Rotatable,
  },
  'Boiler/Gas': {
    svg: 'GenericGasBoiler.svg',
    width: '360px',
    previewWidth: '250px',
    inputs: [
      { top: '9.5%', left: '50%', type: SocketType.return },
      { top: '89.3%', left: '22.5%', type: SocketType.gas },
    ],
    outputs: [{ top: '9.5%', left: '24%', type: SocketType.flow }],
  },
  'CHP/GasEngine': {
    svg: 'BHKW.svg',
    previewWidth: '300px',
    width: '400px',
    inputs: [
      { top: '89%', left: '20%', type: SocketType.gas },
      { top: '9.5%', left: '64.3%', type: SocketType.return },
    ],
    outputs: [
      { top: '9.5%', left: '41.3%', type: SocketType.flow },
      { top: '89%', left: '82%', type: SocketType.electricity },
    ],
  },
}

// Contains definitions for R&I components based on the device definition
export const DeviceDescriptorByDefinition: Record<string, DeviceDescriptor> = {
  'othermo:RMBNeotower': {
    svg: 'BHKW.svg',
    previewWidth: '300px',
    width: '400px',
    inputs: [
      { top: '89%', left: '20%', type: SocketType.gas },
      { top: '9.5%', left: '64.3%', type: SocketType.return },
    ],
    outputs: [
      { top: '9.5%', left: '41.3%', type: SocketType.flow },
      { top: '89%', left: '82%', type: SocketType.electricity },
    ],
  },
  'othermo:GenericGasMeter': {
    svg: 'GenericGasMeter/Gas_0.svg',
    rotatedSvgs: {
      south: 'GenericGasMeter/Gas_90.svg',
      west: 'GenericGasMeter/Gas_180.svg',
      north: 'GenericGasMeter/Gas_270.svg',
    },
    previewWidth: '140px',
    width: '160px',
    commandResponseTemplate: 'GenericGasMeter',
    commandResponseFeature: 'GenericGasMeter',
    inputs: [{ top: '31%', left: '14%', type: SocketType.gas }],
    outputs: [{ top: '70%', left: '14%', type: SocketType.gas }],
    toolboxComponent: ToolboxComponent.DummyNode,
    reteComponent: ScadaReteComponents.Rotatable,
  },
  'othermo:GenericElectricityMeter': {
    svg: 'ElectricityMeter/Electricity_0.svg',
    rotatedSvgs: {
      south: 'ElectricityMeter/Electricity_90.svg',
      west: 'ElectricityMeter/Electricity_180.svg',
      north: 'ElectricityMeter/Electricity_270.svg',
    },
    previewWidth: '140px',
    width: '160px',
    commandResponseTemplate: 'GenericElectricityMeter',
    commandResponseFeature: 'GenericElectricityMeter',
    inputs: [{ top: '27%', left: '15%', type: SocketType.electricity }],
    outputs: [{ top: '85%', left: '15%', type: SocketType.electricity }],
    toolboxComponent: ToolboxComponent.DummyNode,
    reteComponent: ScadaReteComponents.Rotatable,
  },
  'othermo:WolfSolarModule': {
    svg: 'Wolf-Solar-Module.svg',
    width: '550px',
    previewWidth: '300px',
    inputs: [{ top: '58%', left: '4.5%', type: SocketType.flow }],
    outputs: [{ top: '91.75%', left: '4.5%', type: SocketType.return }],
  },
  // Boiler
  'othermo:ViessmannCHP': {
    svg: 'ViessmannCogen.svg',
    width: '500px',
    previewWidth: '250px',
    inputs: [
      { top: '0%', left: '24.6%', type: SocketType.return },
      { top: '100%', left: '14.6%', type: SocketType.gas },
      { top: '100%', left: '42.6%', type: SocketType.electricity },
    ],
    outputs: [
      { top: '0%', left: '16.6%', type: SocketType.flow },
      // { top: '0%', left: '44.8%', type: SocketType.exhaustGas },
      // as we only have outputs for exhaustGas it does not make much sense to offer a socket right now
      // see https://app.clickup.com/t/244y3q0?comment=822475826
    ],
  },
  'othermo:GenericBoilerWolf': {
    svg: 'BoilerWolf.svg',
    width: '500px',
    previewWidth: '250px',
    inputs: [
      { top: '0%', left: '24.8%', type: SocketType.return },
      { top: '100%', left: '14.8%', type: SocketType.gas },
    ],
    outputs: [
      { top: '0%', left: '16.8%', type: SocketType.flow },
      // { top: '0%', left: '44.8%', type: SocketType.exhaustGas }, // top right
      // as we only have outputs for exhaustGas it does not make much sense to offer a socket right now
      // see https://app.clickup.com/t/244y3q0?comment=822475826
      { top: '100%', left: '42.8%', type: SocketType.electricity },
    ],
  },
  // System pressure
  'othermo:Pneumatex': {
    svg: 'Pneumatex.svg',
    width: '230px',
    previewWidth: '190px',
    inputs: [{ top: '22%', left: '50%', type: SocketType.return }],
    outputs: [],
  },
  'othermo:ReflexVariomat': {
    svg: 'Reflex.svg',
    width: '100px',
    previewWidth: '80px',
    inputs: [{ top: '5%', left: '40%', type: SocketType.return }],
    outputs: [],
  },
  // Similar Pump SVGs
  'othermo:GrundfosPump': {
    svg: 'grundfos_pump/grundfos_pump_0.svg',
    rotatedSvgs: {
      south: 'grundfos_pump/grundfos_pump_90.svg',
      west: 'grundfos_pump/grundfos_pump_180.svg',
      north: 'grundfos_pump/grundfos_pump_270.svg',
    },
    previewWidth: '90px',
    width: '110px',
    commandResponseTemplate: 'PumpGrundfos',
    commandResponseFeature: 'GrundfosPump',
    inputs: [{ top: '88%', left: '30%', type: SocketType.flow }],
    outputs: [{ top: '88%', left: '75%', type: SocketType.flow }],
    toolboxComponent: ToolboxComponent.DummyNode,
    reteComponent: ScadaReteComponents.Rotatable,
  },
  'othermo:WiloPump': {
    svg: 'wilo_pump/wilo_pump_0.svg',
    rotatedSvgs: {
      south: 'wilo_pump/wilo_pump_90.svg',
      west: 'wilo_pump/wilo_pump_180.svg',
      north: 'wilo_pump/wilo_pump_270.svg',
    },
    previewWidth: '90px',
    width: '110px',
    commandResponseTemplate: 'WiloPump',
    commandResponseFeature: 'WiloPump',
    inputs: [{ top: '88%', left: '30%', type: SocketType.flow }],
    outputs: [{ top: '88%', left: '75%', type: SocketType.flow }],
    toolboxComponent: ToolboxComponent.DummyNode,
    reteComponent: ScadaReteComponents.Rotatable,
  },
  'othermo:WolfBoilerCGB': {
    svg: 'Boiler-MGK-CBG.svg',
    width: '360px',
    previewWidth: '250px',
    inputs: [
      { top: '9.5%', left: '50%', type: SocketType.return },
      { top: '89.3%', left: '22.5%', type: SocketType.gas },
    ],
    outputs: [{ top: '9.5%', left: '24%', type: SocketType.flow }],
  },
  'othermo:WolfBoilerCGB2': {
    svg: 'GenericGasBoiler.svg',
    width: '360px',
    previewWidth: '250px',
    inputs: [
      { top: '9.5%', left: '50%', type: SocketType.return },
      { top: '89.3%', left: '22.5%', type: SocketType.gas },
    ],
    outputs: [{ top: '9.5%', left: '24%', type: SocketType.flow }],
  },
  'othermo:WolfBoilerMGK': {
    svg: 'Boiler-MGK-CBG.svg',
    width: '360px',
    previewWidth: '250px',
    inputs: [
      { top: '9.5%', left: '50%', type: SocketType.return },
      { top: '89.3%', left: '22.5%', type: SocketType.gas },
    ],
    outputs: [{ top: '9.5%', left: '24%', type: SocketType.flow }],
  },
  'othermo:WolfBoilerMGK2': {
    svg: 'GenericGasBoiler.svg',
    width: '360px',
    previewWidth: '250px',
    inputs: [
      { top: '9.5%', left: '50%', type: SocketType.return },
      { top: '89.3%', left: '22.5%', type: SocketType.gas },
    ],
    outputs: [{ top: '9.5%', left: '24%', type: SocketType.flow }],
  },
  // Similar heat pumps (with a similar template to the previous WolfBoilers)
  'othermo:WolfHeatPumpBWL': {
    svg: 'Wolf-Heat-Pump-BWL.svg',
    width: '360px',
    previewWidth: '250px',
    inputs: [{ top: '9%', left: '67%', type: SocketType.return }],
    outputs: [
      { top: '9%', left: '23%', type: SocketType.flow },
      { top: '9%', left: '41%', type: SocketType.flow },
    ],
  },
  'othermo:WolfHeatPumpCHA': {
    svg: 'WP-CHA.svg',
    width: '360px',
    previewWidth: '250px',
    inputs: [{ top: '9%', left: '66%', type: SocketType.return }],
    outputs: [
      { top: '9%', left: '23%', type: SocketType.flow },
      { top: '9%', left: '40%', type: SocketType.flow },
    ],
  },
}
