import type { SeverityLevel } from '@/api/alarm/models/dto/SeverityLevel'
import type { Duration } from 'moment'
import type { AlertParamDTO, HysteresisAlertParamDTO } from '@/api/alarm/models/dto/AlertParamDTOs'
import PercentageUtil from '@/components/site/alerts/PercentageUtil'

export enum Comparator {
  EQUAL = '==',
  GREATER = '>',
  LESS = '<',
  IN_RANGE = 'in_range',
  OUT_OF_RANGE = 'ex_range',
}

export type AlertRuleFunction = 'mean' | 'max' | 'min' | 'derivative' | 'last'

export type HysteresisRuleFunction = 'mean' | 'last'

export const ALLOWED_TYPES = ['float', 'double', 'int', 'integer', 'boolean']

export abstract class AlertRule {
  protected constructor(
    public timespan: Duration,
    public severity: SeverityLevel,
  ) {}

  abstract get isValid(): boolean

  abstract toDto(): AlertParamDTO | HysteresisAlertParamDTO

  /**
   * See {@link #Alert.convertPercentageFormat}
   */
  abstract convertPercentageFormat(scaleUp: boolean): void

  /**
   * Converts the threshold of an alert between the representation in ditto / the displayed values in the view.
   * See the definition of the format in {@link convertPercentageFormat}
   *
   * @return convertedThreshold
   */
  public static convertPercentageThreshold(threshold: any, scaleUp: boolean) {
    if (!threshold) {
      return null
    }
    /**
     * NOTE: the type is object if the {@link CascadeAlertRule} has a ranged condition with [number, number] input
     *       if further ranges/objects are added to the {@link CascadeAlertRule} or {@link ControlDeviationAlertRule},
     *       this needs to be extended!
     */
    if (typeof threshold === 'object') {
      const convertedThreshold = [2]
      convertedThreshold[0] = PercentageUtil.roundPercentage(threshold[0], scaleUp)
      convertedThreshold[1] = PercentageUtil.roundPercentage(threshold[1], scaleUp)
      return convertedThreshold
    } else {
      return PercentageUtil.roundPercentage(threshold as number, scaleUp)
    }
  }
}
