export const TwinTranslationsEn = {
  digital_twins: 'Digital Twins',
  thingId: 'ID',
  policyId: 'Policy ID',
  definition: 'Definition',
  category: 'Category',
  thing_size: 'Size',
  thing_modified: 'Last modified',
  thing_created: 'Created',
  uncategorized: 'Uncategorized',
  status: 'Status',
  configuration: 'Configuration',
  serial_no: 'Serial Number',
  serial_number: 'Serial Number',
  error_loading_thing: 'Could not load thing: {error}',
  error_loading_twins: 'Could not load twins for this site: {error}',
  copied_to_clipboard: 'Reference copied to clipboard',
  error_copy_to_clipboard: 'Could not copy to clipboard: {error}',
  edit_prop: 'Edit property "{prop}"',
  command_prop: 'Send command for "{prop}"',
  error_sending_command: 'Could not send command',
  send_command_success: 'Command sent successfully.',
  success_setting_property: 'Property "{propertyName}" set successfully to value "{newValue}".',
  error_setting_property: 'Could not set property: {error}',
  copy_reference: 'Copy reference',
  send_command: 'Send command',
  send_command_in_progress: 'Sending command...',
  location_picker_info:
    'Click anywhere on map to place marker - Drag marker to change location - Right click marker to remove - Scroll or +/- to zoom',
  download_history: 'Download history',
  download: 'Download',
  selected_datapoint: 'Selected Property',
  download_options: 'Download Options',
  path: 'Path',
  error_getting_csv: 'Could not download historical data as CSV: {error}',

  // Confirm modal
  change_value: 'Change value on',
  change_comment: 'Reason for modification (optional)',
  change_value_to:
    'Should <b>{property}</b> of <b>{feature}</b> be changed from  <b>{oldValue}</b> to <b>{newValue}</b>?',
  execute: 'Execute',
  error_writing_events_log: 'The change event could not be written to the events-log.',
  month_names: {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  },
  weekday_names: {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  },
  twins: {
    sort_by: 'Sortieren nach',
    sort_by_name: 'Name',
    sort_by_unit: 'Einheit',
  },
}
