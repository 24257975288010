<template>
  <div :class="rotationClassList" class="sensor-reading-container">
    <div :class="[labelPlacement]" class="mr-1 name">{{ description }}</div>
    <div class="current-value">{{ displayValue }}</div>
    <div :class="lineOrientationClasses" class="sensor-reading-connection"></div>
  </div>
</template>
<script lang="ts">
  //@ts-ignore
  import VueRender from 'rete-vue-render-plugin'
  //@ts-ignore
  import AreaPlugin from 'rete-area-plugin'
  //@ts-ignore
  import ConnectionReroutePlugin from 'rete-connection-reroute-plugin'
  //@ts-ignore
  import ConnectionPathPlugin from 'rete-connection-path-plugin'
  import { Component } from 'vue-property-decorator'
  import SiteTreeDropdown from '@/components/app/SiteThingsTree/SiteTreeDropdown.vue'
  import Socket from '@/components/site/scada/editor-components/Socket.vue'
  import SensorReadingComponent from '@/components/site/scada/editor-components/rete_components/SensorReadingComponent.vue'

  /**
   * Shows a single sensor value without any input/output sockets
   */
  @Component({
    components: { SiteTreeDropdown, Socket },
  })
  export default class StandaloneSensorReadingComponent extends SensorReadingComponent {
    get labelPlacement() {
      return 'label-' + (this.node.data.labelPosition ?? 'left')
    }
  }
</script>

<style lang="scss" scoped>
  @import 'SensorReading';

  .name {
    position: absolute;
  }

  .label-top {
    bottom: 100%;
  }

  .label-left {
    right: 100%;
    bottom: 0;
    padding-right: 0.5 * $spacer;
  }

  .label-right {
    padding-left: 0.5 * $spacer;
    bottom: 0;
    left: 100%;
  }

  .label-bottom {
    top: 100%;
  }

  .sensor-reading-container {
    &.south .label-left,
    &.south .label-right {
      top: 0;
      bottom: unset;
    }
  }
</style>
