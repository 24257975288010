import type { Node } from 'rete'
import { Component } from 'rete'
import type { NodeData, WorkerInputs, WorkerOutputs } from 'rete/types/core/data'
import Logger from '@/logger'
import StandaloneSensorReadingComponent from '@/components/site/scada/editor-components/rete_components/StandaloneSensorReadingComponent.vue'
import { ScadaReteComponents } from '@/components/site/scada/editor-components/rete_components/rete_nodes/ReteNode'

export default class ScadaReteStandaloneSensorReadingComponent extends Component {
  constructor() {
    super(ScadaReteComponents.StandaloneSensor)
    // @ts-ignore
    this.data.component = StandaloneSensorReadingComponent
  }

  async builder(node: Node) {
    const descriptor = node.data.deviceDefinition
    node.data.rotation = node.data.rotation ?? 'east'
    node.data.labelPosition = node.data.labelPosition ?? 'left'

    if (descriptor == undefined) {
      Logger.error(`unknown descriptor ${node.data.deviceDefinition}`)
      return
    }
  }

  worker(node: NodeData, inputs: WorkerInputs, outputs: WorkerOutputs, ...args: unknown[]) {}
}
