<template></template>

<script lang="ts">
  import { Component, Inject, Mixins, Model, Prop, Vue } from 'vue-property-decorator'
  import type BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import type { DittoFeatureMessage } from '@/components/site/scada/command_response/DittoMsgHandler'
  import DittoMsgHandler from '@/components/site/scada/command_response/DittoMsgHandler'
  import Logger from '@/logger'
  import ToastMixin from '@/modules/tools/ToastMixin'
  import events from '@/events'

  @Component
  export default class BaseItem extends Mixins(ToastMixin) {
    @Inject()
    submitEventBus!: Vue

    /** This is only used to emit updates to the parent */
    @Model('value-changed')
    readonly model!: any

    /** Name that is displayed  */
    @Prop({ required: true })
    displayName!: string

    /** Tooltip with additional info to show on the item */
    @Prop({ required: false, default: '' })
    toolTip!: string

    /** Subject for the message sent to feature inbox */
    @Prop()
    propertyName!: string

    @Prop()
    propertyType!: string

    /** Name of the feature to send a message to or feature to update */
    @Prop({ default: '' })
    featureName!: string

    /** When set, the message body is an object of the form {msgParamName: value} */
    @Prop({ required: false, default: () => null })
    msgParamName!: string | null

    /**
     * Set to true when the user first edits the value in the input.
     * When set to true, changes of the value from ditto are no longer propagated to the input component
     */
    protected changed = false

    private messageHandler = new DittoMsgHandler(this.$keycloak)

    private submitState = 'idle'

    private submitError = ''

    get message(): DittoFeatureMessage {
      this.checkRequired(['featureName', 'messageSubject'])
      return {
        featureName: this.featureName,
        propertyType: this.propertyType,
        propertyName: this.propertyName,
        payload: this.messageBody,
      }
    }

    get messageBody(): object | string {
      throw Error('not implemented!')
    }

    protected get thingId() {
      return (this.$parent as BaseTemplate).thingId
    }

    created() {
      this.submitEventBus.$on(events.scada.templates.submit, this.onSubmitReceived)
    }

    mounted() {
      this.submitEventBus.$emit(events.scada.templates.mounted)
    }

    beforeDestroy() {
      this.submitEventBus.$off(events.scada.templates.submit, this.onSubmitReceived)
    }

    protected onSubmitReceived() {}

    /**
     * Sends changes to the server
     */
    protected async submit() {
      this.submitState = 'submitting'
      this.submitEventBus.$emit(events.scada.templates.submitStarted)
      try {
        await this.messageHandler.sendMessage(this.thingId, this.message)
        this.submitState = 'success'
        this.changed = false
        this.submitEventBus.$emit(events.scada.templates.submitEnd, true)
      } catch (e) {
        this.submitState = 'error'
        this.submitError = e.message ?? e.toString()
        this.submitEventBus.$emit(events.scada.templates.submitEnd, false)
      }
    }

    private checkRequired(requiredFields: string[]) {
      for (const field of requiredFields) {
        if (this[field as keyof BaseItem] == undefined) {
          Logger.warn(
            `Required field ${field} not set on item of type ${this.$options.name} with name ${this.displayName}`,
          )
        }
      }
    }
  }
</script>

<style lang="scss" scoped></style>
