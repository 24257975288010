/**
 * default mappings for special ENUMS
 */
export const specialEnumColorMapping: Record<string, string> = {
  'no data': '#BEBEBE',
  'Brenner Aus': '#000000',
  'Brenner In Betrieb': '#00bb2d',
  Lastlauf: '#059600',
  Zuendung: 'orange',
  Vorbelueftung: 'blue',
  Running: '#7FFF00',
  Stopped: '#000000',
  'Bereit (-Anf)': '#88ff00',
  Betrieb: '#00bb2d',
  'Betrieb (Reinigung)': '#A5DF00',
  'Bereit (-Reinigung)': '#13a837',
  Nachlauf: '#000088',
  Durchzünden: '#aee4ff',
  'Zünden Heizen': '#cc95ff',
  'Zünden Start Saugzug': '#7bffc9',
  Auto: '#00bb2d',
  Bereit: '#88ff00',
  Standby: '#88ff00',
  Aus: '#cccb81',
  Wartung: '#eeeb67',
}

/** Note that the colors that are defined here have an influence on the different shades
 * of the colors, see {@link StatusChart#introducesDifferentShadesOfDuplicatedColors}*/
const BLUE = '#0c6da6'
const ORANGE = '#cf7916'
const RED = '#952b1d'
const GREY = '#515861'

export const severitiesWithDefaultColors = ['info', 'warning', 'error', 'critical']

/** Make sure to update {@link severitiesWithDefaultColors} when adding new default colors */
export function getSeverityColorMapping(
  severity: string,
  isTrue: boolean = true,
): string | undefined {
  if (!isTrue) {
    return GREY
  }
  switch (severity) {
    case 'info':
      return BLUE
    case 'warning':
      return ORANGE
    case 'error':
    case 'critical':
      return RED
  }
  return undefined
}

export const veryDifferentColors = [
  '#000000',
  '#00FF00',
  '#0000FF',
  '#FF0000',
  '#01FFFE',
  '#FFA6FE',
  '#FFDB66',
  '#006401',
  '#010067',
  '#95003A',
  '#007DB5',
  '#FF00F6',
  '#FFEEE8',
  '#774D00',
  '#90FB92',
  '#0076FF',
  '#D5FF00',
  '#FF937E',
  '#6A826C',
  '#FF029D',
  '#FE8900',
  '#7A4782',
  '#7E2DD2',
  '#85A900',
  '#FF0056',
  '#A42400',
  '#00AE7E',
  '#683D3B',
  '#BDC6FF',
  '#263400',
  '#BDD393',
  '#00B917',
  '#9E008E',
  '#001544',
  '#C28C9F',
  '#FF74A3',
  '#01D0FF',
  '#004754',
  '#E56FFE',
  '#788231',
  '#0E4CA1',
  '#91D0CB',
  '#BE9970',
  '#968AE8',
  '#BB8800',
  '#43002C',
  '#DEFF74',
  '#00FFC6',
  '#FFE502',
  '#620E00',
  '#008F9C',
  '#98FF52',
  '#7544B1',
  '#B500FF',
  '#00FF78',
  '#FF6E41',
  '#005F39',
  '#6B6882',
  '#5FAD4E',
  '#A75740',
  '#A5FFD2',
  '#FFB167',
  '#009BFF',
  '#E85EBE',
]
