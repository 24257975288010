<template>
  <b-alert show class="mb-3 py-1 px-2">
    <span><slot></slot></span>
  </b-alert>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'

  /**
   * Info text to put in an edit template
   */
  @Component
  export default class Info extends Vue {}
</script>

<style lang="scss" scoped>
  img {
    height: 1em;
  }
</style>
