<template>
  <b-dropdown
    class="flat-dropdown"
    :splitClass="isRouteActive ? 'active' : ''"
    :splitTo="selectedOptionTarget"
    split
    variant="outline-primary"
  >
    <template #button-content>
      <div class="button-content">
        <span class="material-icons mr-2">{{ selectedOptionIcon }}</span>
        <span>{{ selectedOptionText }}</span>
      </div>
    </template>
    <b-dropdown-item
      v-for="option in options"
      :key="option.to"
      :to="option.to"
      :active="option.to === activeRoute"
    >
      <div class="d-flex flex-nowrap align-items-center">
        <div class="custom-control custom-radio" @click="onRadioClicked(option, $event)">
          <input
            :checked="selectedOptionTarget === option.to"
            class="custom-control-input"
            :class="{ active: option.to === activeRoute }"
            type="radio"
          />
          <label class="custom-control-label"></label>
        </div>
        <span
          class="material-icons"
          :class="option.to !== activeRoute ? 'text-primary' : 'text-light'"
        >
          {{ option.icon }}
        </span>
        {{ $t(option.translationKey) }}
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator'

  export type RadioDropdownOption = {
    translationKey: string
    icon: string
    to: string
  }

  @Component
  export default class RadioDropdown extends Vue {
    @Prop()
    private readonly options!: RadioDropdownOption[]

    private selectedOption: RadioDropdownOption | null = null

    get selectedOptionText(): string {
      return this.$t(this.selectedOption?.translationKey ?? '') ?? ''
    }

    get selectedOptionTarget(): string {
      return this.selectedOption?.to ?? ''
    }

    get selectedOptionIcon(): string {
      return this.selectedOption?.icon ?? ''
    }

    get isRouteActive() {
      return this.selectedOption?.to === this.$route.fullPath
    }

    get activeRoute() {
      return this.$route.fullPath
    }

    mounted() {
      if (this.selectedOption == null) {
        this.selectedOption = this.options[0]
      }
    }

    onRadioClicked(option: RadioDropdownOption, event: Event) {
      event.preventDefault()
      event.stopPropagation()
      this.selectedOption = option
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';
  @import 'flat-dropdown';

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $blue;
    background-color: $blue;
  }
</style>
