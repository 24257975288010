import { action, createModule, extractVuexModule, mutation } from 'vuex-class-component'
import Vue from 'vue'
import HasuraNotificationClient from '@/api/notifications/HasuraNotificationClient'
import Logger from '@/logger'
import KeycloakTools from '@/modules/tools/KeycloakTools'

const NotificationsModule = createModule({ namespaced: 'notifications' })

export const NOTIFICATION_COUNT_UNKNOWN = -1

export class NotificationsStore extends NotificationsModule {
  private _notificationCount = NOTIFICATION_COUNT_UNKNOWN

  get notificationCount() {
    return this._notificationCount
  }

  @action
  async fetchNotificationCount() {
    const userId = Vue.prototype.$keycloak.subject
    const client = new HasuraNotificationClient(
      userId,
      KeycloakTools.getRealm(Vue.prototype.$keycloak) ?? 'c0000',
    )

    try {
      this._notificationCount = await client.getNotificationCount()
    } catch (e) {
      Logger.error(`Could not load notification count for user ${userId}`, e)
    }
  }

  @mutation
  public updateNotificationCount(diff: number) {
    this._notificationCount += diff
  }
}

export const notifications = extractVuexModule(NotificationsStore)
