<template>
  <div>
    <b-alert v-if="isStaging" class="py-1 m-0 staging-alert" dismissible show variant="warning">
      {{ $t('notice_staging_env') }}
    </b-alert>
    <b-navbar class="my-0 p-0 px-lg-3 align-items-center justify-content-start">
      <template v-if="$screen.md">
        <NavIconButton
          v-for="navItem in navItems"
          :key="navItem.path"
          :icon="navItem.icon"
          :to="navItem.path"
        >
          {{ $t(navItem.title) }}
          <template v-if="navItem.icon === 'othermo'" #icon>
            <img id="icon" alt="Othermo" class="p-0" src="../../assets/othermo_logo_new.png" />
          </template>
        </NavIconButton>
        <SiteSwitcher />
        <div class="mr-auto" />
        <RealmSwitcher :isOthermoUser="isOthermoUser" />
      </template>

      <template v-else>
        <NavIconButton
          :showText="true"
          class="mr-auto flex-shrink-1 collapse-toggle"
          icon="menu"
          @click="toggleCollapse"
        >
          {{ currentRouteName }}
        </NavIconButton>
      </template>

      <NavIconButton :count="notificationCount" icon="notifications_active" to="/notifications" />
      <AccountDropdown :isOthermoUser="isOthermoUser" />
    </b-navbar>
    <b-collapse id="nav-collapse">
      <div class="d-flex flex-column p-2 gap-2 border-top">
        <NavIconButton
          v-for="navItem in [...navItems]"
          :key="navItem.path"
          :icon="navItem.icon"
          :showText="true"
          :to="navItem.path"
          @click="toggleCollapse"
        >
          {{ $t(navItem.title) }}
          <template v-if="navItem.icon === 'othermo'" #icon>
            <img id="icon" alt="Othermo" class="p-0" src="../../assets/othermo_logo_new.png" />
          </template>
        </NavIconButton>
        <SiteSwitcher @navigate="toggleCollapse" />
        <RealmSwitcher :isOthermoUser="isOthermoUser" />
      </div>
    </b-collapse>
    <b-modal id="license-disclaimer-modal" :title="$t('menu.oss_licenses')" okOnly size="xl">
      <iframe
        src="./licenses.txt"
        style="width: 100%; border: none; height: 70vh; background: white"
      ></iframe>
    </b-modal>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'
  import { vxm } from '@/store/store'
  import KeycloakTools from '@/modules/tools/KeycloakTools'
  import { isProd } from '@/config'
  import NotificationBell from '@/components/notifications/NotificationBell.vue'
  import moment from 'moment'
  import RadioDropdown from '@/components/app/nav-bar/NavRadioDropdown.vue'
  import AccountDropdown from '@/components/app/nav-bar/AccountDropdown.vue'
  import SiteSwitcher from '@/components/app/nav-bar/SiteSwitcher.vue'
  import NavIconButton from '@/components/app/nav-bar/NavIconButton.vue'
  import RealmSwitcher from '@/components/app/nav-bar/RealmSwitcher.vue'
  import type { Site } from '@/api/klempner/models/SiteListResponse'

  @Component({
    components: {
      RealmSwitcher,
      NavIconButton,
      SiteSwitcher,
      AccountDropdown,
      RadioDropdown,
      NotificationBell,
    },
  })
  export default class NavBar extends Vue {
    readonly navItems = [
      { icon: 'othermo', path: '/', title: 'dashboard.title' },
      { icon: 'grid_view', path: '/sites?view=grid', title: 'grid.title' },
      { icon: 'notifications', path: '/sites?view=alarm', title: 'alerts' },
    ]

    private interval: number | null = null

    sites: Site[] = []

    get currentRouteName(): string {
      const routeName = this.$route?.matched?.[0]?.name
      if (routeName === undefined) {
        return ''
      }
      return this.generateRouteTitle(routeName) ?? ''
    }

    generateRouteTitle(routeName: string): string | undefined {
      switch (routeName) {
        case 'Dashboard':
          return this.$t('dashboard.title')
        case 'SiteList':
          switch (this.$route.query.view) {
            case 'grid':
              return this.$t('grid.title')
            case 'alarm':
              return this.$t('alerts')
            case 'reports':
              return this.$t('reports.title')
            default:
              return this.$t('sites.title')
          }
        case 'SitePage':
          return this.selectedSite?.name ?? this.$t('site')
        case 'NotificationCenter':
          return this.$t('notification_center.title')
        case 'Settings':
          return this.$t('settings.title')
      }
    }

    get notificationCount() {
      return vxm.notifications.notificationCount
    }

    get user() {
      return vxm.user
    }

    get isOthermoUser() {
      return vxm.user.email.includes('@othermo.de') || vxm.user.email.includes('@selectcode.de')
    }

    get realm() {
      return KeycloakTools.getRealm(this.$keycloak) ?? ''
    }

    get locale() {
      return this.$i18n.locale()
    }

    get isStaging() {
      return !isProd() && process.env.VUE_APP_DEPLOY_ENV !== undefined
    }

    get selectedSite(): Site | null {
      if (
        this.$route.matched.length > 0 &&
        this.$route.matched[0].path === '/site/:id' &&
        this.sites.length > 0
      ) {
        return this.sites.find((site) => site.id === this.$route.params.id) ?? null
      } else {
        return null
      }
    }

    toggleCollapse() {
      this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
    }

    created() {
      if (this.realm !== 'c0011') {
        vxm.notifications.fetchNotificationCount()
        this.interval = window.setInterval(() => {
          vxm.notifications.fetchNotificationCount()
        }, moment.duration(1, 'minute').asMilliseconds())
      }
    }

    destroyed() {
      if (this.interval != null) {
        window.clearInterval(this.interval)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';
  @import 'bootstrap/scss/mixins';
  @import 'nav-bar/flat-dropdown';

  $enable-shadows: true;

  #icon {
    cursor: pointer;
  }

  @mixin nav-shadow {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .navbar {
    @include nav-shadow;
    z-index: 1002;
    display: flex;
    align-items: flex-start;
  }

  .icon-button {
    height: 100%;
    width: 3.5em;
  }

  .staging-alert {
    z-index: 1003;
    background: $warning !important;
    border: none !important;
    border-radius: 0 !important;
    color: lighten($warning, 50%);

    ::v-deep .close {
      padding: calc(0.25 * $spacer) !important;
    }
  }

  #icon {
    width: 1.5em;
    display: inline-block;
    vertical-align: text-top;
  }

  .collapse-toggle {
    min-width: 0;
    overflow: hidden;

    ::v-deep .material-icons {
      vertical-align: text-bottom !important;
    }

    &:not(.material-icons) {
      font-size: $font-size-lg;
      font-family: 'Lato', sans-serif;
      margin-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  #nav-collapse {
    position: absolute;
    z-index: 1003;
    background: $white;
    width: 100%;

    &:after {
      content: '';
      @include nav-shadow;
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      bottom: 0;
      clip-path: inset(0 0 -20px 0);
      z-index: -1;
    }

    ::v-deep .custom-button {
      text-align: left;
    }
  }
</style>
