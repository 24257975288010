<template>
  <div id="app" :class="{ 'text-unselectable': !textSelectable }">
    <NavBar />
    <b-toast id="versionToast" :noAutoHide="true" :title="toastTitle" :visible="displayToast">
      <div class="m-3" v-html="toastContent" />
      <b-button
        class="d-flex ml-auto border-primary"
        variant="primary"
        @click="onToastMoreButtonClicked"
      >
        {{ $t('show_more') }}
        <span class="material-icons">navigate_next</span>
      </b-button>
    </b-toast>
    <keep-alive :max="5">
      <router-view :key="$route.params.id" />
    </keep-alive>
    <b-modal id="changelog-modal" :title="toastTitle" size="xl" okOnly>
      <iframe v-if="changelogPost !== ''" id="changelog-frame" :src="changelogPost" />
      <b-link :href="changelogUrl" target="_blank">
        {{ $t('app.new_version.go_to_changelog') }}
      </b-link>
    </b-modal>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'
  import NavBar from '@/components/app/NavBar.vue'
  import KeycloakAccountClient from '@/api/keycloak/KeycloakAccountClient'
  import KeycloakTools from '@/modules/tools/KeycloakTools'
  import Logger from '@/logger'
  import type KeycloakUser from '@/api/keycloak/models/KeycloakUser'
  import { vxm } from '@/store/store'
  import type { ReleaseAnnouncementFragment } from '@/generated/graphql'
  import { GetReleaseAnnouncementsDocument } from '@/generated/graphql'
  import { config } from '@/config'

  @Component({
    components: { NavBar },
    apollo: {
      releaseAnnouncements: {
        query: GetReleaseAnnouncementsDocument,
        fetchPolicy: 'network-only',
        skip: true,
        context: { headers: { 'X-Hasura-Role': 'user' } },
      },
    },
  })
  export default class App extends Vue {
    private static LAST_VERSION = 'lastVersion'

    currentVersion = config.appVersion

    textSelectable = true

    accountClient: KeycloakAccountClient = new KeycloakAccountClient()

    displayToast = false

    toastTitle = ''

    toastContent = ''

    changelogPost = ''

    declare releaseAnnouncements: ReleaseAnnouncementFragment[]

    changelogUrl = config.api.changelogs.overviewURL

    /**
     * Parsing the version expects a dot ('.') separated numbers.
     *
     * @param version as string of the format '2.3.3'
     * @return version as number array
     */
    private static parseVersion(version: string): number[] {
      return (version + '').split('.').map((value) => parseInt(value))
    }

    created() {
      this.$propertyTranslation.fetchTranslations(
        this.$keycloak.token,
        KeycloakTools.getRealm(this.$keycloak),
        false,
      )
      this.$valueTranslation.fetchTranslations(
        this.$keycloak.token,
        KeycloakTools.getRealm(this.$keycloak),
        false,
      )

      this.$root.$on('show-draggable', () => {
        return (this.textSelectable = false)
      })
      this.$root.$on('hide-draggable', () => {
        return (this.textSelectable = true)
      })
    }

    async mounted() {
      const realmId = KeycloakTools.getRealm(this.$keycloak)!

      const user: KeycloakUser = await this.accountClient.getAccountInfo(
        realmId,
        this.$keycloak.token,
      )

      const location = new URL(window.location.href)
      if (location.searchParams.has('code')) {
        console.log('code found, trying to fetch token')
        try {
          await vxm.guacamoleAuth.fetchToken(location.searchParams.get('code')!)
        } catch (e) {
          Logger.error('Error fetching token', e)
        }
      }

      window.history.replaceState(
        {},
        document.title,
        window.location.origin + window.location.pathname + window.location.hash,
      )

      if (!this.versionToastDisplayedBefore(user)) {
        try {
          const announcement: ReleaseAnnouncementFragment | null =
            await this.findMatchingVersionAnnouncement()

          if (announcement != null) {
            this.changelogPost =
              announcement.postUrl != null
                ? config.api.changelogs.postBaseURL + announcement.postUrl
                : ''
            this.toastTitle = announcement.title
            this.toastContent = announcement.highlights
            this.displayToast = true
          } else {
            console.warn(
              `No matching announcement found for version ${this.currentVersion}, showing generic toast`,
            )
            this.toastTitle = this.$t('app.new_version.toast_title')!
            this.toastContent = this.$t('app.new_version.toast_subtitle')!
            this.displayToast = true
          }
        } catch (e) {
          Logger.error('Error loading new version announcement', e)
          this.toastTitle = this.$t('app.new_version.toast_title')!
          this.toastContent = this.$t('app.new_version.toast_subtitle')!
          this.changelogPost = ''
          this.displayToast = true
        }
        // store new version number to user --> prevents displaying toast for the next login
        try {
          user.attributes[App.LAST_VERSION] = this.currentVersion
          await this.accountClient.updateAccountInfo(user, realmId, this.$keycloak.token)
        } catch (e) {
          Logger.error('Error updating user contact info', e)
        }
      }
    }

    async findMatchingVersionAnnouncement(): Promise<ReleaseAnnouncementFragment | null> {
      this.$apollo.queries.releaseAnnouncements.skip = false
      await this.$apollo.queries.releaseAnnouncements.refetch()
      const matchingAnnouncements = this.releaseAnnouncements.find((announcement) =>
        announcement.versions.includes(this.currentVersion),
      )
      return matchingAnnouncements ?? null
    }

    // creates Info Modal if `show more` was clicked in toast message
    onToastMoreButtonClicked() {
      this.displayToast = false
      if (this.changelogPost !== '') {
        this.$bvModal.show('changelog-modal')
      } else {
        window.open(config.api.changelogs.overviewURL, '_blank')
      }
    }

    /**
     * Checks whether the new version toast shall be displayed.
     *
     * @param user for which is checked if the version toast was already displayed
     * @return false: if the current version is higher than the one that the user has
     *         true: if the user version is higher or equal to the current version
     */
    private versionToastDisplayedBefore(user: KeycloakUser): boolean {
      let userVersion = user.attributes[App.LAST_VERSION]

      if (userVersion === undefined) {
        return false
      } else {
        userVersion = userVersion[0]
      }

      const parsedCurrentVersion = App.parseVersion(this.currentVersion)
      const parsedUserVersion = App.parseVersion(userVersion)

      for (let i = 0; i < parsedCurrentVersion.length; i++) {
        if (parsedCurrentVersion[i] > parsedUserVersion[i]) {
          return false
        }
        // the userVersion may be higher when switching between production and development environments
        if (parsedCurrentVersion[i] < parsedUserVersion[i]) {
          return true
        }
      }

      return parsedCurrentVersion.length <= parsedUserVersion.length
    }
  }
</script>

<style lang="scss">
  @import 'src/vars';

  .text-unselectable {
    user-select: none !important;
  }

  .release-info-modal * {
    max-width: 100%;
    height: auto;
  }

  #versionToast {
    background-color: white;
    font-size: 120%;

    header {
      font-size: 130%;
      font-weight: bold;
      color: $othermo-red;
    }
  }

  #app {
    display: flex;
    //float: left;
    height: 100%;
    max-width: 100%;
    flex-direction: column;
    overflow: hidden;

    #content {
      max-width: 100%;
      overflow: hidden;
      flex-shrink: 1;
      min-height: 0;
    }
  }

  #changelog-frame {
    width: 100%;
    height: 60vh;
    border: none;
  }
</style>
