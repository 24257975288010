import type IUnitHandler from '@/modules/units/handlers/IUnitHandler'
import Logger from '@/logger'

export default class PercentHandler implements IUnitHandler {
  format(type: string, unit: string, value: any, locale = 'de-DE'): string {
    if (value > 1 || value < 0) {
      Logger.warn(`Value ${value} is out of range [0;1]`)
    }

    const rawValue = value * 100
    const formatValue = rawValue.toLocaleString(locale, {
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 3,
    })
    return formatValue + ' %'
  }

  isSupported(type: string, unit: string): boolean {
    return unit === 'percent' && type === 'float'
  }

  formatUnit(type: string, unit: string, locale: string): string {
    return '%'
  }

  scaleValue(value: number, scalingFactor: number): number {
    return value * scalingFactor
  }

  getScaling(
    xValues: [string, number | null][][],
    unit: string,
  ): { best: any; scalingFactor: number } {
    const allValues: number[] = []

    xValues.forEach((series) => {
      series.forEach((seriesDataPoint) => {
        if (seriesDataPoint[1] !== null) {
          allValues.push(seriesDataPoint[1])
        }
      })
    })

    return {
      best: {
        unit: '%',
        decimalPlaces: 2,
        min: Math.min(...allValues.filter(Number.isFinite)),
        max: Math.max(...allValues.filter(Number.isFinite)),
      },
      scalingFactor: 100,
    }
  }

  isDifferentiable(): boolean {
    return true
  }
}
