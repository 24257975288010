var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.siteEmpty)?_c('b-container',{staticClass:"bg-secondary",attrs:{"fluid":""}},[_c('h2',{staticClass:"text-white text-center m-5"},[_vm._v(_vm._s(_vm.$t('tree_site_empty')))])]):_c('div',[_c('b-form-input',{staticClass:"mx-1",attrs:{"placeholder":_vm.$t('search'),"state":null,"debounce":"100","trim":""},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),(_vm.loading)?_c('div',{staticClass:"d-flex w-100 justify-content-center",attrs:{"id":"spinner"}},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e(),_c('Tree',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"},{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],ref:"tree",staticClass:"overflow-x-hidden",attrs:{"filter":_vm.searchString,"options":{
      multiple: false,
      parentSelect: true,
      autoCheckChildren: false,
      filter: _vm.filterOptions,
    }},on:{"node:selected":_vm.updateSelection},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var node = ref.node;
return _c('div',{key:_vm.nodeKey(node),staticClass:"tree-text"},[(node.data.type === 'thing')?[_c('span',{attrs:{"id":node.data.dropData.thingId}},[_c('b',[_vm._v(_vm._s(node.text))])])]:(node.data.type === 'feature')?[_vm._v(" "+_vm._s(node.text)+" ")]:(node.data.type === 'property')?[_c('drag',{attrs:{"transferData":node.data.dropData}},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.ds500.dh50.top",value:(_vm.tooltip(node.data.dropData)),expression:"tooltip(node.data.dropData)",modifiers:{"hover":true,"ds500":true,"dh50":true,"top":true}}],class:{ 'draggable-feature': _vm.dragEnabled }},[_c('UnitIcon',{attrs:{"propertyType":node.data.dropData.propertyType,"unit":node.data.dropData.unit,"type":node.data.dataType}}),_vm._v(" "+_vm._s(node.text + ': ')+" "),_c('i',[_vm._v(" "+_vm._s(_vm.formatValues(node.data.dropData.unit, node.data.dataType, node.data.value))+" ")])],1)])]:[_c('span',[_vm._v(_vm._s(node.text))])]],2)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }