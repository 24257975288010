<template>
  <b-dropdown boundary="window" noCaret right variant="link">
    <template #button-content>
      <b-avatar :size="36" :text="userInitials" variant="primary" />
    </template>
    <b-dropdown-text class="mb-2">
      <span>{{ username }}</span>
      <br />
      <span class="text-muted">{{ userEmail }}</span>
    </b-dropdown-text>
    <b-dropdown-item :to="{ name: 'Settings' }">
      <span class="material-icons grey">settings</span>
      {{ $t('settings.title') }}
    </b-dropdown-item>

    <b-dropdown-item @click="logout">
      <span class="material-icons">logout</span>
      {{ $t('sign_out') }}
    </b-dropdown-item>

    <b-dropdown-divider />
    <b-dropdown-item href="mailto:info@othermo.de">
      <span class="material-icons">help</span>
      {{ $t('request_support') }}
    </b-dropdown-item>
    <b-dropdown-item href="https://www.othermo.de/impressum/" target="_blank">
      <span class="material-icons">description</span>
      {{ $t('imprint') }}
    </b-dropdown-item>
    <b-dropdown-item href="https://www.othermo.de/datenschutz/" target="_blank">
      <span class="material-icons">admin_panel_settings</span>
      {{ $t('privacy') }}
    </b-dropdown-item>
    <b-dropdown-item href="" @click.prevent="$bvModal.show('license-disclaimer-modal')">
      <span class="material-icons">terminal</span>
      {{ $t('menu.oss_licenses') }}
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-text>
      <div class="d-flex flex-column align-items-center">
        <i v-if="isOthermoUser" class="link-gray">{{ realm }}</i>
        <a :href="changelogLink" class="link-gray" target="_blank">
          <i class="link-gray">V{{ appVersion }}, Build {{ build }}</i>
        </a>
        <a class="link-gray" href="https://othermo.de" target="_blank">© Othermo GmbH</a>
      </div>
    </b-dropdown-text>
  </b-dropdown>
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { vxm } from '@/store/store'
  import { config } from '@/config'
  import KeycloakTools from '@/modules/tools/KeycloakTools'

  @Component
  export default class AccountDropdown extends Vue {
    @Prop({ default: false })
    readonly isOthermoUser!: boolean

    private readonly changelogLink = config.api.changelogs.overviewURL

    get appVersion() {
      return config.appVersion
    }

    get username() {
      let name
      if (vxm.user.name != ' ' && vxm.user.name != null) {
        name = vxm.user.name
      } else {
        name = vxm.user.username ?? ''
      }
      return name
    }

    get build() {
      return process.env.VUE_APP_BUILD_NUM ?? 'unknown'
    }

    get realm() {
      return KeycloakTools.getRealm(this.$keycloak) ?? ''
    }

    get userInitials() {
      return this.username
        .split(' ')
        .filter((s) => !['von', 'zu', 'und', 'van', 'de'].includes(s))
        .map((s) => s.substr(0, 1))
        .join('')
    }

    get userEmail() {
      return vxm.user.email ?? ''
    }

    async logout() {
      this.$cookies.remove('realm')
      this.$cookies.remove('userEmail')
      window.location.href = await this.$keycloak.createLogoutUrl()
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  .link-gray:any-link {
    color: $gray-700;
  }
</style>
