import { createModule, extractVuexModule } from 'vuex-class-component'

const SiteModule = createModule({
  namespaced: 'scadaEditor',
  strict: false,
})

/**
 * Stores the state of editor for components that change their state based on the editors editMode
 * current site and things belonging to that site
 */
export class ScadaEditorStore extends SiteModule {
  public editMode: boolean = false
}

export const scadaEditor = extractVuexModule(ScadaEditorStore)
