import DittoPathConverter from '@/modules/svgbindings/DittoPathConverter'
import type { Thing, ThingProperty } from '@/api/klempner/models/SiteListResponse'

/**
 * Represents a binding between a DOM element and a value from an arbitrary data source
 */
export abstract class SVGBinding {
  protected error: string | null = null

  private readonly domElement: Element

  protected readonly valuePath: string

  protected readonly pathConverter: DittoPathConverter | undefined

  /**
   *
   * @param domElement Element to bind to
   * @param valuePath Reference to the value that should be bound as a path separated by / for each layer
   */
  protected constructor(domElement: Element, valuePath: string) {
    this.domElement = domElement
    this.valuePath = valuePath
    try {
      this.pathConverter = new DittoPathConverter(this.valuePath)
    } catch (e) {
      this.error = 'Could not transform reference path: ' + e
    }
  }

  /**
   * Returns if the binding has an error resolving the value
   * @constructor
   */
  get hasError() {
    return this.error != null
  }

  /**
   * Use this getter to modify the selected DOM Element with the values
   */
  protected get element(): Element {
    return this.domElement
  }

  protected get valuePathString(): string {
    return this.valuePath
  }

  public getError(): {
    path: DittoPathConverter | undefined
    error: string | null
    element: Element
  } {
    return { error: this.error, element: this.domElement, path: this.pathConverter }
  }

  /**
   * Takes in new data in (almost) arbitrary format and updates the DOM element according to the type of binding
   */
  public abstract updateBinding(data: Thing[]): Promise<void>

  /**
   * Returns the referenced value of the current binding from the Site object
   * @param things belonging to the current site
   */
  protected getReferencedValue(things: Thing[]): ThingProperty | undefined {
    try {
      if (this.pathConverter) {
        return this.pathConverter.getValue(things)
      }
    } catch (e) {
      this.error = `Could not find a value with path ${this.valuePath}: ${e.message}`
      return undefined
    }
  }
}

export enum ColorType {
  STROKE,
  FILL,
  STOP,
}

export abstract class ColorBinding extends SVGBinding {
  protected defaultColor: string | null

  protected readonly colorType: ColorType

  protected constructor(domElement: Element, valuePath: string, colorType: ColorType) {
    super(domElement, valuePath)
    this.colorType = colorType
    this.defaultColor = this.element.getAttribute(this.attributeName)
  }

  protected get attributeName(): string {
    switch (this.colorType) {
      case ColorType.FILL:
        return 'fill'

      case ColorType.STROKE:
        return 'stroke'

      case ColorType.STOP:
        return 'stop-color'

      default:
        throw `Invalid attribute type: ${this.colorType}`
    }
  }

  /**
   * Removes the inline style if present
   */
  protected resetInlineStyle() {
    const elem = this.element as SVGElement | HTMLElement
    if (elem === null) {
      return
    }
    switch (this.colorType) {
      case ColorType.STROKE:
        elem.style.stroke = ''
        break
      case ColorType.FILL:
        elem.style.fill = ''
        break
      case ColorType.STOP:
        elem.style.stopColor = ''
        break
    }
  }
}
