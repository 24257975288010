<template>
  <!--  This is a test template for development-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting"></SubmitOverlay>

      <Title>Kessel</Title>
      <Info>Stellen Sie hier die Parameter des Weishaupt Kessels ein.</Info>

      <numeric-value-item
        :featureName="featureId"
        :valuePath="`features.${featureId}.properties.configuration.flowTemperatureMin`"
        displayName="Min. VL Soll"
        propertyType="configuration"
        property-name="flowTemperatureMin"
        toolTip="Minimale Sollvorlauftemperatur Kessel"
      />
      <numeric-value-item
        :featureName="featureId"
        :valuePath="`features.${featureId}.properties.configuration.flowTemperatureMax`"
        displayName="Max. VL Soll"
        propertyType="configuration"
        propertyName="flowTemperatureMax"
        toolTip="Maximale Sollvorlauftemperatur Kessel"
      />
      <numeric-value-item
        :featureName="featureId"
        :valuePath="`features.${featureId}.properties.configuration.switchingDifferenceFlow`"
        displayName="Schaltdifferenz VL"
        propertyType="configuration"
        propertyName="switchingDifferenceFlow"
        toolTip="Schaltdifferenz Vorlauf"
      />
      <numeric-value-item
        :featureName="featureId"
        :valuePath="`features.${featureId}.properties.configuration.burnerCycleLock`"
        displayName="Brenner Taktsperre"
        propertyType="configuration"
        propertyName="burnerCycleLock"
        toolTip="Brenner Taktsperre"
      />
      <numeric-value-item
        :featureName="featureId"
        :valuePath="`features.${featureId}.properties.configuration.powerMax`"
        displayName="Max. Leistung Heizung"
        propertyType="configuration"
        propertyName="powerMax"
        toolTip="Maximale Leistung der Heizung"
      />
    </template>
  </div>
</template>

<script lang="ts">
  /*This code does not has to be touched when developing a new template except the ClassName*/
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'

  /*REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      TextItem,
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  /*REPLACE 'TestId' WITH A FITTING COMPONENT NAME*/
  export default class WeishauptBoiler extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style scoped lang="scss">
  /*The edit component can be styled here*/
</style>
