import { config } from '@/config'
import type { VueKeyCloakInstance } from '@/@types/keycloak'
import type { KeycloakInstance, KeycloakTokenParsed } from 'keycloak-js'

export default class KeycloakTools {
  /**
   * Checks if the user has the permission to manage other users in the realm.
   * @param keycloak
   */
  static canUserManageUsers(keycloak: VueKeyCloakInstance) {
    return this.hasUserJWTRole(keycloak, config.keycloak.roles.user_manager)
  }

  /**
   * Checks if the user is allowed to view analytics
   * @param keycloak
   */
  static canUserViewAnalytics(keycloak: VueKeyCloakInstance) {
    return this.hasUserJWTRole(keycloak, config.keycloak.roles.analytics.read)
  }

  /**
   * Checks if the user is allowed to modify analytics
   * @param keycloak
   */
  static canUserEditAnalytics(keycloak: VueKeyCloakInstance) {
    return this.hasUserJWTRole(keycloak, config.keycloak.roles.analytics.write)
  }

  /**
   * Checks if the user has the permission to manage sites for this customer.
   * @param keycloak
   */
  static canUserManageSites(keycloak: VueKeyCloakInstance) {
    return this.hasUserJWTRole(keycloak, config.keycloak.roles.site_manager)
  }

  /**
   * Returns whether the user has the right to display/read trends
   * @param keycloak
   */
  static canUserReadTrends(keycloak: VueKeyCloakInstance): boolean {
    return this.hasUserJWTRole(keycloak, config.keycloak.roles.trends.read)
  }

  /**
   * Returns whether the user has the right to edit/change/create trends
   * @param keycloak
   */
  static canUserWriteTrends(keycloak: VueKeyCloakInstance): boolean {
    return this.hasUserJWTRole(keycloak, config.keycloak.roles.trends.write)
  }

  /**
   * Returns whether the user has the right to display/read Scada SVGS
   * @param keycloak
   */
  static canUserReadScadaSVGs(keycloak: VueKeyCloakInstance): boolean {
    return this.hasUserJWTRole(keycloak, config.keycloak.roles.scada.read)
  }

  /**
   * Returns whether the user has the right to edit/change/create scada svgs
   * @param keycloak
   */
  static canUserWriteScadaSVGs(keycloak: VueKeyCloakInstance): boolean {
    return this.hasUserJWTRole(keycloak, config.keycloak.roles.scada.write)
  }

  /**
   * Wether the user has the ditto_operator role required to modify things in ditto
   * @param keycloak
   */
  static canUserWriteDitto(keycloak: VueKeyCloakInstance): boolean {
    return this.hasUserJWTRole(keycloak, config.keycloak.roles.ditto.write)
  }

  static getRealm(keyCloak: VueKeyCloakInstance | KeycloakInstance) {
    const realmUrl = (keyCloak.tokenParsed as KeycloakTokenParsed & { iss: string }).iss
    return realmUrl.split('/').pop()
  }

  static readonly site_role_regex = /^c\d{4}:[0-9A-F]{16}$/

  /**
   * Checks if a role name matches the pattern for site ids in ditto
   * @param roleName Role to check
   */
  static isSiteRole(roleName: string) {
    return this.site_role_regex.test(roleName)
  }

  private static hasUserJWTRole(keycloak: VueKeyCloakInstance, role: string): boolean {
    //@ts-ignore
    return keycloak.tokenParsed.roles?.includes(role) ?? false
  }

  static isKeycloakTokenExpired(keycloak: VueKeyCloakInstance): boolean {
    const now = Math.round(Date.now() / 1000) //Seconds since 1970
    // @ts-ignore
    return keycloak.idTokenParsed.exp < now
  }

  static canUserManageAlerts(keyCloakInstance: VueKeyCloakInstance) {
    return this.hasUserJWTRole(keyCloakInstance, config.keycloak.roles.alarm_manager)
  }
}
