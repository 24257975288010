import { action, createModule, extractVuexModule, mutation } from 'vuex-class-component'
import KeycloakTools from '@/modules/tools/KeycloakTools'
import Logger from '@/logger'
import Vue from 'vue'
import { KeycloakInstance } from 'keycloak-js'
import type { KeycloakProfile } from '@dsb-norge/vue-keycloak-js/dist/types'

const UserModule = createModule({
  namespaced: 'user',
})

export class UserStore extends UserModule {
  name: string = ''

  username: string = ''

  logOutUrl: string = ''

  managementUrl: string = ''

  token: string = ''

  email: string = ''

  @action
  async loadUserProfile(keycloak: KeycloakInstance) {
    const userProfile: KeycloakProfile = await keycloak.loadUserProfile()
    this.setUserDetails(userProfile)

    this.managementUrl = keycloak.createAccountUrl()
    this.logOutUrl = keycloak.createLogoutUrl()

    Logger.info(`The user ${this.username} is logged in`)

    const realm = KeycloakTools.getRealm(keycloak)
    // Note: the ts config only accepts undefined, but must be null; can't be secure because localhost is not secured
    //@ts-ignore
    Vue.$cookies.config('7d', null, null, null, 'Lax')
    Vue.$cookies.set('realm', realm)
  }

  @mutation
  setUserDetails(user: KeycloakProfile) {
    this.username = user.username ?? ''
    this.name = user.firstName + ' ' + user.lastName
    this.email = user.email ?? ''
  }
}

export const user = extractVuexModule(UserStore)
