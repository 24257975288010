import { config } from '@/config'
import Logger from '@/logger'

/**
 * Translation for a single property.
 */
export interface PropertyTranslation {
  de: string

  en: string
}

/** Supported language codes for translations. */
export type Language = 'de' | 'en'

export default class PropertyTranslationService {
  /** Singleton instance of this service. */
  private static instance: PropertyTranslationService | null = null

  /** Translations for property names. */
  private translations: Record<string, PropertyTranslation> = {}

  /**  Currently active language for translations. */
  private language: Language = 'de'

  private constructor() {}

  /**
   * Get the singleton instance of this service.
   */
  static getInstance(): PropertyTranslationService {
    if (this.instance === null) {
      this.instance = new PropertyTranslationService()
    }
    return this.instance
  }

  /**
   * Returns the model name from the Ditto's definition string.
   * If the definition is undefined or if the model name cannot be extracted, it returns undefined.
   *
   * @param {string | undefined} definition - Ditto's definition string from which to extract the model name.
   *
   * @returns {string | undefined} - Model name if successfully extracted, else undefined.
   *
   * @example
   * const modelName = getModelFromDittoDefinition("othermo:DeviceDefinition:1.0.0");
   * // returns: "DeviceDefinition"
   */
  static getModelFromDittoDefinition(definition: string | undefined): string | undefined {
    if (definition === undefined || definition === '') {
      return undefined
    }
    return /:(\w+):/g.exec(definition)?.[1]
  }

  /**
   * Sets the active language for translations.
   *
   * @param {Language} language - The language to set as active. Supports 'de" and 'en'.
   *
   * @example
   * setLanguage('en');
   */
  public setLanguage(language: Language) {
    this.language = language
  }

  /**
   * Fetches property name translations from the backend server.
   *
   * @param {string} authToken - An authentication token (JWT) required to authorize the fetch request.
   * @param {string} realm - Not used by the backend, appended to the URL to avoid caching responses for other realms.
   * @param {boolean} forceReload - If true, forces the browser to bypass cache and fetch from server. Default is false.
   *
   * @returns {Promise<void>}
   *
   * @example
   * await fetchTranslations('your_auth_token', 'your_realm', true);
   *
   * @throws {Error} If the fetch operation fails.
   */
  public async fetchTranslations(
    authToken: string,
    realm: string = '',
    forceReload: boolean = false,
  ): Promise<void> {
    try {
      const result = await fetch(
        `${config.api.klempner.url}translations/property-names?realm=${realm}`,
        {
          headers: { authorization: 'Bearer ' + authToken },
          cache: forceReload ? 'reload' : 'default',
        },
      )
      this.translations = await result.json()
    } catch (error) {
      Logger.error(error)
    }
  }

  /**
   * Returns the translation for a given model and propertyPath name
   * @param model Model (formerly definition) of the Thing for which a translation is requested
   * @param propertyPath Property name in the format feature.[status|configuration].propertyPath
   * @param fallback Default string to return in case the translation is not found
   */
  public translate(model?: string, propertyPath?: string, fallback: string = ''): string {
    model = model?.toLowerCase()
    propertyPath = propertyPath?.toLowerCase()
    if (model === undefined) {
      if (propertyPath === undefined) {
        return fallback
      } else {
        return this.translateWithoutModel(propertyPath, fallback)
      }
    }

    let key = model
    if (propertyPath !== undefined) {
      key += '.' + propertyPath
    }

    return this.getTranslation(key, fallback)
  }

  /**
   * Translates a category name
   * @param category Category name to translate
   * @param fallback Fallback text that is returned when no translation is found
   */
  public translateCategory(category: string, fallback?: string): string {
    return this.translate(category, undefined, fallback)
  }

  /**
   * Get a property translation directly using the key.
   * This method should only be used to avoid parsing a string that's already combined. Use translate()
   * or any of the helper methods instead.
   * @param key Translation key
   * @param fallback Fallback text
   */
  private getTranslation(key: string, fallback: string = ''): string {
    const translation = this.translations[key]
    if (translation == undefined) {
      return this.getTranslationWithPropertyOnly(key, fallback)
    }
    return translation[this.language]
  }

  /**
   * Finds the property name from a translation key and re-tries the translation with just the property name
   * @param key Translation key in the format 'Model.feature.type.propertyName`
   * @param fallback Fallback value to return
   */
  private getTranslationWithPropertyOnly(key: string, fallback: string = ''): string {
    const pathComponents = key.split(/[./]/)
    if (pathComponents.length == 0) {
      return fallback
    }
    const propertyName = pathComponents[pathComponents.length - 1]
    const translation = this.translations[propertyName]
    if (translation == undefined) {
      return fallback
    }
    return translation[this.language]
  }

  /**
   * Translates a property name into human-readable form, but without a specified model name.
   * If there's no translation found for the property, it returns a fallback value.
   *
   * @param {string} property - Name of the property to be translated.
   * @param {string} fallback - Optional fallback value if translation is not found. Default is an empty string.
   *
   * @returns {string} Translated property name if found, else returns fallback value.
   *
   * @example
   * const translated = translateWithoutModel('propertyName', 'Fallback Property Name');
   */
  private translateWithoutModel(property: string, fallback: string = ''): string {
    const key = property.toLowerCase()

    const matchingKey = Object.keys(this.translations)
      .sort()
      .find((k) => {
        const lowerK = k.toLowerCase()
        return lowerK === key || lowerK.replace(/\w+\./, '') === key
      })

    if (matchingKey === undefined) {
      return fallback
    }

    return this.getTranslation(matchingKey, fallback)
  }
}
