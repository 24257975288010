<template>
  <!--This Template shows a dialog with minimal configuration-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting" />

      <Info>Das Gateway bietet vier PKs, die hier an- und abgeschaltet werden können.</Info>

      <multi-group :thing="thing" title="Potentialfreie Kontakte"></multi-group>

      <Group title="Potentialfreier Kontakt 1">
        <text-item
          :value="thing.features.DO1.properties.configuration.description"
          propertyName="Description"
          dittoMsg="DO1/configuration"
          msgParamName="description"
          toolTip="Set the description"
        />
        <button-item propertyName="an" dittoMsg="DO1/on" />
        <button-item propertyName="aus" dittoMsg="DO1/off" />
      </Group>
      <Group title="Potentialfreier Kontakt 2">
        <text-item
          :value="thing.features.DO2.properties.configuration.description"
          propertyName="Description"
          dittoMsg="DO2/configuration"
          msgParamName="description"
          toolTip="Set the description"
        />
        <button-item propertyName="an" dittoMsg="DO2/on" />
        <button-item propertyName="aus" dittoMsg="DO2/off" />
      </Group>
      <Group title="Potentialfreier Kontakt 3">
        <text-item
          :value="thing.features.DO3.properties.configuration.description"
          propertyName="Description"
          dittoMsg="DO3/configuration"
          msgParamName="description"
          toolTip="Set the description"
        />
        <button-item propertyName="an" dittoMsg="DO3/on" />
        <button-item propertyName="aus" dittoMsg="DO3/off" />
      </Group>
      <Group title="Potentialfreier Kontakt 4">
        <text-item
          :value="thing.features.DO4.properties.configuration.description"
          propertyName="Description"
          dittoMsg="DO4/configuration"
          msgParamName="description"
          toolTip="Set the description"
        />
        <button-item propertyName="an" dittoMsg="DO4/on" />
        <button-item propertyName="aus" dittoMsg="DO4/off" />
      </Group>
    </template>
  </div>
</template>

<script lang="ts">
  /*This code does not has to be touched when developing a new template except the ClassName*/
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'
  import MultiGroup from '@/components/site/scada/command_response/toolbox/base/MultiGroup.vue'

  /*REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      MultiGroup,
      TextItem,
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  /*REPLACE 'TestId' WITH A FITTING COMPONENT NAME*/
  export default class GatewayPKs extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style scoped lang="scss">
  /*The edit component can be styled here*/
</style>
