export const recommendationsDe = {
  sidebar_title: 'Handlungsempfehlungen',
  choice: {
    accept: 'Annehmen',
    decline: 'Ablehnen',
  },
  sendingModal: {
    title: 'Empfehlungen werden verarbeitet',
    success: 'Alle Änderungen wurden erfolgreich angewendet.',
    error:
      'Es gab einen Fehler beim Anwenden der Änderungen. Bitte versuchen Sie es später nochmal oder melden Sie sich beim Kundendienst.',
    partialSuccess: 'Es konnten nicht alle Änderungen angewendet werden.',
  },
  apply_changes: 'Änderungen anwenden',
  no_recommendations: 'Keine Handlungsempfehlungen',
  error_while_loading:
    'Es gab einen Fehler während des Ladens. Bitte versuchen Sie es später nochmal oder wenden Sie sich an den Support.',
  table: {
    open: {
      thingName: 'Komponente',
      property: 'Einstellung',
      currentValue: 'Aktueller Wert',
      targetValue: 'Empfohlener Wert',
      actions: 'Aktionen',
    },
    logged: {
      thingName: 'Komponente',
      property: 'Einstellung',
      previousValue: 'Vorheriger Wert',
      targetValue: 'Empfohlener Wert',
      accepted: 'Angenommen',
      manually: 'Manuell',
      username: 'Angewendet von',
      set_at: 'Angewendet am',
      proposed_at: 'Empfohlen am',
    },
  },
  tabs: {
    open: 'Offene Empfehlungen',
    logged: 'Angewendete Empfehlungen',
  },
  stats: {
    year_of_construction: 'Gebäudebaujahr',
    energy_index: 'Primärenergieverbrauch [kWh/m²a]',
    boiler_type: 'Kessel',
    open_recommendations: 'Offene Empfehlungen',
    applied_recommendations: 'Umgesetzte Empfehlungen',
  },
  disabled: 'Die Handlungsempfehlungen sind für diese Anlage nicht aktiviert',
  autopilot: 'Autopilot aktiviert.',
  applyStatus: {
    unexpected_error: 'Unerwarteter Fehler ',
    device_timeout: 'Gerät antwortet nicht',
    device_error: 'Gerät meldet Fehler',
    success: 'Erfolgreich angewendet',
    invalid_recommendation: 'Ungültige Empfehlung',
  },
}
