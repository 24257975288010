export const chartTranslationsEn = {
  trends: 'Trends',
  new_chart: 'New Chart',
  delete_chart_group: 'Delete "{name}"? (This cannot be undone)',
  new_chart_page: 'Create new chart page',
  new_page_name_descr: 'The name for the new chart page',
  enter_name: 'Enter name',
  no_permission: 'No permission',
  timespan: 'Time',
  chart_no_read_permission: "You don't have the permission to see the trends of this Site.",
  display_style: 'Display options',
  color: 'Color',
  chart_no_write_permission: "You don't have the permission to edit the trends of this Site.",
  y_axis: 'Y-Axis',
  add_series: 'Add series',
  tooltip_timespan: 'Time frame for this page',
  tooltip_interval: 'Temporal resolution',
  tooltip_save: 'Save changes on this page',
  tooltip_reset: 'Reset changes on this page',
  tooltip_delete: 'Delete this page',
  tooltip_add: 'Drag elements to a chart on this page to add a new series',
  auto_update: 'Auto update',
  auto_update_text: 'Automatically refresh charts every {seconds}s',
  error_saving_chart: 'Error while saving.',
  success_saving_chart: 'Successfully saved',
  error_loading_chart: 'Error: Could not load the chart.',
  error_loading_chart_data: 'Error occurred while loading data: {error}',
  error_for_series: 'Error while getting series {name}',
  error_no_data: 'No data for series {name} in selected time frame',
  trends_warning: {
    multiple_units:
      'Unit conflict on y-axis {yAxis} ({units}). The datapoints may not be displayed correctly.',
  },
  charts_no_data: 'Drag a metric to this chart to add a new Series.',
  add_line_chart: 'New line chart',
  add_status_chart: 'New status chart',
  interval: 'Interval',
  chart_name_label: 'Chart title',
  series_name_label: 'Series name',
  line_type: 'line type',
  status_color_start: 'Color 1',
  status_color_end: 'Color 2',
  height: 'Height',
  height_per_series: 'Height (per series)',
  time_last_3h: 'Last 3h',
  time_last_24h: 'Last 24h',
  time_last_2d: 'Last 2 days',
  time_last_7d: 'Last 7 days',
  time_last_1m: 'Last 31 days',
  time_last_3m: 'Last 3 months',
  time_last_1y: 'Last year',
  time_last_3y: 'Last 3 years',
  time_1min: '1 min',
  time_5min: '5 min',
  time_15min: '15 min',
  time_60min: '1 hour',
  time_1d: '1 day',
  time_1w: '1 week',
  time_2w: '2 weeks',
  time_1m: '1 month',
  time_3m: '3 months',
  time_1y: '1 year',
  time_auto: 'Auto',
  time_max: 'Max',
  refresh: 'Refresh',
  no_data: 'No data',
  leave_unsaved: 'Do you want to discard the unsaved changes to this view?',
  reset_zoom_or_shift: 'Reset Zoom or Shift',
  tree_site_empty: 'No entries found.',
  custom: 'custom',
  load_dashboard_failed: 'Error loading tab: ',
  temporary_dashboard_failed: 'The temporary tab could not be created: ',
  error_creating_trend: 'Error creating trends tab: ',
  error_deleting_trends_tab: 'Error deleting trends tab: ',
  transparent: 'auto',
  '#c00000': 'red',
  '#ff9100': 'orange',
  '#ffea00': 'yellow',
  '#32cb00': 'green',
  '#1de9b6': 'turquoise',
  '#00b0ff': 'light blue',
  '#3d5afe': 'blue',
  '#d500f9': 'violett',
  '#263238': 'black',
  derivative: 'Rate of change',
  derivativeUnit: {
    s: 's',
    min: 'min',
    h: 'h',
    d: 'd',
    week: 'week',
  },
  value: 'Value',
  rename_tab: 'Rename Tab',
  rename_save_warning: 'Renaming also saves all pending changes in this tab',
  rename_tooltip: 'Rename tab',
  mode_auto: 'Automatic',
  mode_manual: 'Manual',
  axis_mode: 'Range',
  axis_left: 'Left axis',
  axis_right: 'Right axis',
  min_max_required: 'Min and max must be set to valid numbers',
  must_be_greater: 'Max must not be smaller than or equal to min',
  load_error: {
    metadata_missing:
      'Could not load series "{series}" because required metadata is missing. Contact the support if the problem persists.',
  },
  status_chart_properties: {
    series_name_used_multiple_times:
      'The series name is used multiple times. The datapoints will be merged and might not be displayed properly.',
    datapoint_name_used_multiple_times:
      'The datapoint name is used multiple times. The datapoints will be displayed with the first defined color.',
  },
  save_copy: 'Save copy',
}
