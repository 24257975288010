import { action, createModule, extractVuexModule } from 'vuex-class-component'
import { mutation } from 'vuex-class-component/js'
import PropertyTranslationService from '@/modules/translations/PropertyTranslationService'

export enum DashboardMode {
  MAP = 'map',
  GRID = 'grid',
  ALARM = 'alarm',
  REPORTS = 'reports',
}

export enum PropertySortingOrder {
  NAME = 'name',
  UNIT = 'unit',
}

const SettingsModule = createModule({
  namespaced: 'settings',
})

/**
 * Stores user specific settings in the local storage
 */
export class SettingsStore extends SettingsModule {
  /**
   * The saved language
   */
  public lang: string = ''

  /**
   *  wether the dashboard should show a list of sites, a map or the alarm view
   */
  public dashboardMode: DashboardMode = DashboardMode.GRID

  /**
   * Whether the notification card is expanded or collapsed on the dashboard
   */
  public notificationCardExpanded = false

  /**
   * Whether the sidebar menu is in expanded or collapsed state
   */
  menuExpanded = true

  /**
   * Sorting order for the properties in the twin explorer
   */
  public twinPropertySorting: PropertySortingOrder = PropertySortingOrder.UNIT

  /**
   * Inits the language setting.
   * @param i18n
   */
  @action
  async init(i18n: any) {
    // If a previous setting is loaded via cookie, this.lang is set
    if (!this.lang || !i18n.locales().includes(this.lang)) {
      this.setLanguage('de')
    }
    i18n.set(this.lang)
    i18n.fallback('de')
    PropertyTranslationService.getInstance().setLanguage(this.lang as 'de' | 'en')
  }

  @mutation
  setDashboardMode(mode: DashboardMode) {
    this.dashboardMode = mode
  }

  @mutation
  setLanguage(lang: string) {
    this.lang = lang
  }

  /**
   * Sets the current language and emit an language change event
   * @param payload
   */
  @action
  async changeLanguage(payload: { vue: any; lang: string }) {
    this.setLanguage(payload.lang)
    PropertyTranslationService.getInstance().setLanguage(payload.lang as 'de' | 'en')
    payload.vue.$i18n.set(this.lang)
  }
}

export const settings = extractVuexModule(SettingsStore)
