/**
 * Provides new AbortControllers as needed, i.e. when a controller has been aborted
 * (Because AbortController can't be reset)
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/AbortController|AbortController on MDN}
 */
export default class AbortControllerProvider {
  private abortController: AbortController = new AbortController()

  public getAbortController(): AbortController {
    if (this.abortController?.signal.aborted) {
      this.abortController = new AbortController()
    }
    return this.abortController
  }

  public abort(): void {
    this.abortController.abort()
  }
}
