export const alertTranslationsEn = {
  alerts: 'Alerts',
  alert_history: 'History',
  add_alert: 'Add alert',
  create_alert: 'Create new alert',
  next: 'Next',
  back: 'Back',
  prev_page: 'Previous',
  next_page: 'Next',
  finish: 'Finish',
  step_2_description: 'Description',
  step_3_rules: 'Rules',
  choose_alert_type: 'Choose alert type',
  custom_alerts: 'Custom Alerts',
  alert_type: {
    cascade: 'Threshold Alert',
    control_deviation: 'Control Deviation',
    hysteresis: 'Hysteresis Alert',
  },
  error_loading_history: 'Could not load site history: {error}',
  discard_changes: 'Discard changes and close wizard?',
  selection: 'Selection',
  no_selection: 'Nothing selected',
  help_text_criteria: 'When should the alert be triggered?',
  current_value: 'Current value',
  alert_triggered_when: 'Alert triggered if',
  avg_5_min: '5 minute average',
  avg_15_min: '15 minute average',
  avg_60_min: '60 minute average',
  smaller_than: 'less than (<)',
  bigger_than: 'greater than (>)',
  threshold: 'threshold',
  threshold_active: 'Grenzwert active',
  alert_created: 'Alert "{name}" successfully created',
  alert_updated: 'Alert "{name}" successfully updated',
  error_creating_alert: 'Error during alert creation: {error}',
  truthy_value: 'True / Active / High ',
  falsy_value: 'False / Inactive / Low',
  error_no_value_in_series:
    'No data exists for the selected feature.' +
    ' To create an alert, please select a different feature in the first step.',
  error_unsupported_series:
    'The data type of the selected feature is not supported at the moment.' +
    ' Please select a feature with boolean or numerical type to create an alert.',
  description: 'Description',
  optional: 'optional',
  help_text_severity: 'Choose a severity and description for the alert',
  severity: 'Severity',
  severity_prev: 'Previous Severity',
  value_new: 'New Value',
  value_old: 'Old Value',
  // Severity levels
  severity_critical: 'Critical',
  severity_error: 'Fault',
  severity_warning: 'Warning',
  severity_informational: 'Notification',
  severity_ok: 'OK',
  severity_normal: 'Normal',
  severity_acknowledged: 'Acknowledged',
  severity_indeterminate: 'Indeterminate',
  severity_multiple: 'Multiple',
  severity_info: 'Notification', // where is this used? this is not defined as SeverityLevel
  notification_delay_help_text: 'How long condition needs to be met before sending an notification',
  hours: 'Hours',
  minutes: 'Minutes',
  seconds: 'Seconds',
  help_text_recipients:
    'Email addresses and phone numbers (for sms) of recipients for this alert, one per line.',
  actions: 'Actions',
  datapoint: 'Data point',
  error_loading_alerts: 'Could not load alerts',
  error_changing_alert_activation: 'Could not change the alert activation.',
  error_loading_activations: 'Could not load activations: {error}',
  alert_id: 'Alert ID',
  recipients: 'Recipients',
  clear_filter: 'Clear',
  apply_filter: 'Apply filter',
  found_alerts: '{count} alert matches filter:::{count} alerts match filter',
  last_change: 'Last status change',
  last_change_never: 'No change since creation',
  activation_state: 'Alert Status',
  activated: 'currently active',
  inactive: 'inactive',
  confirm_delete_alert_part1: 'Delete alert with the name ',
  confirm_delete_alert_part2: '?',
  confirm_delete_alert_group: 'Delete all alerts for {name}?',
  duration: 'Duration',
  start_time: 'Start time',
  end_time: 'End time',
  correlation_id: 'Correlation ID',
  active_only: 'Active only',
  alerts_before: 'Until',
  alerts_after: 'From',
  no_alerts: 'No currently active alerts',
  include_device_notifications: 'Device notifications',
  device_notification: 'Device Notification',
  details: 'Show details',
  since: 'Active since',
  refresh_alerts: 'Refresh',
  filter_thing: 'Filter by thing',
  alert_value: 'Alert value',
  notification_delay: 'Notification delay',
  edit_recipients: 'Edit recipients',
  update_recipients_success: 'Recipients updated',
  update_recipients_error: 'Error when updating recipients',
  site_access_full: 'All Sites',
  group_by_thing: 'Group by thing',
  alert_count: 'Alerts',
  alert_delete_failed: 'Alert could not be deleted',
  device: 'Device alert',
  rule: 'Custom alerts',
  expert: 'Expert',
  first_received: 'First Received',
  last_received: 'Last Received',
  reset: 'Reset',

  //Muting
  mute_site: 'Mute this site',
  unmute_site: 'Unmute this site',
  site_muted_until: 'Site is muted until {end}',
  mute_status: 'Mute',
  mute_status_long: 'Notifications',
  muted: 'Muted',
  unmuted: 'Active',
  site_muted: 'Site muted',
  site_unmuted: 'Site unmuted',
  unmute_all: 'Unmute all in group',
  mute_all: 'Mute all in group',
  edit_end: 'Edit end time',
  error_toggling_mute: 'Mute status could not be set',
  until: 'Until',
  tomorrow: 'Tomorrow morning',
  next_week: 'Beginning of next week',
  next_month: 'Beginning of next month',
  custom_time: 'Custom end',
  error_toggling_site_mute: 'Could not mute site: {error}',
  error_getting_site_mute: 'Could not load mute state of site: {error}',
  copy_id: 'Copy ID to clipboard',
  status: 'Status',
  enableAlarm: 'Enable this alarm',
  disableAlarm: 'Disable this alarm',
  enableThingAlarm: 'Enable alarms of this device',
  disableThingAlarm: 'Disable alarms of this device',

  // Filter
  filter_by_severity: 'Filter by severity',
  filter_by_event: 'Filter by event',
  filter_by_type: 'Filter by type',
  showing_items: 'Showing items {start} - {end} of {total}',
  severityFilter_might_hide_sites:
    'When filtering for severities sites without any alerts are not displayed within the dashboard.',
  sites_without_alerts: 'Sites without alerts',

  // Rule editor
  add_alarm: {
    actual_value: 'Actual Value',
    control_value: 'Control Value',
    or: 'or',
    when_control_deviation: 'If the cumulative deviation between actual and control value',
    threshold_mode_path: 'Data point',
    threshold_mode_value: 'Static value',
  },
  when: 'When',
  over: 'over',
  of: 'of',
  is: 'is',
  set_status_to: 'set status to',
  comp_equal: 'equal to',
  comp_less: 'less than',
  comp_greater: 'greater than',
  comp_in_range: 'in range',
  comp_out_of_range: 'not in range',
  last_xm: 'the last minute:::the last {count} minutes',
  last_xh: 'the last hour:::the last {count} hours',
  selection_required: 'A property must be selected',
  threshold_required: 'Threshold value(s) are required',
  active_threshold_required: 'A value is required for active thresholds!',
  threshold_conflicts_explanation: 'The severity thresholds values should either be descending!',
  no_threshold_defined: 'At least one threshold threshold must be activated!',
  hysteresis_not_defined:
    'The hysteresis (difference that needs to be reached for the deactivation of a triggered threshold) must be defined!',
  add_rule: 'Add rule',
  unsupported_type: 'Type {type} of selected property is not supported',
  select_thing: 'Select thing',
  hysteresis: 'Hysteresis',

  //Subscriptions
  alert_subscriptions: 'Alert Subscriptions',
  user: 'User',
  phone: 'Call',
  sms: 'SMS',
  opt_out: 'None',
  daily_summary: 'Daily Summary',
  contact_details: 'Contact Details',
  error_loading_subscriptions: 'Could not load alert subscriptions: {error}',
  invalid_phone_number_feedback:
    'Invalid number - phone numbers must be entered with the country code and without any spaces, e.g. +4915751234567',
  load_contact_info_error: 'Could not load contact details: {error}',
  update_contact_info_success: 'Contact details updated successfully',
  update_contact_info_error: 'Could not update contact details: {error}',
  update_subscriptions_success: 'Notification settings saved',
  update_subscriptions_error: 'Could not save Notification settings: {error}',
  no_subscriptions: 'Could not load alert subscriptions',
  warning_no_phone: 'No phone number is set for this user, alerts will not be sent',
  warning_no_sms: 'No SMS number is set for this user, notifications will not be sent',
  info_text_subscriptions_all_sites:
    'Here you can define how you want to be notified when an alert gets raised in any site',
  info_text_subscriptions:
    'Here you can set additional preferences for individual sites. Site-specific settings always overwrite settings for all sites.',
  subscriptions: {
    leave_unsaved_title: 'Leave without saving?',
    leave_unsaved_text: 'Leave page and discard all changes to alert subscription?',
  },
  form_feedback: {
    incompatible_units:
      'Unit "{unit1}" of the selected data point is not compatible with "{unit2}".',
    type_not_supported:
      'Type "{type}" of the selected data point is not supported for this alert type.',
  },
  alert_status: {
    true: 'Active',
    false: 'Inactive',
  },

  help: {
    cascade: {
      choose:
        'Threshold alerts evaluate one or multiple conditions in order. If one condition is satisfied, the alarm is activated at the selected severity.\n' +
        '\n' +
        'Examples:\n' +
        '• Create a warning if the flow temperature is below a threshold\n' +
        '• Create a warning if the 15 minute rolling average of the pressure is outside of an interval\n' +
        '• Create a warning if the daily energy use is above a threshold\n' +
        '• Create a warning if the filling level in a tank drops below 50%; set the severity to error if the level drops below 20%',
      edit: 'Conditions are evaluated in order. If one condition is satisfied, all further conditions are not evaluated.',
    },
    deviation: {
      choose:
        'Control deviation alerts compare a data point to another data point or a set value. If the difference between the two is greater than a set threshold for a set amount of time, an alert is created.',
    },
    hysteresis: {
      choose:
        'The alert is triggered when the selected measured value falls below a threshold for an extended period of time. The alert is only deactivated once it is back above its target value.',
    },
  },
}
