import type { Thing } from '@/api/klempner/models/SiteListResponse'
import type { ThingProperty } from '@/api/klempner/models/SiteListResponse'

export default class DittoPathConverter {
  private path: string[]

  private readonly thingId: string | undefined

  private readonly pathStart = 'ditto://'

  constructor(path: string) {
    if (!path.startsWith(this.pathStart)) {
      throw 'This converter is not valid for other paths than ditto'
    }
    path = path.substring(this.pathStart.length)
    this.path = path.split('/')
    // Removes the first element since this is the thing id
    this.thingId = this.path.shift()
    if (this.thingId === undefined) {
      throw 'Invalid ditto reference'
    }
  }

  get getThingId(): string {
    return <string>this.thingId
  }

  /**
   * Returns the current value of the given path
   */
  public getValue(things: Thing[]): ThingProperty {
    if (things.length === 0) {
      throw 'No things to search in'
    }
    // Get the corresponding thing
    const thing = things.find((it) => it.id === this.thingId)

    if (thing === undefined) {
      throw `The referenced thing ${this.thingId} is not part of the current site`
    }

    return DittoPathConverter.resolve(this.path, thing)
  }

  private static resolve(path: string[], obj: any): ThingProperty {
    //TODO consolidate with other similar methods
    let current = obj
    for (const part of path) {
      current = current[part]
      if (current == undefined) {
        throw new Error(`Property ${part} does not exist on ${current}`)
      }
    }
    return current
  }

  toString() {
    return this.pathStart + '/' + this.thingId + '/' + this.path.join('/')
  }
}
