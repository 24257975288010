export const siteManagementTranslationsDe = {
  site_management: 'Anlagenverwaltung',
  site_management_permission_denied:
    'Sie haben nicht die notwendige Berechtigung um Anlagen zu bearbeiten.',
  site_visible: 'Sichtbar',
  site_invisible: 'Unsichtbar',

  site_status: 'Status',
  no_gateways: 'Diese Anlage hat keine Router oder Gateways',
  no_ip_configured: 'IP nicht konfiguriert',
  object: 'Objekt:::Objekte',
  error_loading_gateways: 'Fehler beim laden der Gateways/Router: {error}',
  error_getting_count: 'Objekt-Anzahl für {siteName} konnte nicht abgerufen werden: {error}',
  error_updating_visibility: 'Anlagen-Sichtbarkeit konnte nicht gesetzt werden: {error}',
  site_update_success: 'Anlage erfolgreich aktualisiert',
  error_loading_site: 'Anlage konnte nicht geladen werden: {error}',
  // Form translations
  address: 'Adresse',
  edit_site_details: 'Anlagendetails Bearbeiten',
  edit_thing_details: 'Gerätedetails Bearbeiten',
  site_title: 'Name der Anlage',
  site_customer_id: 'Kunden-ID',
  site_description: 'Beschreibung',
  site_address: 'Addresse der Anlage',
  street: 'Straße und Hausnummer',
  postalcode: 'Postleitzahl',
  city: 'Stadt',
  country: 'Land',
  site_location: 'Standort',
  latitude: 'Breitengrad',
  longitude: 'Längengrad',
  altitude: 'Höhe',
  // Form feedback
  title_invalid_feedback: 'Name darf nicht leer bleiben',
  lat_invalid_feedback: 'Nur Dezimalzahlen zwischen -90 und 90 erlaubt',
  long_invalid_feedback: 'Nur Dezimalzahlen zwischen -180 und 180 erlaubt',
  postal_code_invalid_feedback: 'Nur Buchstaben, Zahlen und max. 10 Zeichen zulässig',
  // Delete
  delete_site: 'Anlage löschen',
  delete_site_warning: 'Sind Sie sicher, dass die Anlage {name} gelöscht werden soll?',
  //Toasts
  delete_site_success: 'Anlage gelöscht',
  delete_site_error: 'Anlage könnte nicht gelöscht werden: {error}',
  update_thing_success: 'Gerätedetails erfolgreich geändert',
  update_thing_error: 'Gerätedetails konnten nicht geändert werden: {error}',
  update_site_success: 'Anlagendaten erfolgreich geändert',
  update_site_error: 'Anlagendaten konnten nicht geändert werden: {error}',
  visibility_consistency_warning:
    'Es kann einige Minuten dauern, bis Änderungen an der Sichtbarkeit überall angewendet sind.',
  altitude_invalid_feedback: 'Nur ganze Zahlen größer 0 erlaubt',
  site_gateways: 'Gateways/Router',
  management: {
    delete_site: {
      dialog_title: 'Anlage "{siteName}" löschen?',
      warning: 'Das Löschen einer Anlage kann nicht rückgängig gemacht werden.',
      eventual_consistency_warning:
        'Es kann einige Minuten dauern, bis die Anlage vollständig gelöscht ist.',
      dialog_body:
        'Geben Sie den Namen der Anlage ({siteName}) ein, um die Löschung zu bestätigen.',
      placeholder: '"{siteName}" eingeben zum löschen',
      success: 'Anlage wird gelöscht',
      error: 'Anlage konnte nicht gelöscht werden: {error}',
      in_progress: 'Anlage wird gelöscht...',
    },
  },
}
