import type { Thing } from '@/api/klempner/models/SiteListResponse'
import type {
  DeviceDescriptor,
  ThingDescriptor,
} from '@/components/site/scada/editor-components/KnownDevicesAndSockets'
import {
  DeviceDescriptorByCategory,
  DeviceDescriptorByDefinition,
  GenericScadaComponents,
} from '@/components/site/scada/editor-components/KnownDevicesAndSockets'
import type ThingDisplayMixin from '@/modules/ditto/ThingDisplayMixin'

export default class DeviceDescriptorUtil {
  /**
   * Searches for fitting device definitions and returns the according deviceDescriptor. If no supported device
   * definition was found no device descriptor is added (e.g. for Gateways).
   * NOTE: the definition that is passed here is the actual definition and not the found definition
   *       (when the definition is used again to retrieve descriptors, the fitting definition needs to be retrieved again)
   *
   * @param siteThings that shall be displayed with SVGs
   * @param thingDisplayUtil ThingDisplayMixin instance used to find & translate display names for each thing
   * @return thingDescriptors of devices for which a definition in {@link DeviceDescriptorByDefinition} could be found
   */
  public static retrieveThingDescriptorsOfKnownDevices(
    siteThings: Thing[],
    thingDisplayUtil: ThingDisplayMixin,
  ): ThingDescriptor[] {
    const thingDescriptors: ThingDescriptor[] = []

    for (const thing of siteThings) {
      let deviceDescriptor: DeviceDescriptor | null = null

      deviceDescriptor = this.getDeviceDescriptorByDefinition(thing.definition)
      const definitionFound = deviceDescriptor != null
      if (!definitionFound) {
        deviceDescriptor = this.getDeviceDescriptorByCategory(thing.category)
      }

      if (deviceDescriptor) {
        const thingDescriptor = {
          thingId: thing.id,
          definition: definitionFound ? thing.definition : thing.category,
          displayName: thingDisplayUtil.getDisplayNameWithTypeForThing(thing),
          deviceDescriptor: deviceDescriptor,
          editorToolboxComponent: deviceDescriptor.toolboxComponent ?? 'ScadaDummyNode',
          editorReteComponent: deviceDescriptor.reteComponent ?? 'ScadaReteNode',
        }
        thingDescriptors.push(thingDescriptor)
      }
    }

    return thingDescriptors
  }

  /**
   * Finds the first matching value from the  {@link DeviceDescriptorByDefinition} map.
   * If no matching value is found, null is returned.
   * @param definition Definition (i.e. Vorto Model) of the thing
   */
  public static getDeviceDescriptorByDefinition(definition: string): DeviceDescriptor | null {
    // TODO: the old approach would always check for the definition without the version, but this feature was never used -
    //  so until it is needed, it is not implemented
    const definitionWithoutVersion = this.getDefinitionWithoutVersion(definition)
    const devDescriptor = DeviceDescriptorByDefinition[definitionWithoutVersion]
    return devDescriptor ?? null
  }

  /**
   * Finds the first matching value from the  {@link DeviceDescriptorByCategory} map.
   * @param category Category to search for
   */
  public static getDeviceDescriptorByCategory(category: string): DeviceDescriptor | null {
    const devDescriptor = DeviceDescriptorByCategory[category]
    return devDescriptor ?? null
  }

  /**
   * Finds a device descriptor by its definition or category.
   * @param categoryOrDefinition Definition (i.e. Vorto Model) or category of the thing
   */
  public static findDeviceDescriptor(categoryOrDefinition: string): DeviceDescriptor | null {
    return (
      this.getDeviceDescriptorByDefinition(categoryOrDefinition) ??
      this.getDeviceDescriptorByCategory(categoryOrDefinition) ??
      GenericScadaComponents[categoryOrDefinition] ??
      null
    )
  }

  /**
   * This method assumes the following structure for the definition: <gatewaySupplier>:<deviceCategory>:<version>
   *   e.g. 'othermo:heatExchanger:0.1.0'
   *
   * @param definition of the thing
   * @return definition without the version (or the initial definition if it could not be parsed)
   */
  public static getDefinitionWithoutVersion(definition: string): string {
    const definitionParts = definition.split(':')
    if (definitionParts.length >= 2) {
      return definitionParts[0] + ':' + definitionParts[1]
    } else {
      return definition
    }
  }
}
