export default {
  scada: {
    tabUpdated: 'scada-tab-updated',
    svgThingsUpdated: 'svg-things-updated',
    goToSvg: 'gotoSvg',
    closeModal: 'close-command-modal',
    upload: {
      svgUploaded: 'svg-uploaded',
      svgRemoved: 'svg-removed',
    },
    templates: {
      submit: 'submit-template',
      mounted: 'template-mounted',
      submitStarted: 'item-submit-started',
      submitEnd: 'item-submit-end',
      submitFailed: 'item-submit-failed',
      itemChanged: 'template-item-changed',
    },
  },
  charts: {
    /**
     *  A chart was zoomed, data contains new timespan and interval
     */
    zoomOrShiftChanged: 'zoom-or-shift-changed',

    /**
     * Zoom or timespan/interval changed
     */
    stateChanged: 'state-changed',

    /**
     * The valid state of the properties dialog was reevaluated
     * Params: invalid: bool -> Is true when the dialog is not valid
     */
    validityChanged: 'validity-changed',

    /**
     * Refresh all series in charts
     */
    refresh: 'refresh',

    /** Fired when a chart is modified */
    chartEdited: 'chart-edited',

    chartUpdated: 'chart-updated',

    /** Title of a chart tab changed */
    pageRenamed: 'title-changed',
  },

  analytics: {
    definitionChanged: 'analytics-definition-changed',
  },

  alerts: {
    filter: 'filter-alerts',
    muted: 'alert-mute-toggled',
    delete: 'delete-alert',
    edit: 'edit-alert',
    editRecipients: 'edit-recipients',
    deleteGroup: 'delete-group',
    changed: 'alerts-changed',
    changeActivation: 'activation-changed',
  },
  users: {
    deleteUser: 'delete-user',
    editUser: 'edit-user',
    resetPassword: 'reset-password',
  },
}
