type Bounds = { min: number; max: number }

export const DEFAULT_MAX = 100

export const DEFAULT_MIN = 0

export interface IScale {
  readonly min: number

  readonly max: number

  calculateOptimalAxisBounds(min: number, max: number): { min: number; max: number }
}

export class StaticScale implements IScale {
  constructor(
    readonly min: number,
    readonly max: number,
  ) {}

  calculateOptimalAxisBounds(min: number, max: number) {
    return { min: this.min, max: this.max }
  }
}

export class AutoScale implements IScale {
  private readonly FALLBACK_FACTOR = 0.1

  private _min = Number.NEGATIVE_INFINITY

  private _max = Number.POSITIVE_INFINITY

  get min() {
    return this._min === Number.NEGATIVE_INFINITY ? DEFAULT_MIN : this._min
  }

  get max() {
    return this._max === Number.POSITIVE_INFINITY ? DEFAULT_MAX : this._max
  }

  static getSeriesBounds(s: [string, any][]): Bounds {
    const valuesWithoutNull: number[] = []
    s.forEach((value) => {
      if (value[1] !== null && value[1] !== undefined) {
        valuesWithoutNull.push(value[1] as number)
      }
    })
    const min = Math.min.apply(Math, valuesWithoutNull)
    const max = Math.max.apply(Math, valuesWithoutNull)
    return { min: min, max: max }
  }

  public static getAxisBounds(s: [string, any][][]): Bounds {
    const bounds = { min: Number.POSITIVE_INFINITY, max: Number.NEGATIVE_INFINITY }
    for (let i = 0; i < s.length; i++) {
      const localBounds = AutoScale.getSeriesBounds(s[i])

      if (localBounds.min == Infinity || localBounds.max == Infinity) {
        continue
      }

      bounds.min = Math.min(bounds.min, localBounds.min)
      bounds.max = Math.max(bounds.max, localBounds.max)
    }
    return bounds
  }

  calculateOptimalAxisBounds(preciseMin: number, preciseMax: number) {
    if (preciseMin == preciseMax) {
      preciseMax *= 1 + this.FALLBACK_FACTOR
      preciseMin *= 1 - this.FALLBACK_FACTOR
    }

    const localRange = preciseMin - preciseMax
    const magnitude = Math.round(Math.log10(Math.abs(localRange))) - 1
    const factor = 10 ** magnitude

    this._min = (Math.floor((preciseMin + Number.EPSILON) / factor) - Number.EPSILON) * factor
    this._max = (Math.ceil((preciseMax + Number.EPSILON) / factor) - Number.EPSILON) * factor

    if (this._min > this._max) {
      const temp = this._max
      this._max = this._min
      this._min = temp
    }

    return { min: this._min, max: this._max }
  }
}
