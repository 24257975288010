<template>
  <!--  This is a test template for development-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting"></SubmitOverlay>

      <Title>Kaskadenmanager</Title>
      <Info>Stellen Sie hier die Parameter des Weishaupt Kaskadenmanagers ein.</Info>

      <Group title="Weiche">
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.separatorTemperatureMin`"
          displayName="Weiche Min."
          propertyType="configuration"
          propertyName="separatorTemperatureMin"
          toolTip="Weichentemperatur Minmum"
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.separatorTemperatureMax`"
          displayName="Weiche Max."
          propertyType="configuration"
          propertyName="separatorTemperatureMax"
          toolTip="Weichentemperatur Maximum"
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.separatorTemperatureMax`"
          displayName="Weiche Überhöhung"
          propertyType="configuration"
          propertyName="separatorSuperelevation"
          toolTip="Weiche Überhöhung"
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.settlementDifference`"
          displayName="Abregeldifferenz"
          propertyType="configuration"
          propertyName="settlementDifference"
          toolTip="Abregeldifferenz"
        />
      </Group>
      <Group title="Modulation">
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.modulationMin`"
          displayName="Modulation Min"
          propertyType="configuration"
          propertyName="modulationMin"
          toolTip="Modulation Min"
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.modulationMax`"
          displayName="Modulation Max"
          propertyType="configuration"
          propertyName="modulationMax"
          toolTip="Modulation Max"
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.blockingTime`"
          displayName="Sperrzeit"
          propertyType="configuration"
          propertyName="blockingTime"
          toolTip="Sperrzeit"
        />
      </Group>
      <Group title="Regelverhalten">
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.controllerPpart`"
          displayName="1/P-Regler"
          propertyType="configuration"
          propertyName="controllerPpart"
          toolTip="Proportionalanteil PID Regler"
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.controllerIpart`"
          displayName="I-Regler"
          propertyType="configuration"
          propertyName="controllerIpart"
          toolTip="Integrationsanteil PID Regler"
        />
      </Group>
      <Group title="Kesselfolge">
        <enum-item
          :featureName="featureId"
          :map="[
            ['0', 'Folge 1'],
            ['1', 'Folge 2'],
            ['2', 'zeitlich'],
          ]"
          :value="thing.features[featureId].properties.configuration.followMode"
          displayName="Folgemodus"
          propertyType="configuration"
          propertyName="followMode"
          toolTip="Auswahl des Modus der Kesselfolge"
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.changingTime`"
          displayName="Wechselzeit"
          propertyType="configuration"
          propertyName="changingTime"
          toolTip="Wechselzeit der Kessel"
        />
        <enum-item
          :featureName="featureId"
          :map="[
            ['0', 'Kessel A'],
            ['1', 'Kessel B'],
            ['2', 'Kessel C'],
          ]"
          :value="thing.features[featureId].properties.configuration.sequence1_1"
          displayName="Folge 1 (Prio 1)"
          propertyType="configuration"
          propertyName="sequence1_1"
          toolTip="Priorität 1 Folge 1"
        />
        <enum-item
          :featureName="featureId"
          :map="[
            ['0', 'Kessel A'],
            ['1', 'Kessel B'],
            ['2', 'Kessel C'],
          ]"
          :value="thing.features[featureId].properties.configuration.sequence1_2"
          displayName="Folge 1 (Prio 2)"
          propertyType="configuration"
          propertyName="sequence1_2"
          toolTip="Priorität 2 Folge 1"
        />
        <enum-item
          :featureName="featureId"
          :map="[
            ['0', 'Kessel A'],
            ['1', 'Kessel B'],
            ['2', 'Kessel C'],
          ]"
          :value="thing.features[featureId].properties.configuration.sequence1_3"
          displayName="Folge 1 (Prio 3)"
          propertyType="configuration"
          propertyName="sequence1_3"
          toolTip="Priorität 3 Folge 1"
        />
        <enum-item
          :featureName="featureId"
          :map="[
            ['0', 'Kessel A'],
            ['1', 'Kessel B'],
            ['2', 'Kessel C'],
          ]"
          :value="thing.features[featureId].properties.configuration.sequence2_1"
          displayName="Folge 2 (Prio 1)"
          propertyType="configuration"
          propertyName="sequence2_1"
          toolTip="Priorität 1 Folge 2"
        />
        <enum-item
          :featureName="featureId"
          :map="[
            ['0', 'Kessel A'],
            ['1', 'Kessel B'],
            ['2', 'Kessel C'],
          ]"
          :value="thing.features[featureId].properties.configuration.sequence2_2"
          displayName="Folge 2 (Prio 2)"
          propertyType="configuration"
          propertyName="sequence2_2"
          toolTip="Priorität 3 Folge 2"
        />
        <enum-item
          :featureName="featureId"
          :map="[
            ['0', 'Kessel A'],
            ['1', 'Kessel B'],
            ['2', 'Kessel C'],
          ]"
          :value="thing.features[featureId].properties.configuration.sequence2_3"
          displayName="Folge 2 (Prio 3)"
          propertyType="configuration"
          propertyName="sequence2_3"
          toolTip="Priorität 3 Folge 3"
        />
      </Group>
    </template>
  </div>
</template>

<script lang="ts">
  /*This code does not has to be touched when developing a new template except the ClassName*/
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'

  /*REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      TextItem,
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  /*REPLACE 'TestId' WITH A FITTING COMPONENT NAME*/
  export default class CascadeManagerWeishaupt extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style scoped lang="scss">
  /*The edit component can be styled here*/
</style>
