export const topologyTranslationsDe = {
  topology: 'Anlagen-Topologie',
  cogeneration: 'Blockheizkraftwerk',
  electricity_source: 'Stromnetz',
  electricity_sink: 'Stromnetz',
  electricity_self_consumption: 'Eigenverbrauch',
  boiler: 'Kessel',
  heat_sink: 'Wärmenetz',
  gas_source: 'Gasnetz',
  tank: 'Speicher',
  pump: 'Pumpe',
  heat_meter: 'Wärmezähler',
  gas_meter: 'Gaszähler',
  electricity_production_meter: 'Stromzähler Einspeisung',
  electricity_consumption_meter: 'Stromzähler Bezug',
  grid_connections: 'Netzanschlüsse',
  site_components: 'Anlagenkomponenten',
  no_nodes_in_category: 'Keine Komponenten in dieser Kategorie',
  reset_view: 'Ansicht zurücksetzen',
  show_all: 'Alles zeigen',
  //Errors
  error_loading_nodes: 'Verfügbare Geräte konnten nicht geladen werden: {error}',
  error_loading_topology: 'Topologie konnte nicht geladen werden: {error}',
  error_saving_topology: 'Topologie konnte nicht gespeichert werden: {error}',
  success_saving_topology: 'Topologie gespeichert',
  clear_editor: 'Alles löschen',
  confirm_delete_all:
    'Alle Elemente werden aus der Topologie entfernt. Dies kann nicht rückgängig gemacht werden.',
  discard_topology_changes:
    'Alle nicht gespeicherten Änderungen an der Anlagen-Topologie werden verworfen.',
  leave_topology_unsaved: 'Ohne speichern verlassen',
  available_devices: 'Vorhandene Geräte',
  general_symbols: 'Allgemeine Symbole',
  air_heat_pump: 'Wärmepumpe (Luft)',
  water_heat_pump: 'Wärmepumpe (Wasser)',
  electric_energy_storage: 'Stromspeicher',
  thermal_energy_storage: 'Wärmespeicher',
  solar_thermal: 'Solarthermie',
  solar_pv: 'Photovoltaik',
  solar_inverter: 'Wechselrichter',
}
