<template>
  <div class="d-flex align-items-center justify-content-between spacer-bottom flex-wrap">
    <span class="mr-2">
      {{ displayName }}
      <span v-if="changed">*</span>
    </span>
    <b-checkbox v-model="currentValue" class="mb-1" size="lg" switch @change="onChange" />
    <span v-if="changedInDitto" class="w-100 flex-shrink-0 text-warning">
      {{ $t('value_changed_to', { value: dittoValue }) }}
    </span>
    <SubmitState
      class="w-100"
      :state="submitState"
      :errorMessage="submitError"
      @timeout-end="submitState = 'idle'"
    ></SubmitState>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseItem from '@/components/site/scada/command_response/toolbox/base/BaseItem.vue'
  import ToolTip from '@/components/site/scada/command_response/toolbox/base/ToolTip.vue'
  import SubmitState from '@/components/site/scada/command_response/toolbox/base/SubmitState.vue'
  import events from '@/events'

  /**
   * Component for configuring a boolean value of a ditto thing.
   */
  @Component({
    name: 'ToggleItem',
    components: {
      ToolTip,
      SubmitState,
    },
  })
  export default class ToggleItem extends mixins(BaseItem) {
    @Prop()
    private value!: boolean

    private currentValue: boolean = false

    private dittoValue: boolean = false

    private changedInDitto = false

    get messageBody() {
      if (this.msgParamName != null) {
        return { [this.msgParamName]: this.currentValue }
      } else {
        return JSON.stringify(this.currentValue)
      }
    }

    get commandBody() {
      return this.currentValue
    }

    created() {
      // Initially set value of toggle item to actual value of the thing
      this.currentValue = this.value
    }

    @Watch('value')
    onValueChanged(value: boolean) {
      if (value != this.currentValue) {
        this.dittoValue = value
        this.changedInDitto = true
      }
    }

    onChange() {
      this.changed = true
      this.submitEventBus.$emit(events.scada.templates.itemChanged)
    }

    protected onSubmitReceived() {
      if (this.changed) {
        this.submit()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';
  @import 'item';
</style>
