export const siteManagementTranslationsEn = {
  site_management: 'Site Management',
  site_management_permission_denied: "You don't have the required permissions to madnage sites.",
  site_visible: 'Visible',
  site_invisible: 'Hidden',
  site_status: 'Status',
  no_gateways: 'This site does not have any gateways',
  no_ip_configured: 'No IP configured',
  object: 'Object:::Objects',
  error_loading_gateways: 'Error fetching gateways: {error}',
  error_getting_count: 'Object count could for {siteName} could not be fetched: {error}',
  error_updating_visibility: 'Could not update site visibility: {error}',
  site_update_success: 'Site updated successfully',
  error_loading_site: 'Could not fetch site: {error}',
  address: 'Address',
  // Form translations
  edit_site_details: 'Edit Site Details',
  edit_thing_details: 'Edit Thing Details',
  site_description: 'Description',
  site_title: 'Site Name',
  site_customer_id: 'Customer ID',
  site_address: 'Site Address',
  street: 'Street and Number',
  postalcode: 'Postal Code',
  city: 'City',
  country: 'Country',
  site_location: 'Location',
  latitude: 'Latitude',
  longitude: 'Longitude',
  altitude: 'Altitude',
  // Form feedback
  title_invalid_feedback: 'Name is required',
  lat_invalid_feedback: 'Only numbers between -90 and 90 allowed',
  long_invalid_feedback: 'Only numbers between -180 and 180 allowed',
  altitude_invalid_feedback: 'Only whole numbers greater than 0 allowed',
  postal_code_invalid_feedback: 'Only letters, numbers and a maximum of 10 characters allowed',
  // Delete
  delete_site: 'Delete Site',
  delete_site_warning: 'Are you sure you want to delete the site {name}?',
  //Toasts
  delete_site_success: 'Site deleted',
  delete_site_error: "Couldn't delete site: {error}",
  update_thing_success: 'Thing details successfully updated',
  update_thing_error: 'Thing details could not be updated: {error}',
  update_site_success: 'Site details successfully updated',
  update_site_error: 'Site details could not be updated: {error}',
  visibility_consistency_warning:
    'Changes to site visibility might take a few minutes to be applied everywhere',
  site_gateways: 'Gateways/Router',
  management: {
    delete_site: {
      dialog_title: 'Delete "{siteName}"?',
      warning: 'Deleting a site cannot be undone.',
      eventual_consistency_warning:
        'Deleting a site might take a few minutes to be applied everywhere.',
      dialog_body: 'Please enter the name of the site ({siteName}) to confirm deletion.',
      placeholder: 'Enter "{siteName}" to delete',
      success: 'Site deleted successfully',
      error: 'Site could not be deleted: {error}',
      in_progress: 'Deleting site...',
    },
  },
}
