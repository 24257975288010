import type IUnitHandler from '@/modules/units/handlers/IUnitHandler'
import { AutoScale } from '@/modules/charts/AutoScale'

export default class DefaultHandler implements IUnitHandler {
  format(
    type: string | null,
    unit: string | null,
    value: any,
    locale: string,
    t?: (name: string) => string | undefined,
  ): string {
    if (value !== null && typeof value === 'object') {
      return this.formatJson(value, 0, '', t)
    } else if (value !== null && (typeof value === 'number' || type === 'float')) {
      return Number(value).toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    return value
  }

  formatJson(
    obj: object,
    indent: number,
    prefix = '',
    getTranslatedProperty: undefined | ((name: string) => string | undefined),
  ) {
    let result = prefix
    for (const [key, value] of Object.entries(obj)) {
      const translatedKey = getTranslatedProperty ? getTranslatedProperty(key) : key
      result += '\t'.repeat(indent) + translatedKey + ': '
      if (value === null) {
        result += 'null\n'
      } else if (Array.isArray(value)) {
        if (value.length == 0) {
          result += '[]'
        } else {
          result +=
            '[\n' +
            value.map((o) => this.formatJson(o, indent + 1, '•', getTranslatedProperty)).join('') +
            ']\n'
        }
      } else if (typeof value == 'object') {
        result += '\n'
        result += this.formatJson(value, indent + 1, '', getTranslatedProperty)
      } else {
        result += String(value) + '\n'
      }
    }
    return result
  }

  isSupported(type: string, unit: string): boolean {
    return true
  }

  formatUnit(type: string, unit: string, locale: string): string {
    return ''
  }

  scaleValue(value: number, scalingFactor: number): number {
    return value
  }

  getScaling(
    series: [string, number | null][][],
    unit: string,
  ): { best: any; scalingFactor: number } {
    const { max, min } = AutoScale.getAxisBounds(series)
    return { best: { unit: unit, symbol: '', min, max }, scalingFactor: 1 }
  }

  isDifferentiable(): boolean {
    return true
  }
}
