<template>
  <div id="labeled-node">
    <div :style="svgWidth" class="text-label">
      <b-form-input
        v-if="editMode"
        v-model="node.data.label"
        :placeholder="$t('scada.rete_tab.label')"
        class="text-center scada-editor-font-size"
        size="sm"
        type="text"
      ></b-form-input>
      <span v-else>{{ node.data.label }}</span>
    </div>
    <ScadaNode :bindSocket="bindSocket" :editor="editor" :node="node"></ScadaNode>
  </div>
</template>

<script lang="ts">
  import { Component } from 'vue-property-decorator'
  //@ts-ignore
  import VueRender from 'rete-vue-render-plugin'
  import { mixins } from 'vue-class-component'
  import type { Node } from 'rete'
  import ScadaNode from '@/components/site/scada/editor-components/rete_components/ScadaNode.vue'
  import type { DeviceDescriptor } from '@/components/site/scada/editor-components/KnownDevicesAndSockets'
  import DeviceDescriptorUtil from '@/components/site/scada/editor-components/DeviceDescriptorUtil'
  import { vxm } from '@/store/store'

  @Component({
    components: { ScadaNode },
  })
  export default class ScadaNodeLabeled extends mixins(VueRender.mixin) {
    declare node: Node

    declare bindSocket: any

    declare editor: any

    get svgWidth() {
      return 'min-width:' + this.nodeDescriptor.width + ';'
    }

    get editMode() {
      return vxm.scadaEditor.editMode
    }

    get nodeDescriptor(): DeviceDescriptor {
      return (
        DeviceDescriptorUtil.findDeviceDescriptor(this.node.data.deviceDefinition as string) ?? {
          svg: '',
          inputs: [],
          outputs: [],
        }
      )
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  .text-label {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    min-width: 200px;
  }

  .scada-editor-font-size {
    font-size: $font-size-scada-editor !important;
  }
</style>
