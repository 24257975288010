import Logger from '@/logger'

export class BoolColorMap {
  private readonly trueColor: string

  private readonly falseColor: string

  constructor(stringRepresentation: string) {
    let colors: [{ true: string }, { false: string }]
    try {
      colors = JSON.parse(stringRepresentation)
      this.trueColor = colors[0].true
      this.falseColor = colors[1].false
    } catch (e) {
      Logger.error('Unable to parse ' + stringRepresentation + ' as valid Color Map!')
      throw new Error('Unable to parse')
    }
  }

  getColorForValue(value: boolean): string {
    return value ? this.trueColor : this.falseColor
  }
}

export class NumberColorMap {
  /**
   * Creates a new map from the JSON string representation of the color map.
   * @param stringRepresentation
   * @return The correct color code for the value. If the value is below all min values, undefined is returned.
   */
  static fromString(stringRepresentation: string) {
    try {
      const breakpoints = JSON.parse(stringRepresentation)
      return new NumberColorMap(breakpoints)
    } catch (e) {
      Logger.error('Could not parse json string as color map (type number)', stringRepresentation)
      throw new Error('unable to parse')
    }
  }

  constructor(private breakpoints: Breakpoint[] = []) {}

  public getColorForValue(value: number): string | undefined {
    let color: string = ''
    let correspondingColorFound = false
    let currentMinValue = Number.MIN_VALUE

    for (const breakpoint of this.breakpoints) {
      if (breakpoint.min <= value && breakpoint.min > currentMinValue) {
        correspondingColorFound = true
        color = breakpoint.color
        currentMinValue = breakpoint.min
      }
    }

    return color ? color : undefined
  }
}

class Breakpoint {
  public min: number

  public color: string

  constructor(min: number, color: string) {
    this.min = min
    this.color = color
  }
}
