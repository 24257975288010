import { scadaTranslationsDe } from '@/languages/scada/scada.de'
import { chartTranslationsDe } from '@/languages/trends/trends.de'
import { alertTranslationsDe } from '@/languages/alerts/alerts.de'
import { analyticsTranslationsDe } from '@/languages/analytics/analytics.de'
import { userManagementTranslationsDe } from '@/languages/user-management/user-management.de'
import { siteManagementTranslationsDe } from '@/languages/site_management/site_management.de'
import { meterTranslationsDe } from '@/languages/meters/meters.de'
import { topologyTranslationsDe } from '@/languages/topology/topology.de'
import { TwinTranslationsDE } from '@/languages/twins/twins.de'
import { HeatNetworkTranslationsDe } from '@/languages/heat_networks/heat_networks.de'
import { notificationCenterDe } from '@/languages/notification_center/notification_center.de'
import { RemoteAccessTranslationsDe } from '@/languages/remote_access/remote_access.de'
import { recommendationsDe } from '@/languages/recommendations/recommendations.de'

export const translationsDe = {
  ...chartTranslationsDe,
  ...scadaTranslationsDe,
  ...alertTranslationsDe,
  ...analyticsTranslationsDe,
  ...userManagementTranslationsDe,
  ...siteManagementTranslationsDe,
  ...meterTranslationsDe,
  ...topologyTranslationsDe,
  ...TwinTranslationsDE,
  ...HeatNetworkTranslationsDe,
  ...notificationCenterDe,
  recommendations: recommendationsDe,
  lpb_time_program_editor: {
    on_label: 'Ein',
    off_label: 'Aus',
    active_label: 'Aktiv',
  },
  remote_access: RemoteAccessTranslationsDe,
  hello_world: 'Hallo Welt!',
  manage_account: 'Account verwalten',
  request_support: 'Supportanfrage',
  sign_out: 'Abmelden',
  imprint: 'Impressum',
  privacy: 'Datenschutz',
  base_page_title: 'othermo Portal',
  page_title_home: 'Startseite',
  page_title_about: 'Über',
  site: 'Anlage',
  sites_per_page: 'Anlagen pro Seite',
  schema: 'Anlagen-Schema',
  diagram: 'Diagramm',
  page_not_found: '404 - Seite nicht gefunden',
  sth_went_wrong: 'Etwas hat nicht geklappt.',
  warning: 'Warnung:::Warnungen',
  error: 'Fehler:::Fehler',
  goto_site: 'Details',
  all_sites: 'Alle Anlagen',
  site_details: 'Details zur Anlage',
  location: 'Ort',
  connection_state: 'Verbindungsstatus',
  status_unknown: 'Unbekannt',
  status_connected: 'Verbunden',
  status_disconnected: 'Nicht verbunden',
  status_timeout: 'Zeitüberschreitung',
  status_denied: 'Zugriff verweigert',
  on: 'Ein',
  off: 'Aus',
  enter_chars: 'Geben Sie bitte mindestens {length} Zeichen ein.',
  enter_max_chars: 'Bitte geben Sie höchstens {length} Zeichen ein.',
  last_update: 'Ansicht zuletzt aktualisiert vor {seconds}s.',
  last_update_never: 'Letztes Update: Nie',
  overview: 'Übersicht',
  diagrams: 'Diagramme',
  delete: 'Löschen',
  cancel: 'Abbrechen',
  edit: 'Bearbeiten',
  overview_state: 'Aktueller Status',
  connection_status_unknown_description:
    'Der aktuelle Verbindungsstatus konnte nicht abgerufen werden.',
  connection_status_denied_description: 'Der Zugriff wurde verweigert.',
  connection_status_timeout_description: 'Die Verbindung wurde unterbrochen.',
  connection_status_disconnected_description:
    'Es kann momentan keine Verbindung zur Anlage hergestellt werden.',
  connection_status_connected_description: 'Die Anlage ist verbunden.',
  no_summary: 'Keine Zusammenfassung verfügbar',
  numeric_out_of_range: 'Die Eingabe muss zwischen {min} und {max} liegen.',
  dropdown_please_select: '-- Bitte wählen Sie eine Option --',
  edit_item_value_changed:
    'Der Wert der Eigenschaft {propertyName} hat sich verändert. ' +
    'Ihre Eingabe wurde überschrieben.',
  summary: 'Aktuelle Werte',
  summary_name: 'Name',
  summary_value: 'Wert',
  summary_setpoint: 'Sollwert',
  summary_add_item: 'Zeile hinzufügen',
  summary_empty: 'Zusammenfassung enthält keine Einträge',
  summary_save_success: 'Zusammenfassung gespeichert',
  summary_save_error: 'Zusammenfassung konnte nicht gespeichert werden: {error}',
  summary_edit: 'Zusammenfassung bearbeiten',
  summary_loading_error: 'Datenpunkt konnte nicht geladen werden',
  action_error_not_implemented: 'Fehler: Die Aktion ist nicht implementiert.',
  action_error_unknown: 'Unbekannter Fehler: {error}',
  action_error_server_error: 'Die Aktion war nicht erfolgreich: {error}',
  action_error_not_found: 'Fehler: Die Komponente wurde nicht gefunden.',
  action_error_denied: 'Fehler: Der Nutzer ist nicht berechtigt für diese Aktion.',
  action_error_bad_req: 'Fehler: Inkorrekte Parameter: {error}',
  action_error_timeout: 'Fehler: Die Aktion konnte nicht zugestellt werden. [Zeitüberschreitung]',
  action_success: 'Aktion erfolgreich',
  close: 'Schließen',
  unsaved_changes: 'Sie haben ungesicherte Änderungen',
  apply: 'Anwenden',
  'thingId: %s': 'Id des Things: %s',
  'othermo:DO:0.1.0': 'Deutsche Wert-Übersetzung',
  leave_unsaved: 'Wollen Sie die nicht gespeicherten Änderungen an dieser Ansicht verwerfen?',
  create: 'Erstellen',
  map_view: 'Kartenansicht',
  grid_view: 'Rasteransicht',
  alarm_view: 'Alarmansicht',
  reports_view: 'Auswertungen',
  site_marker_ok: 'Verbunden, keine Fehler/Warnungen',
  site_marker_warning: 'Verbunden, mindestens eine Warnung',
  site_marker_error: 'Verbunden, mindestens ein Fehler',
  site_marker_critical: 'Verbunden, mindestens ein kritischer Fehler',
  site_marker_unknown: 'Status unbekannt',
  site_marker_no_data: 'Keine Daten',
  undefined_locations:
    '{count} Anlagenstandort nicht angezeigt aufgrund fehlender Ortsangabe:::{count} Anlagenstandorte nicht angezeigt aufgrund fehlender Ortsangabe',
  search: 'Suchen',
  loading: 'lädt...',
  save: 'Speichern',
  letter_north: 'N',
  letter_south: 'S',
  letter_east: 'O',
  letter_west: 'W',
  notice_staging_env:
    'Dies ist eine Staging/Development-Umgebung, Änderungen werden nicht zwingend für produktive Anlagen übernommen!',
  attention: 'Achtung',
  ID: 'ID',
  id: 'ID',
  Id: 'ID',
  date: 'Datum',
  time: 'Uhrzeit',
  expand: 'Ausklappen',
  collapse: 'Einklappen',

  app: {
    new_version: {
      toast_title: 'Neue Version veröffentlicht! 🎉',
      toast_subtitle: 'Wir haben dem Portal neue Funktionen hinzugefügt',
      go_to_changelog: 'Änderungen in älteren Versionen anzeigen',
    },
    settings: 'Einstellungen',
  },

  map: {
    title: 'Karte',
  },
  grid: {
    title: 'Rasteransicht',
  },

  dashboard: {
    title: 'Dashboard',
    show_all_sites: 'Alle anzeigen',
    expand_notifications: 'Ausklappen',
    collapse_notifications: 'Einklappen',
    map: {
      toggle_legend: 'Legende ein-/ausblenden',
      show_all_sites: 'Alle Anlagen zeigen',
      fullscreen: 'Vollbild',
      sites_without_location: '{count} Anlagen ohne genauen Standort',
    },
    stats: {
      sites_online: 'Anlagen online',
      sites_offline: 'Anlagen offline',
      with_warning: 'mit Warnungen',
      with_error: 'mit Fehler',
      with_critical: 'mit krit. Fehler',
      sites_total: 'Anlagen',
    },
    filter: {
      by_severity: 'Alarm-Dringlichkeit',
      by_maintenance_mode: 'Wartungsmodus',
      by_connection_status: 'Verbindungsstatus',
    },
    alarm: {
      filter_by_site: 'Nach Anlage filtern',
      maintenance_filter: {
        maintenance_mode: 'Anlagen im Wartungsmodus',
        not_maintenance_mode: 'Anlagen nicht im Wartungsmodus',
      },
      no_active_alarms_to_display:
        'Keine Anlage mit aktiven Alarmen der ausgewählten Dringlichkeitsstufen vorhanden.',
    },
    error_loading_site_alarms: 'Alarme einer Anlage konnten nicht geladen werden',
    error_loading_sites: 'Anlagenliste konnte nicht geladen werden',
    no_site_name: 'Anlage ohne Name',
    error_loading_site_muteStatus:
      'Für eine Anlage konnte Status der Stummschaltung nicht ermittelt werden',
    site_list_update_error: 'Anlagenliste konnte nicht geladen werden: {error}',
    nothing_here: 'Hier scheint es (noch) nichts zu geben',
    nothing_here_helpline:
      'Möglicherweise haben Sie nicht die nötigen Berechtigungen um Anlagen zu sehen oder alle Anlagen wurden ausgeblendet. ',
    nothing_here_filter: 'Hier scheint es nichts zu geben',
    no_site_fulfills_filter_options:
      'Keine Anlage erfüllt die ausgewählten Filter-Optionen. Setzen Sie die Filter-Optionen zurück um Anlagen anzuzeigen.',
    sort: {
      sortBy: 'Sortieren nach: ',
      createdAscending: 'Erstellungsdatum (aufsteigend)',
      createdDescending: 'Erstellungsdatum (absteigend)',
      nameAscending: 'Name (aufsteigend)',
      nameDescending: 'Name (absteigend)',
      severity: 'Dringlichkeit',
    },
    search_for_site: 'Nach Anlage suchen',
  },
  menu: {
    oss_licenses: 'Open-source Lizenzen',
  },
  settings: {
    title: 'Einstellungen',
    general: {
      title: 'Allgemein',
      reload_translations_success: 'Übersetzung erfolgreich neu geladen',
      reload_translations_failed: 'Übersetzung konnte nicht neu geladen werden',
      reload_translations: 'Übersetzung aktualisieren',
      language: {
        title: 'Sprache',
        description: 'Wählen Sie die Sprache für das Portal',
      },
    },
    notifications: 'Benachrichtigungen',
  },
  type: 'Typ',
  show_more: 'Mehr',
  discard_changes_short: 'Änderungen verwerfen',
  reports: {
    title: 'Auswertungen',
    operation: 'Betrieb',
    energy: 'Energie',
    timespan: 'Zeitraum',
    startTime: 'Von',
    endTime: 'Bis',
    error_loading_report: 'Auswertung konnte nicht geladen werden ({error})',
    cannot_display: 'Auswertung kann nicht angezeigt werden',
    error_cause: 'Grund',
    not_available: 'Auswertung noch nicht verfügbar',
    time: {
      last_month: '1 Monat',
      last_year: '1 Jahr',
      current_year_to_date: 'Aktuelles Jahr',
      custom: 'Benutzerdefiniert',
    },
  },
  unsubscribe: {
    title: 'Benachrichtigungen abbestellen',
    from_site: 'Benachrichtigungen von Anlage "{siteName}" abbestellen',
    from_all: 'Alle Benachrichtigungen abbestellen',
    mute_site: 'Anlage stummschalten',
    mute_alert: 'Alarm stummschalten',
    notification_settings_saved: 'Benachrichtigungseinstellungen gespeichert',
    referenced_alert: 'Alarm',
    alert_muted: 'Alarm stummgeschaltet',
    alert_unmuted: 'Stummschaltung aufgehoben',
    error: {
      saving_notification_settings:
        'Benachrichtigungseinstellungen konnten nicht gespeichert werden',
      toggling_alert_mute: 'Alarm konnte nicht stummgeschaltet werden',
    },
  },
}
