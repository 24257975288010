var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataLoaded)?[(_vm.submitting)?_c('SubmitOverlay'):_vm._e(),_c('Title',[_vm._v("Kesselsteuerung")]),_c('Info',[_vm._v("Stellen Sie hier die Parameter des Wolf BM Moduls ein.")]),_c('enum-item',{attrs:{"featureName":_vm.featureId,"map":[
        ['2', 'Automatikbetrieb'],
        ['5', 'Sommerbetrieb'],
        ['3', 'Heizbetrieb'],
        ['4', 'Absenkbetrieb'],
        ['1', 'Standby-Betrieb'],
        ['0', 'Abgastest'] ],"value":_vm.thing.features[_vm.featureId].properties.configuration.operatingMode,"displayName":"Betriebsart","propertyType":"configuration","propertyName":"operatingMode","toolTip":"Betriebsart des Kessels"}}),_c('numeric-value-item',{attrs:{"featureName":_vm.featureId,"valuePath":("features." + _vm.featureId + ".properties.configuration.setpointCorrection"),"displayName":"Sollwertkorrektur","propertyType":"configuration","propertyName":"setpointCorrection","toolTip":"Sollwertkorrektur"}}),_c('numeric-value-item',{attrs:{"featureName":_vm.featureId,"valuePath":("features." + _vm.featureId + ".properties.configuration.dayTemperature"),"displayName":"Tagtemperatur","propertyType":"configuration","propertyName":"dayTemperature","toolTip":"Tagtemperatur"}}),_c('numeric-value-item',{attrs:{"featureName":_vm.featureId,"valuePath":("features." + _vm.featureId + ".properties.configuration.economyTemperature"),"displayName":"Spartemperatur","propertyType":"configuration","propertyName":"economyTemperature","toolTip":"Spartemperatur"}}),_c('enum-item',{attrs:{"featureName":_vm.featureId,"map":[
        ['0', 'Zeitprogramm 1'],
        ['1', 'Zeitprogramm 2'],
        ['2', 'Zeitprogramm 3'] ],"value":_vm.thing.features[_vm.featureId].properties.configuration.timeProgram,"displayName":"Zeitprogramm","propertyType":"configuration","propertyName":"timeProgram","toolTip":"Zeitprogramm"}}),_c('numeric-value-item',{attrs:{"featureName":_vm.featureId,"valuePath":("features" + _vm.featureId + ".properties.configuration.hotWaterTemperature"),"displayName":"Warmwassersolltemperatur","propertyType":"configuration","propertyName":"hotWaterTemperature","toolTip":"Eingestellte Warmwassersolltemperatur"}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }