export const chartTranslationsDe = {
  trends: 'Trends',
  new_chart: 'Neuer Graph',
  delete_chart_group: '"{name}" wirklich löschen? (Kann nicht rückgängig gemacht werden)',
  new_chart_page: 'Neuen Tab erstellen',
  new_page_name_descr: 'Name des neuen Tabs',
  no_permission: 'Keine Berechtigung',
  chart_no_read_permission: 'Sie haben keine Berechtigung zum Anzeigen der Graphen.',
  enter_name: 'Name eingeben',
  interval: 'Auflösung',
  timespan: 'Zeitraum',
  chart_name_label: 'Titel',
  series_name_label: 'Name',
  chart_no_write_permission: 'Sie haben keine Berechtigung zum Bearbeiten der Graphen.',
  display_style: 'Anzeige-Optionen',
  color: 'Farbe',
  line_type: 'Linien-typ',
  y_axis: 'y-Achse',
  add_series: 'Datenreihe hinzufügen',
  auto_update: 'Autom. Update',
  auto_update_text: 'Graphen automatisch alle {seconds}s aktualisieren',
  tooltip_timespan: 'Zeitrahmen für Graphen auf dieser Seite',
  tooltip_interval: 'Zeitliche Auflösung',
  tooltip_save: 'Änderungen an diesem Tab speichern',
  tooltip_reset: 'Änderungen an diesem Tab zurücksetzen',
  tooltip_delete: 'Diesen Tab löschen',
  tooltip_add: 'Elemente zu einem graphen ziehen, um neue Datenreihen hinzuzufügen',
  charts_no_data: 'Ziehen Sie eine Metrik in dieses Diagramm, um eine neue Serie hinzuzufügen.',
  add_line_chart: 'Neues Liniendiagramm',
  add_status_chart: 'Neues Statuschart',
  error_for_series: 'Fehler beim Laden von {name}',
  status_color_start: 'Farbe 1',
  status_color_end: 'Farbe 2',
  height: 'Höhe',
  height_per_series: 'Höhe (pro Datenreihe)',
  time_last_3h: 'Letzte 3h',
  time_last_24h: 'Letzte 24h',
  time_last_2d: 'Letzte 2 Tage',
  time_last_7d: 'Letzte 7 Tage',
  time_last_1m: 'Letzte 31 Tage',
  time_last_3m: 'Letzte 3 Monate',
  time_last_1y: 'Letzte 12 Monate',
  time_last_3y: 'Letzte 3 Jahre',
  time_1min: '1 Min',
  time_5min: '5 Min',
  time_15min: '15 Min',
  time_60min: '1 Stunde',
  time_1d: '1 Tag',
  time_1w: '1 Woche',
  time_2w: '2 Wochen',
  time_1m: '1 Monat',
  time_3m: '3 Monate',
  time_1y: '1 Jahr',
  time_auto: 'Auto',
  time_max: 'Max',
  refresh: 'Aktualisieren',
  no_data: 'Keine Daten',
  error_saving_chart: 'Fehler beim Speichern des Charts.',
  error_loading_chart: 'Fehler beim Laden des Charts.',
  error_loading_chart_data: 'Fehler beim Laden der Daten: {error}',
  error_no_data: 'Der ausgewählte Zeitraum enthält keine Daten für {name}',
  trends_warning: {
    multiple_units:
      'Einheitenkonflikt auf y-Achse {yAxis} ({units}). Die Datenpunkte werden möglicherweise nicht richtig dargestellt.',
  },
  success_saving_chart: 'Erfolgreich gespeichert',
  reset_zoom_or_shift: 'Zoom oder Verschiebung zurücksetzen',
  custom: 'Benutzerdefiniert',
  tree_site_empty: 'Keine Einträge vorhanden.',
  load_dashboard_failed: 'Tab konnte nicht geladen werden: ',
  temporary_dashboard_failed: 'Temporärer Graph konnte nicht erstellt werden: ',
  error_creating_trend: 'Es ist ein Fehler beim Erstellen des Tabs aufgetreten: ',
  error_deleting_trends_tab: 'Es ist ein Fehler beim Löschen des Tabs aufgetreten: ',
  transparent: 'auto',
  '#c00000': 'rot',
  '#ff9100': 'orange',
  '#ffea00': 'gelb',
  '#32cb00': 'grün',
  '#1de9b6': 'türkis',
  '#00b0ff': 'hellblau',
  '#3d5afe': 'blau',
  '#d500f9': 'violett',
  '#263238': 'schwarz',
  derivative: 'Änderungsrate',
  derivativeUnit: {
    s: 's',
    min: 'min',
    h: 'h',
    d: 'd',
    week: 'woche',
  },
  value: 'Wert',
  rename_tab: 'Tab umbenennen',
  rename_save_warning: 'Das Umbenennen speichert auch alle ungesicherten Änderungen in diesem Tab',
  rename_tooltip: 'Tab umbenennen',
  mode_auto: 'Automatisch',
  mode_manual: 'Manuell',
  axis_mode: 'Wertebereich',
  axis_left: 'Linke Achse',
  axis_right: 'Rechte Achse',
  min_max_required: 'Minimum und Maximum müssen eine gültige Zahl sein',
  must_be_greater: 'Maximum darf nicht kleiner als oder gleich dem Minimum sein',
  load_error: {
    metadata_missing:
      'Datenreihe "{series}" konnte nicht geladen werden, da benötigte Metadaten fehlen.',
  },
  status_chart_properties: {
    series_name_used_multiple_times:
      'Der Name wird für mehrere Serien genutzt. Dies führt dazu dass die Datenpunkte zusammengefasst und möglicherweise nicht richtig dargestellt werden.',
    datapoint_name_used_multiple_times:
      'Der Name wird für mehrere Datenpunkte verwendet. Die Datenpunkte werden mit der zuerst definierten Farbe angezeigt.',
  },
  save_copy: 'Kopie erstellen',
}
