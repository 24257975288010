import type { NumberColorMap } from '@/modules/svgbindings/ColorMap'
import type { ColorType } from '@/modules/svgbindings/SVGBinding'
import { ColorBinding } from '@/modules/svgbindings/SVGBinding'
import type { Thing } from '@/api/klempner/models/SiteListResponse'

/**
 * Binds a number value to a map of colors
 */
export class NumberColorBinding extends ColorBinding {
  private colorMap!: NumberColorMap

  constructor(
    domElement: Element,
    valuePath: string,
    colorMap: NumberColorMap,
    colorType: ColorType,
  ) {
    super(domElement, valuePath, colorType)
    this.colorMap = colorMap
  }

  async updateBinding(data: Thing[]): Promise<void> {
    const value = this.getReferencedValue(data)?.value
    if (typeof value !== 'number') {
      return
    }
    const color = this.colorMap.getColorForValue(value)
    this.resetInlineStyle()
    if (color !== undefined) {
      this.element.setAttribute(this.attributeName, color)
    } else {
      this.element.setAttribute(this.attributeName, <string>this.defaultColor)
    }
  }
}
