import type { ClientMapping } from '@/api/keycloak/models/RoleMapping'
import type UserRoleMappings from '@/api/keycloak/models/RoleMapping'
import type MappingsRepresentation from 'keycloak-admin/lib/defs/mappingsRepresentation'
import type { KeycloakRole } from '@/api/keycloak/models/KeycloakRole'
import RoleMapper from '@/api/keycloak/mappers/RoleMapper'

export default class RoleMappingMapper {
  static toDomain(mappings: MappingsRepresentation): UserRoleMappings {
    const realmMappings: KeycloakRole[] = RoleMapper.multipleToDomain(mappings.realmMappings ?? [])

    const clientMappings: ClientMapping[] = Object.entries(mappings.clientMappings ?? {}).map(
      (value) => {
        const clientMapping = value[1]
        const mappings = RoleMapper.multipleToDomain(clientMapping.mappings)
        return { clientName: clientMapping.client, clientId: clientMapping.id, roles: mappings }
      },
    )

    return { realmMappings: realmMappings, clientMappings: clientMappings }
  }
}
