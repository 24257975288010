<template>
  <div class="spacer-bottom">
    <div class="d-flex justify-content-between align-items-center">
      <div class="flex-shrink-0 d-flex align-items-center">
        <span>{{ displayName }}</span>
        <span v-if="changed">*</span>
        <tool-tip :toolTipText="toolTip" />
      </div>
      <b-form-input v-model="currentValue" class="flex-grow-1" size="sm" @input="onChange" />
    </div>
    <SubmitState
      class="w-100"
      :state="submitState"
      :errorMessage="submitError"
      @timeout-end="submitState = 'idle'"
    ></SubmitState>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseItem from '@/components/site/scada/command_response/toolbox/base/BaseItem.vue'
  import ToolTip from '@/components/site/scada/command_response/toolbox/base/ToolTip.vue'
  import SubmitState from '@/components/site/scada/command_response/toolbox/base/SubmitState.vue'
  import events from '@/events'

  /**
   * Provides an item to let the user enter a text
   */
  @Component({
    name: 'TextItem',
    components: {
      SubmitState,
      ToolTip,
    },
  })
  export default class TextItem extends mixins(BaseItem) {
    @Prop()
    private value!: string

    private currentValue: string = ''

    get messageBody() {
      if (this.msgParamName != null) {
        return { [this.msgParamName]: this.currentValue }
      } else {
        return this.currentValue
      }
    }

    get commandBody(): string {
      return this.currentValue
    }

    created() {
      // Initially set value of dropdown menu to actual value of the thing
      this.currentValue = this.value
    }

    protected onSubmitReceived() {
      if (this.changed) {
        this.submit()
      }
    }

    @Watch('value')
    onValueChanged(value: string) {
      // Update dropdown menu whenever actual value of thing changes
      if (value !== this.currentValue && this.value !== this.currentValue) {
        //this.toastOnUpdate()
      }
      this.currentValue = value
    }

    onChange(): void {
      this.changed = true
      this.submitEventBus.$emit(events.scada.templates.itemChanged)
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';
  @import 'item';
</style>
