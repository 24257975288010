import { ValueTextBinding } from '@/modules/svgbindings/impl/ValueTextBinding'
import { Attribute } from '@/modules/svgbindings/BindingParser'
import type { IBindingVisitor } from '@/modules/svgbindings/visitors/binding/IBindingVisitor'
import type { SVGBinding } from '@/modules/svgbindings/SVGBinding'

/**
 * Visits an element and checks if it has a NumberTextBinding
 */
export default class NumberTextVisitor implements IBindingVisitor {
  visit(element: Element, path: string): SVGBinding | undefined {
    if (element.hasAttribute(Attribute.TEXT_FORMAT)) {
      return new ValueTextBinding(element, path)
    }
  }
}
