<template>
  <div id="scada-editor" :class="{ 'edit-mode': editMode }" class="flex-resize d-flex flex-column">
    <div id="toolbar" class="w-100 gap-2 d-flex pb-2 align-items-center flex-wrap flex-shrink-1">
      <b-button :disabled="loading" size="sm" variant="primary" @click="showAll">
        <span class="material-icons">fullscreen</span>
        {{ $t('show_all') }}
      </b-button>
      <b-button
        v-if="editMode"
        :disabled="loading || nodeCount === 0"
        size="sm"
        variant="primary"
        @click="confirmClearAll"
      >
        <span class="material-icons">clear</span>
        {{ $t('clear_editor') }}
      </b-button>
      <div class="flex-grow-1" />

      <b-button-group>
        <b-button
          v-if="editMode"
          :disabled="!changed || loading"
          size="sm"
          variant="success"
          @click="saveDefinition"
        >
          <span class="material-icons">done</span>
        </b-button>
        <b-button size="sm" variant="primary" @click="editButtonClicked">
          <span class="material-icons">{{ editMode ? 'close' : 'edit' }}</span>
        </b-button>
        <b-button size="sm" variant="primary" @click="confirmDeleteTab">
          <span class="material-icons">delete</span>
        </b-button>
      </b-button-group>
    </div>
    <drop class="flex-grow-1 h-100 w-100 position-relative" @drop="handleNodeDrop">
      <div v-if="loading" id="loading-overlay">
        <b-spinner class="spinner-xl" variant="primary"></b-spinner>
      </div>
      <div id="rete" :class="{ 'no-click': !editMode }" class="flex-grow-1 h-100" />
    </drop>
    <SensorReadingConfigurationModal
      :currentSiteId="siteId"
      :transferData="transferData"
      @create-node="createNode(transferData, nativeEvent)"
    />
    <ThermalStorageConfigurationModal
      :currentSiteId="siteId"
      :transferData="transferData"
      @create-node="createNode(transferData, nativeEvent)"
    />
    <PumpConfigurationModal
      :currentSiteId="siteId"
      :transferData="transferData"
      @create-node="createNode(transferData, nativeEvent)"
    />
    <ScadaViewEditModal ref="edit-modal" />
    <b-modal
      :id="'copy-template-' + tab.uuid"
      :okDisabled="titleOfCopy.length === 0"
      @ok="copyTemplate"
    >
      <template #modal-title>
        <span v-if="tab.template_name !== null && tab.template_name !== ''" class="material-icons">
          lock
        </span>
        {{ $t('template_tab') }}
      </template>
      <div class="m-1">
        {{ $t('template_generated_tab') }}
        <label id="copy-name" class="mt-3">{{ $t('title_for_copy') }}</label>
        <b-form-input id="copy-name" v-model="titleOfCopy" />
      </div>
      <br />
      <div v-if="titleOfCopy" class="m-1">
        {{ $t('confirm_copy_template_tab') }}
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
  //@ts-ignore
  import VueRenderPlugin from 'rete-vue-render-plugin'
  //@ts-ignore
  import AreaPlugin from 'rete-area-plugin'
  //@ts-ignore
  import ConnectionReroutePlugin from 'rete-connection-reroute-plugin'
  //@ts-ignore
  import ConnectionPathPlugin from 'rete-connection-path-plugin'
  //@ts-ignore
  import ContextMenuPlugin, { Item, Menu, Search } from 'rete-context-menu-plugin'
  import { Component, Inject, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
  import type { Connection, Node as ReteNode, NodeEditor } from 'rete'
  import Rete from 'rete'
  import ConnectionPlugin from 'rete-connection-plugin'
  import SiteMixin from '@/modules/ditto/SiteMixin'
  import { mixins } from 'vue-class-component'
  import Logger from '@/logger'
  import ToastMixin from '@/modules/tools/ToastMixin'
  import * as d3 from 'd3-shape'
  import ScadaReteSensorReadingComponent from '@/components/site/scada/editor-components/rete_components/rete_nodes/ScadaReteSensorReadingComponent'
  import { vxm } from '@/store/store'
  import SensorReadingConfigurationModal from '@/components/site/scada/modals/SensorReadingConfigurationModal.vue'
  import SiteTreeDropdown from '@/components/app/SiteThingsTree/SiteTreeDropdown.vue'
  import ReadonlyPlugin from 'rete-readonly-plugin'
  import {
    PumpReteComponent,
    ScadaReteComponentLabeled,
    ScadaReteComponents,
    ScadaReteNode,
    ScadaReteNodeRotatable,
    ThermalStorageReteComponent,
  } from '@/components/site/scada/editor-components/rete_components/rete_nodes/ReteNode'
  import ScadaTextlabelComponent from '@/components/site/scada/editor-components/rete_components/rete_nodes/ScadaTextlabelComponent'
  import ScadaReteStandaloneSensorReadingComponent from '@/components/site/scada/editor-components/rete_components/rete_nodes/ScadaReteStandaloneSensorReadingComponent'
  import DeviceDescriptorUtil from '@/components/site/scada/editor-components/DeviceDescriptorUtil'
  import ScadaViewEditModal from '@/components/site/scada/ScadaViewEditModal.vue'
  import ThermalStorageConfigurationModal from '@/components/site/scada/modals/ThermalStorageConfigurationModal.vue'
  import PumpConfigurationModal from '@/components/site/scada/modals/PumpConfigurationModal.vue'
  import { config } from '@/config'
  import { v4 as uuidv4 } from 'uuid'
  import events from '@/events'
  import { ScadaTabFragment } from '@/generated/graphql'
  import {
    DeleteSchemaDocument,
    GetSchemaByIdDocument,
    SoftDeleteTemplateReteTabDocument,
    UpdateRiDefinitionDocument,
    UpsertSchemaTabDocument,
  } from '@/generated/graphql'

  export type TransferData = { name: string; data: any }

  @Component({
    components: {
      PumpConfigurationModal,
      ThermalStorageConfigurationModal,
      ScadaViewEditModal,
      SiteTreeDropdown,
      SensorReadingConfigurationModal,
    },
    filters: {
      round: Math.round,
    },
  })
  export default class ScadaEditor extends mixins(SiteMixin, ToastMixin) {
    @Prop()
    readonly tab!: ScadaTabFragment

    @Prop()
    readonly siteId!: string

    @Ref('edit-modal')
    readonly editModal!: ScadaViewEditModal

    @Inject('scadaMessageHub')
    private scadaMessageHub!: Vue

    private editor: NodeEditor | null = null

    /** Whether definition has been edited */
    private changed = false

    private loading = false

    private transferData: TransferData | null = null

    private nativeEvent: DragEvent | null = null

    private titleOfCopy: string = ''

    get editMode() {
      return vxm.scadaEditor.editMode
    }

    set editMode(value: boolean) {
      vxm.scadaEditor.editMode = value
    }

    get nodeCount() {
      return this.editor?.nodes.length ?? 0
    }

    @Watch('editMode')
    editModeChanged() {
      this.editor?.trigger('readonly', !this.editMode)
    }

    async editButtonClicked() {
      if (!this.editMode) {
        const isTabGeneratedFromTemplate = this.tab.template_name === null
        if (isTabGeneratedFromTemplate) {
          this.editMode = true
        } else {
          this.$bvModal.show('copy-template-' + this.tab.uuid)
        }
      } else {
        if (this.changed) {
          const confirmResult = await this.$bvModal.msgBoxConfirm(
            this.$t('scada.discard_editor_changes')!,
            {
              title: this.$t('scada.leave_editor_unsaved')! + '?',
              okVariant: 'primary',
              okTitle: this.$t('discard_changes_short')!,
              cancelTitle: this.$t('cancel')!,
              centered: true,
            },
          )
          if (confirmResult) {
            this.editMode = false
            await this.loadDefinition()
          }
        } else {
          this.editMode = false
        }
      }
    }

    async mounted() {
      if (this.editor == null) {
        this.setupEditor()
        await this.loadDefinition()
      }
      this.showAll()
    }

    deactivated() {
      this.editMode = false
    }

    onNodeRemoved(node: ReteNode) {
      this.$emit('node-removed', node)
    }

    onNodeAdded(node: ReteNode) {
      this.$emit('node-added', node)
    }

    destroyed() {
      this.editMode = false
      this.editor?.destroy()
    }

    /**
     * Resets scale & transform on the editor
     */
    public resetView() {
      if (this.editor != null) {
        const area = this.editor.view.area
        area.transform = { x: 0, y: 0, k: 1 }
        area.update()
      }
    }

    /**
     * Centers view around bounding box of nodes
     */
    public showAll() {
      AreaPlugin.zoomAt(this.editor, this.editor?.nodes ?? [])
    }

    async handleNodeDrop(transferData: TransferData, nativeEvent: DragEvent) {
      if (this.loading) {
        return
      }
      this.transferData = transferData
      this.nativeEvent = nativeEvent

      switch (transferData.name) {
        case ScadaReteComponents.Sensor:
        case ScadaReteComponents.StandaloneSensor:
          /*
           * creating the node will only take place if the user has entered the needed values
           * and triggered by event of {@link SensorReadingConfigurationModal}
           * (the user needs to define relevant transfer data)
           */
          this.$bvModal.show('sensor-reading-modal-' + this.currentSiteId)
          break
        case ScadaReteComponents.ThermalStorage:
          this.$bvModal.show('thermal-storage-config-modal-' + this.currentSiteId)
          break
        case ScadaReteComponents.Pump:
          this.$bvModal.show('pump-modal-' + this.currentSiteId)
          break

        default:
          await this.createNode(transferData, nativeEvent)
          break
      }
    }

    async createNode(transferData: TransferData, nativeEvent: DragEvent) {
      const component = this.editor?.getComponent(transferData.name)

      const node = await component?.createNode({ ...transferData.data })

      if (node != undefined) {
        const element = this.editor?.view.area.el

        const rect = element?.getBoundingClientRect() ?? new DOMRect()
        const mouseX = nativeEvent.clientX - rect.left
        const mouseY = nativeEvent.clientY - rect.top

        const { k } = this.editor?.view.area.transform ?? { k: 1 }
        node.position = [mouseX / k, mouseY / k]
        this.editor?.addNode(node)
      }
    }

    async saveDefinition() {
      try {
        this.loading = true
        const definition = this.editor?.toJSON()
        await this.$apollo.mutate({
          mutation: UpdateRiDefinitionDocument,
          variables: { id: this.tab.uuid, definition },
          context: { headers: { 'X-Hasura-Role': config.keycloak.roles.scada.write } },
        })
        this.toastSuccess(this.$t('scada.success_saving'))
        this.setChanged(false)
        this.editMode = false
      } catch (e) {
        this.toastError(this.$t('scada.error_saving', { error: e.message ?? e }))
        Logger.error('Error saving SCADA representation', e)
      } finally {
        this.loading = false
      }
    }

    async confirmClearAll() {
      try {
        const result = await this.$bvModal.msgBoxConfirm(this.$t('scada.confirm_delete_all')!, {
          title: this.$t('clear_editor')! + '?',
          okVariant: 'primary',
          okTitle: this.$t('clear_editor')!,
          cancelTitle: this.$t('cancel')!,
          centered: true,
        })
        if (result) {
          this.editor?.clear()
        }
      } catch (e) {
        //User closed dialog, ignore
      }
    }

    /**
     * Shows a confirmation modal that asks the user to confirm that they intend to delete this tab.
     */
    async confirmDeleteTab() {
      try {
        const result = await this.$bvModal.msgBoxConfirm(this.$t('scada.rete_tab.undo_warning')!, {
          title: this.$t('scada.rete_tab.confirm_delete')!,
          okVariant: 'primary',
          okTitle: this.$t('delete')!,
          cancelTitle: this.$t('cancel')!,
          centered: true,
        })
        if (result) {
          await this.deleteTab()
        }
      } catch (e) {
        //User closed dialog, ignore
      }
    }

    async deleteTab() {
      try {
        if (this.tab.template_name === null) {
          await this.$apollo.mutate({
            mutation: DeleteSchemaDocument,
            context: { headers: { 'X-Hasura-Role': config.keycloak.roles.scada.write } },
            variables: { uuid: this.tab.uuid },
          })
        } else {
          await this.$apollo.mutate({
            mutation: SoftDeleteTemplateReteTabDocument,
            context: { headers: { 'X-Hasura-Role': config.keycloak.roles.scada.write } },
            variables: { uuid: this.tab.uuid },
          })
        }
        this.$emit('delete')
      } catch (e) {
        this.toastError(this.$t('error_deleting_tab', { error: e.message ?? e }))
        Logger.error('Error saving editor-components', e)
      }
    }

    async loadDefinition() {
      try {
        this.loading = true
        const result = await this.$apollo.query({
          query: GetSchemaByIdDocument,
          context: { headers: { 'X-Hasura-Role': config.keycloak.roles.scada.read } },
          variables: { id: this.tab.uuid },
        })
        const definition = result.data.tab.definition
        await this.editor?.fromJSON(definition)

        // Avoid connections in the wrong places by re-drawing all connections after loading json
        this.editor?.nodes.forEach((node) => {
          this.editor?.view.updateConnections({ node })
        })
        this.setChanged(false)
      } catch (e) {
        if (e.response?.status != 404) {
          this.toastError(this.$t('scada.error_loading', { error: e.message ?? e }))
          Logger.error('Error getting SCADA representation', e)
        }
      } finally {
        this.loading = false
      }
    }

    setChanged(value: boolean) {
      this.changed = value
      this.$emit('changed', value)
    }

    onRenderConnection(args: { el: HTMLElement; connection: Connection; points: number[] }) {
      //TODO deduplicate
      const path = args.el.getElementsByTagNameNS(
        'http://www.w3.org/2000/svg',
        'path',
      )[0] as SVGPathElement | null

      if (path == null) {
        return
      }

      path.addEventListener('click', () => {
        this.changed = true
      })

      path.oncontextmenu = () => {
        this.editor?.removeConnection(args.connection)
      }
    }

    async copyTemplate() {
      const variables = {
        uuid: uuidv4(),
        siteId: this.currentSiteId,
        title: this.titleOfCopy,
        type: this.tab.type,
        definition: this.tab.definition,
        description: this.tab.description,
      }

      try {
        const tabId = await this.$apollo.mutate({
          mutation: UpsertSchemaTabDocument,
          context: { headers: { 'X-Hasura-Role': config.keycloak.roles.scada.write } },
          variables: { ...variables },
        })
        this.scadaMessageHub.$emit(events.scada.tabUpdated, tabId)
        this.scadaMessageHub.$emit(events.scada.goToSvg, this.tab.title, tabId)
        this.toastSuccess(this.$t('scada_tab_upload_success'))
      } catch (error) {
        Logger.error('Error saving scada tab', error)
        this.toastError(this.$t('scada_tab_save_error'))
      }
    }

    private setupEditor() {
      const container = document.getElementById('rete')!

      this.editor = new Rete.NodeEditor('othermo-r-and-i@1.0.0', container)

      this.editor.use(ConnectionPlugin)
      this.editor.use(ConnectionReroutePlugin, {
        curve: d3.curveStepAfter,
        curvature: 0,
      })

      this.editor.use(VueRenderPlugin)

      this.editor.use(ReadonlyPlugin)

      this.editor.use(ContextMenuPlugin, {
        searchBar: false,
        nodeItems: this.getContextMenuItemsForNode,
        items: {},
      })

      //@ts-ignore
      this.editor.on('showcontextmenu', ({ _, node }) => {
        return Boolean(node) && this.editMode // Show context menu only for nodes
      })

      this.editor.use(AreaPlugin, {
        snap: { size: 20, dynamic: true },
        scaleExtent: { min: 0.5, max: 2 },
        translateExtent: { width: 3000, height: 2000 },
      })

      this.editor?.register(new ScadaReteNode())
      this.editor?.register(new ScadaReteComponentLabeled())
      this.editor?.register(new ScadaReteNodeRotatable())
      this.editor?.register(new ScadaReteSensorReadingComponent())
      this.editor?.register(new ScadaReteStandaloneSensorReadingComponent())
      this.editor?.register(new ScadaTextlabelComponent())
      this.editor?.register(new ThermalStorageReteComponent())
      this.editor?.register(new PumpReteComponent())

      this.editor.on(
        ['nodecreated', 'connectioncreated', 'connectionremoved', 'noderemoved', 'nodedragged'],
        () => {
          this.setChanged(true)
        },
      )
      this.editor.on('rendernode', ({ el, node }) => {
        const deviceDescriptor = DeviceDescriptorUtil.findDeviceDescriptor(
          node.data.deviceDefinition as string,
        )
        if (deviceDescriptor?.commandResponseTemplate != undefined) {
          el.addEventListener('click', () => {
            if (!this.editMode) {
              this.editModal.open(
                node.data.thingId as string,
                deviceDescriptor.commandResponseTemplate!,
                deviceDescriptor.commandResponseFeature ?? '',
              )
            }
          })
        }
      })
      this.editor.on('noderemoved', this.onNodeRemoved)
      this.editor.on('nodecreated', this.onNodeAdded)
      this.editor.on('renderconnection', this.onRenderConnection)
      //Disables zoom on double click
      this.editor.on('zoom', ({ source }) => {
        return source !== 'dblclick'
      })
    }

    private getContextMenuItemsForNode(node: ReteNode) {
      function setNodeRotation(node: ReteNode, rotation: string, that: ScadaEditor) {
        node.data.rotation = rotation
        node.update()
        that.setChanged(true)
      }

      function setLabelPosition(node: ReteNode, position: string) {
        node.data.labelPosition = position
        node.update()
      }

      let contextMenu: Record<string, any> = { Delete: true, Clone: false }

      if (this.$i18n.locale() == 'de') {
        contextMenu = {
          Delete: false,
          Clone: false,
          // Context menu uses the property name as a display name =>
          [this.$t('delete') ?? 'delete']: () => {
            this.editor!.removeNode(node)
          },
        }
      }

      if (
        node.name === ScadaReteComponents.Sensor ||
        node.name === ScadaReteComponents.StandaloneSensor ||
        node.name === ScadaReteComponents.Rotatable ||
        node.name === ScadaReteComponents.Pump
      ) {
        // noinspection NonAsciiCharacters Non-Ascii Characters in the identifier are required here because the property name is used as the text for the menu entry
        contextMenu[this.$t('scada.rete_tab.node_rotation')!] = {
          '0°': () => setNodeRotation(node, 'east', this),
          '90°': () => setNodeRotation(node, 'south', this),
          '180°': () => setNodeRotation(node, 'west', this),
          '270°': () => setNodeRotation(node, 'north', this),
        }
      }
      if (node.name == ScadaReteComponents.StandaloneSensor) {
        // noinspection NonAsciiCharacters Non-Ascii Characters in the identifier are required here because the property name is used as the text for the menu entry
        contextMenu[this.$t('scada.rete_tab.label_position')!] = {
          [this.$t('scada.rete_tab.label_pos.left')!]: () => setLabelPosition(node, 'left'),
          [this.$t('scada.rete_tab.label_pos.right')!]: () => setLabelPosition(node, 'right'),
          [this.$t('scada.rete_tab.label_pos.top')!]: () => setLabelPosition(node, 'top'),
          [this.$t('scada.rete_tab.label_pos.bottom')!]: () => setLabelPosition(node, 'bottom'),
        }
      }
      return contextMenu
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';
  @import 'editor-components/scada_node';

  .edit-mode {
    max-width: min(80%, calc(100% - 350px));
  }

  #rete {
    max-width: 100% !important;
    $grid-color: $gray-300;
    background-size: 25px 25px;
    background-image: linear-gradient(to right, $grid-color 1px, transparent 1px),
      linear-gradient(to bottom, $grid-color 1px, transparent 1px);
    cursor: move;
    border-right: thin solid $grid-color;
    border-bottom: thin solid $grid-color;

    ::v-deep .connection path {
      stroke: $gray-500;
      cursor: pointer;
      pointer-events: visibleStroke;

      &:hover {
        stroke: $gray-500 !important;
      }
    }
  }

  #loading-overlay {
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinner-xl {
    width: 3rem;
    height: 3rem;
  }

  ::v-deep .context-menu,
  ::v-deep .subitems {
    border-radius: $border-radius-sm !important;
    background: white !important;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
    padding: 0 !important;
    min-width: fit-content;

    .item {
      margin: 0 !important;
      background: none !important;
      color: $body-color;
      border: none !important;

      &:not(:last-child) {
        border-bottom: thin solid $gray-400;
      }

      &:hover {
        color: $primary !important;
        background: $gray-200 !important;
      }
    }
  }

  .no-click {
    ::v-deep .connection path,
    ::v-deep div.pin {
      pointer-events: none !important;
    }
  }

  .flex-resize {
    flex-shrink: 1;
    flex-grow: 1;
  }
</style>
