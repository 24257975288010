export const analyticsTranslationsDe = {
  analytics: 'Analyse',
  create_analysis: 'Neue Analyse erstellen',
  x_axis: 'X-Achse',
  y_axis: 'Y-Achse',
  add_series: 'Neue Datenreihe hinzufügen',
  edit_series: '"{name}" bearbeiten',
  discard_analytics_changes: 'Änderungen verwerfen',
  unit: 'Einheit',
  series_unit_mismatch:
    'Einheit {series_unit} der {axis}-Achse ist nicht kompatibel mit {first_unit} der ersten Serie. Daten werden eventuell nicht korrekt dargestellt.',
  no_datapoint_selected: 'Kein Datenpunkt ausgewählt',
  //Analytics analytics_types
  BAR_CHART: 'Balkendiagramm',
  PROFILE: 'Lastprofil',
  SCATTER_PLOT: 'Streudiagramm',
  DURATION_CURVE: 'Dauerlinie',
  HISTOGRAM: 'Histogram',
  CARPET_PLOT: 'Spektralanalyse',
  // Form feedback
  type_required: 'Es muss ein Typ ausgewählt werden.',
  name_required: 'Name darf nicht leer bleiben.',
  reference_required: 'Es muss ein Datenpunkt gewählt werden',
  select_datapoint: 'Datenpunkt wählen',
  series: 'Datenreihe:::Datenreihen',
  // Aggregations
  min: 'Minimum',
  max: 'Maximum',
  last: 'Letzter Wert',
  all_values: 'Alle Werte',
  difference: 'Differenz',
  '95%': '95. Perzentil',
  '5%': '5. Perzentil',
  aggregation: 'Aggregation',
  count: 'Anzahl',
  distinct: 'Verschiedene',
  integral: 'Integral',
  mean: 'Mittelwert',
  median: 'Median',
  mode: 'Modus',
  spread: 'Streuung',
  stddev: 'Standardabweichung',
  sum: 'Summe',
  delete_tab: 'Tab löschen',
  //Toasts
  error_loading_analyses: 'Analysen konten nicht geladen werden: {error}',
  error_deleting_tab: 'Tab konnte nicht gelöscht werden: {error}',
  error_getting_analysis: 'Analyse konnte nicht geladen werden: {error}',
  tab_save_success: 'Tab gespeichert',
  tab_save_error: 'Tab konnte nicht gespeichert werden: {error}',
  new_analysis_created: 'Neue Analyse angelegt',
  error_creating_analysis: 'Analyse konnte nicht angelegt werden',
  error_getting_analysis_data: 'Analysedaten konnten nicht geladen werden: {error}',
  analytics_read_forbidden:
    'Sie haben nicht die notwendigen Berechtigungen um Analysen für diese Anlage zu sehen',
  analytics_write_forbidden:
    'Sie haben nicht die notwendigen Berechtigungen um Analysen zu berarbeiten',
  //Heatmap
  calendar_week: 'KW',
  //Bar chart
  stacked: 'Gestapelt',
  grouped: 'Gruppiert',
  //Histogram
  bin_mode: 'Gruppierung',
  bin_mode_auto: 'Auto',
  bin_mode_manual: 'Manuell',
  bin_min: 'Min',
  bin_max: 'Max',
  bin_size: 'Gruppen-Größe',
  cumulative_mode: 'Kumulative Ansicht',
  binning_function: 'Gruppierungs-Funktion',
  avg: 'Durchschnitt',
  // Load Profiles
  mode_daily: 'Tagesprofil',
  mode_daily_average: 'Tagesdurchschnitt',
  mode_weekly: 'Wochenprofil',
}
