<template>
  <b-modal
    :id="'pump-modal-' + currentSiteId"
    :cancelTitle="$t('cancel')"
    :okTitle="$t('scada.rete_tab.pump_modal.create')"
    :title="$t('scada.rete_tab.pump_modal.title')"
    @cancel="resetModal"
    @close="resetModal"
    @ok="createNode"
  >
    <b-form-group :label="$t('scada.rete_tab.pump_modal.running_datapoint')">
      <SiteTreeDropdown
        v-model="runningReference"
        :filter="booleanNodeFilter"
        :placeholder="$t('select_thing')"
        selectionLevels="properties"
      />
    </b-form-group>
    <b-form-group :label="$t('scada.rete_tab.pump_modal.warning_datapoint')">
      <SiteTreeDropdown
        v-model="warningReference"
        :filter="booleanNodeFilter"
        :placeholder="$t('select_thing')"
        selectionLevels="properties"
      />
    </b-form-group>
    <b-form-group :label="$t('scada.rete_tab.pump_modal.error_datapoint')">
      <SiteTreeDropdown
        v-model="errorReference"
        :filter="booleanNodeFilter"
        :placeholder="$t('select_thing')"
        selectionLevels="properties"
      />
    </b-form-group>
  </b-modal>
</template>

<script lang="ts">
  import { Component, Prop } from 'vue-property-decorator'
  import ToastMixin from '@/modules/tools/ToastMixin'
  import { mixins } from 'vue-class-component'
  import SiteTreeDropdown from '@/components/app/SiteThingsTree/SiteTreeDropdown.vue'
  import type ThingReference from '@/components/shared/ThingReference'
  import { TransferData } from '@/components/site/scada/ScadaEditor.vue'
  import type { PropertyNodeFilter } from '@/components/app/SiteThingsTree/TreeGenerator'

  @Component({
    components: { SiteTreeDropdown },
  })
  export default class PumpConfigurationModal extends mixins(ToastMixin) {
    @Prop()
    readonly currentSiteId!: string

    @Prop()
    private transferData!: TransferData

    private runningReference: ThingReference | null = null

    private warningReference: ThingReference | null = null

    private errorReference: ThingReference | null = null

    readonly booleanNodeFilter: PropertyNodeFilter = (node) => {
      return node.data.dataType == 'boolean'
    }

    /**
     * Before the 'create-node' event is emitted the data from the modal needs
     * to be set in the transfer data
     */
    private createNode() {
      this.transferData.data.running = this.runningReference?.toRefString()
      this.transferData.data.warning = this.warningReference?.toRefString()
      this.transferData.data.error = this.errorReference?.toRefString()
      this.$emit('create-node')
      this.resetModal()
    }

    private resetModal() {
      this.runningReference = null
      this.warningReference = null
      this.errorReference = null
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .dropdown-toggle {
    white-space: break-spaces !important;

    &::after {
      white-space: normal;
    }
  }
</style>
