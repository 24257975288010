import { Component, Vue } from 'vue-property-decorator'

/**
 * Provides methods to show toasts in the UI
 */
@Component
export default class ToastMixin extends Vue {
  toastError(text: string | undefined) {
    if (text == undefined) {
      return
    }
    this.$bvToast.toast(text, {
      variant: 'danger',
      title: this.$t('error', {}, 1),
      autoHideDelay: 5000,
      solid: true,
    })
  }

  toastSuccess(text: string | undefined) {
    if (text == undefined) {
      return
    }
    this.$bvToast.toast(text, {
      variant: 'success',
      title: 'Ok',
      autoHideDelay: 5000,
      solid: true,
    })
  }

  toastWarning(text: string | undefined) {
    if (text == undefined) {
      return
    }
    this.$bvToast.toast(text, {
      variant: 'warning',
      title: this.$t('attention'),
      autoHideDelay: 5000,
      solid: true,
    })
  }
}
