<template>
  <hr class="w-100" />
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'

  @Component
  export default class Divider extends Vue {}
</script>

<style lang="scss" scoped></style>
