import { SVGBinding } from '@/modules/svgbindings/SVGBinding'
import UnitHandler from '@/modules/units/UnitHandler'
import type { Thing } from '@/api/klempner/models/SiteListResponse'

/**
 * Binds a number as text using the {@link UnitHandler}.
 */
export class ValueTextBinding extends SVGBinding {
  constructor(domElement: Element, valuePath: string) {
    super(domElement, valuePath)
  }

  async updateBinding(things: Thing[]) {
    const enrichedProperty = this.getReferencedValue(things)
    if (enrichedProperty == undefined) {
      this.error = `Value undefined for ${this.valuePathString}`
      return
    }

    /// Leave the default text if the value string is empty
    if (
      enrichedProperty.value === null ||
      enrichedProperty.value === undefined ||
      enrichedProperty.value.toString().trim() === ''
    ) {
      return
    }

    this.element.textContent = UnitHandler.getInstance().format(
      enrichedProperty.value_unit ?? '',
      enrichedProperty.value_type ?? '',
      enrichedProperty.value ?? '',
    )
  }
}
