import type IUnitHandler from '@/modules/units/handlers/IUnitHandler'

export default class BooleanHandler implements IUnitHandler {
  format(
    type: string | null,
    unit: string | null,
    value: any,
    locale: string,
    t?: (name: string) => string | undefined,
  ): string {
    const isTrue = value === 1 || value === '1' || value === true || value === 'true'
    return t?.(isTrue ? 'on' : 'off') ?? String(isTrue)
  }

  isSupported(type: string, unit: string): boolean {
    return type === 'boolean'
  }

  formatUnit(type: string, unit: string, locale: string): string {
    return ''
  }

  scaleValue(value: number, scalingFactor: number): number {
    return value
  }

  getScaling(
    series: [string, number | null][][],
    unit: string,
  ): { best: any; scalingFactor: number } {
    return { best: { unit: unit, symbol: '' }, scalingFactor: 1 }
  }

  isDifferentiable(): boolean {
    return false
  }
}
