<template>
  <div class="overlay">
    <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'

  @Component
  export default class SubmitOverlay extends Vue {}
</script>

<style lang="scss" scoped>
  .spinner-grow {
    width: 3rem;
    height: 3rem;
  }

  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
  }
</style>
