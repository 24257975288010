<template>
  <div class="d-flex justify-content-between align-items-center spacer-bottom flex-wrap">
    <div class="flex-shrink-0 d-flex align-items-center">
      <span>{{ displayName }}</span>
      <span v-if="changed">*</span>
      <tool-tip :toolTipText="toolTip" />
    </div>
    <b-form-select
      id="input"
      v-model="currentValue"
      size="sm"
      class="flex-shrink-0"
      :options="enumValues"
      :state="isValid()"
      @change="onChange"
    >
      <template #first>
        <option disabled value="">{{ $t('dropdown_please_select') }}</option>
      </template>
    </b-form-select>
    <SubmitState
      class="w-100"
      :state="submitState"
      :errorMessage="submitError"
      @timeout-end="submitState = 'idle'"
    ></SubmitState>
    <span v-if="changedInDitto" class="w-100 flex-shrink-0 text-right text-warning">
      {{ $t('value_changed_to', { value: formattedValue }) }}
    </span>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseItem from '@/components/site/scada/command_response/toolbox/base/BaseItem.vue'
  import ToolTip from '@/components/site/scada/command_response/toolbox/base/ToolTip.vue'
  import SubmitState from '@/components/site/scada/command_response/toolbox/base/SubmitState.vue'
  import events from '@/events'

  /**
   * Provides an item to let the user switch between different enums
   */
  @Component({
    name: 'EnumItem',
    components: {
      ToolTip,
      SubmitState,
    },
  })
  export default class EnumItem extends mixins(BaseItem) {
    @Prop()
    readonly map!: [string, string][]

    @Prop()
    readonly value!: string

    private currentValue: string = ''

    private changedInDitto: boolean = false

    get formattedValue() {
      const enumTranslationKey = this.map?.find(
        ([enumEntry]: [string, string]) => enumEntry === this.value,
      )?.[1]
      return enumTranslationKey ? `"${this.$t(enumTranslationKey)}"` : ''
    }

    /**
     * Converts the map (mapping of value -> translation key) into the proper format for dropdown menus (including
     * translated texts to display).
     */
    get enumValues(): { text: string | undefined; value: string }[] {
      const map = new Map(this.map)
      const mappedResult = []
      for (const key of map.keys()) {
        const value = map.get(key) as string
        const translated = this.$t(value)
        mappedResult.push({
          text: translated,
          value: key,
        })
      }
      return mappedResult
    }

    get messageBody(): string | object {
      if (this.msgParamName != null) {
        return { [this.msgParamName]: this.currentValue }
      } else {
        return this.currentValue
      }
    }

    get commandBody(): string {
      return this.currentValue
    }

    created() {
      // Initially set value of dropdown menu to actual value of the thing
      this.currentValue = this.value
    }

    protected onSubmitReceived() {
      if (this.changed) {
        this.submit()
      }
    }

    @Watch('value')
    onValueChanged(value: string) {
      // Update dropdown menu whenever actual value of thing changes
      this.changedInDitto = value !== this.currentValue && this.value !== this.currentValue
    }

    onChange() {
      this.changed = true
      this.submitEventBus.$emit(events.scada.templates.itemChanged)
    }

    isValid() {
      return this.currentValue !== ''
    }
  }
</script>

<style scoped lang="scss">
  @import 'src/vars';
  @import 'item';
  #input {
    flex-basis: 10px;
    flex-grow: 0.3;
  }
</style>
