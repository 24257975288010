export const meterTranslationsDe = {
  meter: 'Zähler:::Zähler',
  history: 'Historie',
  deadline: 'Stichtag',
  heat: 'Wärme',
  gas: 'Gas',
  electricity: 'Strom',
  water: 'Wasser',
  cold: 'Kälte',
  heatCostAllocator: 'Heizkostenverteiler',
  units: 'Einheiten',
  point_in_time: 'Zeitpunkt',
  name: 'Name',
  show_history: 'Historie anzeigen',
  show_analytics: 'In Analyse anzeigen',
  show_trends: 'In Trends anzeigen',
  select_meter: 'Zähler auswählen',
  //Heat/cold/water meters
  work: 'Arbeit',
  volume: 'Volumen',
  //Gas meters
  standard_volume: 'Normalvolumen',
  operating_volume: 'Betriebsvolumen',
  // Electricity meters
  supply_total: 'Bezug Gesamt',
  supply_t1: 'Bezug T1',
  supply_t2: 'Bezug T2',
  feed_total: 'Einspeisung Gesamt',
  feed_t1: 'Einspeisung T1',
  feed_t2: 'Einspeisung T2',
  // Errors & toasts
  no_meters_of_category: 'Keine Zähler in dieser Kategorie vorhanden',
  error_loading_meters: 'Zähler vom Typ {category} konnten nicht geladen werden: {error}',
  warning_high_diff: 'Zeitpunkt der Werte nicht einheitlich',
  error_getting_meters: '{count} Zähler-Kategorien konnten nicht geladen werden',
  no_data_for_meter: 'Keine Daten für diesen Zähler',
  resolution: 'Auflösung',
  download_csv: 'CSV herunterladen',
  no_meter_selected: 'Kein Zähler ausgewählt',
  error_loading_meter_history: 'Zählerdaten konnten nicht geladen werden: {error}',
  error_loading_meter_values: 'Zählerstände konnten nicht geladen werden: {error}',
}
