import type { RoleMappingPayload } from 'keycloak-admin/lib/defs/roleRepresentation'
import type RoleRepresentation from 'keycloak-admin/lib/defs/roleRepresentation'
import type { KeycloakRole } from '@/api/keycloak/models/KeycloakRole'

export default class RoleMapper {
  static toDomain(role: RoleRepresentation): KeycloakRole {
    return { id: role.id ?? '', name: role.name ?? '' }
  }

  static multipleToDomain(roles: RoleRepresentation[]): KeycloakRole[] {
    return roles.map(RoleMapper.toDomain)
  }

  static toDto(role: KeycloakRole): RoleMappingPayload {
    return { id: role.id, name: role.name }
  }
}
