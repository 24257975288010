import type { Node } from 'rete'
import { Component, Input, Output } from 'rete'
import type { NodeData, WorkerInputs, WorkerOutputs } from 'rete/types/core/data'
import type {
  SocketDescriptor,
  SocketType,
  DeviceDescriptor,
} from '@/components/site/scada/editor-components/KnownDevicesAndSockets'
import {
  GenericScadaComponents,
  getSocketByType,
  DeviceDescriptorByDefinition,
} from '@/components/site/scada/editor-components/KnownDevicesAndSockets'
import Logger from '@/logger'
import DeviceDescriptorUtil from '@/components/site/scada/editor-components/DeviceDescriptorUtil'
import ScadaNodeLabeled from '@/components/site/scada/editor-components/rete_components/ScadaNodeLabeled.vue'
import ScadaNode from '@/components/site/scada/editor-components/rete_components/ScadaNode.vue'
import ScadaNodeRotatable from '@/components/site/scada/editor-components/rete_components/ScadaNodeRotatable.vue'
import ThermalStorageComponent from '@/components/site/scada/editor-components/rete_components/ThermalStorageComponent.vue'
import PumpComponent from '@/components/site/scada/editor-components/rete_components/PumpComponent.vue'

/** Contains names of all Rete components used in the SCADA editor */
export enum ScadaReteComponents {
  /** Basic Scada node that shows an (interactive) SVG */
  Base = 'ScadaReteNode',

  /** Scada node that shows an SVG with an editable label */
  Labeled = 'ScadaReteComponentLabeled',

  /** Scada node for rotateable SVGs */
  Rotatable = 'ScadaReteRotatableNode',

  /** Shows a user-selectable single value from Ditto */
  Sensor = 'ScadaReteSensorReadingComponent',

  /** Shows a user-selectable single value from Ditto without any connector points  */
  StandaloneSensor = 'ScadaReteStandaloneSensorReadingComponent',

  /** User-editable text area  */
  Comment = 'ScadaCommentComponent',

  /** User-Editable thermal storage */
  ThermalStorage = 'ThermalStorageComponent',

  /** Generic Pump component */
  Pump = 'PumpComponent',
}

export class ReteNode extends Component {
  declare data: { component: any }

  async builder(node: Node) {
    const descriptor: DeviceDescriptor = DeviceDescriptorUtil.findDeviceDescriptor(
      node.data.deviceDefinition as string,
    ) ?? { svg: '', inputs: [], outputs: [] }
    if (descriptor == undefined) {
      Logger.error(`unknown descriptor ${node.data.deviceDefinition}`)
      return
    }

    descriptor.inputs.forEach((input, index) => {
      node.addInput(
        new Input(
          input.type +
            '_' +
            ReteNode.getNumberOfPreviousSocketsWithSameType(index, descriptor.inputs),
          input.type,
          getSocketByType(input.type),
          true,
        ),
      )
    })

    descriptor.outputs.forEach((output, index) => {
      node.addOutput(
        new Output(
          output.type +
            '_' +
            ReteNode.getNumberOfPreviousSocketsWithSameType(index, descriptor.outputs),
          output.type,
          getSocketByType(output.type),
          true,
        ),
      )
    })
  }

  worker(node: NodeData, inputs: WorkerInputs, outputs: WorkerOutputs, ...args: unknown[]) {}

  /**
   * @param socketIndex of the current socket for from which previous entries are considered
   * @param socketDescriptors that contain all inputs/outputs
   */
  private static getNumberOfPreviousSocketsWithSameType(
    socketIndex: number,
    socketDescriptors: SocketDescriptor[],
  ): number {
    const socketType: SocketType = socketDescriptors[socketIndex].type
    let previousSocketsWithSameType: number = 0
    for (let i = 0; i < socketIndex; i++) {
      if (socketType === socketDescriptors[i].type) {
        previousSocketsWithSameType++
      }
    }
    return previousSocketsWithSameType
  }
}

export class ScadaReteComponentLabeled extends ReteNode {
  constructor() {
    super(ScadaReteComponents.Labeled)
    this.data.component = ScadaNodeLabeled
  }
}

export class ScadaReteNode extends ReteNode {
  constructor() {
    super(ScadaReteComponents.Base)
    this.data.component = ScadaNode
  }
}

export class ThermalStorageReteComponent extends ReteNode {
  constructor() {
    super(ScadaReteComponents.ThermalStorage)
    this.data.component = ThermalStorageComponent
  }

  async builder(node: Node) {
    await super.builder(node)
    if (node.data.sensors === undefined) {
      node.data.sensors = []
    }
  }
}
export class PumpReteComponent extends ReteNode {
  constructor() {
    super(ScadaReteComponents.Pump)
    this.data.component = PumpComponent
  }

  async builder(node: Node) {
    await super.builder(node)
    if (node.data.rotation === undefined) {
      node.data.rotation = 'east'
    }
  }
}

export class ScadaReteNodeRotatable extends ReteNode {
  constructor() {
    super(ScadaReteComponents.Rotatable)
    this.data.component = ScadaNodeRotatable
  }

  async builder(node: Node) {
    await super.builder(node)
    if (node.data.rotation === undefined) {
      node.data.rotation = 'east'
    }
  }
}
