<template>
  <div>
    <Pump :running="running" :hasWarning="hasWarning" :hasError="hasError" :rotation="rotation" />
    <Socket
      v-for="(input, index) in inputs()"
      :key="`input_${index}`"
      v-socket:input="input"
      :name="input.name"
      v-bind="transformedConnectorPositions(nodeDescriptor.inputs, index)"
      type="input"
    />
    <Socket
      v-for="(output, index) in outputs()"
      :key="`output_${index}`"
      v-socket:output="output"
      :name="output.name"
      type="output"
      v-bind="transformedConnectorPositions(nodeDescriptor.outputs, index)"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Watch } from 'vue-property-decorator'
  import type { Node } from 'rete'
  import Pump from '@/components/site/scada/editor-components/rete_components/Pump.vue'
  import ThingReference from '@/components/shared/ThingReference'
  import { resolve } from '@/modules/tools/Resolve'

  //@ts-ignore
  import VueRender from 'rete-vue-render-plugin'
  import ScadaNodeRotatable from '@/components/site/scada/editor-components/rete_components/ScadaNodeRotatable.vue'
  import Socket from '@/components/site/scada/editor-components/Socket.vue'

  @Component({
    components: { Pump, Socket },
  })
  export default class PumpComponent extends ScadaNodeRotatable {
    //Injected by Rete.js
    declare node: Node

    private running: boolean | null = false
    private hasWarning: boolean = false
    private hasError: boolean = false

    @Watch('things')
    updateValues() {
      this.running = this.updateRunning()
      this.hasWarning = this.updateHasWarning()
      this.hasError = this.updateHasError()
    }

    private resolveProperty(propertyPath: string) {
      const dittoReference = ThingReference.fromString(propertyPath)
      const propertyPathComponents = propertyPath.split('/')

      const thing = this.things.find((thing) => thing.id === dittoReference.thingId)
      if (thing === undefined) {
        return null
      }
      // Because we already found the thing with the right ID, we don't need it in the path to find the value anymore
      // (Array.slice(1) returns all elements except the one with index 0)
      return resolve(propertyPathComponents.slice(1), thing)
    }

    updateRunning() {
      if (this.node?.data?.running != undefined) {
        const running: boolean | null = this.resolveProperty(this.node.data.running as string)
        if (running != null) {
          return running
        }
        return false
      }
      return null
    }

    updateHasWarning() {
      if (this.node?.data?.warning != undefined) {
        const hasWarning = this.resolveProperty(this.node.data.warning as string)
        if (hasWarning != null) {
          return hasWarning
        }
      }
      return false
    }

    updateHasError() {
      if (this.node?.data?.error != undefined) {
        const hasError = this.resolveProperty(this.node.data.error as string)
        if (hasError != null) {
          return hasError
        }
      }
      return false
    }
  }
</script>

<style lang="scss" scoped></style>
