import { Attribute, safeGet } from '@/modules/svgbindings/BindingParser'
import { BoolTextBinding } from '@/modules/svgbindings/impl/BoolTextBinding'
import type { IBindingVisitor } from '@/modules/svgbindings/visitors/binding/IBindingVisitor'
import type { SVGBinding } from '@/modules/svgbindings/SVGBinding'

/**
 * Visits an element and checks if it has a BoolTextBinding
 */
export default class BoolTextVisitor implements IBindingVisitor {
  visit(element: Element, path: string): SVGBinding | undefined {
    if (element.hasAttribute(Attribute.TEXT_TRUE) || element.hasAttribute(Attribute.TEXT_FALSE)) {
      const textTrue = safeGet(element, Attribute.TEXT_TRUE)!
      const textFalse = safeGet(element, Attribute.TEXT_FALSE)!

      return new BoolTextBinding(element, path, textTrue, textFalse)
    }
  }
}
