/**
 * Resolves the path within the passed object and returns the respective value.
 *
 * Note: The path can be passed in multiple ways
 *       1. string --> the separator within the path needs to be defined
 *       2. string[] --> no seperator needs to be defined
 *
 * @param path that shall be resolved within the object
 * @param obj for which the path shall be resolved
 * @param separator that is needed if the path is passed a string
 * @return value of the attribute of the passed path
 */
export function resolve(path: string | string[], obj: any = self, separator = '.') {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((previous: any, propertyName: string) => {
    if (previous === undefined) {
      return undefined
    }
    return previous[propertyName]
  }, obj)
}
