<template>
  <!--  This is a test template for development-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting"></SubmitOverlay>

      <Title>Mischersteuerung</Title>
      <Info>Stellen Sie hier den Modus und die Paramter des Mischers ein.</Info>
      <Group title="Übergeordnet">
        <enum-item
          :featureName="featureId"
          :map="[
            ['0', 'manual_off'],
            ['1', 'manual_on'],
            ['2', 'auto'],
          ]"
          :value="thing.features[featureId].properties.configuration.mode"
          displayName="Modus"
          propertyType="configuration"
          propertyName="mode"
          toolTip="Der Betriebsmodus des Mischers."
        />
      </Group>
      <Group title="Automatik Modus">
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.flowTemperatureDay`"
          displayName="Vorlauftemperatur (Tag)"
          maxValue="100"
          minValue="0"
          propertyType="configuration"
          propertyName="flowTemperatureDay"
          toolTip="Die Soll-Vorlauftemperatur in °C am Tage."
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.flowTemperatureNight`"
          displayName="Vorlauftemperatur (Nacht)"
          maxValue="100"
          minValue="0"
          propertyType="configuration"
          propertyName="flowTemperatureNight"
          toolTip="Die Soll-Vorlauftemperatur in °C in der Nacht."
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.timeOn`"
          displayName="Zeit (EIN)"
          propertyType="configuration"
          propertyName="timeOn"
          toolTip="Zeitpunkt zum Einschalten in Sekunden."
        />
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.timeOff`"
          displayName="Zeit (AUS)"
          propertyType="configuration"
          propertyName="timeOff"
          toolTip="Zeitpunkt zum Ausschalten in Sekunden."
        />
      </Group>
      <Group title="Manueller Modus">
        <numeric-value-item
          :featureName="featureId"
          :valuePath="`features.${featureId}.properties.configuration.mixerPosition`"
          displayName="Mixer Position"
          propertyType="configuration"
          propertyName="mixerPosition"
          toolTip="Die Position des Mischers in Prozent."
        />
      </Group>
    </template>
  </div>
</template>

<script lang="ts">
  /*This code does not has to be touched when developing a new template except the ClassName*/
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'

  /*REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      TextItem,
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  /*REPLACE 'TestId' WITH A FITTING COMPONENT NAME*/
  export default class MixerControl extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style scoped lang="scss">
  /*The edit component can be styled here*/
</style>
