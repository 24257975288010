import type IUnitHandler from '@/modules/units/handlers/IUnitHandler'

export class AddressHandler implements IUnitHandler {
  format(_type: string, _unit: string, value: any): string {
    if (value == null || typeof value !== 'object') {
      return ''
    }

    let address = ''
    if ((value['street'] ?? '') !== '') {
      address += value.street + (value.number != undefined ? ' ' + value.number : '') + ', '
    }
    if ((value['postalcode'] ?? '') !== '') {
      address += value.postalcode + ' '
    }
    if ((value['city'] ?? '') !== '') {
      address += value.city
    }
    if ((value['country'] ?? '') !== '') {
      address += ', ' + value.country
    }

    return address
  }

  formatUnit(): string {
    return ''
  }

  isSupported(type: string): boolean {
    return type == 'Address'
  }

  scaleValue(value: number, scalingFactor: number): number {
    return value
  }

  getScaling(
    xValues: [string, number | null][][],
    unit: string,
  ): { best: any; scalingFactor: number } {
    return { best: { unit: unit }, scalingFactor: 1 }
  }

  isDifferentiable(): boolean {
    return false
  }
}
