import { SVGBinding } from '@/modules/svgbindings/SVGBinding'
import type { Thing } from '@/api/klempner/models/SiteListResponse'

/**
 * Binds a bool value to the visibility of the element
 */
export class BoolVisibilityBinding extends SVGBinding {
  private visibleWhen!: boolean

  /**
   *
   * @param domElement
   * @param valuePath
   * @param visibleWhen defines in which case the element is visible, i.e. if it should be visible when the value is true or false
   */
  constructor(domElement: Element, valuePath: string, visibleWhen: boolean) {
    super(domElement, valuePath)
    this.visibleWhen = visibleWhen
  }

  async updateBinding(data: Thing[]): Promise<void> {
    const value = this.getReferencedValue(data)?.value
    const isVisible = this.visibleWhen == value
    const visibleValue = isVisible ? 'visible' : 'hidden'

    this.element.setAttribute('visibility', visibleValue)
  }
}
