export const TwinTranslationsDE = {
  digital_twins: 'Digitale Zwillinge',
  thingId: 'ID',
  policyId: 'Policy ID',
  definition: 'Definition',
  category: 'Kategorie',
  thing_size: 'Größe',
  thing_modified: 'Letzte Änderung',
  thing_created: 'Erstellt',
  uncategorized: 'Ohne Kategorie',
  status: 'Status',
  configuration: 'Konfiguration',
  serial_no: 'Seriennummer',
  serial_number: 'Seriennummer',
  error_loading_thing: 'Thing konnte nicht geladen werden: {error}',
  error_loading_twins: 'Digitale zwillinge konnten nicht geladen werden: {error}',
  copied_to_clipboard: 'Referenz in Zwischenablage kopiert',
  error_copy_to_clipboard: 'Referenz konnte nicht kopiert werden: {error}',
  edit_prop: 'Eigenschaft "{prop}" bearbeiten',
  command_prop: 'Befehl senden für "{prop}"',
  send_command_success: 'Befehl erfolgreich gesendet.',
  error_sending_command: 'Fehler beim Senden des Befehls',
  success_setting_property:
    'Eigenschaft "{propertyName}" erfolgreich auf den Wert "{newValue}" geändert.',
  error_setting_property: 'Eigenschaft konnte nicht geändert werden: {error}',
  copy_reference: 'Referenz kopieren',
  send_command: 'Befehl senden',
  send_command_in_progress: 'Befehl wird gesendet...',
  location_picker_info:
    'Auf die Karte clicken, um Marker zu platzieren - Marker ziehen um Ort zu ändern - Rechtsclick auf den Marker zum löschen - Mausrad oder +/- für Zoom',
  download_history: 'Historie herunterladen',
  download: 'Herunterladen',
  selected_datapoint: 'Ausgewählter Datenpunkt',
  download_options: 'Download-Optionen',
  path: 'Pfad',
  error_getting_csv: 'Historische Daten konnten nicht heruntergeladen werden: {error}',

  // Confirm modal
  change_value: 'Wertänderung',
  change_comment: 'Grund für die Änderung',
  change_value_to:
    'Soll <b>{property}</b> von <b>{feature}</b> von <b>{oldValue}</b> auf <b>{newValue}</b> geändert werden?',
  execute: 'Ausführen',
  error_writing_events_log: 'Die Änderung konnte nicht im Änderungs-Log festgehalten werden.',
  month_names: {
    1: 'Januar',
    2: 'Februar',
    3: 'März',
    4: 'April',
    5: 'Mai',
    6: 'Juni',
    7: 'Juli',
    8: 'August',
    9: 'September',
    10: 'Oktober',
    11: 'November',
    12: 'Dezember',
  },
  weekday_names: {
    1: 'Montag',
    2: 'Dienstag',
    3: 'Mittwoch',
    4: 'Donnerstag',
    5: 'Freitag',
    6: 'Samstag',
    7: 'Sonntag',
  },
  twins: {
    sort_by: 'Sortieren nach',
    sort_by_name: 'Name',
    sort_by_unit: 'Einheit',
  },
}
