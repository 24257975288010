import type { Comparator } from '@/components/site/alerts/wizard/models/AlertRule'
import type { AlertDTO } from '@/components/site/alerts/wizard/models/Alert'
import Alert from '@/components/site/alerts/wizard/models/Alert'
import { CascadeAlertRule } from '@/components/site/alerts/wizard/models/CascadeAlertRule'
import type { SeverityLevel } from '@/api/alarm/models/dto/SeverityLevel'
import type { AlertFragment } from '@/generated/graphql'

export default class CascadeAlert extends Alert {
  static default() {
    return new CascadeAlert([], '', 0)
  }

  getType(): 'cascade' {
    return 'cascade'
  }

  static fromFragment(customAlert: AlertFragment['custom_alert']) {
    if (customAlert == null) {
      throw new TypeError('Alert cannot be null/undefined!')
    }
    let rules: CascadeAlertRule[] = []
    if (Array.isArray(customAlert.params)) {
      rules = customAlert.params.map(CascadeAlertRule.fromDTO) ?? []
    }
    return new CascadeAlert(rules, customAlert.name, customAlert.id)
  }
}

export interface CascadeAlertDto extends AlertDTO {
  type: 'cascade'

  params: {
    timespan: number
    severity: SeverityLevel
    propertyPath: string
    threshold: number | [number, number] | boolean | null
    comparator: Comparator
    function: 'mean' | 'max' | 'min' | 'derivative' | 'last' | 'value'
  }[]
}
