import ThingNode from '@/components/app/SiteThingsTree/ThingNode'
import FeatureNode from '@/components/app/SiteThingsTree/FeatureNode'
import type SiteTreeNode from '@/components/app/SiteThingsTree/SiteTreeNode'
import PropertyNode from '@/components/app/SiteThingsTree/PropertyNode'
import { compareFeaturePropertyUnits } from '@/components/app/SiteThingsTree/PropertySortingHelpers'
import PropertyTranslationService from '@/modules/translations/PropertyTranslationService'
import type { PropertyType } from '@/@types/Things'
import Sugar from 'sugar'
import type { Thing } from '@/api/klempner/models/SiteListResponse'
import type { Feature } from '@/api/klempner/models/SiteListResponse'

export type SelectionLevels = 'all' | 'properties' | 'none'

export type PropertyNodeFilter = (node: PropertyNode) => boolean

export interface DittoFormatter {
  getDisplayNameWithTypeForThing(thing: Thing): string

  getDisplayNameForFeature(model: string | undefined, feature: Feature): string

  getDisplayNameForProperty(
    model: string | undefined,
    feature: string,
    propertyType: string,
    propertyName: string,
    fallback?: string,
  ): string
}

export default class TreeGenerator {
  /**
   * Creates a new instance of a tree formatter
   * @param dittoFormatter Handles formatting of display names
   * @param translate Handles translation
   */
  constructor(
    private readonly dittoFormatter: DittoFormatter
  ) {}

  private static compareNodes(a: SiteTreeNode, b: SiteTreeNode) {
    return a.text.localeCompare(b.text)
  }

  /**
   * Generates a tree structure for a list of ditto things
   * @param things Array of things to map to tree
   * @param selectionLevels Determines which nodes are selectable
   * @param depth Maximum depth for tree generation
   * @param filter Optional filter to only show some property nodes
   */
  public generateSiteTree(
    things: Thing[],
    selectionLevels: SelectionLevels,
    depth = 3,
    filter: PropertyNodeFilter | null = null,
  ) {
    let thingNodes = things
      .map((thing: Thing) => {
        const children = this.getFeatureNodes(thing, selectionLevels, depth - 1, filter)

        return new ThingNode(
          this.dittoFormatter.getDisplayNameWithTypeForThing(thing),
          thing,
          children,
          selectionLevels == 'all',
        )
      })
      .sort(TreeGenerator.compareNodes)

    if (selectionLevels !== 'all') {
      thingNodes = thingNodes.filter((node) => node.children.length > 0)
    }
    return thingNodes
  }

  /**
   * Returns feature nodes for all features of a thing
   * @param thing The thing to iteratively generate child nodes for
   * @param selectionLevels Determines which nodes are selectable
   * @param depth Maximum depth for tree generation
   * @param filter Optional filter to show only some property nodes
   */
  private getFeatureNodes(
    thing: Thing,
    selectionLevels: SelectionLevels,
    depth: number,
    filter: PropertyNodeFilter | null = null,
  ): FeatureNode[] {
    if (depth == 0) {
      return []
    }
    const model = PropertyTranslationService.getModelFromDittoDefinition(thing.definition)
    let result = Object.values(thing.features).map((feature) => {
      const children = this.getChildrenForFeature(
        model,
        feature,
        thing.id,
        selectionLevels,
        depth - 1,
        filter,
      )

      const displayname = this.dittoFormatter.getDisplayNameForFeature(model, feature)

      return new FeatureNode(
        displayname,
        thing.id,
        feature.name,
        thing.description ?? '',
        children,
        selectionLevels == 'all',
      )
    })
    if (depth > 1) {
      result = result.filter((feature) => feature.children.length > 0)
    }

    return result.sort(TreeGenerator.compareNodes)
  }

  /**
   * Returns status and configuration property nodes for a feature
   * @param model Model the feature belongs to, required for correct translations
   * @param feature The feature object to extract properties from
   * @param featureName The name of the feature
   * @param thingId The id of the thing of the feature
   * @param metadata
   * @param selectionLevels
   * @param depth
   * @param filter Optional filter to only show some nodes
   */

  private getChildrenForFeature(
    model: string | undefined,
    feature: Feature,
    thingId: string,
    selectionLevels: SelectionLevels,
    depth: number,
    filter: PropertyNodeFilter | null = null,
  ): PropertyNode[] {
    if (depth == 0) {
      return []
    }

    let statusNodes: PropertyNode[] = this.getPropertyNodes(
      model,
      feature,
      thingId,
      selectionLevels,
      'status',
    )

    let configNodes: PropertyNode[] = this.getPropertyNodes(
      model,
      feature,
      thingId,
      selectionLevels,
      'configuration',
    )

    if (filter != null) {
      statusNodes = statusNodes.filter(filter)
      configNodes = configNodes.filter(filter)
    }
    return [...statusNodes, ...configNodes].sort((a, b) =>
      compareFeaturePropertyUnits(a.text, a.data.dropData.unit, b.text, b.data.dropData.unit),
    )
  }

  /**
   * Extract lowest-level nodes (status/config values)
   * @param model Name of the model the feature belongs to
   * @param data Data for nodes
   * @param metadata Metadata to return only entries which have a unit set
   * @param thingId Used for referencing the value in ditto
   * @param featureName Used for referencing the value in ditto
   * @param selectionLevels Used to determine if the node should be selectable
   * @param propertyType whether the nodes are config or status properties
   */

  private getPropertyNodes(
    model: string | undefined,
    feature: Feature,
    thingId: string,
    selectionLevels: SelectionLevels,
    propertyType: PropertyType,
  ) {
    if (!(propertyType in feature.properties)) {
      return []
    }
    return Object.entries(feature.properties[propertyType]).map(([propertyName, value]) => {
      return new PropertyNode(
        this.dittoFormatter.getDisplayNameForProperty(
          model,
          feature.name,
          propertyType,
          propertyName,
          Sugar.String.titleize(propertyName),
        ),
        thingId,
        feature.name,
        propertyType,
        propertyName,
        selectionLevels == 'properties' || selectionLevels == 'all',
        value.value_unit,
        value.value_type,
        value.value,
      )
    })
  }
}
