<template>
  <b-modal
    :id="'thermal-storage-config-modal-' + currentSiteId"
    :cancelTitle="$t('cancel')"
    :okTitle="$t('scada.rete_tab.storage.create')"
    :title="$t('scada.rete_tab.storage.modal_title')"
    size="xl"
    :okDisabled="!isValid"
    @cancel="cancelModalExplicitly"
    @hidden="cancelModal"
    @ok="createNode"
  >
    <div class="thermal-storage-config-modal d-flex align-items-start">
      <ThermalStorage
        :sensorPositionsAndValues="sensorPositionsAndValues"
        class="thermal-storage my-3"
      />
      <div class="sensor-config-list p-2">
        <template v-if="sensors.length > 0">
          <p class="grid-header">{{ $t('scada.rete_tab.storage.value') }}</p>
          <p class="grid-header">{{ $t('scada.rete_tab.storage.sensor_position') }}</p>
          <p class="grid-header"></p>
        </template>
        <template v-for="(sensor, index) in sensors">
          <SiteTreeDropdown
            :key="`dropdown_${index}`"
            :placeholder="$t('select_thing')"
            :selectedThing="sensor.propertyPath"
            class="align-self-center"
            selectionLevels="properties"
            :filter="onlyTemperaturePropertiesFilter"
            @thing-changed="updateSensor(index, $event)"
          />
          <b-input-group :key="`input_group_${index}`" append="%" size="sm">
            <b-input
              :max="100"
              :min="0"
              :step="1"
              :value="sensors[index].positionInPercent"
              type="number"
              @change="updateSensorPosition(index, $event)"
            />
          </b-input-group>
          <b-button :key="index" class="p-0" size="sm" variant="link" @click="removeSensor(index)">
            <span class="material-icons">cancel</span>
          </b-button>
        </template>
        <div class="add-sensor-row">
          <b-button block size="sm" variant="outline-primary" @click="addSensor">
            <span class="material-icons">add_box</span>
            {{ $t('scada.rete_tab.storage.add_sensor') }}
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="!isValid" class="invalid-feedback d-block text-right">
      {{ $t('scada.rete_tab.storage.modal_invalid_warning') }}
    </div>
  </b-modal>
</template>

<script lang="ts">
  import { Component, Prop } from 'vue-property-decorator'
  import ThermalStorage from '@/components/site/scada/editor-components/rete_components/ThermalStorage.vue'
  import SiteThingsTree from '@/components/app/SiteThingsTree.vue'
  import SiteTreeDropdown from '@/components/app/SiteThingsTree/SiteTreeDropdown.vue'
  import Logger from '@/logger'
  import { TransferData } from '@/components/site/scada/ScadaEditor.vue'
  import type ThingReference from '@/components/shared/ThingReference'
  import Sugar from 'sugar'
  import type PropertyNode from '@/components/app/SiteThingsTree/PropertyNode'
  import type { BvMsgBoxData } from 'bootstrap-vue'
  import { mixins } from 'vue-class-component'
  import ApiMixin from '@/modules/tools/ApiMixin'

  /**
   * Configuration for a temperature sensor attached to a Thermal Storage module
   */
  export interface TemperatureSensor {
    /** Where to find the value that should be used/displayed for the sensor */
    propertyPath: ThingReference | null

    /**
     * Vertical positioning of the sensor inside the thermal storage as a value in the range [0, 1], where
     * 0: all the way at the bottom
     * 1: all the way at the top
     */
    positionInPercent: number
  }

  /** DTO for the TemperatureSensor that can be used to represent a sensor in the node.data */
  export interface TemperatureSensorDTO {
    position: number

    dittoPath: string
  }

  const SENSOR_DEFAULT_VALUES = [
    { positionInPercent: 100, propertyPath: null },
    { positionInPercent: 66, propertyPath: null },
    { positionInPercent: 33, propertyPath: null },
    { positionInPercent: 0, propertyPath: null },
  ]
  @Component({
    components: { SiteTreeDropdown, SiteThingsTree, ThermalStorage },
  })
  export default class ThermalStorageConfigurationModal extends mixins(ApiMixin) {
    @Prop()
    readonly currentSiteId!: string

    @Prop()
    private transferData!: TransferData

    private onlyTemperaturePropertiesFilter = (node: PropertyNode) => {
      return node.data.dropData.unit === 'C'
    }

    private sensors: TemperatureSensor[] = [...SENSOR_DEFAULT_VALUES]

    private sensorValues: (number | null)[] = []

    get sensorPositionsAndValues() {
      return this.sensors.map((sensor, index) => ({
        position: sensor.positionInPercent,
        value: this.sensorValues[index],
      }))
    }

    addSensor() {
      this.sensors.push({ propertyPath: null, positionInPercent: 0 })
      this.sensorValues.push(null)
    }

    updateSensor(index: number, dittoReference: ThingReference | null) {
      this.sensors.splice(index, 1, { ...this.sensors[index], propertyPath: dittoReference })
      if (dittoReference?.thingId == null) {
        this.sensorValues.splice(index, 1, null)
      } else {
        this.fetchSensorValue(index, dittoReference)
      }
    }

    updateSensorPosition(index: number, value: string) {
      this.sensors.splice(index, 1, {
        propertyPath: this.sensors[index].propertyPath,
        positionInPercent: Number(value),
      })
    }

    async fetchSensorValue(index: number, dittoReference: ThingReference) {
      if (
        dittoReference.thingId === undefined ||
        dittoReference.feature === undefined ||
        dittoReference.propertyType === undefined ||
        dittoReference.featureProperty === undefined
      ) {
        return
      }
      try {
        const thing = await this.getThingApi().fetchById(dittoReference.thingId)
        const result = thing.features?.[dittoReference.feature]?.properties[
          dittoReference.propertyType
        ]?.[dittoReference.featureProperty].value as number | undefined
        if (result === undefined) {
          return
        }
        this.sensorValues.splice(index, 1, result)
      } catch (e) {
        Logger.error(
          `Couldn't get value for sensor at index ${index} (${dittoReference?.toRefString()})`,
          e,
        )
      }
    }

    removeSensor(index: number) {
      this.sensors.splice(index, 1)
      this.sensorValues.splice(index, 1)
    }

    private resetModal() {
      this.sensors = [...SENSOR_DEFAULT_VALUES]
      this.sensorValues = new Array(SENSOR_DEFAULT_VALUES.length).fill(null)
    }

    get isValid(): boolean {
      for (const sensor of this.sensors) {
        if (sensor.propertyPath == null || sensor.propertyPath.thingId == undefined) {
          return false
        }
      }
      return true
    }

    createNode() {
      this.transferData.data.sensors = this.sensors.map((sensor) => {
        return {
          position: sensor.positionInPercent,
          dittoPath: sensor.propertyPath?.toRefString() ?? '',
        }
      })
      this.$emit('create-node')
      this.resetModal()
    }

    private async cancelModal() {
      if (this.modalCanceledExplicitly) {
        this.modalCanceledExplicitly = false
      } else {
        if (this.thermalStorageUpdated()) {
          const closeWithoutSaving: BvMsgBoxData = await this.$bvModal.msgBoxConfirm(
            this.$t('scada.rete_tab.storage.discard_changes')!,
            {
              id: 'confirm-discard-thermal-storage-changes-' + this.currentSiteId,
              title: this.$t('scada.rete_tab.storage.leave_unsaved')! + '?',
              okVariant: 'primary',
              okTitle: this.$t('discard_changes_short')!,
              cancelTitle: this.$t('cancel')!,
              centered: true,
            },
          )

          if (!closeWithoutSaving) {
            this.$bvModal.show('thermal-storage-config-modal-' + this.currentSiteId)
            return // we do not want to reset the modal
          }
        }
      }
      this.resetModal()
    }

    private modalCanceledExplicitly: boolean = false

    private cancelModalExplicitly() {
      this.modalCanceledExplicitly = true
    }

    /**
     * @returns true if the storage was updated _(Note: as the thermal storage is not editable yet compared to the {@link SENSOR_DEFAULT_VALUES})_
     */
    private thermalStorageUpdated(): boolean {
      return !Sugar.Object.isEqual(this.sensors, SENSOR_DEFAULT_VALUES)
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  .thermal-storage-config-modal {
    overflow-x: auto;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: start;
    row-gap: 0.5 * $spacer;

    .thermal-storage {
      height: 300px;
      min-width: 200px;

      svg {
        max-height: 100%;
      }
    }
  }

  .sensor-config-list {
    display: grid;
    column-gap: 0.5 * $spacer;
    row-gap: 0.75 * $spacer;
    grid-template-columns: min-content 14ch min-content;
    justify-items: start;
    align-items: center;
  }

  .add-sensor-row {
    grid-column: 1/3;
    white-space: nowrap;
    justify-self: stretch;
  }

  .grid-header {
    font-weight: bold;
    margin: 0;
  }
</style>
