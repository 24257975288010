import type { RawAxiosRequestConfig } from 'axios'
import axios from '@/api/keycloak/KeycloakAxiosInstance'
import { config } from '@/config'
import type KeycloakUser from '@/api/keycloak/models/KeycloakUser'

/**
 * Provides methods to get and update account info for a user that is not an admin in keycloak
 */
export default class KeycloakAccountClient {
  /**
   * Get user info for the currently logged in account
   * @param realm
   * @param token
   */
  async getAccountInfo(realm: string, token: string): Promise<KeycloakUser> {
    const requestConfig: RawAxiosRequestConfig = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
      },
      url: `${config.keycloak.url}/realms/${realm}/account`,
    }

    return (await axios(requestConfig)).data
  }

  /**
   * Update user info for currently logged in account
   * @param user
   * @param realm
   * @param token
   */
  async updateAccountInfo(user: KeycloakUser, realm: string, token: string) {
    const requestConfig: RawAxiosRequestConfig = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
      },
      url: `${config.keycloak.url}/realms/${realm}/account`,
      data: user,
    }
    axios(requestConfig)
  }
}
