import { action, createModule, extractVuexModule } from 'vuex-class-component'
import type KeycloakUser from '@/api/keycloak/models/KeycloakUser'
import KeycloakAccountClient from '@/api/keycloak/KeycloakAccountClient'
import KeycloakApiClient from '@/api/keycloak/KeycloakApiClient'

const UserInfoModule = createModule({
  namespaced: 'userInfo',
})

/**
 * Stores the profile information (contact info, attributes etc.) of one arbitrary user
 */
export class UserInfoStore extends UserInfoModule {
  user: KeycloakUser | null = null

  private accountClient: KeycloakAccountClient = new KeycloakAccountClient()

  get hasPhoneNumber(): boolean {
    return (
      this.user !== null &&
      this.user.attributes?.phone?.[0] !== undefined &&
      this.user.attributes.phone[0] !== ''
    )
  }

  get hasSmsNumber(): boolean {
    return (
      this.user !== null &&
      this.user.attributes?.sms?.[0] !== undefined &&
      this.user.attributes.sms[0] !== ''
    )
  }

  @action
  async loadUserInfo(payload: {
    id: string
    isOwnUserInfo: boolean
    realm: string
    token: string
  }) {
    if (payload.isOwnUserInfo) {
      this.user = await this.accountClient.getAccountInfo(payload.realm, payload.token)
    } else {
      const kcApi = new KeycloakApiClient(payload.realm)
      this.user = await kcApi.getUserById(payload.id, payload.token)
    }
  }

  @action
  async saveUserInfo(payload: {
    id: string
    isOwnUserInfo: boolean
    realm: string
    token: string
  }) {
    if (payload.isOwnUserInfo) {
      await this.accountClient.updateAccountInfo(this.user!, payload.realm, payload.token)
    } else {
      const kcApi = new KeycloakApiClient(payload.realm)
      await kcApi.updateUser(this.user!, payload.token)
    }
  }
}

export const userInfo = extractVuexModule(UserInfoStore)
