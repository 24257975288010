import { SVGBinding } from '@/modules/svgbindings/SVGBinding'
import type { Thing } from '@/api/klempner/models/SiteListResponse'

/**
 * Binds a bool value to two different text options for true and false.
 * The text options refer to an id in the translation file.
 */
export class BoolTextBinding extends SVGBinding {
  private trueText: string
  private falseText: string

  /**
   *
   * @param domElement
   * @param valuePath
   * @param trueText text that is shown when the bound value is true
   * @param falseText text that is shown when the bound value is false
   */
  constructor(domElement: Element, valuePath: string, trueText: string, falseText: string) {
    super(domElement, valuePath)
    this.trueText = trueText
    this.falseText = falseText
  }

  async updateBinding(data: Thing[]): Promise<void> {
    //TODO: Get translated text with the id
    const value = this.getReferencedValue(data)
    this.element.textContent = value ? this.trueText : this.falseText
  }
}
