<template>
  <b-dropdown
    v-if="isOthermoUser"
    boundary="window"
    class="flat-dropdown"
    noCaret
    :right="$screen.lg"
    variant="primary"
  >
    <template #button-content>
      <div class="d-flex justify-content-between d-lg-block">
        <span>Realm wechseln</span>
        <span class="material-icons align-text-top">expand_more</span>
      </div>
    </template>
    <div class="dropdown-body">
      <b-dropdown-text v-if="realms.length === 0">Realms werden geladen...</b-dropdown-text>
      <b-dropdown-item-button
        v-for="realm in realms"
        v-else
        :key="realm.id"
        class="d-flex flex-column"
        @click="jumpToRealm(realm.id)"
      >
        <div>{{ realm.name }}</div>
        <div class="text-muted smaller">{{ realm.id }}</div>
      </b-dropdown-item-button>
    </div>
  </b-dropdown>
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import Logger from '@/logger'
  import { config } from '@/config'

  @Component({})
  export default class RealmSwitcher extends Vue {
    @Prop()
    readonly isOthermoUser!: boolean

    realms: { name: string; id: string }[] = []

    created() {
      if (this.isOthermoUser) {
        this.fetchRealms()
      }
    }

    jumpToRealm(realmNumber: string) {
      Vue.$cookies.set('realm', realmNumber)
      this.$router.push({ path: '/' })
      window.location.reload()
    }

    /** Fetches the list of available realms from https://iot.othermo.de/api/auth/realms */
    private async fetchRealms() {
      try {
        const response = await fetch(`${config.api.klempner.url}realms/names`, {
          headers: { authorization: 'Bearer ' + this.$keycloak.token },
        })
        const realmDict = (await response.json()) as Record<string, string>
        this.realms = Object.entries(realmDict).map(([key, value]) => ({
          name: value,
          id: key,
        }))
      } catch (e) {
        Logger.error('Could not load realms for navbar', e)
      }
    }
  }
</script>

<style scoped lang="scss">
  .smaller {
    font-size: 0.8em;
  }

  .dropdown-body {
    max-height: calc(100vh - 6rem);
    overflow-y: auto;
  }
</style>
