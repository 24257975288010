export const notificationCenterEn = {
  notification_center: {
    title: 'Notifications',
    unread: 'New',
    dismissed: 'Read',
    group_by_site: 'Group by site',
    clear_all: 'Mark all as read',
    mark_read: 'Mark as read',
    mark_group_read: 'Mark group as read',
    no_notifications: 'No new notifications available',
    error: {
      mark_read: 'Notification could not be marked as read: {error}',
      fetching_notifications: 'Notifications could not be fetched: {error}',
      clear_all: 'Notifications could not be marked as read: {error}',
    },
    show_more: 'Show more',
  },
}
