import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Logger from '@/logger'
import ApiMixin from '@/modules/tools/ApiMixin'
import type { Site } from '@/api/klempner/models/SiteListResponse'
import type { SiteDetailParameters } from '@/api/klempner/apiClients/SiteFilterOptionsBuilder'

/**
 * Provides functionality related to retrieving the current site etc.
 */
@Component
export default class SiteMixin extends mixins(ApiMixin) {
  get currentSiteId() {
    return this.$route.params.id
  }

  async fetchCurrentSite(detailParameters?: SiteDetailParameters): Promise<Site | null> {
    const siteId = this.currentSiteId
    if (!siteId) {
      return null
    }
    try {
      return await this.getSiteApiClient().fetchById(siteId, detailParameters)
    } catch (e) {
      Logger.error('could not get site info', e)
      return null
    }
  }
}
