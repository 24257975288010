import type SiteTreeNode from '@/components/app/SiteThingsTree/SiteTreeNode'
import { SITE_TREE_MAX_NAME_LENGTH } from '@/components/app/SiteThingsTree/SiteTreeNode'
import { ellipsis } from '@/modules/tools/Filters'
import ThingReference from '@/components/shared/ThingReference'
import type { Thing } from '@/api/klempner/models/SiteListResponse'

export default class ThingNode implements SiteTreeNode {
  text: string

  children: SiteTreeNode[]

  state: unknown

  constructor(displayName: string, thing: Thing, children: SiteTreeNode[], selectable: boolean) {
    this.text = ellipsis(displayName, SITE_TREE_MAX_NAME_LENGTH)
    this.children = children

    const keywords = [
      thing.id,
      thing.description,
      thing.name,
      thing.serial_number,
      thing.manufacturer,
      thing.model,
      displayName,
    ]

    this.data = {
      keywords: keywords.join(','),
      type: 'thing',
      dropData: new ThingReference(thing.id, '', '', ''),
    }

    this.state = {
      expanded: false,
      selectable: selectable,
    }
  }

  keywords(thing: Thing) {
    return `${thing.id},${thing.description},${thing.name},${thing.serial_number},${thing.manufacturer},${thing.model},`
  }

  data: { dropData: ThingReference; type: string; keywords: string }
}
