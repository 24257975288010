export const alertTranslationsDe = {
  alerts: 'Alarme',
  alert_history: 'Historie',
  add_alert: 'Alarm hinzufügen',
  create_alert: 'Neuen Alarm hinzufügen',
  next: 'Weiter',
  back: 'Zurück',
  prev_page: 'Vorherige Seite',
  next_page: 'Nächste Seite',
  finish: 'Abschließen',
  step_2_description: 'Beschreibung',
  step_3_rules: 'Regeln',
  choose_alert_type: 'Alarm-Typ auswählen',
  custom_alerts: 'Benutzerdefinierte Alarme',
  alert_type: {
    cascade: 'Grenzwert-Alarm',
    control_deviation: 'Regelabweichung',
    hysteresis: 'Hysteresen-Alarm',
  },
  alert_group: {
    custom: 'Benutzerdefiniert',
    expert: 'Experte',
    device: 'Gerät',
  },
  error_loading_history: 'Anlagen-Historie konnte nicht geladen werden: {error}',
  discard_changes: 'Änderungen verwerfen und Wizard schließen?',
  selection: 'Auswahl',
  no_selection: 'Nichts ausgewählt',
  help_text_criteria: 'Wann soll der Alarm ausgelöst werden?',
  current_value: 'Aktueller Wert',
  alert_triggered_when: 'Alarm wir ausgelöst wenn:',
  avg_5_min: '5 Minuten Durchschnitt',
  avg_15_min: '15 Minuten Durchschnitt',
  avg_60_min: '60 Minuten Durchschnitt',
  smaller_than: 'kleiner als (<)',
  bigger_than: 'größer als (>)',
  threshold: 'Grenzwert',
  threshold_active: 'Grenzwert aktiv',
  alert_created: 'Alarm "{name}" erfolgreich erstellt',
  alert_updated: 'Alarm "{name}" erfolgreich aktualisiert',
  error_creating_alert: 'Alarm konnte nicht erstellt werden: {error}',
  truthy_value: 'True / Aktiv / High',
  falsy_value: 'False / Inaktiv / Low',
  error_no_value_in_series:
    'Für das ausgewählte Feature konnten keine Daten gefunden werden. Bitte wählen Sie ein anderes Feature aus, um einen Alarm zu erstellen.',
  error_unsupported_series:
    'Das ausgewählte Feature hat einen nicht unterstützten Datentyp. Bitte wählen Sie ein Feature mit numerischem oder booleschem Datentyp um einen Alarm zu erstellen.',
  help_text_severity: 'Beschreibung und Dringlichkeit festlegen',
  description: 'Beschreibung',
  optional: 'optional',
  severity: 'Dringlichkeit',
  severity_prev: 'Vorherige Dringlichkeit',
  value_new: 'Neuer Zustand',
  value_old: 'Vorheriger Zustand',
  //Severity levels
  severity_critical: 'Kritische Störung',
  severity_error: 'Störung',
  severity_warning: 'Warnung',
  severity_informational: 'Meldung',
  severity_ok: 'Ok',
  severity_normal: 'Normal',
  severity_acknowledged: 'Bestätigt',
  severity_indeterminate: 'Unbestimmt',
  severity_multiple: 'Mehrere',
  severity_info: 'Meldung',
  notification_delay_help_text:
    'Wie lange muss die Bedingung erfüllt sein bevor eine Benachrichtigung verschickt wird?',
  hours: 'Stunden',
  minutes: 'Minuten',
  seconds: 'Sekunden',
  help_text_recipients:
    'E-Mail-Adressen oder Telefonnummern der Empfänger für diesen Alarm (Eine Adresse/Nummer pro Zeile, Nummern mit Ländervorwahl)',
  actions: 'Aktionen',
  datapoint: 'Datenpunkt',
  error_loading_alerts: 'Alarme konnten nicht geladen werden.',
  error_changing_alert_activation: 'Die Aktivierung des Alarms konnte nicht verändert werden.',
  error_loading_activations: 'Alarm-Historie konnte nicht geladen werden: {error}',
  alert_id: 'Alarm ID',
  recipients: 'Empfänger',
  clear_filter: 'Zurücksetzen',
  apply_filter: 'Filter anwenden',
  found_alerts: '{count} Alarm gefunden:::{count} Alarme gefunden',
  last_change: 'Letzte Änderung',
  last_change_never: 'Keine Aktivierung seit Erstellung',
  activation_state: 'Alarm-Status',
  activated: 'Aktiv',
  inactive: 'Inaktiv',
  duration: 'Dauer',
  start_time: 'Auslösezeitpunkt',
  end_time: 'Endzeitpunkt',
  correlation_id: 'Correlation ID',
  confirm_delete_alert_part1: 'Alarm mit dem Namen ',
  confirm_delete_alert_part2: ' wirklich löschen?',
  confirm_delete_alert_group: 'Alle Alarme für {name} löschen?',
  active_only: 'Nur aktive',
  alerts_before: 'Bis',
  alerts_after: 'Von',
  no_alerts: 'Aktuell keine Alarme',
  include_device_notifications: 'Geräte-Meldungen',
  device_notification: 'Geräte-Meldung',
  details: 'Mehr Details',
  since: 'Aktiv seit',
  refresh_alerts: 'Aktualisieren',
  filter_thing: 'Nach Thing filtern',
  alert_value: 'Alarm-Wert',
  notification_delay: 'Benachrichtigungsverzögerung',
  edit_recipients: 'Empfänger bearbeiten',
  update_recipients_success: 'Empfänger erfolgreich geändert',
  update_recipients_error: 'Fehler beim ändern der Empfänger',
  site_access_full: 'Alle Anlagen',
  group_by_thing: 'Nach Thing gruppieren',
  alert_count: 'Alarme',
  alert_delete_failed: 'Alarm konnte nicht gelöscht werden',
  device: 'Geräte-Alarm',
  rule: 'Benutzerdefinierte Alarme',
  expert: 'Experten-Alarm',
  first_received: 'Erste Meldung',
  last_received: 'Letzte Meldung',
  reset: 'Zurücksetzen',
  status: 'Zustand',

  //Muting
  mute_site: 'Diese Anlage stummschalten',
  unmute_site: 'Stummschaltung aufheben',
  site_muted_until: 'Anlage ist stummgeschaltet bis {end}.',
  mute_status: 'Stumm',
  mute_status_long: 'Benachrichtigungen',
  muted: 'Stummgeschaltet',
  unmuted: 'Aktiviert',
  site_muted: 'Anlage stummgeschaltet',
  site_unmuted: 'Anlagen-Stummschaltung aufgehoben',
  unmute_all: 'Stummschaltung für Thing aufheben',
  mute_all: 'Thing stummschalten',
  edit_end: 'Ende bearbeiten',
  error_toggling_mute: 'Stummschaltung konnte nicht geändert werden',
  until: 'Bis',
  tomorrow: 'Morgen früh',
  next_week: 'Anfang der nächsten Woche',
  next_month: 'Anfang des nächsten Monats',
  custom_time: 'Benutzerdefinierte Zeit',
  error_toggling_site_mute: 'Anlage konnte nicht stummgeschaltet werden: {error}',
  error_getting_site_mute: 'Status der Anlage konnte nicht geladen werden: {error}',
  enableAlarm: 'Alarm aktivieren',
  disableAlarm: 'Alarm deaktivieren',
  enableThingAlarm: 'Alle Alarme zu diesem Thing aktivieren',
  disableThingAlarm: 'Alle Alarme zu diesem Thing deaktivieren',

  //Filter
  filter_by_severity: 'Nach Dringlichkeit filtern',
  filter_by_event: 'Nach Ereignis filtern',
  filter_by_type: 'Nach Typ filtern',
  showing_items: 'Einträge {start} - {end} von {total}',
  severityFilter_might_hide_sites:
    'Beim Filtern nach Dringlichkeiten werden Anlagen ohne Alarme in der Übersicht nicht angezeigt.',
  sites_without_alerts: 'Anlagen ohne Alarme',

  copy_id: 'ID in Zwischenablage kopieren',

  // Rule editor
  add_alarm: {
    actual_value: 'Ist-Wert',
    control_value: 'Soll-Wert',
    or: 'oder',
    when_control_deviation: 'Wenn die kumulierte Abweichung zwischen Ist- und Soll-Wert',
    threshold_mode_path: 'Datenpunkt',
    threshold_mode_value: 'Fester Wert',
  },
  when: 'Wenn',
  over: 'über',
  of: 'von',
  is: 'ist',
  set_status_to: 'setzte den Status auf',
  comp_equal: 'gleich',
  comp_less: 'kleiner als',
  comp_greater: 'größer als',
  comp_in_range: 'im Bereich',
  comp_out_of_range: 'nicht im Bereich',
  last_xm: 'die letzte Minute:::die letzten {count}min',
  last_xh: 'die letzte Stunde:::die letzten {count}h',
  selection_required: 'Es muss ein Datenpunkt ausgewählt werden',
  threshold_required: 'Grenzwerte dürfen nicht leer bleiben',
  active_threshold_required: 'Für aktive Grenzwerte muss ein Wert angegeben werden!',
  threshold_conflicts_explanation:
    'Die Grenzwerte der Dringlichkeiten sollten absteigenden Werten entsprechen!',
  no_threshold_defined: 'Es muss mindestens eine Grenzwertregel aktiviert werden!',
  hysteresis_not_defined:
    'Die Hysterese (Differenz zur ausgelösten Grenzwertregel, die zur Deaktivierung dieser erreicht werden muss) muss definiert werden!',
  add_rule: 'Regel hinzufügen',
  unsupported_type: 'Typ "{type}" des gewählten Datenpunktes wird nicht unterstützt',
  select_thing: 'Datenpunkt auswählen',
  hysteresis: 'Hysterese',

  //Subscriptions
  alert_subscriptions: 'Benachrichtigungseinstellungen',
  user: 'Benutzer',
  sms: 'SMS',
  phone: 'Anruf',
  opt_out: 'Keine',
  contact_details: 'Kontakt-Informationen',
  daily_summary: 'Tägliche Zusammenfassung',
  error_loading_subscriptions:
    'Benachrichtigungseinstellungen konnten nicht geladen werden: {error}',
  invalid_phone_number_feedback:
    'Es muss eine gültige Telefonnummer im E.164 Format (ohne Leerzeichen, mit Ländervorwahl) angegeben werden. Beispiel: +4915751234567',
  load_contact_info_error: 'Kontaktinformationen konnten nicht geladen werden: {error}',
  update_contact_info_success: 'Kontaktinformationen erfolgreich aktualisiert',
  update_contact_info_error: 'Kontaktinformationen konnten nicht aktualisiert werden: {error}',
  update_subscriptions_success: 'Benachrichtigungseinstellungen gespeichert',
  update_subscriptions_error:
    'Benachrichtigungseinstellungen konnten nicht gespeichert werden: {error}',
  no_subscriptions: 'Benachrichtigungseinstellungen konnten nicht geladen werden',
  warning_no_phone:
    'Für diesen Benutzer ist keine Telefonnummer eingetragen, Benachrichtigungen werden nicht zugestellt',
  warning_no_sms:
    'Für diesen Benutzer ist keine SMS-Nummer eingetragen, Benachrichtigungen werden nicht zugestellt',
  info_text_subscriptions:
    'Neben den standortübergreifenden Einstellungen können Sie zusätzlich Standortspezifische Benachrichtigungseinstellungen definieren.' +
    ' Standortspezifische Vorgaben haben Vorrang vor den allgemeinen Einstellungen.',
  info_text_subscriptions_all_sites:
    'Hier können Sie standortübergreifend definieren, wie Sie benachrichtigt werden wollen, wenn es zu einer Meldung von einer Anlage kommt.',
  subscriptions: {
    leave_unsaved_title: 'Seite verlassen ohne zu speichern?',
    leave_unsaved_text:
      'Seite verlassen und alle Änderungen an den Benachrichtigungseinstellungen verwerfen?',
  },
  form_feedback: {
    incompatible_units:
      'Einheit "{unit1}" des gewählten Datenpunktes ist nicht kompatibel mit "{unit2}".',
    type_not_supported:
      'Typ "{type}" des gewählten Datenpunktes wird bei diesem Alarm-Typen nicht unterstützt.',
  },
  alert_status: {
    true: 'Aktiv',
    false: 'Inaktiv',
  },

  help: {
    cascade: {
      choose:
        'Bei einem Grenzwert-Alarm werden eine oder mehrere Bedingungen überprüft, sobald eine Bedingung erfüllt ist, wird ein Alarm auf der ausgewählten Dringlichkeit aktiviert.\n' +
        '\n' +
        'Beispiele:\n' +
        '• Erstelle eine Störung, wenn die Vorlauftemperatur einen Grenzwert unterschreitet\n' +
        '• Erstelle eine Warnung, wenn der durchschnittliche Vorlaufdruck über 15 Minuten außerhalb eines Bereiches liegt\n' +
        '• Erstelle eine Warnung, wenn der tägliche Energiebedarf einen Grenzwert überschreitet\n' +
        '• Erstelle eine Warnung, wenn der Füllstand eines Tanks unter 50% fällt; ändere die Dringlichkeit zu Störung, wenn der Füllstand unter 25% fällt',
      edit: 'Bedingungen werden der Reihe nach geprüft. Sobald eine Bedingung erfüllt ist, wird der Alarm aktiviert und die restlichen Bedingungen nicht mehr geprüft.',
    },
    deviation: {
      choose:
        'Bei einem Regelabweichungs-Alarm wird ein Datenpunkt mit einem Sollwert (Datenpunkt oder fester Wert) verglichen. Liegt die Abweichung für einen gewissen Zeitraum über einer einstellbaren Grenze, wird ein Alarm erstellt.',
    },
    hysteresis: {
      choose:
        'Der Alarm wird ausgelöst, wenn der gewählte Messwert für einen bestimmten Zeitraum unter eine Schwelle fällt. Der Alarm wird erst dann wieder aufgehoben, sobald er wieder über seinem Zielwert liegt.',
    },
  },
}
