<template>
  <h4 class="pb-0 mb-3">
    <slot></slot>
  </h4>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'

  /**
   * This component can be used as a heading for a section of an edit template
   */
  @Component
  export default class Heading extends Vue {}
</script>

<style lang="scss" scoped>
  @import 'src/vars';
</style>
