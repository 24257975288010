import { Component, Vue } from 'vue-property-decorator'
import Logger from '@/logger'
import KeycloakTools from '@/modules/tools/KeycloakTools'

/**
 * Provides functionality related to authentication
 */
@Component
export default class AuthMixin extends Vue {
  get token() {
    if (!this.$keycloak.authenticated || KeycloakTools.isKeycloakTokenExpired(this.$keycloak)) {
      Logger.warn('Keycloak not authenticated or token expired')
      window.location.href = this.$keycloak.createLoginUrl()
    }
    return this.$keycloak.token
  }
}
