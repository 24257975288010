import { render, staticRenderFns } from "./Divider.vue?vue&type=template&id=5dc78bea&scoped=true&"
import script from "./Divider.vue?vue&type=script&lang=ts&"
export * from "./Divider.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dc78bea",
  null
  
)

export default component.exports