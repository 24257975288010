import type DomVisitor from '@/modules/svgbindings/DomVisitor'
import { Attribute } from '@/modules/svgbindings/BindingParser'

/**
 *  Checks if the visited element has a link to another tab
 */
export default class SvgLinkVisitor implements DomVisitor {
  private results: Element[] = []

  collectResults(): Element[] {
    return this.results
  }

  visit(element: Element, path: string): void {
    if (element.hasAttribute(Attribute.SVGLINK)) {
      this.results.push(element)
    }
  }
}
