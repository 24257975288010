<template>
  <div v-if="editMode" :key="node.id" class="p-2 comment">
    <b-textarea v-model="comment" noResize size="sm"></b-textarea>
  </div>
  <div v-else :key="node.id" class="p-2 comment">
    {{ comment }}
  </div>
</template>

<script lang="ts">
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  // @ts-ignore
  import VueRender from 'rete-vue-render-plugin'
  import type { Node } from 'rete'
  import { vxm } from '@/store/store'

  @Component
  export default class ScadaTextlabel extends mixins(VueRender.mixin) {
    declare node: Node

    get editMode() {
      return vxm.scadaEditor.editMode
    }

    get comment(): string {
      return (this.node.data.comment as string) ?? ''
    }

    set comment(value: string) {
      this.$set(this.node.data, 'comment', value)
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';
  .comment {
    border: thin solid $gray-400;
    white-space: pre-line;
    background: #fff;
    user-select: none;

    textarea {
      min-width: 50ch;
      font-size: $font-size-scada-editor;
    }
  }
</style>
