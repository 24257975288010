import { action, createModule, extractVuexModule, mutation } from 'vuex-class-component'
import { AlertRule } from '@/components/site/alerts/wizard/models/AlertRule'
import CascadeAlert from '@/components/site/alerts/wizard/models/CascadeAlert'
import Alert from '@/components/site/alerts/wizard/models/Alert'
import ControlDeviationAlert from '@/components/site/alerts/wizard/models/ControlDeviationAlert'
import { ControlDeviationAlertRule } from '@/components/site/alerts/wizard/models/ControlDeviationAlertRule'
import HysteresisAlert from '@/components/site/alerts/wizard/models/HysteresisAlert'
import { HysteresisDownAlertRule } from '@/components/site/alerts/wizard/models/HysteresisDownAlertRule'

const WizardModule = createModule({
  namespaced: 'alertwizard',
})

export type Recipients = { emails: string[] | null; numbers: string[] | null }

export class AlertWizardStore extends WizardModule {
  mode: 'edit' | 'new' = 'new'

  /**
   * The type of alert that is being created
   */
  selectedAlertType = 'cascade'

  alertName: string = ''

  alertRules: (AlertRule | HysteresisDownAlertRule)[] = []

  initialAlertName: string = ''

  initialAlertRules: (AlertRule | HysteresisDownAlertRule)[] = []

  /**
   * Contains the ID of the alert being edited
   */
  private alertId: number | null = null

  get alert() {
    if (this.selectedAlertType == 'cascade') {
      return new CascadeAlert(this.alertRules, this.alertName, this.alertId ?? 0)
    } else if (this.selectedAlertType === 'controlDeviation') {
      return new ControlDeviationAlert(this.alertRules, this.alertName, this.alertId ?? 0)
    } else {
      return new HysteresisAlert(this.alertRules, this.alertName, this.alertId ?? 0)
    }
  }

  get alertValid() {
    return this.alert.isValid
  }

  /**
   * Whether unsaved changes exist in the wizard that need to be discarded on close
   */
  get hasUnsavedChanges(): boolean {
    if (
      this.alertName !== this.initialAlertName ||
      this.alertRules.length !== this.initialAlertRules.length
    ) {
      return true
    }
    for (let i = 0; i < this.alertRules.length; i++) {
      if (this.alertRules[i] !== this.initialAlertRules[i]) {
        return true
      }
    }
    return false
  }

  /**
   * Adds a new rule to the list of cascade rules
   * @param rule
   */
  @mutation
  addCascadeAlertRule(rule: AlertRule) {
    if (this.selectedAlertType != 'cascade') {
      return
    }
    this.alertRules.push(rule)
  }

  /**
   * Removes the rule at the specified index
   * @param index
   */
  @mutation
  deleteCascadeAlertRule(index: number) {
    if (this.selectedAlertType != 'cascade') {
      return
    }
    this.alertRules.splice(index, 1)
  }

  @action
  async setSelectedAlertType(value: string) {
    this.selectedAlertType = value
    if (value === 'cascade') {
      this.alertRules = []
    } else if (value == 'controlDeviation') {
      this.alertRules = [ControlDeviationAlertRule.default()]
    } else {
      this.alertRules = [HysteresisDownAlertRule.default()]
    }
  }

  @action
  async edit(alert: Alert) {
    this.mode = 'edit'
    alert.convertPercentageFormat(true)
    this.updateValues(alert)
  }

  @action
  public async reset() {
    this.mode = 'new'
    this.selectedAlertType = 'cascade'
    this.updateValues(CascadeAlert.default())
  }

  /**
   * This mutation is needed to trigger the change detection for editing and creating alarms
   * @param updatedAlert
   */
  @mutation
  updateValues(updatedAlert: Alert | HysteresisAlert) {
    this.selectedAlertType = updatedAlert.getType()
    this.alertId = this.mode == 'edit' ? updatedAlert.id : null
    this.alertName = updatedAlert.name
    this.alertRules = [...updatedAlert.rules]

    this.initialAlertName = updatedAlert.name
    this.initialAlertRules = [...updatedAlert.rules]
  }
}

export const alertWizard = extractVuexModule(AlertWizardStore)
