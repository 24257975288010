<template>
  <div class="bell-with-counter mr-2">
    <b-button to="/notifications" variant="link primary">
      <span class="material-icons">notifications</span>
    </b-button>
    <b-badge v-if="count > 0" pill variant="warning" class="notification-counter" size="sm">
      {{ countText }}
    </b-badge>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class NotificationBell extends Vue {
    @Prop({ required: false, default: 0 })
    readonly count!: number

    get countText() {
      if (this.count > 99) {
        return '99+'
      }
      return this.count
    }
  }
</script>

<style lang="scss" scoped>
  .bell-with-counter {
    position: relative;
  }

  .notification-counter {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
