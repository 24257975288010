import { render, staticRenderFns } from "./EnumItem.vue?vue&type=template&id=4c88e56b&scoped=true&"
import script from "./EnumItem.vue?vue&type=script&lang=ts&"
export * from "./EnumItem.vue?vue&type=script&lang=ts&"
import style0 from "./EnumItem.vue?vue&type=style&index=0&id=4c88e56b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c88e56b",
  null
  
)

export default component.exports