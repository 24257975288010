import type SiteTreeNode from '@/components/app/SiteThingsTree/SiteTreeNode'
import { SITE_TREE_MAX_NAME_LENGTH } from '@/components/app/SiteThingsTree/SiteTreeNode'
import { ellipsis } from '@/modules/tools/Filters'
import ThingReference from '@/components/shared/ThingReference'

export default class PropertyNode implements SiteTreeNode {
  /**
   * Creates new node for the property level of the tree
   * @param displayName Name to show in ui
   * @param thingId Used to reference thing in ditto
   * @param featureName  Used to reference thing in ditto
   * @param propertyType configuration or status property
   * @param property Used to reference thing in ditto
   * @param selectable Whether this item is selectable
   * @param unit Physical unit of the series
   * @param dataType of the property, e.g. string, boolean, GeoLocation
   * @param value of the property
   */
  constructor(
    displayName: string,
    thingId: string,
    featureName: string,
    propertyType: 'configuration' | 'status',
    property: string,
    selectable: boolean,
    unit: string,
    dataType: string,
    value: any,
  ) {
    this.text = ellipsis(displayName, SITE_TREE_MAX_NAME_LENGTH)
    this.data = {
      type: 'property',
      keywords: displayName,
      dropData: new ThingReference(thingId, featureName, propertyType, property, unit, dataType),
      dataType: dataType,
      value: value,
    }

    this.state = {
      expanded: false,
      selectable: selectable,
    }
  }

  children: SiteTreeNode[] = []

  data: {
    dropData: ThingReference
    type: string
    keywords: string
    icon?: string
    unitSymbol?: string
    dataType?: string
    value?: any
  }

  state: any

  text: string
}
