<template>
  <div id="node">
    <inline-svg
      id="svg-content"
      :class="{ pointer: hasTemplate && !editMode }"
      :src="require(`@/assets/scada/${nodeDescriptor.svg}`)"
      :width="nodeDescriptor.width"
      height="100%"
      @loaded="svgLoaded"
    />
    <Socket
      v-for="(input, index) in inputs()"
      :key="`input_${index}`"
      v-socket:input="input"
      :left="retrievePositionFromDescriptor(true, index, 'left')"
      :name="input.name"
      :top="retrievePositionFromDescriptor(true, index, 'top')"
      type="input"
    />
    <Socket
      v-for="(output, index) in outputs()"
      :key="`output_${index}`"
      v-socket:output="output"
      :left="retrievePositionFromDescriptor(false, index, 'left')"
      :name="output.name"
      :top="retrievePositionFromDescriptor(false, index, 'top')"
      type="output"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Watch } from 'vue-property-decorator'
  //@ts-ignore
  import VueRender from 'rete-vue-render-plugin'
  import Socket from '@/components/site/scada/editor-components/Socket.vue'
  import { mixins } from 'vue-class-component'
  import type { DeviceDescriptor } from '@/components/site/scada/editor-components/KnownDevicesAndSockets'
  import type { Node, NodeEditor } from 'rete'
  import DeviceDescriptorUtil from '@/components/site/scada/editor-components/DeviceDescriptorUtil'
  import { parseBindings } from '@/modules/svgbindings/BindingParser'
  import type { SVGBinding } from '@/modules/svgbindings/SVGBinding'
  import type { Thing } from '@/api/klempner/models/SiteListResponse'
  import { vxm } from '@/store/store'

  @Component({
    components: { Socket },
  })
  export default class ScadaNode extends mixins(VueRender.mixin) {
    declare node: Node

    declare editor: NodeEditor

    protected bindings: SVGBinding[] = []

    get things(): Thing[] {
      return vxm.currentSite.things
    }

    get thingId(): string {
      return (this.node?.data?.thingId as string) ?? ''
    }

    updated() {
      window.setTimeout(() => {
        this.editor.view.updateConnections({ node: this.node })
      }, 1000)
    }

    get nodeDescriptor(): DeviceDescriptor {
      return (
        DeviceDescriptorUtil.findDeviceDescriptor(this.node.data.deviceDefinition as string) ?? {
          svg: '',
          inputs: [],
          outputs: [],
        }
      )
    }

    get editMode() {
      return vxm.scadaEditor.editMode
    }

    get hasTemplate() {
      return this.nodeDescriptor.commandResponseTemplate !== undefined
    }

    get styleObject() {
      if (!this.editMode && this.hasTemplate) {
        return { cursor: 'pointer' }
      } else {
        return {}
      }
    }

    @Watch('things', { deep: true })
    thingsUpdated() {
      for (const binding of this.bindings) {
        binding.updateBinding(this.things)
      }
    }

    svgLoaded(svg?: SVGElement) {
      if (svg != undefined && this.thingId != '') {
        this.bindings = parseBindings(svg, 'ditto://' + this.node.data.thingId)
      }
    }

    async mounted() {
      await this.node.update()
      this.editor.view.updateConnections({ node: this.node })
    }

    /**
     * Retrieves the left/top values from the device description to define the position of the sockets dynamically.
     *
     * CARE: This method assumes that the inputs and outputs in the KnownDevicesAndSockets Record have the same order as
     *       within the sockets!
     *
     * @param isInput if the socket is an input socket
     * @param index of the socket within the input/output variable of the device description
     * @param requiredValue the left/top attribute value that needs to be defined for the inline-svg
     */
    retrievePositionFromDescriptor(
      isInput: boolean,
      index: number,
      requiredValue: 'top' | 'left',
    ): string {
      let deviceDescription
      if (isInput) {
        deviceDescription = this.nodeDescriptor.inputs[index]
      } else {
        deviceDescription = this.nodeDescriptor.outputs[index]
      }

      if (requiredValue === 'top') {
        return deviceDescription.top
      } else {
        return deviceDescription.left
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../scada_node.scss';
  .pointer {
    pointer-events: all !important;
    cursor: pointer !important;
  }
</style>
