<template>
  <div class="spacer-bottom">
    <h5>{{ title }}</h5>
    <div class="grouped p-2">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import events from '@/events'

  /**
   * Groups Components together in edit template
   */
  @Component({
    name: 'GroupItem',
    components: { Heading },
  })
  export default class Group extends Vue {
    @Inject()
    submitEventBus!: Vue

    @Prop({ default: '' })
    title!: string

    /**
     * Pass trough the thingId of the template
     */
    get thingId() {
      //@ts-ignore
      return this.$parent.thingId
    }

    mounted() {
      this.submitEventBus.$emit(events.scada.templates.mounted)
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';
  @import '../item';

  .grouped {
    border: thin solid $othermo-grey;
    border-radius: $border-radius;
  }
</style>
