<template>
  <b-btn-group class="mr-2">
    <b-button
      v-b-tooltip.hover
      :disabled="loading"
      :title="toolTip"
      variant="primary"
      @click="sendMessage"
    >
      {{ displayName }}
    </b-button>
    <b-button v-if="loading || success || error" disabled>
      <b-spinner v-show="loading && !success && !error" small type="grow" />
      <span v-show="success" class="material-icons">done</span>
      <span v-show="error" class="material-icons">close</span>
    </b-button>
  </b-btn-group>
</template>

<script lang="ts">
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseItem from '@/components/site/scada/command_response/toolbox/base/BaseItem.vue'
  import ToolTip from '@/components/site/scada/command_response/toolbox/base/ToolTip.vue'
  import DittoMsgHandler from '@/components/site/scada/command_response/DittoMsgHandler'

  /**
   * Component for configuring a boolean value of a ditto thing.
   */
  @Component({
    name: 'ButtonItem',
    components: {
      ToolTip,
    },
  })
  export default class ButtonItem extends mixins(BaseItem) {
    loading = false

    error = false

    success = false

    handler = new DittoMsgHandler(this.$keycloak)

    get messageBody(): object | string {
      return ''
    }

    get commandBody(): any {
      return ''
    }

    /**
     * Sends the given message to Ditto to change a value of the thing
     */
    async sendMessage() {
      this.loading = true
      try {
        await this.handler.sendMessage(this.thingId, this.message)
        await this.$root.$emit('ditto-msg-sent')
        this.error = false
        this.success = true
        this.loading = false
      } catch (e) {
        this.error = true
        this.success = false
        setTimeout(this.resetState, 2000)
      }
    }

    private resetState() {
      this.loading = false
      this.error = false
      this.success = false
    }
  }
</script>

<style lang="scss" scoped></style>
