import { action, createModule, extractVuexModule } from 'vuex-class-component'
import { Site } from '@/api/klempner/models/SiteListResponse'
import type { Thing } from '@/api/klempner/models/SiteListResponse'

const SiteModule = createModule({
  namespaced: 'currentSite',
  strict: false,
})

/**
 * Stores current site and things belonging to that site
 */
export class CurrentSiteStore extends SiteModule {
  public site: Site | null = null

  private _things: Thing[] = []

  get things() {
    return this._things
  }

  set things(value: Thing[]) {
    this._things = value
  }

  @action
  async setSite(newSite: Site) {
    this.site = newSite
  }
}

export const currentSite = extractVuexModule(CurrentSiteStore)
