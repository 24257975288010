import type { Node } from 'rete'
import { Component } from 'rete'
import type { NodeData, WorkerInputs, WorkerOutputs } from 'rete/types/core/data'
import ScadaTextlabel from '@/components/site/scada/editor-components/rete_components/ScadaTextlabel.vue'
import type Vue from 'vue'
import { ScadaReteComponents } from '@/components/site/scada/editor-components/rete_components/rete_nodes/ReteNode'

export default class ScadaTextlabelComponent extends Component {
  declare data: {
    component: typeof Vue
  }

  constructor() {
    super(ScadaReteComponents.Comment)
    this.data.component = ScadaTextlabel
  }

  async builder(node: Node) {}

  worker(node: NodeData, inputs: WorkerInputs, outputs: WorkerOutputs, ...args: unknown[]) {}
}
