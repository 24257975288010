<template>
  <div :class="[type, name, small ? 'small' : ''] | kebab" :style="styleObject" class="socket" />
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class Socket extends Vue {
    @Prop({})
    readonly top!: string

    @Prop({})
    readonly left!: string

    @Prop()
    readonly bottom!: string

    @Prop()
    readonly right!: string

    @Prop()
    readonly type!: string

    @Prop()
    readonly name!: string

    @Prop({ required: false, default: false })
    readonly small!: boolean

    get styleObject() {
      const socketRadius = this.small ? '0.4rem' : '0.5rem'
      const calc = (value: string) => `calc(${value} - ${socketRadius})`
      return {
        ...(this.top != undefined ? { top: calc(this.top) } : {}),
        ...(this.left != undefined ? { left: calc(this.left) } : {}),
        ...(this.bottom != undefined ? { bottom: calc(this.bottom) } : {}),
        ...(this.right != undefined ? { right: calc(this.right) } : {}),
      }
    }

    mounted() {
      this.$emit('mounted')
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';
  @import 'scada_node';

  .socket {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    width: $socket-size;
    height: $socket-size;
    background: $socket-color;
    z-index: 2;
    box-sizing: border-box;

    &.small {
      width: $socket-size-small;
      height: $socket-size-small;
    }

    &:hover {
      border-width: 4px;
    }

    &.multiple {
      border-color: yellow;
    }
  }
</style>
