<template>
  <!--This Template shows a dialog with minimal configuration-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting" />

      <Title>{{ $t('command_and_response.digital_outputs_of') }} {{ thing.id }}</Title>
      <Info>{{ thing.features.DeviceDescription.properties.configuration.description }}</Info>
      <Group>
        <text-item
          :value="thing.features.DeviceDescription.properties.configuration.description"
          :displayName="$t('command_and_response.description')"
          propertyType="configuration"
          propertyName="description"
          featureName="DeviceDescription"
          :toolTip="$t('command_and_response.description_tooltip')"
        />
      </Group>

      <multi-group
        v-slot="data"
        title="Digital Outputs"
        featureNamePrefix="DigitalOutput"
        :thing="thing"
      >
        <Group :title="$t('command_and_response.digital_output') + ' ' + data.featureName">
          <text-item
            :value="thing.features[data.featureName].properties.configuration.description"
            :displayName="$t('command_and_response.description')"
            propertyType="configuration"
            propertyName="description"
            :featureName="data.feature.name"
            toolTip="Set a meaningful description for this DO."
          />
          <button-item
            :displayName="$t('command_and_response.on')"
            :featureName="data.feature.name"
            propertyType="operation"
            propertyName="on"
          />
          <button-item
            :displayName="$t('command_and_response.off')"
            :featureName="data.feature.name"
            propertyType="operation"
            propertyName="off"
          />
        </Group>
      </multi-group>
    </template>
  </div>
</template>

<script lang="ts">
  /*This code does not has to be touched when developing a new template except the ClassName*/
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'
  import MultiGroup from '@/components/site/scada/command_response/toolbox/base/MultiGroup.vue'

  /*REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      TextItem,
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
      MultiGroup,
    },
  })
  /*REPLACE 'TestId' WITH A FITTING COMPONENT NAME*/
  export default class DigitalOutputGroup extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style lang="scss" scoped>
  /*The edit component can be styled here*/
</style>
