import { render, staticRenderFns } from "./BaseItem.vue?vue&type=template&id=6a7853ba&scoped=true&"
import script from "./BaseItem.vue?vue&type=script&lang=ts&"
export * from "./BaseItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a7853ba",
  null
  
)

export default component.exports