export const recommendationsEn = {
  sidebar_title: 'Recommendations',
  choice: {
    accept: 'Accept',
    decline: 'Decline',
    error_while_loading:
      'Es gab einen Fehler während des Ladens. Bitte versuchen Sie es später nochmal oder wenden Sie sich an den Support.',
  },
  sendingModal: {
    title: 'Applying recommendations',
    success: 'All recommendations have been applied successfully.',
    error:
      'There was an error while applying the recommendations. Please try again later or contact support.',
    partialSuccess: 'Not all recommendations could be applied.',
  },
  apply_changes: 'Apply changes',
  no_recommendations: 'No recommendations',
  error_while_loading:
    'There was an error while loading. Please try again later or contact support.',
  table: {
    open: {
      thingName: 'Component',
      property: 'Setting',
      currentValue: 'Current value',
      targetValue: 'Recommended value',
      actions: 'Actions',
    },
    logged: {
      thingName: 'Component',
      property: 'Setting',
      previousValue: 'Previous Value',
      targetValue: 'Target Value',
      accepted: 'Accepted',
      manually: 'Manually Processed',
      username: 'Applied By',
      set_at: 'Applied At',
      proposed_at: 'Proposed At',
    },
  },
  tabs: {
    open: 'Open recommendations',
    logged: 'Applied Recommendations',
  },
  stats: {
    year_of_construction: 'Year of construction',
    energy_index: 'Primary energy consumption [kWh/m²a]',
    boiler_type: 'Boiler type',
    open_recommendations: 'Open recommendations',
    applied_recommendations: 'Applied recommendations',
  },
  disabled: 'Recommendations are deactivated for this site',
  autopilot: 'Auto pilot activated.',
  applyStatus: {
    unexpected_error: 'Unexpected Error',
    device_timeout: "Device didn't respond in time",
    device_error: 'Device reported error',
    success: 'Successfully applied',
    invalid_recommendation: 'Invalid recommendation',
  },
}
