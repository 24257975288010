import { config } from '@/config'
import AbortControllerProvider from '@/api/AbortControllerProvider'

export abstract class ApiClient {
  private abortControllerProvider: AbortControllerProvider | undefined

  protected constructor(private readonly tokenProvider: () => string) {}

  public getAbortControllerProvider(): AbortControllerProvider {
    if (this.abortControllerProvider === undefined) {
      this.abortControllerProvider = new AbortControllerProvider()
    }
    return this.abortControllerProvider
  }

  protected fillUrlParameters(
    searchParams: URLSearchParams,
    rawParameters: Record<string, unknown>,
  ): void {
    for (const key in rawParameters) {
      const value = rawParameters[key]
      if (value === undefined || value === null) {
        continue
      }
      if (Array.isArray(value)) {
        value.forEach((v) => searchParams.append(key, v))
      } else {
        searchParams.append(key, String(value))
      }
    }
  }

  protected async doRequest(
    endpoint: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    urlParameters?: Record<string, unknown>,
    body?: unknown,
  ): Promise<Response> {
    const url = new URL(endpoint, config.api.klempner.url)
    if (urlParameters !== undefined) {
      this.fillUrlParameters(url.searchParams, urlParameters)
    }
    const response = await fetch(url, {
      signal: this.abortControllerProvider?.getAbortController().signal,
      headers: {
        authorization: 'Bearer ' + this.tokenProvider(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: method,
      cache: 'reload',
      body: body === undefined ? undefined : JSON.stringify(body),
    })
    if (response.status >= 400) {
      throw new Error(`Fetch failed. ${await response.text()}`)
    }
    return response
  }
}
