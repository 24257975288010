import { ApiClient } from '@/api/klempner/apiClients/ApiClient'
import type { LoggedRecommendation, OpenRecommendation } from '@/components/recommendations/dtos'
import type {
  RecommendationChangeRequest,
  RecommendationChangeResponse,
} from '@/api/klempner/models/RecommendationChange'

export class RecommendationApiClient extends ApiClient {
  constructor(tokenProvider: () => string) {
    super(tokenProvider)
  }

  public async listOpen(siteId: string): Promise<OpenRecommendation[]> {
    const request = await this.doRequest(`site/${siteId}/recommendations/open`, 'GET')
    return await request.json()
  }

  public async listLogged(siteId: string): Promise<LoggedRecommendation[]> {
    const request = await this.doRequest(`site/${siteId}/recommendations/logged`, 'GET')
    return await request.json()
  }

  public async applyRecommendations(
    requests: RecommendationChangeRequest[],
  ): Promise<RecommendationChangeResponse[]> {
    const request = await this.doRequest(`recommendations/apply`, 'POST', {}, requests)
    return await request.json()
  }
}
