<template>
  <!--This Template shows a dialog with minimal configuration-->
  <div>
    <template v-if="dataLoaded">
      <!-- Needs to be here, is shown when  -->
      <SubmitOverlay v-if="submitting" />

      <Title>{{ $t('command_and_response.digital_output') }} {{ featureId }}</Title>
      <Info>{{ thing.features.DeviceDescription.properties.configuration.description }}</Info>
      <Group :title="$t('command_and_response.configuration')">
        <text-item
          :value="thing.features[featureId].properties.configuration.description"
          :displayName="$t('command_and_response.description')"
          propertyType="configuration"
          propertyName="description"
          :featureName="featureId"
          toolTip="$t('command_and_response.description_tooltip')"
        />
        <toggle-item
          :value="thing.features[featureId].properties.status.active"
          :displayName="$t('command_and_response.active')"
          :featureName="featureId"
          propertyType="configuration"
          propertyName="active"
        />
      </Group>
      <Group :title="$t('command_and_response.operations')">
        <button-item
          :displayName="$t('command_and_response.on')"
          :featureName="featureId"
          propertyType="operation"
          propertyName="on"
        />
        <button-item
          :displayName="$t('command_and_response.off')"
          :featureName="featureId"
          propertyType="operation"
          propertyName="off"
        />
      </Group>
    </template>
  </div>
</template>

<script lang="ts">
  /*This code does not has to be touched when developing a new template except the ClassName*/
  import { Component } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import ButtonItem from '@/components/site/scada/command_response/toolbox/ButtonItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import Group from '@/components/site/scada/command_response/toolbox/base/Group.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Info from '@/components/site/scada/command_response/toolbox/base/Info.vue'
  import Title from '@/components/site/scada/command_response/toolbox/base/Title.vue'
  import TextItem from '@/components/site/scada/command_response/toolbox/TextItem.vue'
  import MultiGroup from '@/components/site/scada/command_response/toolbox/base/MultiGroup.vue'

  /*REMEMBER TO REFERENCE THE COMPONENT IN THE templates.ts FILE */
  @Component({
    components: {
      TextItem,
      SubmitOverlay,
      Divider,
      Group,
      Heading,
      Info,
      Title,
      ButtonItem,
      EnumItem,
      ToggleItem,
      NumericValueItem,
      MultiGroup,
    },
  })
  /*REPLACE 'TestId' WITH A FITTING COMPONENT NAME*/
  export default class DigitalOutput extends mixins(BaseTemplate) {
    /**
     * Custom logic for the edit component can be implemented here
     */
  }
</script>

<style lang="scss" scoped>
  /*The edit component can be styled here*/
</style>
