export const meterTranslationsEn = {
  meter: 'Meter:::Meters',
  history: 'History',
  deadline: 'Deadline',
  heat: 'Heat',
  gas: 'Gas',
  electricity: 'Electricity',
  water: 'Water',
  cold: 'Cold',
  point_in_time: 'Time',
  name: 'Name',
  show_history: 'Show history',
  show_analytics: 'Go to analytics',
  show_trends: 'Go to trends',
  select_meter: 'Select meter',
  //Heat/cold/water meters
  work: 'Work',
  volume: 'Volume',
  //Gas meters
  standard_volume: 'Standard Volume',
  operating_volume: 'Operating Volume',
  // Electricity meters
  supply_total: 'Supply total',
  supply_t1: 'Supply T1',
  supply_t2: 'Supply T2',
  feed_total: 'Feed Total',
  feed_t1: 'Feed T1',
  feed_t2: 'Feed T2',
  // Errors & toasts
  no_meters_of_category: 'No meters in this category',
  error_loading_meters: 'Error loading meters of type {category}: {error}',
  warning_high_diff: 'Time differs for some values',
  error_getting_meters: '{count} meter categories could not be loaded',
  no_data_for_meter: 'No data found for this device',
  resolution: 'Resolution',
  download_csv: 'Download CSV',
  no_meter_selected: 'No meter selected',
  error_loading_meter_history: 'Could not load meter history: {error}',
  error_loading_meter_values: 'Could not load meter values: {error}',
}
