<template>
  <p v-if="error != null" class="text-danger">{{ error }}</p>
</template>

<script lang="ts">
  import { Component, Inject, Prop, ProvideReactive, Vue } from 'vue-property-decorator'
  import ToastMixin from '@/modules/tools/ToastMixin'
  import { mixins } from 'vue-class-component'
  import events from '@/events'
  import Logger from '@/logger'
  import { Thing } from '@/api/klempner/models/SiteListResponse'
  import ApiMixin from '@/modules/tools/ApiMixin'

  /**
   * Provides the base functionality for every template
   */
  @Component
  export default class BaseTemplate extends mixins(ToastMixin, ApiMixin) {
    @Inject()
    submitEventBus!: Vue

    dataLoaded = false

    error: string | null = null

    @Prop({ type: String, required: true })
    readonly thingId!: string

    @Prop({ required: false, default: '' })
    readonly featureId!: string

    @ProvideReactive()
    protected thing!: Thing

    /**
     * Counts how many child elements of this template are currently sending data
     */
    private elementsSubmitting = 0

    /**
     * Whether all submissions have been successful
     */
    private allSuccessful = true

    constructor() {
      super()
    }

    /**
     * Indicates that the template is currently being submitted
     */
    get submitting() {
      return this.elementsSubmitting > 0
    }

    async created() {
      await this.refreshThingFromDitto()
      this.dataLoaded = true
      this.$root.$on('ditto-msg-sent', this.refreshThingFromDitto)
      setInterval(() => this.refreshThingFromDitto(), 1000)
      this.submitEventBus.$on(events.scada.templates.submit, this.submitTemplate)
      this.submitEventBus.$on(events.scada.templates.submitStarted, this.submitStarted)
      this.submitEventBus.$on(events.scada.templates.submitEnd, this.submitEnd)
    }

    mounted() {
      this.submitEventBus.$emit(events.scada.templates.mounted)
    }

    submitTemplate() {
      this.allSuccessful = true
    }

    submitStarted() {
      this.elementsSubmitting++
    }

    submitEnd(success: boolean) {
      this.elementsSubmitting--
      this.allSuccessful = this.allSuccessful && success
      if (this.elementsSubmitting == 0) {
        this.$emit('submit-finished', this.allSuccessful)
      }
    }

    beforeDestroy() {
      this.submitEventBus.$off(events.scada.templates.submit, this.submitTemplate)
      this.submitEventBus.$off(events.scada.templates.submitStarted, this.submitStarted)
      this.submitEventBus.$off(events.scada.templates.submitEnd, this.submitEnd)
    }

    async refreshThingFromDitto() {
      try {
        this.$set(this, 'thing', await this.getThingApi().fetchById(this.thingId))
        this.error = null
      } catch (e) {
        this.error = this.$t('template.error.loading_thing', { error: e.message ?? e }) ?? e.message
        Logger.error('Error getting thing for this template', e)
      }
    }

    /**
     * Gets all ToolBox Child Components recursively
     * Returns Enum. Numeric and Toggle Items
     */
    getChildren(component: Vue): Vue[] {
      return component.$children.flatMap((component) => {
        const name = component.$options.name
        if (
          name === 'EnumItem' ||
          name === 'NumericValueItem' ||
          name === 'TextItem' ||
          name === 'ToggleItem'
        ) {
          return component
        } else if (name === 'GroupItem' || name === 'MultiGroup') {
          return this.getChildren(component)
        } else {
          return []
        }
      })
    }
  }
</script>

<style lang="scss" scoped></style>
