import { Component, Vue } from 'vue-property-decorator'
import type { Moment } from 'moment'
import moment, { duration } from 'moment'

@Component
export default class TimeMixin extends Vue {
  get locale() {
    return this.$i18n.locale() ?? 'de-DE'
  }

  /**
   * Accepts a date string which is assumed to be in UTC if ambiguous
   * @param date datetime string
   * @return local date as a string, localized using current i18n locale
   */
  utcToLocalDateString(date: string): string {
    return moment.utc(date).locale(this.locale).local().format('L')
  }

  /**
   * Accepts a date string which is assumed to be in UTC if ambiguous
   * @param date datetime string
   * @return local time as a string, localized using current i18n locale
   */
  utcToLocalTimeString(date: string): string {
    return moment.utc(date).locale(this.locale).local().format('LTS')
  }

  /**
   * Formats a moment instance as a localized date string
   * @param date The date to format
   */
  toLocalDateString(date: Moment) {
    return date.locale(this.locale).format('L')
  }

  toLocalDateTimeString(date: Moment) {
    return date.locale(this.locale).format('L LTS')
  }

  /**
   * Accepts a date string that is assumed to be in UTC and returns a local & localized time
   * @param date
   */
  utcToLocalDateTimeString(date: string): string {
    return moment.utc(date).local().locale(this.locale).format('L LTS')
  }

  /**
   * Accepts a date string that is assumed to be in UTC and returns the time since that date.
   * If the timespan is greater than 2 weeks than the localized date is returned
   * @param date
   */
  timeSince(date: string): string {
    const time = moment.utc(date).local().locale(this.locale)
    if (moment().diff(time, 'weeks') > 1) {
      return time.format('L')
    } else {
      return time.fromNow()
    }
  }

  /**
   * Converts the given duration ins seconds to a string
   * @param durationInSeconds
   */
  durationToString(durationInSeconds: number): string {
    if (durationInSeconds < 0) {
      return '-'
    }
    const d = duration(durationInSeconds, 'seconds')
    let durationString = ''
    if (d.years() > 0) {
      durationString += d.years() + 'y '
    }
    if (d.months() > 0) {
      durationString += d.months() + 'm '
    }
    if (d.weeks() > 0) {
      durationString += d.weeks() + 'w '
    }
    if (d.days() > 0) {
      durationString += d.days() + 'd '
    }
    if (d.hours() > 0) {
      durationString += d.hours() + 'h '
    }
    if (d.minutes() > 0) {
      durationString += d.minutes() + 'm '
    }

    durationString += d.seconds() + 's'
    return durationString
  }
}
